export default {
  dashboard: 'Dashboard',
  products: 'Products',
  product: 'Product',
  offers: 'Offers',
  offers_all: 'All',
  offers_available: 'Available',
  statistics: 'Statistics',
  customers: 'Customers',
  promo: 'Promotional materials',
  settings: 'Settings',
  payments: 'Payments',
  admins: 'Admins',
  advertisers: 'Advertisers',
  partners: 'Partners',
  wallet: 'Wallet',
  your_manager: 'Your manager',
  support: 'Support',
  referrals: 'Referrals',
  members: 'Members',
  api_doc: 'API documentation',
};
