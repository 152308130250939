import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const PaymentSystem = {
  GET_PAYMENT_SYSTEMS: {
    url: generatePartnerApiUrl(ModelPath.PaymentSystem),
    method: 'GET',
  },
} as const;

export default PaymentSystem;
