import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Currency = {
  GET_LIST: {
    url: generateAdminApiUrl('', ModelPath.Currency),
    method: 'GET',
  },
  GET_ENABLED_LIST: {
    url: generateAdminApiUrl('enabled', ModelPath.Currency),
    method: 'GET',
  },
  ENABLE_CURRENCY: {
    url: generateAdminApiUrl(':currency_code/enable', ModelPath.Currency),
    method: 'PATCH',
  },
  DISABLE_CURRENCY: {
    url: generateAdminApiUrl(':currency_code/disable', ModelPath.Currency),
    method: 'PATCH',
  },
  CREATE: {
    url: generateAdminApiUrl(ModelPath.Currency),
    method: 'POST',
  },
  UPDATE: {
    url: generateAdminApiUrl(':currency_code', ModelPath.Currency),
    method: 'PATCH',
  },
};

export default Currency;
