import Profile from './Profile';
import Statistics from './Statistics';
import Dict from './Dict';
import Product from './Product';
import Offer from './Offer';
import Contact from './Contact';
import Security from './Security';
import Settings from './Settings';

const Api = {
  Profile,
  Statistics,
  Dict,
  Product,
  Offer,
  Contact,
  Security,
  Settings,
};

export default Api;
