export default {
  add_tracking_domain: 'Añadir un dominio de seguimiento',
  no_tracking_domains: 'No se han creado dominios de seguimiento',
  name: 'Dominio de seguimiento',
  protocol: 'Protocolo',
  default_tracking_domain: 'Dominio predeterminado',
  status: 'Estado',
  status_checked: 'Aprobado la verificación',
  status_not_checked: 'Falló la verificación',
  verify: 'Comprobar',
  tracking_domain_successfully_created: 'Dominio añadido correctamente',
  tracking_domain_successfully_updated: 'Dominio actualizado con éxito',
  tracking_domain_adding: 'Agregar un dominio',
  tracking_domain_editing: 'Editar dominio',
  tracking_domain_instructions_do:
    'En la configuración de los registros DNS del dominio, agregue el registro CNAME:',
  tracking_domain_instructions_after:
    'Después de que los registros CNAME se actualicen, haga clic en el botón "Verificar" y el dominio estará listo para el uso con las ofertas',
  tracking_domain_name: 'Nombre de dominio',
  tracking_domain_name_placeholder: 'Ingrese un nombre de dominio',
  tracking_domain_remove_confirm: '¿Desea eliminar un dominio?',
  tracking_domain_verify_pass: 'Dominio validado con éxito',
  tracking_domain_verify_not_pass: 'Dominio no validado',
  tracking_domain_changed_default: 'Dominio predeterminado cambiado',
  tracking_domain_replace: 'Reemplazar dominios por enlaces de referencia',
  tracking_domain_replace_in_offers: 'Sustitución de dominio en ofertas',
  tracking_domain_replaced_in_offers:
    'El dominio de rastreo en las ofertas ha sido reemplazado con éxito',
  tracking_domain_replace_offers: 'Ofertas',
  tracking_domain_replace_domain: 'Dominio de rastreo',
  tracking_domain_replace_domain_tooltip: 'No hay dominios verificados',
  tracking_domain_replace_in_offers_help:
    'Para las ofertas seleccionadas en el campo "Ofertas", el dominio de seguimiento actual será reemplazado por el dominio especificado en el campo "Dominio de seguimiento"',
  tracking_domain_target: 'Reemplazar dominios en enlaces de destino',
  tracking_domain_target_offers: 'Ofertas',
  tracking_domain_target_old_domain: 'Antiguo dominio',
  tracking_domain_target_new_domain: 'Nuevo dominio',
  tracking_domain_target_new_domain_hint:
    'Puede especificar un dominio de cualquier nivel. Todo el dominio del enlace será reemplazado por completo.',
  tracking_domain_target_domain_placeholder:
    'Introduzca el formato del dominio: ejemplo.com',
  tracking_domain_target_replace_in: 'Reemplazar en',
  tracking_domain_target_link: 'Enlace de destino de oferta',
  tracking_domain_target_trafficback: 'URL de Trafficback de oferta',
  tracking_domain_target_landings: 'Landings',
  tracking_domain_target_landings_all:
    'Landings pertenecientes al producto y oferta',
  tracking_domain_target_landings_offers: 'Landings pertenecientes a la oferta',
  tracking_domain_target_landings_help: `Seleccione las landings donde desea reemplazar el dominio
    <ul>
      <li>
        Al elegir <strong>$t(trackingDomains:tracking_domain_target_landings_all)</strong> el dominio será sustituido en las páginas web pertenecientes a los productos de los proveedores seleccionados, así como en las páginas web pertenecientes a los proveedores seleccionados.
      </li>
      <li>
        Al elegir <strong>$t(trackingDomains:tracking_domain_target_landings_offers)</strong> se sustituirán los dominios de las páginas web vinculadas al producto, que están vinculadas únicamente a la oferta. Los dominios de las páginas web relacionadas con productos disponibles en las ofertas seleccionadas permanecerán sin cambios.
      </li>
    </ul>
    `,
  tracking_domain_target_replace_confirm:
    '¿Puedes encontrar una casa en el destino?',
  tracking_domain_target_replace_confirm_hint:
    'Después del reemplazo, verifique que el dominio de destino haya sido reemplazado correctamente y que los enlaces de referencia funcionen correctamente. Los cambios se pueden cancelar dentro de los 7 minutos en esta página.',
  tracking_domain_target_report_successful: 'Reemplazos exitosos',
  tracking_domain_target_report_unsuccessful: 'Fracasado',
  tracking_domain_target_report_ids:
    'ID de ofertas en las que no se pudo cambiar el dominio de destino',
  tracking_domain_target_cancel: 'Cancelar reemplazo de destino de enlace',
  tracking_domain_target_cancel_confirm:
    '¿Estás seguro de que quieres cancelar la acción?',
  tracking_domain_target_report: 'Informe',
};
