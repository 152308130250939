export default {
  'admin.list': 'Просмотр списка администраторов',
  'admin.create': '	Создание администратора',
  'admin.enable': 'Включение администратора',
  'admin.disable': 'Отключение администратора',
  'admin.update': 'Редактирование администратора',
  'admin.roles': 'Роли администратора',
  'admin.own_partners_show': 'Доступ только к своим партнерам',
  'advertiser.list': 'Просмотр списка рекламодателей',
  'advertiser.approve': 'Одобрение рекламодателя',
  'advertiser.reject': 'Отклонение рекламодателя',
  'advertiser.create': 'Создание рекламодателя',
  'advertiser.show': 'Просмотр рекламодателя',
  'advertiser.update': 'Редактирование рекламодателя',
  'advertiser.ban': 'Бан рекламодателя',
  'advertiser.unban': 'Разбан рекламодателя',
  'news.all': 'Работа с новостями',
  'export.all': 'Экспорт данных',
  'partner.list': 'Просмотр списка партнеров',
  'partner.approve': 'Одобрение партнера',
  'partner.reject': 'Отклонение партнера',
  'partner.create': 'Создание партнера',
  'partner.show': 'Просмотр партнера',
  'partner.update': 'Редактирование партнера',
  'partner.ban': 'Бан партнера',
  'partner.unban': 'Разбан партнера',
  'partner.auth': 'Авторизация под партнером',
  'partner.wallet_add': 'Добавления способа оплаты партнёру',
  'partner.wallet_edit': 'Изменение способа оплаты партнёра',
  'partner.wallet_delete': 'Удаление способа оплаты партнёра',
  'partner.wallet_set_default':
    'Установление способа оплаты по умолчанию партнёру',
  'partner.change_password': 'Доступ к изменению пароля партнера',
  'product.list': 'Просмотр списка продуктов',
  'product.create': 'Создание продукта',
  'product.show': 'Просмотр продукта',
  'product.update': 'Редактирование продукта',
  'product.enable': 'Включение продукта',
  'product.disable': 'Отключение продукта',
  'product.create_events': 'Создание события',
  'product.update_events': 'Редактирование события',
  'product.delete_events': 'Удаление события',
  'product.create_landings': 'Создание лендинга',
  'product.update_landings': 'Редактирование лендинга',
  'product.delete_landings': 'Удаление лендинга',
  'product.create_goals': 'Создание цели',
  'product.update_goals': 'Редактирование цели',
  'product.delete_goals': 'Удаление цели',
  'product.offers_list': 'Просмотр списка офферов',
  'product.show_offer': 'Просмотр оффера',
  'product.create_offers': 'Создание оффера',
  'product.update_offers': 'Редактирование оффера',
  'product.delete_offers': 'Удаление оффера',
  'statistics.show': 'Просмотр статистики',
  'statistics.cancel_conversions': 'Отмена конверсий',
  'statistics.edit': 'Редактирование статистики',
  'statistics.change_qualification': 'Изменение конверсии',
  'statistics.change_conversion': 'Изменение конверсии',
  'payments.list': '	Просмотр списка заявок',
  'payments.approve': 'Подтверждение заявки',
  'payments.reject': 'Отклонение заявки',
  'settings.common': 'Доступ к основным настройкам',
  'settings.moderation': 'Доступ к настройкам модерации',
  'settings.contacts': 'Доступ к настройкам контактов',
  'settings.tracking_domains': 'Доступ к настройкам трекинг-доменов',
  'settings.main_domains': 'Доступ к настройкам основных доменов',
  'settings.conditions_hide_to_partner':
    'Доступ к настройке скрытия обычных условий у партнера',
  'settings.categories': 'Доступ к настройкам категорий',
  'settings.tags': 'Доступ к настройкам меток',
  'settings.partner_tags': 'Доступ к настройкам меток партнеров',
  'settings.referral': 'Доступ к настройкам реферальной программы',
  'settings.email_send':
    'Доступ к настройкам отправки письма для подтверждения почты при самостоятельной регистрации',
  'settings.currency': 'Таблица валют',
  'settings.currency_edit': 'Редактирование валюты',
  'settings.payment_systems': 'Доступ к настройке платежных систем',
  'settings.default_manager': 'Доступ к настройке менеджера по умолчанию',
  'settings.auto_invoice': 'Доступ к настройке автогенерации счетов',
  'documents.terms_of_use': 'Работа с правовыми документами',
  'role.create': 'Создание роли',
  'role.delete': 'Удаление роли',
  'role.update': 'Редактирование роли',
  'role.list': 'Просмотр списка ролей',
  'role.show': 'Просмотр роли',
  'promo.list': 'Просмотр списка промоматериалов',
  'promo.create': 'Создание промоматериала',
  'promo.update': 'Изменение промоматериала',
  'promo.delete': 'Удаление промоматериала',
  'promo.show': 'Просмотр промоматериала',
  'promocode.all': 'Работа с промокодами',
  'invoices.all': 'Доступ к функционалу работы со счетами на вывод средств',
  'revshare.all':
    'Работа с ревшарой (добавление, редактирование и удаление формул в продукте)',
  'logs.list': 'Доступ к логам',
  'dashboard.all': 'Работа с дашбордами',
  business_and_above: 'Доступно на тарифах Pro и выше',
  admin: 'Администратор',
  advertiser: 'Рекламодатель',
  news: 'Новости',
  partner: 'Партнер',
  product: 'Продукт',
  statistics: 'Статистика',
  payments: 'Заявки',
  settings: 'Настройки',
  dashboard: 'Дашборд',
  role: 'Роли',
  promo: 'Промоматериалы',
  export: 'Экспорт',
  event_group: 'Группы событий',
  promocode: 'Промокоды',
  documents: 'Документы',
  invoices: 'Счета',
  revshare: 'Ревашара',
  logs: 'Логи',
};
