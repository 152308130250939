export default {
  offers: 'Offers',
  offer: 'Offer',
  new_offer: 'New offer',
  edit_offer: 'Edit offer',
  edit: 'Edit',
  general_data: 'General data',
  offer_name: 'Offer name',
  status_name: 'Status name',
  product: 'Product',
  product_hint: 'Product this offer creates for',
  unlimited: 'Unlimited',
  use_hold_period: 'Hold period',
  percent: 'Percent',
  fixed: 'Fixed',
  days: 'days',
  to_days: 'days',
  offer_successfully_created: 'Offer successfully created',
  offer_successfully_updated: 'Offer successfully updated',
  select_product_from_the_list: 'Select product from the list',
  set_limit_of_each_condition_performance_per_day_the_minimum_value_is_10_the_maximum_values_is_1_000_000_you_can_also_set_unlimited_value_by_checkbox:
    'Set limit of each condition performance per day. The minimum value is 10, the maximum values is 1 000 000. You can also set unlimited value.',
  set_day_count_to_hold_period_the_minimum_values_is_0_days_the_maximum_values_is_60_days:
    'Set day count to hold period. The minimum values is 0 days, the maximum values is 60 days.',
  no_offers: 'No offers',
  no_active_offers: 'No active offers',
  no_active_goals: 'No active goals.',
  no_goals_groups: 'No goals groups',
  please_create_goal: 'Please create goal.',
  table_name: 'Offer',
  table_product: 'Product',
  table_category: 'Category',
  table_advertiser: 'Advertiser',
  table_status: 'Status',
  table_privacy: 'Privacy',
  table_hold: 'Hold',
  table_creation_date: 'Creation date',
  status_active: 'Active',
  status_inactive: 'Inactive',
  event: 'Event',
  range: 'Range',
  model: 'Model',
  values: 'Values',
  name_of_goal: 'Name of Goal',
  id_of_goal: 'ID of Goal',
  id: 'ID',
  from_3_to_30_Latin_letters_and_symbols:
    'From 3 to 30 Latin letters, digits and symbols',
  condition: 'Condition',
  individual: 'Individual',
  individual_conditions: 'Individual rewards',
  no_goals: 'No goals',
  no_formulas: 'No formulas',
  conditions: 'Rewards',
  range_help:
    'Set the range of values to count goal performances in [X,Y] format. X and Y values must be positive numbers. Also you can set value as infinity by use symbol @. For example: [10, @], [5, 15], @',
  value_help:
    'Specify a range of values in the format [X, Y] that defines the amount of the event to be committed for this goal. X and Y must be numbers. You can also use the @ sign, which stands for infinity. For example [10,@], [5,15], @. Also, X can be equal to -@, for example [-@, 100], [-@, @].',
  model_help:
    'Indicate the counting model:\n' +
    'Sum - values from product postbacks will be summed up and the sum will be compared with the range in the field Values\n' +
    'Between - the value from the product postback will be compared with the range in the field Values',
  basic: 'Basic',
  goals: 'Goals',
  goal: 'Goal',
  conversion_statuses: 'Conversion statuses',
  benefit: 'Benefit',
  countries: 'Countries',
  cities: 'Cities',
  operating_systems: 'Operating systems',
  operating_systems_short: 'OS',
  device_types: 'Device types',
  device_types_short: 'Device',
  type: 'Type',
  benefit_type: 'Benefit type',
  value: 'Value',
  cannot_delete_active_offer: 'Can not delete active offer',
  qualification_mode: 'Qualification mode',
  qualification_mode_help:
    'Qualification is a conversion that is created only when all the goals specified in this block are met.',
  qualification_type_help:
    'A conversion is created only when all the goals specified in this block are met.',
  add_first_condition: 'Add a first condition for this offer',
  add_first_individual_condition:
    'Add a first individual condition for this offer',
  frequency_of_payments: 'Frequency of payments',
  add_goal: 'Add goal',
  add_goals: 'Add goals',
  edit_goal: 'Edit goal',
  add_landing: 'Add landing',
  offer_tracking_target_link: 'Target link',
  offer_tracking_target_link_hint:
    '{click_id} - unique click ID;\n{partner_id} - partner ID;\n{offer_id} - offer ID;\n{creative_id} - promo-material ID;\n{sub_id1..10} - SubId #1 - SubId #10.',
  offer_trafficback_url: 'Trafficback URL',
  offer_trafficback_url_hint:
    'If the traffic does not match the parameters, it will be redirected to the given URL. Macros are available in the link:\n\n{click_id} - unique click ID;\n{partner_id} - partner ID;\n{sub_id1} - sub_id #1, up to 10 sub_id can be added;\n{offer_id} - offer ID;\n{creative_id} - promo-material ID.',
  offer_tracking_tracking_domain: 'Tracking domain',
  condition_successfully_created: 'Condition successfully created',
  condition_successfully_updated: 'Condition successfully updated',
  conditions_successfully_updated: 'Conditions successfully updated',
  condition_successfully_deleted: 'Condition successfully deleted',
  an_error_occurred_while_deleting_a_condition:
    'An error occurred while deleting a condition',
  show_all_partners: 'Show all {{count}} partners',
  something_went_wrong: 'Something went wrong',
  form_product: 'Product',
  form_advertiser: 'Advertiser',
  form_category: 'Category',
  form_type: 'Type',
  form_day_limit: 'Day limit',
  form_hold_period: 'Hold period',
  form_postclick: 'Postclick',
  form_postclick_from: 'Count postclick from',
  form_target_link: 'Target link',
  my_referral_link: 'My referral link',
  partners: 'Partners',
  partner: 'Partner',
  add_partner: 'Add partner',
  add_condition: 'Add reward',
  add_individual_conditions: 'Add individual reward',
  edit_conditions: 'Edit Conditions',
  edit_individual_conditions: 'Edit Individual Conditions',
  day_limit: 'Day limit',
  no_limit: 'No limit',
  no_conditions_created: 'No conditions created',
  view_form_table_countries: 'Countries',
  view_form_table_day_limit: 'Day limit',
  view_form_table_hold: 'Hold',
  view_form_table_benefit: 'To partner',
  view_form_table_goals: 'Goals',
  view_form_table_name_id: 'Name / ID',
  view_form_table_event: 'Event',
  view_form_table_range: 'Range',
  view_form_table_model: 'Model',
  view_form_table_value: 'Value',
  choose_partners: 'Choose partners...',
  get_referral_link: 'Link generator',
  hold_period: 'Hold period',
  postbacks: 'Postbacks',
  rule: 'Rule',
  frequency: 'Frequency',
  referral_link: 'Referral link',
  copy_link: 'Copy link',
  get_qr_code: 'Get QR code',
  save_as_png: 'Save as PNG',
  save_as_svg: 'Save as SVG',
  landing: 'Landing',
  sub_id: 'Sub id',
  add_sub_id: 'Add Sub id',
  maximum_10_sub_id: 'Maximum 10 Sub_id',
  from_3_to_30_characters: 'From 3 to 30 characters',
  from_1_to_60_characters: 'From 1 to 60 characters',
  from_3_to_60_characters: 'From 3 to 60 characters',
  are_you_sure_you_want_to_delete_the_condition:
    'Are you sure you want to delete the condition?',
  are_you_sure_you_want_to_delete_the_goal:
    'Are you sure you want to delete the goal?',
  are_you_sure_you_want_to_delete_the_formula:
    'Are you sure you want to delete the formula?',
  goal_successfully_deleted: 'Goal successfully deleted',
  goal_successfully_created: 'Goal successfully created',
  goal_successfully_updated: 'Goal successfully updated',
  unable_delete_formula:
    'Unable to delete expression. Expression is used in condition.',
  formula_successfully_deleted: 'Formula successfully deleted',
  formula_successfully_updated: 'Formula successfully updated',
  enabled_offer_tooltip: 'Enable offer.',
  disable_offer_tooltip:
    'Disable offer. If offer is disabled, it will be unavailable for the partner.',
  more_countries: 'more countries',
  more_partners: 'more partners',
  tracking: 'Tracking',
  'Errors were made in creating the condition.':
    'Errors were made in creating the condition.',
  'Please correct them.': 'Please correct them.',
  hidden: 'Hidden',
  hidden_from: 'Hidden from',
  hide_condition_from_partner: 'From partner',
  hide_condition_from_advertiser: 'From advertiser',
  hide_condition_partner: 'Partner',
  hide_condition_advertiser: 'Advertiser',
  show_to_partner: 'Show to partner',
  show_to_advertiser: 'Show to advertiser',
  show_individual_tag: 'Show label Individual',
  hide_condition_in_offer:
    'Hide a condition from a partner or advertiser. If you hide a condition, it will not be displayed in the list of offer conditions for a partner or advertiser. Also, due to a hidden condition, conversions and qualifications in statistics will not be displayed for a partner or advertiser.',
  do_you_want_to_delete: 'Do you want to delete',
  condition_is_hidden: 'condition\nis hidden',
  from_advertiser: 'From advertiser',
  to_partner: 'To partner',
  payout_value_help:
    'The amount from the advertiser by qualification. If the Payment Type is "Percentage", then the percentage of the qualification value is used.',
  reward_value_help:
    'The amount you pay to the partner. If the Payment Type is "Percentage", then the percentage of the qualification value is used. By default, the payout is zero.',
  public: 'Public',
  private: 'Private',
  premoderate: 'Pre-moderation',
  available: 'Available',
  hide_unavailable: 'Hide unavailable',
  tags: 'Tags',
  partners_for_private_offer: 'Partners for private offer',
  choosed: 'Choosed',
  no_partners_selected: 'No partners selected',
  partners_successfully_saved: 'Partners successfully saved',
  all_countries: 'All countries',
  postback_successfully_updated: 'Postback successfully updated',
  add_postback: 'Add postback',
  edit_postback: 'Edit postback',
  postback_URL: 'Postback URL',
  max_1024_symbols: 'Max 1024 symbols',
  conversion: 'Conversion',
  qualification: 'Qualification',
  conversion_name: 'Conversion name',
  the_following_data_will_be_sent: 'The following data will be sent:',
  postback_url_help_conversion:
    '{status} - conversion status;\n{goal} - conversion goal key;\n{revenue} - amount of revenue;\n{currency} - revenue currency;\n{promocode} - promocode;\n{country} - country;\n{sub_id1..sub_id10} - SubId #1 - SubId #10 parameters;\n{id} - conversion identifier;',
  revshare_postback_url_help: `{id} - conversion ID;
{revenue} - partner revenue by Revshare;
{currency} - revenue currency;
{sub_id1..sub_id10} - SubId #1 - SubId #10 (sub_id is substituted from the click);`,
  postback_url_help_value: `{value} - conversion value;
{value_currency} - conversion value currency;`,
  revshare_postback_url_help_value: `{value} - formula calculation result (income);
{value_currency} - currency from condition with formula;`,
  global_postback_url_help: `{status} - conversion status;
{goal} - goal key;
{offer_id} - offer ID;
{revenue} - amount of revenue;
{currency} - revenue currency;
{sub_id1..sub_id10} - SubId #1 - SubId #10 parameters;
{id} - conversion ID;
{country} - country;
{landing_id} - landing ID;
{click_date} - click date and time in 'YYYY.MM.DD HH:mm:ss';
{conversion_date} - conversion date and time in 'YYYY.MM.DD HH:mm:ss';
{ip} - click IP address;
{promocode} - promo code;`,
  global_admin_postback_url_help: `{status} - conversion status;
{goal} - conversion goal group key;
{advertiser_id} - advertiser ID;
{partner_id} - partner ID;
{offer_id} - offer ID;
{revenue} - amount of revenue;
{currency} - revenue currency;
{value} - amount of conversion;
{value_currency} - conversion currency;
{sub_id1..sub_id10} - SubId #1 - SubId #10 parameters;
{id} - conversion ID;
{country} - country;
{landing_id} - landing ID;
{click_date} - click date and time in 'YYYY.MM.DD HH:mm:ss';
{conversion_date} - conversion date and time in 'YYYY.MM.DD HH:mm:ss';
{ip} - click IP address;
{click_id} - click ID;
{tid} - transaction ID;
{promocode} -  promo code;
{useragent} - userAgent value;`,
  do_you_really_want_to_delete_postback:
    'Do you really want to delete postback?',
  an_error_occurred_while_deleting: 'An error occurred while deleting',
  postback_successfully_created: 'Postback successfully created',
  postback_successfully_deleted: 'Postback successfully deleted',
  no_postbacks: 'No postbacks',
  access_request_sent: 'Access request sent',
  access_request_denied: 'Access request denied',
  request_access: 'Request access',
  request_again: 'Request again',
  requests: 'Requests',
  save: 'Save',
  create: 'Create',
  description: 'Description',
  add_partners: 'Add partners',
  exclude_partners: 'Exclude partners',
  excluded_partners: 'Excluded partners',
  unique_goals: 'Unique goals',
  poster: 'Poster image',
  special_payout: 'Special payout',
  special_payouts: 'Special payouts',
  payouts: 'Payouts',
  revshare: 'Revshare',
  revshare_description:
    'Percentage payment from the net profit of the product (NGR, etc.)',
  cpm: 'CPM',
  cpm_description: 'Fixed payment for 1000 user actions',
  default_value: 'Default value',
  overridden: 'Overridden',
  goals_not_found: 'No goals created.',
  create_goal_in_product_form: 'Add a goal on',
  product_page: 'the product page',
  formulas_not_found: 'No formulas created.',
  add_new_goal: 'Add a new goal',
  create_formula_in_product_form: 'Add a formula on',
  can_not_edit_revshare_condition:
    'Unable to edit the condition, because revshare is disabled',
  cost_per_action: 'CPA',
  per_conversion: 'Per conversion',
  select_type: 'Select type',
  convert_to_qualification: 'Convert to qualification',
  convert_to_goal: 'Convert to simple goal',
  offer_form_special_payouts_one: '{{count}} special payout',
  offer_form_special_payouts_other: '{{count}} special payouts',
  special_payout_is_empty: 'Empty sub parameters',
  payout: 'Payout',
  payout_number: 'Special payout {{number}}',
  payout_from_advertiser: 'Payout from advertiser',
  payout_from_partner: 'Payout from partner',
  add_payout: 'Special Payout',
  the_amount_of_payments_can_be_specified_as_a_fractional_number:
    'The amount of payments can be specified as a fractional number.',
  name: 'Name',
  formula: 'Formula',
  formulas: 'Formulas',
  no_conditions_yet: 'There are no conditions yet.',
  add_new_condition: 'Add new',
  privacy_level: 'Privacy level',
  request_type: 'Request type',
  special_settings: 'Special settings',
  back_to_offer: 'Back to offer',
  no_active_conditions: 'No active conditions.',
  please_create_condition: 'Please create a common or individual condition.',
  payment_currency: 'Payment currency',
  payout_currency_from_advertiser: 'Payout currency from advertiser',
  partner_payout_currency: 'Partner payout currency',
  goal_by_event: 'Goal by event of the product',
  goal_by_event_help:
    'Add an event to the goal so that the affiliate network processes actions on its own.',
  goal_by_event_cpm_help:
    'If the goal participates in a condition with a CPM model, then an event cannot be specified for it.',
  field_macros_goal: 'The value will be assigned to the {goal} macro',
  field_macros_status: 'The value will be assigned to the {status} macro',
  postclick_help:
    'Postclick is the time (in days) during which the user is considered to be related to a partner.',
  group: 'Group',
  request_date: 'Request date',
  requests_for_access: 'Requests for access',
  offer_statistics: 'Offer statistics',
  duplicate_label: 'Duplicate offer',
  duplicate_form_title: 'Duplicate offer',
  duplicate_form_name_field: 'New offer name',
  duplicate_form_submit: 'Duplicate',
  to_duplicate: 'Duplicate',
  s2s_postback: 'S2S-postback',
  note: 'Internal note',
  card_note: 'Note',
  limit: 'Limit',
  limit_conversions: 'Conversions limit',
  limit_period: 'Period',
  limit_period_1: 'Per day',
  limit_period_2: 'Per week',
  limit_period_3: 'Per month',
  limit_period_4: 'Total',
  limit_period_label_1: '1 day',
  limit_period_label_2: '1 week',
  limit_period_label_3: '1 month',
  limit_period_label_4: 'Total',
  limit_action: 'Action in case of exceeding',
  limit_action_1: 'Reject conversion',
  limit_action_2: 'Change payment to partner',
  limit_action_3: 'Change advertiser income',
  limit_action_4: 'Change partner payment and advertiser income',
  partner_select_label: 'For partner',
  excluded_partners_label: 'Exclude partners',
  excluded_partners_placeholder: 'Partners for exclusion(ID)',
  need_to_add_partners: 'Need to add partners',
  referral_link_tooltip: 'Choose a partner from the list below',
  referral_link_tooltip_offer: 'Choose an offer from the list below',
  partner_placeholder: 'Enter id or Email for partner',
  offer_placeholder: 'Enter id or name of offer',
  landing_placeholder: 'Landing Title',
  goal_presets: 'Goal presets',
  trigger: 'Trigger',
  trigger_creating: 'When creating a conversion',
  trigger_status_change: 'When changing status ',
  trigger_payout_change: 'When changing the payout',
  is_use_in_app: 'Used for applications',
  is_use_in_app_hint:
    'A mark that this offer is intended for users of the application. When receiving a click on such an offer, a corresponding mark will be added to the click.',
  conditions_search_label: 'Partner ID',
  copy_offer: 'Copy offer',
  copy_offer_form_title: 'Copy offer',
  copy_offer_alert:
    'Will not be copied: Partner terms and conditions, lendings, promotional materials and postbacks',
  copy_form_submit: 'Copy',
};
