export default {
  'name of event': 'Name of Event',
  'create event': 'Create event',
  'edit event': 'Edit event',
  id: 'ID',
  key: 'Key',
  monetary: 'Monetary',
  monetary_event_hint:
    'Specifies whether the event is monetary.\nA money event is an event where a user spends or receives money.\nExamples of a monetary event: purchase, deposit, withdrawal, etc. \nExamples of non-monetary events: registration, passing a level in the game, attracting a user, etc.\nAfter creating an event you cannot change its monetary type.',
  'no events list': 'No events list',
  'from 3 to 30 characters': 'From 3 to 30 characters',
  'event successfully created': 'Event successfully created',
  'event successfully deleted': 'Event successfully deleted',
  'event successfully updated': 'Event successfully updated',
  'Are you sure you want to delete the event':
    'Are you sure you want to delete the event?',
  event_cannot_be_deleted:
    "Can't delete event, because it use in the goals of product",
  event_key_help:
    'Event identifier, which will come with postback in the {event} parameter. The value will be converted to lowercase.',
  enter_to_search_or_add: 'Enter to search or add new...',
  group: 'Group',
};
