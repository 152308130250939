import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const generalContacts = ModelPath.GeneralContact;
const adminsContacts = `${ModelPath.Admin}/${ModelPath.UserContact}`;
const partnersContacts = `${ModelPath.Partner}/${ModelPath.UserContact}`;
const advertiserContacts = `${ModelPath.Advertiser}/${ModelPath.UserContact}`;

const Contact = {
  GET_CONTACTS: {
    url: generateAdminApiUrl(adminsContacts),
    method: 'GET',
  },
  GET_GENERAL_CONTACTS: {
    url: generateAdminApiUrl(generalContacts),
    method: 'GET',
  },
  GET_PARTNER_CONTACTS: {
    url: generateAdminApiUrl(partnersContacts),
    method: 'GET',
  },
  GET_ADVERTISER_CONTACTS: {
    url: generateAdminApiUrl(advertiserContacts),
    method: 'GET',
  },
  CREATE_CONTACT: {
    url: generateAdminApiUrl(adminsContacts),
    method: 'POST',
  },
  CREATE_GENERAL_CONTACT: {
    url: generateAdminApiUrl(generalContacts),
    method: 'POST',
  },
  CREATE_PARTNER_CONTACT: {
    url: generateAdminApiUrl(partnersContacts),
    method: 'POST',
  },
  GET_CONTACT_BY_ID: {
    url: generateAdminApiUrl(':id', adminsContacts),
    method: 'GET',
  },
  GET_PARTNER_CONTACT_BY_ID: {
    url: generateAdminApiUrl(':id', partnersContacts),
    method: 'GET',
  },
  UPDATE_CONTACT_BY_ID: {
    url: generateAdminApiUrl(':id', adminsContacts),
    method: 'PATCH',
  },
  UPDATE_GENERAL_CONTACT_BY_ID: {
    url: generateAdminApiUrl(':id', generalContacts),
    method: 'PATCH',
  },
  UPDATE_PARTNER_CONTACT_BY_ID: {
    url: generateAdminApiUrl(':id', partnersContacts),
    method: 'PATCH',
  },
  DELETE_CONTACT_BY_ID: {
    url: generateAdminApiUrl(':id', adminsContacts),
    method: 'DELETE',
  },
  DELETE_GENERAL_CONTACT_BY_ID: {
    url: generateAdminApiUrl(':id', generalContacts),
    method: 'DELETE',
  },
  DELETE_PARTNER_CONTACT_BY_ID: {
    url: generateAdminApiUrl(':id', partnersContacts),
    method: 'DELETE',
  },
} as const;

export default Contact;
