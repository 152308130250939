export default {
  terms_of_use: 'Terms of Use',
  privacy_policy: 'Privacy Policy',
  privacy_policy_accusative_case: 'Privacy Policy',
  legal_documents_agreement: 'Agreement with legal documents',
  legal_documents_agreement_text: 'Changes have been made to ',
  legal_documents_agreement_text_accept:
    'To continue working with the system, you must read the new version of the document and confirm your agreement.',
  legal_documents_agreement_text_accept_plural:
    'To continue working with the system, you must read the new version of the documents and confirm your agreement.',
  legal_documents_agreement_agree: 'I accept',
};
