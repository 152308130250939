import { call, takeEvery, put, CallEffect } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import assign from 'lodash/assign';
import isString from 'lodash/isString';
import defaultTo from 'lodash/defaultTo';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import urlParse from 'url-parse';
import { generatePath } from 'react-router-dom';
import queryString from 'query-string';
import omit from 'lodash/omit';

import { setModel } from 'core/redux/actions/models';
import { FETCH } from 'core/redux/constants/fetch';
import request from 'utils/request';

import { IAction } from 'models/IAction';
import {
  IDataProvider,
  BodyMapping,
  BodyMappingKey,
  QueryMapping,
  QueryMappingKey,
} from 'models/IDataProvider';
import { IOptions } from 'models/IOptions';
import { getFlattenQueryMapping } from 'core/utils/fetchUtils';
import { getAccessToken } from 'utils/authUtils';
import schedulerApi from 'utils/SchedulerApi';
import { IApiDto } from 'models/IApiDto';

const JSON_HEADERS_WITH_TOKEN = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getAccessToken()}`,
};

function getBaseUrl(): string | Error {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_API_DEVELOPMENT_BASE_URL
  ) {
    return process.env.REACT_APP_API_DEVELOPMENT_BASE_URL;
  } else if (process.env.REACT_APP_API_BASE_URL) {
    return process.env.REACT_APP_API_BASE_URL;
  }

  throw new Error(
    `The process.env does not contain a ${
      process.env.NODE_ENV === 'development'
        ? 'REACT_APP_API_DEVELOPMENT_BASE_URL'
        : 'REACT_APP_API_BASE_URL'
    }`,
  );
}

async function compileUrl(
  dataProvider: IDataProvider,
  pathMapping = {},
  queryMapping = {},
): Promise<string> {
  const { pathname } = await schedulerApi.backgroundTask(
    function parsePageUrl() {
      return urlParse(dataProvider.url);
    },
  );
  const baseUrl = dataProvider.baseUrl || getBaseUrl();
  let compiledUrl = await schedulerApi.backgroundTask(function compileUrl() {
    return baseUrl + encodeURI(generatePath(pathname, pathMapping));
  });
  const filteredQueryMapping = await schedulerApi.backgroundTask(
    function resolveParams() {
      return resolveDataParam(queryMapping, dataProvider.queryMapping, true);
    },
  );

  if (!isEmpty(filteredQueryMapping)) {
    const queryPlainString = await schedulerApi.backgroundTask(
      function stringifyQuery() {
        return queryString.stringify(
          getFlattenQueryMapping(filteredQueryMapping),
          {
            skipEmptyString: true,
            arrayFormat: 'bracket',
          },
        );
      },
    );
    compiledUrl += await schedulerApi.backgroundTask(
      function decodeQueryString() {
        return decodeURI(`?${queryPlainString}`);
      },
    );
  }

  return compiledUrl;
}

function resolveDataParam(
  params: { [key: string]: any },
  mapping?: BodyMapping | QueryMapping,
  isQuery?: boolean,
) {
  if (!mapping) return params;

  const newBody = {};

  map(mapping, (mappingKey: BodyMappingKey | QueryMappingKey) => {
    const key = isString(mappingKey) ? mappingKey : mappingKey.key;
    const mappingValue = get(params, key);
    const required = isQuery
      ? !!get(mappingKey, 'required')
      : !get(mappingKey, 'optional');

    if (isNil(mappingValue) && required) {
      console.error(params);
      throw new Error(`Key "${key}" is required!`);
    }

    if (!isUndefined(mappingValue)) set(newBody, key, mappingValue);
  });

  return newBody;
}

export function* fetchSaga(dataProvider: IDataProvider, options: IOptions) {
  try {
    const compiledUrl: string = yield (yield schedulerApi.visibleTask(
      function getUrl() {
        return compileUrl(
          dataProvider,
          options.pathMapping,
          options.queryMapping,
        );
      },
    )) as Promise<string>;
    let fetchHeaders = defaultTo(dataProvider.headers, {});
    const token: string | null = yield schedulerApi.visibleTask(getAccessToken);
    if (token) {
      //it must be reread every time because it can be updated after authorization
      JSON_HEADERS_WITH_TOKEN.Authorization = `Bearer ${token}`;
      fetchHeaders = assign({}, JSON_HEADERS_WITH_TOKEN, fetchHeaders);
    }

    let body: Record<string, any> = get(options, 'body', null);

    if (body) {
      if (!(body instanceof FormData)) {
        body = yield schedulerApi.visibleTask(function stringifyJson() {
          return JSON.stringify(
            resolveDataParam(body, dataProvider.bodyMapping, false),
          );
        });
      } else {
        fetchHeaders = yield schedulerApi.visibleTask(
          function omitContentType() {
            return omit(fetchHeaders, 'Content-Type');
          },
        );
      }
    }

    const fetchOptions = {
      method: dataProvider.method,
      body: body,
      headers: fetchHeaders,
    };
    schedulerApi.yield();
    const response: IApiDto = yield (yield schedulerApi.backgroundTask(
      function sendRequest() {
        return call(request, compiledUrl, fetchOptions);
      },
    )) as CallEffect;
    return response;
  } catch (err) {
    throw err;
  }
}

export function* handleFetch({ payload }: IAction): Generator<any> {
  try {
    const response = yield yield schedulerApi.backgroundTask(
      function callFetch() {
        return call(fetchSaga, payload.dataProvider, payload.options);
      },
    );
    schedulerApi.yield();
    yield yield schedulerApi.backgroundTask(function setModelChanges() {
      return put(setModel(payload.modelId, response as IApiDto));
    });
  } catch (err) {
    console.error(err);
    yield yield schedulerApi.backgroundTask(function setModelChanges() {
      return put(setModel(payload.modelId, err as any));
    });
  }
}

export default [takeEvery(FETCH, handleFetch)];
