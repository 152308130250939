import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const PromoMaterials = {
  GET_PROMO_MATERIALS: {
    url: generatePartnerApiUrl('', ModelPath.PromoMaterial),
    method: 'GET',
  },
} as const;

export default PromoMaterials;
