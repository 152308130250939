import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { Location } from '@remix-run/router';

import { WidgetActionTypes } from 'core/redux/constants/WidgetActionTypes';
import {
  QueryAndFilterMapToURLAction,
  RegisterWidgetAction,
  RemoveFilterParamsAction,
  SetErrorAction,
  SetFilterMappingFromUrlDoneAction,
  SetFiltersAction,
  SetLoadingAction,
  SetMetaAction,
  UnregisterWidget,
  WidgetHandleAddInMeta,
  WidgetHandleDeleteInMeta,
  WidgetRequestAction,
} from './types';
import { IWidgetFetchOptions } from 'models/widget/IWidgetFetchOptions';
import { IDataProvider } from 'models/IDataProvider';
import { IMeta } from 'models/IMeta';

export function registerWidget(
  widgetId: string,
  navigate: NavigateFunction,
  location: Location,
  options = {},
): RegisterWidgetAction {
  return {
    type: WidgetActionTypes.RegisterWidget,
    payload: {
      widgetId,
      navigate,
      location,
      ...options,
    },
  };
}

export function unregisterWidget(widgetId: string): UnregisterWidget {
  return {
    type: WidgetActionTypes.UnregisterWidget,
    payload: { widgetId },
  };
}

export function handleAddInMeta(widgetId: string): WidgetHandleAddInMeta {
  return {
    type: WidgetActionTypes.HandleAddInMeta,
    payload: { widgetId },
  };
}

export function handleDeleteInMeta(widgetId: string): WidgetHandleDeleteInMeta {
  return {
    type: WidgetActionTypes.HandleDeleteInMeta,
    payload: { widgetId },
  };
}

export function widgetRequest(
  widgetId: string,
  dataProvider: IDataProvider,
  navigate: NavigateFunction,
  location: Location,
  options: IWidgetFetchOptions = {},
): WidgetRequestAction {
  return {
    type: WidgetActionTypes.RequestWidget,
    payload: {
      widgetId,
      dataProvider,
      navigate,
      location,
      options,
    },
  };
}

export function setError(widgetId: string, error: any): SetErrorAction {
  return {
    type: WidgetActionTypes.SetError,
    payload: {
      widgetId,
      error,
    },
  };
}

export function setLoading(
  widgetId: string,
  isLoading: boolean,
): SetLoadingAction {
  return {
    type: WidgetActionTypes.SetLoading,
    payload: {
      widgetId,
      isLoading,
    },
  };
}

export function setMeta(widgetId: string, meta: IMeta): SetMetaAction {
  return {
    type: WidgetActionTypes.SetMeta,
    payload: {
      widgetId,
      meta,
    },
  };
}

export function setFilters(
  widgetId: string,
  filter: Record<string, any>,
  merge?: boolean,
  removeUndefined = true,
  partialUpdate = false,
): SetFiltersAction {
  return {
    type: WidgetActionTypes.SetFilter,
    payload: {
      widgetId,
      filter,
      merge,
      removeUndefined,
      partialUpdate,
    },
  };
}

export function removeFilterParams(
  widgetId: string,
  params: string[],
): RemoveFilterParamsAction {
  return {
    type: WidgetActionTypes.RemoveFilterParams,
    payload: {
      widgetId,
      params,
    },
  };
}

export function setFilterMappingFromUrlDone(
  widgetId: string,
  isDone: boolean,
): SetFilterMappingFromUrlDoneAction {
  return {
    type: WidgetActionTypes.SetFilterMappingFromUrlDone,
    payload: {
      widgetId,
      isDone,
    },
  };
}

export function filterMapToURL(
  widgetId: string,
  navigate: NavigateFunction,
  location: Location,
): QueryAndFilterMapToURLAction {
  return {
    type: WidgetActionTypes.FilterMapToURL,
    payload: {
      widgetId,
      navigate,
      location,
    },
  };
}
