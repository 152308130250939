import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';

import IRoute from 'models/IRoute';
import { setTitle } from 'core/redux/actions/globals';

type PathParams = Record<string, any>;

export interface IPathContext {
  params?: PathParams;
  route?: IRoute;
  setParams?: (params: PathParams) => void;
  addParams?: (params: PathParams) => void;
  setPageTitle?: (title?: string) => void;
  onClick?: () => void;
  setOnClick?: (func: () => void, removeAfterUse: boolean) => void;
}
export const PathContext = React.createContext<IPathContext>({});

const PathContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [pathParams, setPathParams] = useState<PathParams>({});
  const [onClick, setOnClick] = useState<() => void>();

  const setParams = (params: PathParams) => {
    setPathParams(params || {});
  };

  const addParams = (params: PathParams) => {
    setPathParams(assign({}, pathParams, params));
  };

  const setFuncOnClick = (func: () => void, removeAfterUse: boolean) => {
    if (!removeAfterUse) {
      setOnClick(() => func);
    } else {
      setOnClick(() => () => {
        func?.();
        setOnClick(undefined);
      });
    }
  };

  const setPageTitle = useCallback(
    (title?: string) => {
      if (title && !isEmpty(title)) {
        dispatch(setTitle(title));
      }
    },
    [dispatch],
  );

  return (
    <PathContext.Provider
      value={{
        params: pathParams,
        setParams,
        addParams,
        onClick,
        setOnClick: setFuncOnClick,
        setPageTitle,
      }}
    >
      {children}
    </PathContext.Provider>
  );
};

export default PathContextProvider;
