import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import map from 'lodash/map';

import ICommonModalState from 'models/ICommonModalState';
import { IReduxState } from 'models/IReduxState';
import ConfirmModal from 'components/ConfirmModal';
import { deleteModal } from 'core/redux/reducers/modals';

interface ICommonModalsProps {}

const CommonModals: React.FC<ICommonModalsProps> = ({}): JSX.Element => {
  const dispatch = useDispatch<Dispatch>();
  const modals: ICommonModalState[] = useSelector(
    (state: IReduxState) => state.modals.modals,
    shallowEqual,
  );

  const handleClose = (id?: string) => () => {
    if (id) {
      dispatch(deleteModal({ id }));
    }
  };

  return (
    <div id="common-modals">
      {map(modals, (modal: ICommonModalState) => {
        const { content, id, ...options } = modal;
        const close = handleClose(id);
        return (
          <ConfirmModal
            key={id}
            show={true}
            onConfirm={close}
            onCancel={close}
            onBackdropClick={close}
            {...options}
          >
            {content}
          </ConfirmModal>
        );
      })}
    </div>
  );
};

export default CommonModals;
