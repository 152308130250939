import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Documents = {
  GET_LEGAL_DOCUMENTS_META: {
    url: generatePartnerApiUrl('terms_and_privacy', ModelPath.Documents),
    method: 'GET',
  },
  GET_TERMS_OF_USE: {
    url: generatePartnerApiUrl('terms', ModelPath.Documents),
    method: 'GET',
  },
  GET_PRIVACY_POLICY: {
    url: generatePartnerApiUrl('privacy', ModelPath.Documents),
    method: 'GET',
  },
  UPDATE_DOCUMENTS: {
    url: generatePartnerApiUrl(ModelPath.Documents),
    method: 'PATCH',
  },
  ACCEPT_DOCUMENTS: {
    url: generatePartnerApiUrl('/terms/accept', ModelPath.Documents),
    method: 'PATCH',
  },
} as const;

export default Documents;
