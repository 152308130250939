import { Middleware } from 'redux';
import { configureStore as configureReduxStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { batchDispatchMiddleware } from 'redux-batched-actions';

import rootSaga from './sagas';
import widgets from './reducers/widgets';
import models from './reducers/models';
import notifications from './reducers/notifications';
import dicts from './reducers/dicts';
import globals from './reducers/globals';
import offerPartners from './reducers/offerPartners';
import landings from './reducers/landings';
import modals from './reducers/modals';
import tables from './reducers/tables';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}) {
  const middleware: Array<Middleware> = [
    batchDispatchMiddleware,
    sagaMiddleware,
  ];

  const store = configureReduxStore({
    reducer: {
      widgets,
      models,
      notifications,
      dicts,
      globals,
      offerPartners,
      landings,
      modals,
      tables,
    } as any,
    preloadedState: initialState,
    middleware: middleware,
  });
  sagaMiddleware.run(rootSaga);

  return store;
}
