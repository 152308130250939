export default {
  'new product': 'Новый продукт',
  name: 'Название',
  offers: 'Офферы',
  category: 'Категория',
  note: 'Внутренняя заметка',
  table_note: 'Заметка',
  advertiser: 'Рекламодатель',
  status: 'Статус',
  created: 'Создано',
  'no products': 'Нет созданных продуктов',
  products: 'Продукты',
  products_list: 'Список продуктов',
  product: 'Продукт',
  monetary: 'Денежное',
  'no events list': 'Нет спика созданных событий',
  'no offers list': 'Нет списка созданных офферов',
  'name and id': 'Название и ID',
  privacy: 'Приватность',
  'creation date': 'Дата создания',
  events: 'События',
  edit: 'Редактировать',
  delete: 'Удалить',
  enabled: 'Активно',
  manager: 'Менеджер',
  'click lifetime': 'Время жизни клика',
  landings: 'Лендинги',
  target_link: 'Target-ссылка',
  'create new project': 'Создать новый продукт',
  'product name': 'Название продукта',
  description: 'Описание',
  'poster image (120 × 120)': 'Постер (рекомендуемое разрешение 120 × 120)',
  'create new product': 'Создать новый продукт',
  'edit product': 'Редактировать продукт',
  'product successfully created': 'Продукт успешно создан',
  'product successfully edited': 'Продукт успешно обновлен',
  hours: 'часов',
  new_landing: 'Новый лендинг',
  disable_product: 'Отключить продукт',
  are_you_sure_you_want_to_disable_the_product:
    'Вы уверены, что хотите отключить продукт?\nКогда вы отключите продукт, все его предложения будут отключены.',
  unlimited: 'Не ограничено',
  days: 'дней',
  goal_remove_conditions:
    'Нельзя удалять цель, пока она используется в условиях оффера.',
  goal_deleting: 'Удаление цели',
  goal_key_help:
    'Идентификатор цели, который будет приходить в постбеке в параметре {goal}. Значение будет приведено в нижний регистр.',
  formula_variable_amount_of: 'Сумма',
  formula_variable_goal_sum: '$t(product:formula_variable_amount_of) {{name}}',
  product_variables: 'Переменные продукта',
  coefficients: 'Коэффициенты',
  edit_formula: 'Редактировать формулу',
  add_formula: 'Новая формула',
  add_coefficient: 'Добавить коэффициент',
  formula_form_name_help:
    'Наименование формулы, которое будет отображаться при работе с условиями оффера',
  formula_form_expression_help: 'Формула для вычисления дохода продукта',
  formula_form_coefficient_help:
    'Наименование и значение коэффициентов, которые будут использоваться в формуле. Значение указывается в числовом поле в абсолютных числах (не в процентах). Коэффициенты могут быть переопределены в условиях оффера.',
  coefficient_name: 'Название коэффициента',
  coefficient_value: 'Значение по умолчанию',
  coefficient_delete_error:
    'Вы не можете удалить коэффициент, добавленный в формулу',
};
