export default {
  promo_codes: 'Промокоды',
  promo_types: 'Типы',
  add_new_type: 'Новый тип',
  add_new_promo_code: 'Новый промокод',
  delete_type: 'Удалить тип',
  add_type: 'Добавить тип',
  edit_type_properties: 'Редактировать параметры типа',
  type_editing: 'Редактирование типа',
  type_adding: 'Добавление типа',
  type_successfully_created: 'Тип успешно добавлен',
  type_successfully_updated: 'Тип успешно обновлен',
  type_form_product: 'Продукт',
  type_form_name: 'Наименование',
  type_form_description: 'Описание',
  type_form_poster: 'Постер',
  type_form_upload: 'Загрузить',
  type_delete_title: 'Удалить тип?',
  type_delete_message:
    'Уверены, что хотите удалить Тип промокода? Вместе с типом удалятся все его промокоды, в том числе активные.',
  type_delete_button: 'Да, удалить',
  column_promo_code: 'Промокод',
  column_limit: 'Использования / Всего',
  column_partner_offer: 'Партнёр & Оффер',
  column_offer: 'Оффер',
  column_type_product: 'Тип & Продукт',
  column_description: 'Описание',
  promo_code_personal: 'Персональный',
  promo_code_end_limit: 'Достигнут лимит использований',
  promo_code_expired: 'Истёкший',
  promo_code_assign: 'Привязать',
  promo_code_unlimited: 'Безлимитно',
  promo_code_import_csv: 'Импортировать из CSV',
  promo_code_create_personal: 'Создать персональный',
  promo_code_filter_promo_code: 'Промокод',
  promo_code_filter_offer: 'Оффер',
  promo_code_filter_type: 'Тип',
  promo_code_filter_partner: 'Партнёр',
  promo_code_filter_product: 'Продукт',
  promo_code_filter_assignment: 'Привязка',
  promo_code_filter_reset: 'Очистить фильтры',
  promo_code_no_data: 'Нет данных',
  promo_code_successfully_created: 'Промокод успешно добавлен',
  promo_code_successfully_updated: 'Промокод успешно обновлен',
  promo_code_successfully_assigned: 'Промокод успешно привязан',
  promo_code_delete_title: 'Удалить промокод?',
  promo_code_form_title_add: 'Персональный промокод',
  promo_code_form_title_edit: 'Редактировать промокод',
  promo_code_form_title_assign: 'Привязать промокод',
  promo_code_form_active_from: 'Активен с',
  promo_code_form_active_to: 'по',
  promo_code_form_conversions_limit: 'Лимит использований',
  promo_code_form_type_placeholder: 'Выберите тип...',
  promo_code_form_period: 'Период',
  promo_code_form_conversions: 'Использований',
  promo_code_form_assign_code: 'Привязать код',
  promo_code_form_import_title: 'Импорт промокодов',
  promo_code_form_import_codes: 'Импортировать коды',
  promo_code_form_template: 'Получить шаблон',
  promo_code_form_import_upload_csv: 'Загрузить CSV',
  promo_code_form_import_help_1:
    'Новые коды будут добавлены к списку доступных этого типа.',
  promo_code_form_import_help_2:
    'Для существующих кодов будут применены новые параметры (лимит и период действия).',
  promo_code_form_import_help_3:
    'Изменения применятся как к свободным, так и к выданным промокодам.',
  promo_code_form_import_success: 'Импорт завершён',
  promo_code_form_import_fail: 'Импорт отклонён',
  promo_code_form_go_to_list: 'Вернуться к списку',
  promo_code_form_try_again: 'Попробовать снова',
  promo_code_form_imported: 'импортировано',
  promo_code_form_processing_errors: 'ошибок обработки',
  promo_code_form_fail_message:
    'В файле найдены критические ошибки. \nПромокоды с этими ошибками не могут быть загружены.',
  promo_code_import_table_number: 'Номер строки',
  promo_code_import_table_field: 'Поле',
  promo_code_import_table_message: 'Сообщение ошибки',
  promo_code_copied: 'Промокод скопирован',
  promo_code_search_promo_code: 'Искать промокод',
  promo_code_dont_have_promo_codes:
    'У вас нет промокодов с заданными параметрами',
  promo_code_to_get_new_promo_code: 'Чтобы получить новый промокод,',
  promo_code_choose_offer: 'выберите оффер',
  promo_code_open_tab: 'и откройте вкладку Промокоды',
  promo_code_why: 'Зачем нужны промокоды',
  promo_code_why_place:
    'Промокод можно использовать там, где нет возможности размещать реферальные ссылки и рекламировать товары и услуги (в инстаграм, на видео, в оффлайн рекламе).',
  promo_code_why_registration:
    'Пользователь может ввести промокод при регистрации на сайте, что позволяет автоматически связать его с вами. В этом случае для нового клиента нет необходимости в переходе на сайт по партнерской ссылке.',
  promo_code_why_bonus:
    'При регистрации по промокоду пользователь получает увеличенный бонус, поэтому заинтересован в его использовании. Условия начисления бонусов при регистрации по промокоду можно уточнить у менеджера.',
  promo_code_your_promo_code: 'Ваш промокод',
  promo_code_copy_code: 'Скопировать код',
  promo_code_get_new_code: 'Получить новый код',
  promo_code_maximum: 'Максимум {{count}} кодов для этого оффера',
  promo_code_reach_maximum:
    'Вы достигли лимита в {{count}} кодов для этого оффера',
  promo_code_also_you_can: 'Также вы можете',
  promo_code_ask_code: 'попросить персональный промокод',
  promo_code_active_codes: '{{count}} активных кодов',
  promo_code_maximum_tooltip_1:
    'Это ограничение распространяется на все промокоды (персональные и обычные), но администратор сети в особых случаях может выдать дополнительные коды сверх лимита.',
  promo_code_maximum_tooltip_2:
    'Чтобы получить дополнительный или персональный код (с уникальным наименованием и параметрами), нажмите «Запросить персональный промокод».',
  promo_code_request: 'Запросить промокод',
  promo_code_request_admin: 'Напишите своему администратору',
  promo_code_to_telegram: 'в телеграмм',
  promo_code_request_message:
    'запрос на получение дополнительного промо кода. В сообщении укажите:',
  promo_code_request_type_name: 'название промо акции',
  promo_code_request_period: 'период действия',
  promo_code_request_conversions: 'лимит использований',
  promo_code_request_personal:
    'Если нужен персональный промокод, тогда еще укажите желаемое промо слово.',
  promo_code_partner_empty_types: 'Нет доступных промокодов',
  promo_code_partner_empty_types_extra: 'У этого оффера нет активных акций',
  promo_code_partner_offer_empty_promo_codes: 'У вас нет активных промокодов',
  promo_code_partner_offer_empty_promo_codes_extra:
    'Чтобы получить промокод, нажмите на кнопку «Получить новый код».',
};
