import { generateAdvertiserApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Profile = {
  GET_PROFILE: {
    url: generateAdvertiserApiUrl(ModelPath.Profile),
    method: 'GET',
  },
  CHANGE_PASSWORD: {
    url: generateAdvertiserApiUrl('password/change'),
    method: 'PATCH',
    bodyMapping: ['old_password', 'new_password', 'new_password_confirmation'],
  },
  UPDATE_PROFILE: {
    url: generateAdvertiserApiUrl(ModelPath.Profile),
    method: 'PATCH',
  },
  LOGOUT: {
    url: generateAdvertiserApiUrl('logout'),
    method: 'POST',
  },
} as const;

export default Profile;
