import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const PromoCode = {
  GET_PROMO_CODES: {
    url: generatePartnerApiUrl('', ModelPath.PromoCode),
    method: 'GET',
  },
  REQUEST_PROMOCODE: {
    url: generatePartnerApiUrl('', ModelPath.PromoCode),
    method: 'POST',
    query_mapping: [
      {
        key: 'type_id',
        required: true,
      },
      {
        key: 'offer_id',
        required: true,
      },
    ],
  },
  GET_TYPES: {
    url: generatePartnerApiUrl('types', ModelPath.PromoCode),
    method: 'GET',
    query_mapping: [
      {
        key: 'offer_id',
        required: true,
      },
    ],
  },
} as const;

export default PromoCode;
