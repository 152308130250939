export default {
  'admin.list': 'Ver la lista de administradores',
  'admin.create': 'Creación de un administrador',
  'admin.enable': 'Habilitación del administrador',
  'admin.disable': 'Deshabilitar el administrador',
  'admin.update': 'Editar el administrador',
  'admin.roles': 'Roles de administrador',
  'admin.own_partners_show': 'Acceso solo a sus socios',
  'advertiser.list': 'Ver la lista de anunciantes',
  'advertiser.approve': 'Aprobación del anunciante',
  'advertiser.reject': 'Rechazo del anunciante',
  'advertiser.create': 'Creación de un anunciante',
  'advertiser.show': 'Visualización del anunciante',
  'advertiser.update': 'Edición del anunciante',
  'advertiser.ban': 'Prohibición del anunciante',
  'advertiser.unban': 'Anunciante desordenado',
  'news.all': 'Trabajar con noticias',
  'export.all': 'Exportación de datos',
  'partner.list': 'Ver la lista de socios',
  'partner.approve': 'Aprobación del socio',
  'partner.reject': 'Rechazo del socio',
  'partner.create': 'Creación de un socio',
  'partner.show': 'Visualización de socios',
  'partner.update': 'Edición de socios',
  'partner.ban': 'Prohibición de socio',
  'partner.unban': 'Afiliado de pareja',
  'partner.auth': 'Autorización bajo socio',
  'partner.wallet_add': 'Creando un método de pago para un socio',
  'partner.wallet_edit': 'Edición del método de pago del socio',
  'partner.wallet_delete': 'Eliminación del método de pago del socio',
  'partner.wallet_set_default':
    'Seleccionar el método de pago predeterminado para un socio',
  'partner.change_password': 'Acceso al cambio de contraseña de socio',
  'product.list': 'Ver lista de productos',
  'product.create': 'Creación de un producto',
  'product.show': 'Visualización del producto',
  'product.update': 'Edición del producto',
  'product.enable': 'Inclusión del producto',
  'product.disable': 'Desactivación del producto',
  'product.create_events': 'Crear un evento',
  'product.update_events': 'Editar un evento',
  'product.delete_events': 'Eliminar un evento',
  'product.create_landings': 'Creación de Landing',
  'product.update_landings': 'Editar landing',
  'product.delete_landings': 'Eliminación de landing',
  'product.offers_list': 'Ver la lista de ofertas',
  'product.show_offer': 'Ver oferta',
  'product.create_offers': 'Creación de una oferta',
  'product.update_offers': 'Edición de la oferta',
  'product.delete_offers': 'Eliminación de la oferta',
  'product.create_goals': 'Creación de un objetivo',
  'product.update_goals': 'Editar el objetivo',
  'product.delete_goals': 'Eliminar el objetivo',
  'statistics.show': 'Ver estadísticas',
  'statistics.cancel_conversions': 'Cancelar conversiones',
  'statistics.edit': 'Edición de estadísticas',
  'statistics.change_qualification': 'Cambiar la conversión',
  'statistics.change_conversion': 'Cambiar la conversión',
  'payments.list': 'Ver la lista de solicitudes',
  'payments.approve': 'Confirmación de la solicitud',
  'payments.reject': 'Rechazo de la solicitud',
  'settings.common': 'Acceso a la configuración básica',
  'settings.moderation': 'Acceso a la configuración de moderación',
  'settings.contacts': 'Acceso a la configuración de contactos',
  'settings.tracking_domains':
    'Acceso a la configuración de los dominios de seguimiento',
  'settings.main_domains':
    'Acceso a la configuración de los dominios principales',
  'settings.conditions_hide_to_partner':
    'Acceda a la configuración de ocultación de las condiciones normales del socio',
  'settings.categories': 'Acceso a la configuración de categorías',
  'settings.tags': 'Acceso a la configuración de etiquetas',
  'settings.partner_tags': 'Acceso a la configuración de etiquetas de socios',
  'settings.referral': 'Acceso a la configuración del programa de referencia',
  'settings.email_send':
    'Acceda a la configuración de envío de correo electrónico para confirmar el correo electrónico cuando se registre usted mismo',
  'settings.currency': 'Tabla de divisas',
  'settings.currency_edit': 'Edición de moneda',
  'settings.payment_systems':
    'Acceso a la configuración de los sistemas de pago',
  'settings.default_manager':
    'Acceso a la configuración predeterminada del administrador',
  'settings.auto_invoice':
    'Acceso a la configuración de generación automática de cuentas',
  'documents.terms_of_use': 'Trabajo con documentos legales',
  'role.create': 'Creación de roles',
  'role.delete': 'Eliminación de roles',
  'role.update': 'Editar roles',
  'role.list': 'Ver la lista de roles',
  'role.show': 'Visualización de roles',
  'promo.list': 'Ver la lista de materiales promocionales',
  'promo.create': 'Creación de material promocional',
  'promo.update': 'Modificación del material promocional',
  'promo.delete': 'Eliminación de material promocional',
  'promo.show': 'Ver material promocional',
  'promocode.all': 'Trabajar con códigos promocionales',
  'invoices.all': 'Acceso a la funcionalidad de cuentas de retiro',
  'revshare.all':
    'Trabajar con reversa (añadir, editar y eliminar fórmulas en el producto)',
  'logs.list': 'Acceso a los registros',
  'dashboard.all': 'Trabajar con dashboards',
  business_and_above: 'Disponible en planes Pro y superiores',
  admin: 'Administrador',
  advertiser: 'Anunciante',
  news: 'Noticias',
  partner: 'Socio',
  product: 'Producto',
  statistics: 'Estadísticas',
  payments: 'Solicitudes',
  settings: 'Ajustes',
  dashboard: 'Tablero de instrumentos',
  role: 'Roles',
  promo: 'Materiales promocionales',
  export: 'Exportación',
  event_group: 'Grupos de eventos',
  promocode: 'Códigos promocionales',
  documents: 'Documentos',
  invoices: 'Cuentas',
  revshare: 'Revashara',
  logs: 'Logis',
};
