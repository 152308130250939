import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';
import {
  cohortAnalysisFilterKeys,
  commonFilterKeys,
  conversionsExportFilterKeys,
  conversionsFilterKeys,
  partnerEventsFilterKeys,
  customersFilterKeys,
  eventsExportFilterKeys,
} from 'pages/StatisticsPage/keys';

const Statistics = {
  GET_COMMON_STATISTICS: {
    url: generatePartnerApiUrl('commons', ModelPath.Statistic),
    method: 'GET',
    queryMapping: commonFilterKeys,
  },
  GET_CUSTOMERS_STATISTICS: {
    url: generatePartnerApiUrl('customers', ModelPath.Statistic),
    method: 'GET',
    queryMapping: customersFilterKeys,
  },
  GET_DASHBOARD_STATISTICS: {
    url: generatePartnerApiUrl('dashboards', ModelPath.Statistic),
    method: 'GET',
    queryMapping: commonFilterKeys,
  },
  GET_CONVERSIONS_STATISTICS: {
    url: generatePartnerApiUrl('conversions', ModelPath.Statistic),
    method: 'GET',
    queryMapping: conversionsFilterKeys,
  },
  EXPORT_CONVERSIONS_STATISTICS: {
    url: generatePartnerApiUrl('conversions/export', ModelPath.Statistic),
    method: 'POST',
    queryMapping: conversionsExportFilterKeys,
  },
  GET_EVENTS_STATISTICS: {
    url: generatePartnerApiUrl('events', ModelPath.Statistic),
    method: 'GET',
    queryMapping: partnerEventsFilterKeys,
  },
  EXPORT_EVENTS_STATISTICS: {
    url: generatePartnerApiUrl('events/export', ModelPath.Statistic),
    method: 'POST',
    queryMapping: eventsExportFilterKeys,
  },
  GET_COHORT_ANALYSIS: {
    url: generatePartnerApiUrl('cohort', ModelPath.Statistic),
    method: 'GET',
    queryMapping: cohortAnalysisFilterKeys,
  },
  GET_TABLE_SCHEMES: {
    url: generatePartnerApiUrl('schemes', ModelPath.Statistic),
    method: 'GET',
  },
  GET_BROWSER_LIST: {
    url: generatePartnerApiUrl('browsers', ModelPath.Statistic),
    method: 'GET',
  },
  GET_OS_LIST: {
    url: generatePartnerApiUrl('os', ModelPath.Statistic),
    method: 'GET',
  },
  GET_OS_VERSIONS_LIST: {
    url: generatePartnerApiUrl('os_versions', ModelPath.Statistic),
    method: 'GET',
  },
  GET_DEVICE_TYPE_LIST: {
    url: generatePartnerApiUrl('device_types', ModelPath.Statistic),
    method: 'GET',
  },
} as const;

export default Statistics;
