import React from 'react';
import useBrandLogo from './useBrandLogo';

export interface IBrandLogoProps {
  maxHeight?: string;
  maxWidth?: string;
  variant?: 'white' | 'black';
}

const BrandLogo: React.FC<IBrandLogoProps> = (props): JSX.Element => {
  const { logo } = useBrandLogo(props);
  return <>{logo}</>;
};

export default BrandLogo;
