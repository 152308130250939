import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import unset from 'lodash/unset';
import floor from 'lodash/floor';

import { WidgetActionTypes } from 'core/redux/constants/WidgetActionTypes';
import getObjectOnlyValidValues from 'utils/getObjectOnlyValidValues';
import { WidgetActions } from '../actions/types';
import { IWidget } from 'models/widget/IWidget';

const initialState: IWidget = {
  widgetId: null,
  isInit: false,
  isLoading: false,
  fetchOnInit: false,
  meta: {},
  selected: [],
  error: null,
  filter: {},
};

function resolveWidgetState(state = initialState, action: WidgetActions) {
  let newTotal = 0;
  let perPage = 0;
  switch (action.type) {
    case WidgetActionTypes.SetLoading:
      return assign({}, state, { isLoading: action.payload.isLoading });
    case WidgetActionTypes.SetMeta:
      return assign({}, state, { meta: action.payload.meta });
    case WidgetActionTypes.SetError:
      return assign({}, state, { error: action.payload.error });
    case WidgetActionTypes.SetFilter:
      const partialUpdate = !!action.payload.partialUpdate;
      const removeUndefined = !!action.payload.removeUndefined;
      const actionNewInnerFilter = assign(
        {},
        state.filter.values?.filter,
        getObjectOnlyValidValues(
          action.payload.filter?.filter,
          removeUndefined && partialUpdate,
        ),
      );
      const actionValidNewInnerFilter = getObjectOnlyValidValues(
        actionNewInnerFilter,
        removeUndefined,
      );
      const newFilter = assign(
        {},
        state.filter.values,
        getObjectOnlyValidValues(
          action.payload.filter,
          removeUndefined && partialUpdate,
        ),
        {
          filter: actionValidNewInnerFilter,
        },
      );
      const newValidFilter = getObjectOnlyValidValues(
        newFilter,
        removeUndefined,
      );
      return assign({}, state, {
        filter: assign({}, state.filter, {
          values: action.payload.merge ? newValidFilter : action.payload.filter,
        }),
      });
    case WidgetActionTypes.SetFilterMappingFromUrlDone:
      return assign({}, state, {
        filter: assign({}, state.filter, {
          mappingFromUrlDone: action.payload.isDone,
        }),
      });
    case WidgetActionTypes.RemoveFilterParams:
      const values = cloneDeep(state.filter?.values);
      forEach(action.payload.params, (param: string) => unset(values, param));
      return assign({}, state, {
        filter: assign({}, state.filter, { values }),
      });
    case WidgetActionTypes.HandleAddInMeta:
      if (state.meta?.total && state.meta?.per_page) {
        newTotal = (state.meta?.total || 0) + 1;
        perPage = state.meta?.per_page;
        return assign({}, state, {
          meta: assign({}, state.meta, {
            total: newTotal,
            count: (state.meta?.count || 0) + 1,
            last_page:
              floor(newTotal / perPage) + (newTotal % perPage > 0 ? 1 : 0),
          }),
        });
      }
      break;
    case WidgetActionTypes.HandleDeleteInMeta:
      newTotal = (state.meta?.total || 1) - 1;
      perPage = state.meta?.per_page;
      const lastPage =
        floor(newTotal / perPage) + (newTotal % perPage > 0 ? 1 : 0);
      const currentPage = state.meta?.current_page || 1;
      return assign({}, state, {
        meta: assign({}, state.meta, {
          total: newTotal,
          count: (state.meta?.count || 1) - 1,
          last_page: lastPage,
          current_page: currentPage > lastPage ? lastPage : currentPage,
        }),
      });
    default:
      return state;
  }
}

export default function widgetsReducer(
  state: Record<string, IWidget> = {},
  action: WidgetActions,
): typeof state {
  const widgetId = action.payload?.widgetId;

  switch (action.type) {
    case WidgetActionTypes.RegisterWidget:
      return assign({}, state, {
        [widgetId]: assign({}, initialState, {
          ...action.payload,
          isInit: true,
        }),
      });
    case WidgetActionTypes.UnregisterWidget:
      return assign({}, state, {
        [widgetId]: undefined,
      });
    case WidgetActionTypes.SetLoading:
    case WidgetActionTypes.SetMeta:
    case WidgetActionTypes.SetError:
    case WidgetActionTypes.SetFilter:
    case WidgetActionTypes.SetFilterMappingFromUrlDone:
    case WidgetActionTypes.RemoveFilterParams:
    case WidgetActionTypes.HandleDeleteInMeta:
    case WidgetActionTypes.HandleAddInMeta:
      return assign({}, state, {
        [widgetId]: resolveWidgetState(state[widgetId], action),
      });
    default:
      return state;
  }
}
