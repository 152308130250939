export default {
  'search by id or name': 'Buscar por ID o nombre',
  search_by_id_or_email: 'Buscar por ID o correo electrónico',
  switch_to_lang: 'Cambiar a {{lang}}',
  next: 'Siguiente',
  active: 'Activo',
  inactive: 'Inactivo',
  registration_not_completed: 'Inscripción incompleta',
  banned: 'Prohibido',
  item_selected: 'elemento seleccionado',
  items_selected: 'elemento seleccionado',
  items_more_selected: 'elementos seleccionados',
  disabled: 'Bloqueado',
  moderation: 'En la moderación',
  pending: 'En espera',
  rejected: 'Rechazado',
  profile_disabled: 'Bloqueado',
  profile_rejected: 'Rechazado',
  approved: 'Confirmado',
  canceled: 'Cancelado',
  select_to_choice: 'Seleccionar',
  select_to_search: 'Buscar',
  select_no_options: 'No hay opciones disponibles',
  today: 'Hoy',
  last: 'Últimos',
  '3_days': '3 días',
  '15_days': '15 días',
  week: 'Semana',
  month: 'Mes',
  apply: 'Aplicar',
  clear_all: 'Limpiar',
  payed: 'Pagado',
  hold: 'Hold',
  required: 'Obligatorio',
  optional: 'Opcional',
  qualification: 'Calificación',
  conversion: 'Conversión',
  event: 'Evento',
  show: 'Mostrar',
  hide: 'Ocultar',
  public: 'Público',
  private: 'Privado',
  premoderate: 'Pre-moderación',
  select_all_label: 'Seleccionar todo',
  filter: 'Filtro',
  any_option: 'Cualquiera',
  close_filter: 'Cerrar filtro',
  login_by_partner_part_1: '¡Atención! Está utilizando la página de socios ',
  login_by_partner_part_2: ' en nombre del administrador.',
  back_to_admin_page: 'Volver al panel de administración',
  allowed_formats_png_jpeg_jpg: 'Formatos permitidos: .png, .jpeg, .jpg',
  confirmed: 'Confirmado',
  select_all: 'Seleccionar todo',
  unselect_all: 'Eliminar todo',
  group_actions: 'Acciones de grupo',
  or: 'o',
  click: 'Clic',
  promo_code: 'Código Promocional',
  any_status: 'Cualquier estado',
  edited_by_a_moderator: 'Edición por un moderador',
  goal_missing_in_condition: 'Falta el objetivo en la condición',
  over_limit: 'Límite excedido',
  over_postclick: 'Excedido post-clic',
  payment_not_found: 'Pago no encontrado',
  promocode_over_limit: 'Límite de códigos de promoción excedido',
  promocode_is_expired: 'Código promocional caducado',
  disconnected_from_offer: 'Desactivado de la oferta',
  rejected_by_product: 'Rechazado por el producto',
  templates_max_count: 'Solo se puede añadir {{count}} veces',
  template_value: 'Significado',
  template_currency: 'Moneda',
  template_currency_symbol: 'Signo de Moneda',
  template_default_currency: 'Valor y código en moneda predeterminada',
  template_default_currency_tooltip:
    'Se puede añadir 1 vez. Disponible si se especifica Moneda o Signo de Moneda',
  in_en_language: 'En inglés',
  in_ru_language: 'En ruso',
  in_es_language: 'En español',
  promo_code_assignment_type_all: 'Todo',
  promo_code_assignment_type_assigned: 'Atado',
  promo_code_assignment_type_unassigned: 'Sin consolidar',
  promo_code_activity_type_all: 'Todo',
  promo_code_activity_type_active: 'Activo',
  promo_code_activity_type_inactive: 'Inactivo',
  selected_of: 'Seleccionado {{selected}} de {{all}}',
  selected: 'Seleccionado {{selected}}',
  showed: 'Mostrado',
  show_full: 'Mostrar todo',
  'nintendo proprietary': 'Nintendo',
  'nokia products': 'Nokia',
  other: 'El resto',
  type: 'tipo',
  type_one: 'tipo',
  type_other: 'tipos',
  of: 'de',
  preparing: 'En ejecución',
  done: 'Terminado',
  per_page: 'en la página',
  enter_value: 'Introduzca un valor',
  collapse: 'Presionar',
  more_number: 'más {{count}}',
  all: 'Todo',
  new: 'Nuevo',
  drop_file: 'Arrastre y suelte el archivo',
  upload: 'Descargar',
  change: 'Cambiar',
  wrong_file_type: 'Tipo de archivo incorrecto',
  date_from: 'C',
  date_to: 'Por',
  loading: 'Descargas',
  load_more: 'Descargar más',
  input_more_symbols_to_search:
    'Introduzca {{count}} caracteres más para buscar',
  input_one_more_symbols_to_search: 'Introduzca otro carácter para buscar',
  paid: 'Pagado',
  cpa: 'CPA',
  cpm: 'CPM',
  revshare: 'Revshare',
  awaiting_payment: 'Pendiente de pago',
  for_the_product: 'Para el producto',
  for_the_offer: 'Para la oferta',
  fraud_level_not_analyzed: 'No analizado',
  fraud_level_ok: 'Sin riesgo',
  fraud_level_low: 'Bajo',
  fraud_level_mid: 'Medio',
  fraud_level_high: 'Alto',
  clicks: 'Clics',
  referral_link: 'Enlace de referencia',
  promocodes: 'Códigos promocionales',
  leads: 'Leads externos',
  offer_target_link: 'Enlaces directos de ofertas',
  landing_target_link: 'Ofertas de Landing',
  expand: 'Expandir',
  device_type_desktop: 'Computadora personal',
  device_type_mobile_phone: 'Teléfono móvil',
  device_type_mobile_device: 'Dispositivo móvil',
  device_type_tablet: 'Tableta',
  device_type_tv_device: 'Televisión',
  device_type_digital_camera: 'Cámara digital',
  device_type_car_entertainment_system: 'Sistema de entretenimiento del coche',
  device_type_ebook_reader: 'Libro electrónico',
  device_type_console: 'Consola',
  show_user_contacts_filter: 'Mostrar filtros por contacto',
  hide_user_contacts_filter: 'Ocultar filtros de contactos',
  pin: 'Fijar',
  unpin: 'Desbloquear',
  exclude: 'Excluir',
  exclude_help: 'Los elementos seleccionados serán excluidos',
  exclude_preview: 'Todo, excl.:',
  activity_check_text: 'Los datos han sido actualizados',
  activity_check_refresh: 'Refrescar',
};
