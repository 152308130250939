export default {
  product: 'Producto',
  offer: 'Oferta',
  country: 'País',
  preview: 'Vista previa',
  size: 'Dimensiones',
  img_type: 'Tipo',
  language: 'Idioma',
  currency: 'Moneda',
  width_min: 'Anchura mínima',
  height_min: 'Altura mínima',
  width_max: 'Max. anchura',
  height_max: 'Max. altura',
  no_data: 'Sin material promocional',
  more_countries: 'países',
  name: 'Nombre',
  promo_editing: 'Edición de material promocional',
  promo_adding: 'Añadir material promocional',
  promo_successfully_created:
    'El material de promoción se ha añadido correctamente',
  promo_successfully_updated:
    'El material promocional se ha modificado con éxito',
  promo_remove_confirm: '¿Desea retirar el material promocional?',
  add_new_promo: 'Nuevo material promocional',
  iframe: 'IFrame',
  embed_on_site: 'Incrustar en el sitio',
};
