export default {
  'no advertisers': 'Нет рекламодателей',
  id: 'ID',
  email: 'Email',
  password: 'Пароль',
  manager: 'Менеджер',
  'offers count': 'Количество офферов',
  'registration date': 'Дата регистрации',
  creation_date: 'Дата создания',
  status: 'Статус',
  actions: 'Действия',
  'new advertiser': 'Новый рекламодатель',
  rejected: 'Отклонен',
  banned: 'Забанен',
  moderation: 'На модерации',
  active: 'Активен',
  accept: 'Одобрить',
  reject: 'Отклонить',
  advertisers: 'Рекламодатели',
  new_advertiser: 'Новый рекламодатель',
  creation_advertiser: 'Создание рекламодателя',
  advertiser_successfully_created: 'Рекламодатель успешно создан',
  created: 'Создано',
  edit_profile: 'Редактировать профиль',
  offers: 'Офферы',
  incomplete_registration: 'Неполная регистрация',
  advertiser_successfully_updated: 'Рекламодатель успешно обновлен',
  list_of_advertisers: 'Список рекламодателей',
};
