import createActionHelper from './createActionHelper';
import { GlobalActions } from 'core/redux/enums/GlobalActions';

export const setTitle = (title: string) =>
  createActionHelper(GlobalActions.SetTitle)({ title });

export const setDict = (dict: { [key: string]: { [key: string]: any } }) =>
  createActionHelper(GlobalActions.SetDict)({ dict });

export const setLoading = (loading: boolean) =>
  createActionHelper(GlobalActions.SetLoading)({ loading });

export const changeLang = (lang: string) =>
  createActionHelper(GlobalActions.ChangeLang)({ lang });
