import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const PaymentThreshold = {
  GET_ALL: {
    url: generateAdminApiUrl(ModelPath.PaymentThreshold),
    method: 'GET',
  },
  UPDATE_ALL: {
    url: generateAdminApiUrl(ModelPath.PaymentThreshold),
    method: 'PATCH',
  },
} as const;

export default PaymentThreshold;
