import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Invoices = {
  GET_ALL_RECEIVED: {
    url: generateAdminApiUrl('logs/received', ModelPath.Postback),
    method: 'GET',
  },
  GET_RECEIVED: {
    url: generateAdminApiUrl('logs/received/:id', ModelPath.Postback),
    method: 'GET',
  },
  GET_ALL_SENT: {
    url: generateAdminApiUrl('logs/sended', ModelPath.Postback),
    method: 'GET',
  },
  GET_ALL_CLICKS: {
    url: generateAdminApiUrl('logs', ModelPath.Click),
    method: 'GET',
  },
} as const;

export default Invoices;
