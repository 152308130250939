export default {
  add_tag: 'Добавить тег',
  add_offer_tag: 'Добавить тег оффера',
  add_partner_tag: 'Добавить тег партнера',
  no_tags: 'Нет созданных тегов',
  tag_remove_confirm: 'Вы хотите удалить тег?',
  tag_successfully_created: 'Тег успешно добавлен',
  tag_successfully_updated: 'Тег успешно обновлен',
  tag_adding: 'Добавление тега',
  tag_editing: 'Редактирование тега',
  tag_name: 'Название тега',
  tag_name_placeholder: 'Введите название тега',
  click_to_remove_tag: 'Нажмите, чтобы удалить тег',
  offer_tags: 'Теги оффера',
  partner_tags: 'Теги партнера',
};
