export default {
  contact: 'Contact',
  add_contact: 'Add contact',
  add_partner_contact: 'Add partner contact',
  admin_contacts: 'Admin contacts',
  partner_contacts: 'Partner contacts',
  no_contacts: 'No contacts',
  contact_remove_confirm: 'Do you want to delete the contact?',
  admin_contact_remove_confirm:
    'Are you sure you want to delete the contact? All administrator contacts will also be deleted.',
  contact_successfully_created: 'Contact successfully added',
  contact_successfully_updated: 'Contact successfully updated',
  contact_adding: 'Adding contact',
  contact_editing: 'Editing contact',
  contact_name: 'Name of contact',
  contact_requirements: 'Requirements',
  contact_name_placeholder: 'Enter name of the contact',
  contact_required_parameter: 'Required parameter',
  show_in_registration_form: 'Show in registration form',
  field_input: 'Text',
  field_select: 'Select',
  field_checkbox: 'Checkboxes',
  field_radio: 'Radio buttons',
  field_textarea: 'Multiline text',
  field_type: 'Field type',
  field_values: 'Values',
  add_field_value: 'Add value',
  admin_visible_contacts:
    'For a partner in the manager block, only the fields with the Text type will be shown.',
};
