export default {
  'new product': 'Nuevo producto',
  name: 'Nombre',
  offers: 'Ofertas',
  category: 'Categoría',
  note: 'Nota interna',
  table_note: 'Nota',
  advertiser: 'Anunciante',
  status: 'Estado',
  created: 'Creado',
  'no products': 'No hay productos creados',
  products: 'Productos',
  products_list: 'Lista de productos',
  product: 'Producto',
  monetary: 'Dinero',
  'no events list': 'No hay lista de eventos creados',
  'no offers list': 'No hay lista de ofertas creadas',
  'name and id': 'Nombre e ID',
  privacy: 'Privacidad',
  'creation date': 'Fecha de creación',
  events: 'Eventos',
  edit: 'Editar',
  delete: 'Eliminar',
  enabled: 'Activo',
  manager: 'Gerente',
  'click lifetime': 'Tiempo de vida del clic',
  landings: 'Landing',
  target_link: 'Target-enlace',
  'create new project': 'Crear un nuevo producto',
  'product name': 'Nombre del producto',
  description: 'Descripción',
  'poster image (120 × 120)': 'Cartel (resolución recomendada 120 × 120)',
  'create new product': 'Crear un nuevo producto',
  'edit product': 'Editar producto',
  'product successfully created': 'Producto creado con éxito',
  'product successfully edited': 'Producto actualizado con éxito',
  hours: 'horas',
  new_landing: 'Nuevo aterrizaje',
  disable_product: 'Desactivar producto',
  are_you_sure_you_want_to_disable_the_product:
    '¿Seguro que desea desactivar el producto?\nCuando desactive un producto, todas sus ofertas se desactivarán.',
  unlimited: 'Ilimitado',
  days: 'días',
  goal_remove_conditions:
    'No se puede eliminar un objetivo mientras esté en uso en las condiciones de la oferta.',
  goal_deleting: 'Eliminar el objetivo',
  goal_key_help:
    'El identificador del objetivo que vendrá en el postback en el parámetro {goal}. El valor se mostrará en minúsculas.',
  formula_variable_amount_of: 'Cantidad',
  formula_variable_goal_sum: '$t(product:formula_variable_amount_of) {{name}}',
  product_variables: 'Variables de producto',
  coefficients: 'Coeficientes',
  edit_formula: 'Editar fórmula',
  add_formula: 'Nueva fórmula',
  add_coefficient: 'Añadir factor',
  formula_form_name_help:
    'El nombre de la fórmula que se mostrará cuando trabaje con los términos de la oferta',
  formula_form_expression_help:
    'Fórmula para calcular los ingresos del producto',
  formula_form_coefficient_help:
    'Nombre y valor de los coeficientes que se utilizarán en la fórmula. El valor se indica en el campo numérico en números absolutos (no como porcentaje). Los coeficientes pueden ser redefinidos en las condiciones de la oferta.',
  coefficient_name: 'Nombre del coeficiente',
  coefficient_value: 'Valor predeterminado',
  coefficient_delete_error:
    'No se puede eliminar el factor añadido a la fórmula',
};
