type ActionHelper = {
  type: string;
  payload: { [key: string]: any };
  meta: { [key: string]: any };
};

export default function createActionHelper(type: string) {
  return function (
    payload: { [key: string]: any },
    meta: { [key: string]: any } = {},
  ): ActionHelper {
    return {
      type,
      payload,
      meta,
    };
  };
}
