export default {
  settings: 'Settings',
  domains: 'Domains',
  tracking_domains: 'Tracking domains',
  categories: 'Categories',
  trafficback: 'Trafficback',
  offers: 'Offers',
  event_group: 'Event group',
  event_groups: 'Event groups',
  goal_group: 'Goal group',
  goal_groups: 'Goal groups',
  security: 'Security',
  security_use_whitelist: 'Use a whitelist of IP addresses for postbacks',
  security_use_whitelist_help_1:
    'Postbacks from these IP addresses will be allowed. Enter one IP address per line.',
  security_use_whitelist_help_2: 'Single IPs are allowed. Example:',
  security_2fa_enable: 'Enable two-factor authentification',
  security_2fa_admin:
    'Enable two-factor authentification for the admin accounts',
  security_2fa_partner:
    'Enable two-factor authentification for the partner accounts',
  two_factor_auth_enter_code: 'Enter a code',
  two_factor_auth_enter_code_hint:
    'The confirmation code has been sent by email',
  two_factor_auth_enter_code_ga_hint:
    'The confirmation code has been sent by Google Authenticator',
  two_factor_auth_enter_get_new_code: 'You can receive a new code in',
  two_factor_auth_enter_send_new_code: 'Send new code',
  contacts: 'Adding admin contacts',
  partner_advertiser_contacts: 'Partner/Advertiser contacts',
  integrations: 'Integrations',
  tags: 'Tags',
  general_settings: 'General settings',
  settings_successfully_updated: 'Settings successfully updated',
  settings_invoices_successfully_updated:
    'Invoices autogenerating settings successfully updated',
  general_settings_name: 'Name of the affiliate network',
  general_settings_logo: 'Logo of the affiliate network',
  general_settings_favicon: 'Icon of the affiliate network',
  general_contacts: 'General contacts of the affiliate network',
  general_contacts_help:
    'These contacts are displayed on the authorization page for non-activated users',
  general_contacts_limit: 'You have reached the limit of {{count}} contacts',
  technical_support_email: 'Technical support email',
  users: 'User registration',
  default_manager: 'Default manager',
  default_manager_empty: 'Not set',
  users_sending_email:
    'Obligatory email confirmation by advertiser and partner',
  users_moderation: 'Moderation of advertiser and partner',
  recommendation_horizontal_logo:
    'We recommend uploading horizontal logos with an aspect ratio of 3:1',
  currencies: 'Currencies',
  no_currencies: 'No active currencies',
  currency_code: 'Currency code',
  rate: 'Rate',
  is_enabled: 'Enabled',
  currency_successfully_updated: 'Currency successfully updated',
  main_domains: 'Main domains',
  admin_panel_url: 'Admin panel URL',
  partner_panel_url: 'Partner panel URL',
  advertiser_panel_url: 'Advertiser panel URL',
  documents: 'Documents',
  terms_of_use: 'Terms of Use',
  privacy_policy: 'Privacy Policy',
  show_terms_of_use: 'Show terms of use for partners?',
  show_privacy_policy: 'Show privacy policy for partners?',
  save_with_partners_acceptance: 'Save with mandatory acceptance by partners',
  save_with_partners_acceptance_help:
    'After saving, all partners have to be accept the updated terms of use and privacy policy',
  save_with_partners_acceptance_confirm:
    'Are you sure you want to save the updated documents with the mandatory acceptance of the partners?',
  resale_period: 'Resale period (in days)',
  referral_program: 'Referral program',
  referral_percent: 'Referral percent',
  payment_systems: 'Payment systems',
  payments: 'Payments',
  thresholds: 'Thresholds',
  thresholds_hint:
    'The minimum amount for withdrawal or invoice. Each currency has its own minimum threshold.',
  invoices: 'Invoice generation',
  invoices_hint: 'An administrator can create any invoice manually.',
  invoices_partner: 'A partner can independently generate invoice',
  invoices_partner_hint:
    'A partner will be able to independently generate invoices from his personal account',
  invoice_generation_method: 'Generation method',
  invoices_autogenerate: 'Autogenerate',
  invoices_autogenerate_help:
    'Invoices will be generated automatically for all active partners, based on the selected frequency settings and offers.',
  invoices_autogenerate_week: 'Once a week',
  invoices_autogenerate_month: 'Once a month',
  invoices_autogenerate_month_twice: 'Twice a month',
  invoices_autogenerate_partners: 'Partners',
  invoices_autogenerate_offers: 'Offers',
  invoices_autogenerate_week_day_1: 'Monday',
  invoices_autogenerate_week_day_2: 'Tuesday',
  invoices_autogenerate_week_day_3: 'Wednesday',
  invoices_autogenerate_week_day_4: 'Thursday',
  invoices_autogenerate_week_day_5: 'Friday',
  invoices_autogenerate_week_day_6: 'Saturday',
  invoices_autogenerate_week_day_7: 'Sunday',
  invoices_autogenerate_week_every_day_1: 'Every Monday',
  invoices_autogenerate_week_every_day_2: 'Every Tuesday',
  invoices_autogenerate_week_every_day_3: 'Every Wednesday',
  invoices_autogenerate_week_every_day_4: 'Every Thursday',
  invoices_autogenerate_week_every_day_5: 'Every Friday',
  invoices_autogenerate_week_every_day_6: 'Every Saturday',
  invoices_autogenerate_week_every_day_7: 'Every Sunday',
  invoices_day_picker_help:
    'If there is no selected date in the month, the invoice will be generated on the last day of the month.',
  invoices_on_nth_day_one: 'On {{day}}st day',
  invoices_on_nth_day_two: 'On {{day}}nd day',
  invoices_on_nth_day_few: 'On {{day}}rd day',
  invoices_on_nth_day_other: 'On {{day}}th day',
  active_user_criteria: 'Active user criteria',
  active_user_help:
    'An active user is a user who has performed a target action from a selected group of goals in a specified period of time. Active users will be determined depending on the selected group of goals. Used for cohort analysis.',
  trafficback_url: 'Trafficback URL',
  trafficback_url_hint:
    'If the traffic does not match the parameters, it will be redirected to the given URL. Macros are available in the link:\n\n{click_id} - unique click ID;\n{partner_id} - partner ID;\n{sub_id1} - sub_id #1, up to 10 sub_id can be added;\n{offer_id} - offer ID.\n{creative_id} - promo-material ID.',
  promocodes: 'Promo Codes',
  max_promocode_count:
    'The number of free promo codes in the offer that the partner can receive independently',
  promo_code_postback_priority: 'Postback processing priority',
  conversion_settings: 'Working with Conversions',
  conversion_settings_partners_label:
    'Make macros {value} and {value_currency} available to selected partners',
  conversion_settings_partners_help:
    "For selected partners in global and offer's postbacks, sending the value of the value field and its currency will be available",
  conversion_settings_hide_reasons_title:
    'Hide rejected conversions from partners:',
  conversion_settings_not_hide: 'Do not hide',
  conversion_settings_hide_all: 'All',
  conversion_settings_hide_reasons: 'With reasons',
  show_custom_fields_for_partner: 'Show partners the following parameters:',
  setting_name: 'Name',
  global_postbacks: 'Global postbacks',
  add_global_postback: 'Add global postback',
  global_postback_always_send: 'Send with a local postback in offer',
  is_conditions_hidden_to_partner:
    'Hide common conditions from a partner if at least one individual condition is present',
  conversions_by_hidden_conditions:
    'Use conversions to calculate and generate common statistics that are created according to conditions hidden from partners',
  is_referral_email_hidden_to_partner: 'Hide email referrals from partners',
  cookies: 'Cookies',
  cookie_lifetime: 'Cookie lifetime in days',
  cookies_help:
    'To activate the lifetime of Cookies, copy and paste the given js-script into the site. The code should be placed in the <body></body> section of your site.',
  add_currency: 'Add currency',
  ga_qr_text:
    'To enable two-factor authentication Google Authenticator, install the application via the AppStore or GoogleMarket and scan the QR code.',
  ga_text: 'To manually add, provide the following information:',
  ga_connected_label: 'Device connected',
  ga_connected_text:
    'To change the type of two-factor verification, turn off the device.',
  ga_secret_key: 'secret_key',
  ga_connect: 'Connect',
  ga_disconnect: 'Disconnect',
  timezone: 'Timezone',
  menu_settings_title: 'Additional links in the partner menu',
  menu_settings_title_help: 'These links will be displayed in the partner menu',
  add_menu_item: 'Add menu item',
  menu_setting_adding_modal_title: 'Adding menu item',
  menu_setting_editing_modal_title: 'Editing menu item',
  no_menu_items: 'No menu items',
  menu_settings_remove_confirm:
    'Are you sure you want to delete the menu item?',
  menu_settings_name: 'Menu item name',
  menu_settings_link: 'Link',
  menu_settings_color: 'Color',
  menu_settings_successfuly_created: 'Menu item successfully added',
  menu_settings_successfuly_updated: 'Menu item successfully updated',
  menu_settings_limit: 'You have reached the limit of {{count}} menu items',
  color_black: 'Black',
  color_white: 'White',
  color_red: 'Red',
  color_orange: 'Orange',
  color_green: 'Green',
  'color_blue-green': 'Blue-green',
  color_purple: 'Purple',
  'color_light-red': 'Light red',
  'color_light-orange': 'Light orange',
  'color_light-green': 'Light green',
  'color_light-blue-green': 'Light blue-green',
  'color_light-purple': 'Light purple',
  color_blue: 'Blue',
  color_yellow: 'Yellow',
  color_gray: 'Gray',
  is_hmac_activated:
    'Enable signature verification in postbacks based on the HMAC',
};
