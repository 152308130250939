export default {
  add_goal_groups: 'Añadir grupo de objetivos',
  no_goal_groups: 'Sin grupo de objetivos',
  name: 'Nombre del grupo de objetivos',
  name_of_goal: 'Nombre del objetivo',
  key_of_goal: 'La clave',
  key_of_goal_hint:
    'La clave de grupo de objetivos se usará como valor predeterminado para la macro {goal} en los postbacks globales del socio. El valor se mostrará en minúsculas.',
  product: 'Producto',
  goal_group_editing: 'Editar un grupo de objetivos',
  goal_group_adding: 'Agregar un grupo de objetivos',
  goal_group_name: 'Nombre del grupo de objetivos',
  goal_group_key: 'Clave de grupo de objetivos',
  goal_group_name_placeholder: 'Introduzca el nombre del grupo de objetivos',
  goal_group_key_placeholder: 'Introduzca la clave de grupo de objetivos',
  goal_group_successfully_created: 'Grupo de objetivos agregado con éxito',
  goal_group_successfully_updated: 'Grupo de objetivos cambiado con éxito',
  goal_group_remove_confirm: '¿Desea eliminar un grupo de objetivos?',
};
