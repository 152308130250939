import { ReactComponent as FlagAd } from 'assets/icons/flags/ad.svg';
import { ReactComponent as FlagAe } from 'assets/icons/flags/ae.svg';
import { ReactComponent as FlagAf } from 'assets/icons/flags/af.svg';
import { ReactComponent as FlagAg } from 'assets/icons/flags/ag.svg';
import { ReactComponent as FlagAi } from 'assets/icons/flags/ai.svg';
import { ReactComponent as FlagAl } from 'assets/icons/flags/al.svg';
import { ReactComponent as FlagAm } from 'assets/icons/flags/am.svg';
import { ReactComponent as FlagAn } from 'assets/icons/flags/an.svg';
import { ReactComponent as FlagAo } from 'assets/icons/flags/ao.svg';
import { ReactComponent as FlagAq } from 'assets/icons/flags/aq.svg';
import { ReactComponent as FlagAr } from 'assets/icons/flags/ar.svg';
import { ReactComponent as FlagAs } from 'assets/icons/flags/as.svg';
import { ReactComponent as FlagAt } from 'assets/icons/flags/at.svg';
import { ReactComponent as FlagAu } from 'assets/icons/flags/au.svg';
import { ReactComponent as FlagAw } from 'assets/icons/flags/aw.svg';
import { ReactComponent as FlagAx } from 'assets/icons/flags/ax.svg';
import { ReactComponent as FlagAz } from 'assets/icons/flags/az.svg';
import { ReactComponent as FlagBa } from 'assets/icons/flags/ba.svg';
import { ReactComponent as FlagBb } from 'assets/icons/flags/bb.svg';
import { ReactComponent as FlagBd } from 'assets/icons/flags/bd.svg';
import { ReactComponent as FlagBe } from 'assets/icons/flags/be.svg';
import { ReactComponent as FlagBf } from 'assets/icons/flags/bf.svg';
import { ReactComponent as FlagBg } from 'assets/icons/flags/bg.svg';
import { ReactComponent as FlagBh } from 'assets/icons/flags/bh.svg';
import { ReactComponent as FlagBi } from 'assets/icons/flags/bi.svg';
import { ReactComponent as FlagBj } from 'assets/icons/flags/bj.svg';
import { ReactComponent as FlagBl } from 'assets/icons/flags/bl.svg';
import { ReactComponent as FlagBm } from 'assets/icons/flags/bm.svg';
import { ReactComponent as FlagBn } from 'assets/icons/flags/bn.svg';
import { ReactComponent as FlagBo } from 'assets/icons/flags/bo.svg';
import { ReactComponent as FlagBq } from 'assets/icons/flags/bq.svg';
import { ReactComponent as FlagBr } from 'assets/icons/flags/br.svg';
import { ReactComponent as FlagBs } from 'assets/icons/flags/bs.svg';
import { ReactComponent as FlagBt } from 'assets/icons/flags/bt.svg';
import { ReactComponent as FlagBv } from 'assets/icons/flags/bv.svg';
import { ReactComponent as FlagBw } from 'assets/icons/flags/bw.svg';
import { ReactComponent as FlagBy } from 'assets/icons/flags/by.svg';
import { ReactComponent as FlagBz } from 'assets/icons/flags/bz.svg';
import { ReactComponent as FlagCa } from 'assets/icons/flags/ca.svg';
import { ReactComponent as FlagCc } from 'assets/icons/flags/cc.svg';
import { ReactComponent as FlagCd } from 'assets/icons/flags/cd.svg';
import { ReactComponent as FlagCf } from 'assets/icons/flags/cf.svg';
import { ReactComponent as FlagCg } from 'assets/icons/flags/cg.svg';
import { ReactComponent as FlagCh } from 'assets/icons/flags/ch.svg';
import { ReactComponent as FlagCi } from 'assets/icons/flags/ci.svg';
import { ReactComponent as FlagCk } from 'assets/icons/flags/ck.svg';
import { ReactComponent as FlagCl } from 'assets/icons/flags/cl.svg';
import { ReactComponent as FlagCm } from 'assets/icons/flags/cm.svg';
import { ReactComponent as FlagCn } from 'assets/icons/flags/cn.svg';
import { ReactComponent as FlagCo } from 'assets/icons/flags/co.svg';
import { ReactComponent as FlagCr } from 'assets/icons/flags/cr.svg';
import { ReactComponent as FlagCs } from 'assets/icons/flags/cs.svg';
import { ReactComponent as FlagCu } from 'assets/icons/flags/cu.svg';
import { ReactComponent as FlagCv } from 'assets/icons/flags/cv.svg';
import { ReactComponent as FlagCw } from 'assets/icons/flags/cw.svg';
import { ReactComponent as FlagCx } from 'assets/icons/flags/cx.svg';
import { ReactComponent as FlagCy } from 'assets/icons/flags/cy.svg';
import { ReactComponent as FlagCz } from 'assets/icons/flags/cz.svg';
import { ReactComponent as FlagDe } from 'assets/icons/flags/de.svg';
import { ReactComponent as FlagDj } from 'assets/icons/flags/dj.svg';
import { ReactComponent as FlagDk } from 'assets/icons/flags/dk.svg';
import { ReactComponent as FlagDm } from 'assets/icons/flags/dm.svg';
import { ReactComponent as FlagDo } from 'assets/icons/flags/do.svg';
import { ReactComponent as FlagDz } from 'assets/icons/flags/dz.svg';
import { ReactComponent as FlagEc } from 'assets/icons/flags/ec.svg';
import { ReactComponent as FlagEe } from 'assets/icons/flags/ee.svg';
import { ReactComponent as FlagEg } from 'assets/icons/flags/eg.svg';
import { ReactComponent as FlagEh } from 'assets/icons/flags/eh.svg';
import { ReactComponent as FlagEr } from 'assets/icons/flags/er.svg';
import { ReactComponent as FlagEs } from 'assets/icons/flags/es.svg';
import { ReactComponent as FlagEt } from 'assets/icons/flags/et.svg';
import { ReactComponent as FlagEu } from 'assets/icons/flags/eu.svg';
import { ReactComponent as FlagFi } from 'assets/icons/flags/fi.svg';
import { ReactComponent as FlagFj } from 'assets/icons/flags/fj.svg';
import { ReactComponent as FlagFk } from 'assets/icons/flags/fk.svg';
import { ReactComponent as FlagFm } from 'assets/icons/flags/fm.svg';
import { ReactComponent as FlagFo } from 'assets/icons/flags/fo.svg';
import { ReactComponent as FlagFr } from 'assets/icons/flags/fr.svg';
import { ReactComponent as FlagGa } from 'assets/icons/flags/ga.svg';
import { ReactComponent as FlagGb } from 'assets/icons/flags/gb.svg';
import { ReactComponent as FlagGd } from 'assets/icons/flags/gd.svg';
import { ReactComponent as FlagGe } from 'assets/icons/flags/ge.svg';
import { ReactComponent as FlagGf } from 'assets/icons/flags/gf.svg';
import { ReactComponent as FlagGg } from 'assets/icons/flags/gg.svg';
import { ReactComponent as FlagGh } from 'assets/icons/flags/gh.svg';
import { ReactComponent as FlagGi } from 'assets/icons/flags/gi.svg';
import { ReactComponent as FlagGl } from 'assets/icons/flags/gl.svg';
import { ReactComponent as FlagGm } from 'assets/icons/flags/gm.svg';
import { ReactComponent as FlagGn } from 'assets/icons/flags/gn.svg';
import { ReactComponent as FlagGp } from 'assets/icons/flags/gp.svg';
import { ReactComponent as FlagGq } from 'assets/icons/flags/gq.svg';
import { ReactComponent as FlagGr } from 'assets/icons/flags/gr.svg';
import { ReactComponent as FlagGs } from 'assets/icons/flags/gs.svg';
import { ReactComponent as FlagGt } from 'assets/icons/flags/gt.svg';
import { ReactComponent as FlagGu } from 'assets/icons/flags/gu.svg';
import { ReactComponent as FlagGw } from 'assets/icons/flags/gw.svg';
import { ReactComponent as FlagGy } from 'assets/icons/flags/gy.svg';
import { ReactComponent as FlagHk } from 'assets/icons/flags/hk.svg';
import { ReactComponent as FlagHm } from 'assets/icons/flags/hm.svg';
import { ReactComponent as FlagHn } from 'assets/icons/flags/hn.svg';
import { ReactComponent as FlagHr } from 'assets/icons/flags/hr.svg';
import { ReactComponent as FlagHt } from 'assets/icons/flags/ht.svg';
import { ReactComponent as FlagHu } from 'assets/icons/flags/hu.svg';
import { ReactComponent as FlagId } from 'assets/icons/flags/id.svg';
import { ReactComponent as FlagIe } from 'assets/icons/flags/ie.svg';
import { ReactComponent as FlagIl } from 'assets/icons/flags/il.svg';
import { ReactComponent as FlagIm } from 'assets/icons/flags/im.svg';
import { ReactComponent as FlagIn } from 'assets/icons/flags/in.svg';
import { ReactComponent as FlagIo } from 'assets/icons/flags/io.svg';
import { ReactComponent as FlagIq } from 'assets/icons/flags/iq.svg';
import { ReactComponent as FlagIr } from 'assets/icons/flags/ir.svg';
import { ReactComponent as FlagIs } from 'assets/icons/flags/is.svg';
import { ReactComponent as FlagIt } from 'assets/icons/flags/it.svg';
import { ReactComponent as FlagJe } from 'assets/icons/flags/je.svg';
import { ReactComponent as FlagJm } from 'assets/icons/flags/jm.svg';
import { ReactComponent as FlagJo } from 'assets/icons/flags/jo.svg';
import { ReactComponent as FlagJp } from 'assets/icons/flags/jp.svg';
import { ReactComponent as FlagKe } from 'assets/icons/flags/ke.svg';
import { ReactComponent as FlagKg } from 'assets/icons/flags/kg.svg';
import { ReactComponent as FlagKh } from 'assets/icons/flags/kh.svg';
import { ReactComponent as FlagKi } from 'assets/icons/flags/ki.svg';
import { ReactComponent as FlagKm } from 'assets/icons/flags/km.svg';
import { ReactComponent as FlagKn } from 'assets/icons/flags/kn.svg';
import { ReactComponent as FlagKp } from 'assets/icons/flags/kp.svg';
import { ReactComponent as FlagKr } from 'assets/icons/flags/kr.svg';
import { ReactComponent as FlagKw } from 'assets/icons/flags/kw.svg';
import { ReactComponent as FlagKy } from 'assets/icons/flags/ky.svg';
import { ReactComponent as FlagKz } from 'assets/icons/flags/kz.svg';
import { ReactComponent as FlagLa } from 'assets/icons/flags/la.svg';
import { ReactComponent as FlagLb } from 'assets/icons/flags/lb.svg';
import { ReactComponent as FlagLc } from 'assets/icons/flags/lc.svg';
import { ReactComponent as FlagLi } from 'assets/icons/flags/li.svg';
import { ReactComponent as FlagLk } from 'assets/icons/flags/lk.svg';
import { ReactComponent as FlagLr } from 'assets/icons/flags/lr.svg';
import { ReactComponent as FlagLs } from 'assets/icons/flags/ls.svg';
import { ReactComponent as FlagLt } from 'assets/icons/flags/lt.svg';
import { ReactComponent as FlagLu } from 'assets/icons/flags/lu.svg';
import { ReactComponent as FlagLv } from 'assets/icons/flags/lv.svg';
import { ReactComponent as FlagLy } from 'assets/icons/flags/ly.svg';
import { ReactComponent as FlagMa } from 'assets/icons/flags/ma.svg';
import { ReactComponent as FlagMc } from 'assets/icons/flags/mc.svg';
import { ReactComponent as FlagMd } from 'assets/icons/flags/md.svg';
import { ReactComponent as FlagMe } from 'assets/icons/flags/me.svg';
import { ReactComponent as FlagMf } from 'assets/icons/flags/mf.svg';
import { ReactComponent as FlagMg } from 'assets/icons/flags/mg.svg';
import { ReactComponent as FlagMh } from 'assets/icons/flags/mh.svg';
import { ReactComponent as FlagMk } from 'assets/icons/flags/mk.svg';
import { ReactComponent as FlagMl } from 'assets/icons/flags/ml.svg';
import { ReactComponent as FlagMm } from 'assets/icons/flags/mm.svg';
import { ReactComponent as FlagMn } from 'assets/icons/flags/mn.svg';
import { ReactComponent as FlagMo } from 'assets/icons/flags/mo.svg';
import { ReactComponent as FlagMp } from 'assets/icons/flags/mp.svg';
import { ReactComponent as FlagMq } from 'assets/icons/flags/mq.svg';
import { ReactComponent as FlagMr } from 'assets/icons/flags/mr.svg';
import { ReactComponent as FlagMs } from 'assets/icons/flags/ms.svg';
import { ReactComponent as FlagMt } from 'assets/icons/flags/mt.svg';
import { ReactComponent as FlagMu } from 'assets/icons/flags/mu.svg';
import { ReactComponent as FlagMv } from 'assets/icons/flags/mv.svg';
import { ReactComponent as FlagMw } from 'assets/icons/flags/mw.svg';
import { ReactComponent as FlagMx } from 'assets/icons/flags/mx.svg';
import { ReactComponent as FlagMy } from 'assets/icons/flags/my.svg';
import { ReactComponent as FlagMz } from 'assets/icons/flags/mz.svg';
import { ReactComponent as FlagNa } from 'assets/icons/flags/na.svg';
import { ReactComponent as FlagNc } from 'assets/icons/flags/nc.svg';
import { ReactComponent as FlagNe } from 'assets/icons/flags/ne.svg';
import { ReactComponent as FlagNf } from 'assets/icons/flags/nf.svg';
import { ReactComponent as FlagNg } from 'assets/icons/flags/ng.svg';
import { ReactComponent as FlagNi } from 'assets/icons/flags/ni.svg';
import { ReactComponent as FlagNl } from 'assets/icons/flags/nl.svg';
import { ReactComponent as FlagNo } from 'assets/icons/flags/no.svg';
import { ReactComponent as FlagNp } from 'assets/icons/flags/np.svg';
import { ReactComponent as FlagNr } from 'assets/icons/flags/nr.svg';
import { ReactComponent as FlagNu } from 'assets/icons/flags/nu.svg';
import { ReactComponent as FlagNz } from 'assets/icons/flags/nz.svg';
import { ReactComponent as FlagOm } from 'assets/icons/flags/om.svg';
import { ReactComponent as FlagPa } from 'assets/icons/flags/pa.svg';
import { ReactComponent as FlagPe } from 'assets/icons/flags/pe.svg';
import { ReactComponent as FlagPf } from 'assets/icons/flags/pf.svg';
import { ReactComponent as FlagPg } from 'assets/icons/flags/pg.svg';
import { ReactComponent as FlagPh } from 'assets/icons/flags/ph.svg';
import { ReactComponent as FlagPk } from 'assets/icons/flags/pk.svg';
import { ReactComponent as FlagPl } from 'assets/icons/flags/pl.svg';
import { ReactComponent as FlagPm } from 'assets/icons/flags/pm.svg';
import { ReactComponent as FlagPn } from 'assets/icons/flags/pn.svg';
import { ReactComponent as FlagPr } from 'assets/icons/flags/pr.svg';
import { ReactComponent as FlagPs } from 'assets/icons/flags/ps.svg';
import { ReactComponent as FlagPt } from 'assets/icons/flags/pt.svg';
import { ReactComponent as FlagPw } from 'assets/icons/flags/pw.svg';
import { ReactComponent as FlagPy } from 'assets/icons/flags/py.svg';
import { ReactComponent as FlagQa } from 'assets/icons/flags/qa.svg';
import { ReactComponent as FlagRe } from 'assets/icons/flags/re.svg';
import { ReactComponent as FlagRo } from 'assets/icons/flags/ro.svg';
import { ReactComponent as FlagRs } from 'assets/icons/flags/rs.svg';
import { ReactComponent as FlagRu } from 'assets/icons/flags/ru.svg';
import { ReactComponent as FlagRw } from 'assets/icons/flags/rw.svg';
import { ReactComponent as FlagSa } from 'assets/icons/flags/sa.svg';
import { ReactComponent as FlagSb } from 'assets/icons/flags/sb.svg';
import { ReactComponent as FlagSc } from 'assets/icons/flags/sc.svg';
import { ReactComponent as FlagSd } from 'assets/icons/flags/sd.svg';
import { ReactComponent as FlagSe } from 'assets/icons/flags/se.svg';
import { ReactComponent as FlagSg } from 'assets/icons/flags/sg.svg';
import { ReactComponent as FlagSh } from 'assets/icons/flags/sh.svg';
import { ReactComponent as FlagSi } from 'assets/icons/flags/si.svg';
import { ReactComponent as FlagSk } from 'assets/icons/flags/sk.svg';
import { ReactComponent as FlagSl } from 'assets/icons/flags/sl.svg';
import { ReactComponent as FlagSm } from 'assets/icons/flags/sm.svg';
import { ReactComponent as FlagSn } from 'assets/icons/flags/sn.svg';
import { ReactComponent as FlagSo } from 'assets/icons/flags/so.svg';
import { ReactComponent as FlagSr } from 'assets/icons/flags/sr.svg';
import { ReactComponent as FlagSs } from 'assets/icons/flags/ss.svg';
import { ReactComponent as FlagSt } from 'assets/icons/flags/st.svg';
import { ReactComponent as FlagSv } from 'assets/icons/flags/sv.svg';
import { ReactComponent as FlagSx } from 'assets/icons/flags/sx.svg';
import { ReactComponent as FlagSy } from 'assets/icons/flags/sy.svg';
import { ReactComponent as FlagSz } from 'assets/icons/flags/sz.svg';
import { ReactComponent as FlagTc } from 'assets/icons/flags/tc.svg';
import { ReactComponent as FlagTd } from 'assets/icons/flags/td.svg';
import { ReactComponent as FlagTf } from 'assets/icons/flags/tf.svg';
import { ReactComponent as FlagTg } from 'assets/icons/flags/tg.svg';
import { ReactComponent as FlagTh } from 'assets/icons/flags/th.svg';
import { ReactComponent as FlagTj } from 'assets/icons/flags/tj.svg';
import { ReactComponent as FlagTk } from 'assets/icons/flags/tk.svg';
import { ReactComponent as FlagTl } from 'assets/icons/flags/tl.svg';
import { ReactComponent as FlagTm } from 'assets/icons/flags/tm.svg';
import { ReactComponent as FlagTn } from 'assets/icons/flags/tn.svg';
import { ReactComponent as FlagTo } from 'assets/icons/flags/to.svg';
import { ReactComponent as FlagTr } from 'assets/icons/flags/tr.svg';
import { ReactComponent as FlagTt } from 'assets/icons/flags/tt.svg';
import { ReactComponent as FlagTv } from 'assets/icons/flags/tv.svg';
import { ReactComponent as FlagTw } from 'assets/icons/flags/tw.svg';
import { ReactComponent as FlagTz } from 'assets/icons/flags/tz.svg';
import { ReactComponent as FlagUa } from 'assets/icons/flags/ua.svg';
import { ReactComponent as FlagUg } from 'assets/icons/flags/ug.svg';
import { ReactComponent as FlagUs } from 'assets/icons/flags/us.svg';
import { ReactComponent as FlagUy } from 'assets/icons/flags/uy.svg';
import { ReactComponent as FlagUz } from 'assets/icons/flags/uz.svg';
import { ReactComponent as FlagVa } from 'assets/icons/flags/va.svg';
import { ReactComponent as FlagVc } from 'assets/icons/flags/vc.svg';
import { ReactComponent as FlagVe } from 'assets/icons/flags/ve.svg';
import { ReactComponent as FlagVg } from 'assets/icons/flags/vg.svg';
import { ReactComponent as FlagVi } from 'assets/icons/flags/vi.svg';
import { ReactComponent as FlagVn } from 'assets/icons/flags/vn.svg';
import { ReactComponent as FlagVu } from 'assets/icons/flags/vu.svg';
import { ReactComponent as FlagWf } from 'assets/icons/flags/wf.svg';
import { ReactComponent as FlagWs } from 'assets/icons/flags/ws.svg';
import { ReactComponent as FlagXk } from 'assets/icons/flags/xk.svg';
import { ReactComponent as FlagYe } from 'assets/icons/flags/ye.svg';
import { ReactComponent as FlagYt } from 'assets/icons/flags/yt.svg';
import { ReactComponent as FlagZa } from 'assets/icons/flags/za.svg';
import { ReactComponent as FlagZm } from 'assets/icons/flags/zm.svg';
import { ReactComponent as FlagZw } from 'assets/icons/flags/zw.svg';

const FlagIcons = {
  flagAd: FlagAd,
  flagAe: FlagAe,
  flagAf: FlagAf,
  flagAg: FlagAg,
  flagAi: FlagAi,
  flagAl: FlagAl,
  flagAm: FlagAm,
  flagAn: FlagAn,
  flagAo: FlagAo,
  flagAq: FlagAq,
  flagAr: FlagAr,
  flagAs: FlagAs,
  flagAt: FlagAt,
  flagAu: FlagAu,
  flagAw: FlagAw,
  flagAx: FlagAx,
  flagAz: FlagAz,
  flagBa: FlagBa,
  flagBb: FlagBb,
  flagBd: FlagBd,
  flagBe: FlagBe,
  flagBf: FlagBf,
  flagBg: FlagBg,
  flagBh: FlagBh,
  flagBi: FlagBi,
  flagBj: FlagBj,
  flagBl: FlagBl,
  flagBm: FlagBm,
  flagBn: FlagBn,
  flagBo: FlagBo,
  flagBq: FlagBq,
  flagBr: FlagBr,
  flagBs: FlagBs,
  flagBt: FlagBt,
  flagBv: FlagBv,
  flagBw: FlagBw,
  flagBy: FlagBy,
  flagBz: FlagBz,
  flagCa: FlagCa,
  flagCc: FlagCc,
  flagCd: FlagCd,
  flagCf: FlagCf,
  flagCg: FlagCg,
  flagCh: FlagCh,
  flagCi: FlagCi,
  flagCk: FlagCk,
  flagCl: FlagCl,
  flagCm: FlagCm,
  flagCn: FlagCn,
  flagCo: FlagCo,
  flagCr: FlagCr,
  flagCs: FlagCs,
  flagCu: FlagCu,
  flagCv: FlagCv,
  flagCw: FlagCw,
  flagCx: FlagCx,
  flagCy: FlagCy,
  flagCz: FlagCz,
  flagDe: FlagDe,
  flagDj: FlagDj,
  flagDk: FlagDk,
  flagDm: FlagDm,
  flagDo: FlagDo,
  flagDz: FlagDz,
  flagEc: FlagEc,
  flagEe: FlagEe,
  flagEg: FlagEg,
  flagEh: FlagEh,
  flagEr: FlagEr,
  flagEs: FlagEs,
  flagEt: FlagEt,
  flagEu: FlagEu,
  flagFi: FlagFi,
  flagFj: FlagFj,
  flagFk: FlagFk,
  flagFm: FlagFm,
  flagFo: FlagFo,
  flagFr: FlagFr,
  flagGa: FlagGa,
  flagGb: FlagGb,
  flagGd: FlagGd,
  flagGe: FlagGe,
  flagGf: FlagGf,
  flagGg: FlagGg,
  flagGh: FlagGh,
  flagGi: FlagGi,
  flagGl: FlagGl,
  flagGm: FlagGm,
  flagGn: FlagGn,
  flagGp: FlagGp,
  flagGq: FlagGq,
  flagGr: FlagGr,
  flagGs: FlagGs,
  flagGt: FlagGt,
  flagGu: FlagGu,
  flagGw: FlagGw,
  flagGy: FlagGy,
  flagHk: FlagHk,
  flagHm: FlagHm,
  flagHn: FlagHn,
  flagHr: FlagHr,
  flagHt: FlagHt,
  flagHu: FlagHu,
  flagId: FlagId,
  flagIe: FlagIe,
  flagIl: FlagIl,
  flagIm: FlagIm,
  flagIn: FlagIn,
  flagIo: FlagIo,
  flagIq: FlagIq,
  flagIr: FlagIr,
  flagIs: FlagIs,
  flagIt: FlagIt,
  flagJe: FlagJe,
  flagJm: FlagJm,
  flagJo: FlagJo,
  flagJp: FlagJp,
  flagKe: FlagKe,
  flagKg: FlagKg,
  flagKh: FlagKh,
  flagKi: FlagKi,
  flagKm: FlagKm,
  flagKn: FlagKn,
  flagKp: FlagKp,
  flagKr: FlagKr,
  flagKw: FlagKw,
  flagKy: FlagKy,
  flagKz: FlagKz,
  flagLa: FlagLa,
  flagLb: FlagLb,
  flagLc: FlagLc,
  flagLi: FlagLi,
  flagLk: FlagLk,
  flagLr: FlagLr,
  flagLs: FlagLs,
  flagLt: FlagLt,
  flagLu: FlagLu,
  flagLv: FlagLv,
  flagLy: FlagLy,
  flagMa: FlagMa,
  flagMc: FlagMc,
  flagMd: FlagMd,
  flagMe: FlagMe,
  flagMf: FlagMf,
  flagMg: FlagMg,
  flagMh: FlagMh,
  flagMk: FlagMk,
  flagMl: FlagMl,
  flagMm: FlagMm,
  flagMn: FlagMn,
  flagMo: FlagMo,
  flagMp: FlagMp,
  flagMq: FlagMq,
  flagMr: FlagMr,
  flagMs: FlagMs,
  flagMt: FlagMt,
  flagMu: FlagMu,
  flagMv: FlagMv,
  flagMw: FlagMw,
  flagMx: FlagMx,
  flagMy: FlagMy,
  flagMz: FlagMz,
  flagNa: FlagNa,
  flagNc: FlagNc,
  flagNe: FlagNe,
  flagNf: FlagNf,
  flagNg: FlagNg,
  flagNi: FlagNi,
  flagNl: FlagNl,
  flagNo: FlagNo,
  flagNp: FlagNp,
  flagNr: FlagNr,
  flagNu: FlagNu,
  flagNz: FlagNz,
  flagOm: FlagOm,
  flagPa: FlagPa,
  flagPe: FlagPe,
  flagPf: FlagPf,
  flagPg: FlagPg,
  flagPh: FlagPh,
  flagPk: FlagPk,
  flagPl: FlagPl,
  flagPm: FlagPm,
  flagPn: FlagPn,
  flagPr: FlagPr,
  flagPs: FlagPs,
  flagPt: FlagPt,
  flagPw: FlagPw,
  flagPy: FlagPy,
  flagQa: FlagQa,
  flagRe: FlagRe,
  flagRo: FlagRo,
  flagRs: FlagRs,
  flagRu: FlagRu,
  flagRw: FlagRw,
  flagSa: FlagSa,
  flagSb: FlagSb,
  flagSc: FlagSc,
  flagSd: FlagSd,
  flagSe: FlagSe,
  flagSg: FlagSg,
  flagSh: FlagSh,
  flagSi: FlagSi,
  flagSj: FlagBv,
  flagSk: FlagSk,
  flagSl: FlagSl,
  flagSm: FlagSm,
  flagSn: FlagSn,
  flagSo: FlagSo,
  flagSr: FlagSr,
  flagSs: FlagSs,
  flagSt: FlagSt,
  flagSv: FlagSv,
  flagSx: FlagSx,
  flagSy: FlagSy,
  flagSz: FlagSz,
  flagTc: FlagTc,
  flagTd: FlagTd,
  flagTf: FlagTf,
  flagTg: FlagTg,
  flagTh: FlagTh,
  flagTj: FlagTj,
  flagTk: FlagTk,
  flagTl: FlagTl,
  flagTm: FlagTm,
  flagTn: FlagTn,
  flagTo: FlagTo,
  flagTr: FlagTr,
  flagTt: FlagTt,
  flagTv: FlagTv,
  flagTw: FlagTw,
  flagTz: FlagTz,
  flagUa: FlagUa,
  flagUg: FlagUg,
  flagUm: FlagUs,
  flagUs: FlagUs,
  flagUy: FlagUy,
  flagUz: FlagUz,
  flagVa: FlagVa,
  flagVc: FlagVc,
  flagVe: FlagVe,
  flagVg: FlagVg,
  flagVi: FlagVi,
  flagVn: FlagVn,
  flagVu: FlagVu,
  flagWf: FlagWf,
  flagWs: FlagWs,
  flagXk: FlagXk,
  flagYe: FlagYe,
  flagYt: FlagYt,
  flagZa: FlagZa,
  flagZm: FlagZm,
  flagZw: FlagZw,
} as const;

export default FlagIcons;
