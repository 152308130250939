import keys from 'lodash/keys';
import { Constants } from 'constants/Constants';

export interface IBaseTableFilters {
  page?: number;
  per_page?: number;
  sort?: string[];
  include?: string[];
}

export interface IBaseTableFiltersRequestModel extends IBaseTableFilters {}

const defaultCursorTableFilter: IBaseTableFilters = Object.freeze({
  per_page: Constants.PagingDefault as number,
});

const defaultTableFilter: IBaseTableFilters = Object.freeze({
  page: 1,
  ...defaultCursorTableFilter,
});

const getDefaultTableFilter = (): IBaseTableFilters => defaultTableFilter;
export const getDefaultCursorTableFilter = (): IBaseTableFilters =>
  defaultCursorTableFilter;

export const defaultTableFilterKeys = Object.freeze(keys(defaultTableFilter));
export const defaultTableFilterKeysWithSort = Object.freeze([
  ...defaultTableFilterKeys,
  'sort',
]);

export const baseTableFilterToRequestModelMapper = ({
  page,
  per_page,
  sort,
  include,
}: IBaseTableFilters): IBaseTableFiltersRequestModel => ({
  page,
  per_page,
  sort,
  include,
});

export const baseTableRequestModelToFilterMapper = ({
  page,
  per_page,
  sort,
  include,
}: IBaseTableFiltersRequestModel): IBaseTableFilters => ({
  page,
  per_page,
  sort,
  include,
});

export default getDefaultTableFilter;
