export default {
  add_tracking_domain: 'Добавить трекинг-домен',
  no_tracking_domains: 'Нет созданных трекинг-доменов',
  name: 'Домен для трекинга',
  protocol: 'Протокол',
  default_tracking_domain: 'Домен по умолчанию',
  status: 'Статус',
  status_checked: 'Прошел проверку',
  status_not_checked: 'Не прошёл проверку',
  verify: 'Проверить',
  tracking_domain_successfully_created: 'Домен успешно добавлен',
  tracking_domain_successfully_updated: 'Домен успешно обновлен',
  tracking_domain_adding: 'Добавление домена',
  tracking_domain_editing: 'Редактирование домена',
  tracking_domain_instructions_do:
    'В настройках DNS-записей домена добавьте CNAME-запись вида:',
  tracking_domain_instructions_after:
    'После того, как CNAME-записи обновятся, нажмите кнопку "Проверить", и домен будет готов к использованию в офферах',
  tracking_domain_name: 'Название домена',
  tracking_domain_name_placeholder: 'Введите название домена',
  tracking_domain_remove_confirm: 'Вы хотите удалить домен?',
  tracking_domain_verify_pass: 'Домен успешно прошёл проверку',
  tracking_domain_verify_not_pass: 'Домен не прошёл проверку',
  tracking_domain_changed_default: 'Домен по умолчанию изменен',
  tracking_domain_replace: 'Заменить домены для реф.ссылок',
  tracking_domain_replace_in_offers: 'Замена домена в офферах',
  tracking_domain_replaced_in_offers: 'Трекинг-домен в офферах успешно заменён',
  tracking_domain_replace_offers: 'Офферы',
  tracking_domain_replace_domain: 'Трекинг-домен',
  tracking_domain_replace_domain_tooltip: 'Нет проверенных доменов',
  tracking_domain_replace_in_offers_help:
    'Для офферов, выбранных в поле "Офферы", будет заменен текущий трекинг-домен на домен, указанный в поле "Трекинг-домен"',
  tracking_domain_target: 'Заменить домены в таргет ссылках',
  tracking_domain_target_offers: 'Офферы',
  tracking_domain_target_old_domain: 'Старый домен',
  tracking_domain_target_new_domain: 'Новый домен',
  tracking_domain_target_new_domain_hint:
    'Можно указать домен любого уровня. Будет произведена полная замена всего домена в ссылке.',
  tracking_domain_target_domain_placeholder:
    'Введите домен формата: example.com',
  tracking_domain_target_replace_in: 'Заменить в',
  tracking_domain_target_link: 'Таргет-ссылка оффера',
  tracking_domain_target_trafficback: 'Trafficback URL оффера',
  tracking_domain_target_landings: 'Лендинги',
  tracking_domain_target_landings_all:
    'Лендинги, принадлежащие продукту и офферу',
  tracking_domain_target_landings_offers: 'Лендинги, принадлежащие офферу',
  tracking_domain_target_landings_help: `Выберите лендинги, в к-ых следует заменить домен
    <ul>
      <li>
        При выборе <strong>$t(trackingDomains:tracking_domain_target_landings_all)</strong> домен будет заменен в лендингах, к-ые принадлежат продуктам выбранных офферов, а так же в лендингах, которые принадлежат выбранным офферам.
      </li>
      <li>
        При выборе <strong>$t(trackingDomains:tracking_domain_target_landings_offers)</strong> заменены будут домены в лендингах, к-ые привязаны только к офферу. Домены лендингов, привязанных к продукту и доступные в выбранных офферах останутся без изменений.
      </li>
    </ul>
    `,
  tracking_domain_target_replace_confirm:
    'Подтверждаете замену домена в таргет-ссылках?',
  tracking_domain_target_replace_confirm_hint:
    'После замены проверьте корректность замены таргет-домена и корректность работы реф. ссылок. Изменения можно отменить в течение 7 минут на этой странице.',
  tracking_domain_target_report_successful: 'Успешных замен',
  tracking_domain_target_report_unsuccessful: 'Неуспешных',
  tracking_domain_target_report_ids:
    'ID офферов, в которых не удалось изменить таргет-домен',
  tracking_domain_target_cancel: 'Отменить замену таргет ссылок',
  tracking_domain_target_cancel_confirm:
    'Вы действительно хотите отменить действие?',
  tracking_domain_target_report: 'Отчёт',
};
