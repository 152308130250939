import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Settings = {
  GET_GENERAL_SETTINGS: {
    url: generatePartnerApiUrl('common', ModelPath.Settings),
    method: 'GET',
  },
  GET_GENERAL_MENU_SETTINGS: {
    url: generatePartnerApiUrl('menu/links', ModelPath.Settings),
    method: 'GET',
  },
} as const;

export default Settings;
