import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const PaymentSystem = {
  GET_PAYMENT_SYSTEMS: {
    url: generateAdminApiUrl(ModelPath.PaymentSystem),
    method: 'GET',
  },
  CREATE_PAYMENT_SYSTEM: {
    url: generateAdminApiUrl(ModelPath.PaymentSystem),
    method: 'POST',
  },
  GET_PAYMENT_SYSTEM_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.PaymentSystem),
    method: 'GET',
  },
  UPDATE_PAYMENT_SYSTEM_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.PaymentSystem),
    method: 'PATCH',
  },
  DELETE_PAYMENT_SYSTEM_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.PaymentSystem),
    method: 'DELETE',
  },
} as const;

export default PaymentSystem;
