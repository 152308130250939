import React from 'react';
import { Navigate } from 'react-router-dom';

import ErrorPage from 'pages/Error';
import { Path } from './Path';
import IRoute from 'models/IRoute';
import { RouteKey } from './RouteKey';
import { getDefaultPath } from 'utils/apiUtils';

const routes: IRoute[] = [
  {
    id: RouteKey.Main,
    path: Path.Main,
    exact: true,
    element: <Navigate to={getDefaultPath()} replace />,
    security: [],
    title: '',
  },
  {
    id: RouteKey.Error,
    element: <ErrorPage />,
    security: [],
    title: null,
    layout: false,
  },
];

export default routes;
