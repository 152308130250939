import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Wallet = {
  CREATE_WALLET: {
    url: generatePartnerApiUrl(ModelPath.Wallet),
    method: 'POST',
    bodyMapping: [
      'payment_system_id',
      'currency_code',
      'field_values',
      {
        key: '2fa_code',
        optional: true,
      },
    ],
    twoFactorAuth: true,
  },
  EDIT_WALLET: {
    url: generatePartnerApiUrl(':id', ModelPath.Wallet),
    method: 'PATCH',
    bodyMapping: ['wallet'],
  },
  DELETE_WALLET: {
    url: generatePartnerApiUrl(':id', ModelPath.Wallet),
    method: 'DELETE',
    twoFactorAuth: true,
  },
  GET_WALLETS: {
    url: generatePartnerApiUrl(ModelPath.Wallet),
    method: 'GET',
  },
  GET_WALLET_BY_ID: {
    url: generatePartnerApiUrl(':id', ModelPath.Wallet),
    method: 'GET',
  },
  SET_WALLET_AS_DEFAULT: {
    url: generatePartnerApiUrl(':id/default', ModelPath.Wallet),
    method: 'PATCH',
    twoFactorAuth: true,
  },
} as const;

export default Wallet;
