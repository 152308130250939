export default {
  add_categories: 'Add new category',
  no_categories: 'No categories',
  name: 'Category name',
  category_editing: 'Editing the category',
  category_adding: 'Addition a category',
  category_name: 'Category name',
  category_name_placeholder: 'Enter category name',
  category_successfully_created: 'Category successfully added',
  category_successfully_updated: 'Category successfully updated',
  category_remove_confirm: 'Do you want to delete the category?',
};
