import { generateAdvertiserApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Offer = {
  GET_OFFERS: {
    url: generateAdvertiserApiUrl(ModelPath.Offer),
    method: 'GET',
  },
} as const;

export default Offer;
