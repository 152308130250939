import { generateAdminApiUrl } from 'utils/apiUtils';

const Cdn = {
  UPLOAD_LOGO: {
    url: generateAdminApiUrl('logo', '', ''),
    method: 'POST',
    bodyMapping: [{ key: 'resource', optional: false }],
  },
  UPLOAD_FAVICON: {
    url: generateAdminApiUrl('favicon', '', ''),
    method: 'POST',
    bodyMapping: [{ key: 'resource', optional: false }],
  },
  UPLOAD_POSTER: {
    url: generateAdminApiUrl('poster', '', ''),
    method: 'POST',
    bodyMapping: [{ key: 'resource', optional: false }],
  },
  UPLOAD_PROMO: {
    url: generateAdminApiUrl('promo', '', ''),
    method: 'POST',
    bodyMapping: [{ key: 'resource', optional: false }],
  },
} as const;

export default Cdn;
