import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const TrackingLink = {
  GET_LINK_BY_OFFER_ID: {
    url: generatePartnerApiUrl(':offer_id', ModelPath.TrackingLink),
    method: 'GET',
  },
} as const;

export default TrackingLink;
