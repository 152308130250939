import React, { HTMLProps } from 'react';
import cn from 'classnames';
import emptyObject from 'utils/emptyObject';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';

import { Color } from 'constants/enums/Color';
import Icons from './Icons';
import Tooltip from 'components/Tooltip';
import { TooltipOptions } from 'components/Tooltip/Tooltip';

export type IconSrc = keyof typeof Icons;

export interface IIconProps extends HTMLProps<HTMLSpanElement> {
  src: IconSrc;
  className?: string;
  style?: React.CSSProperties;
  color?: Color | 'currentColor' | 'none' | string;
  strokeColor?: Color | 'currentColor' | 'none' | null;
  width?: string | number | 'initial';
  height?: string | number | 'initial';
  tooltip?: React.ReactNode | TooltipOptions;
  type?: 'svg' | 'png';
}

const Icon: React.FC<IIconProps> = ({
  onClick,
  style = {},
  width,
  height,
  color,
  strokeColor,
  src,
  className,
  tooltip,
  type = 'svg',
  ...rest
}): JSX.Element => {
  const ImageIcon = Icons[src];
  const iconStyles: React.CSSProperties = {
    width,
    height,
    ...style,
  };
  let tooltipOptions: TooltipOptions = {
    overlay: undefined,
    delay: 400,
  };
  if (!isNil((tooltip as TooltipOptions)?.overlay)) {
    tooltipOptions = {
      ...tooltipOptions,
      ...(tooltip || emptyObject),
    };
  } else {
    tooltipOptions.overlay = tooltip as React.ReactNode;
  }
  return (
    <>
      {ImageIcon && (
        <Tooltip {...tooltipOptions}>
          <span
            role="img"
            onClick={onClick}
            className={cn(
              'icon',
              {
                [`icon--${color}`]: !!color,
                [`icon__stroke--${strokeColor}`]: !!strokeColor,
              },
              className,
            )}
            {...rest}
          >
            {isString(ImageIcon) ? (
              <img src={ImageIcon} alt={src} />
            ) : (
              <ImageIcon style={iconStyles} />
            )}
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default Icon;
