import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Landing = {
  GET_LANDINGS: {
    url: generatePartnerApiUrl(ModelPath.Landing),
    method: 'GET',
  },
} as const;

export default Landing;
