import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const News = {
  GET_ALL: {
    url: generateAdminApiUrl(ModelPath.News),
    method: 'GET',
  },
  CREATE: {
    url: generateAdminApiUrl(ModelPath.News),
    method: 'POST',
  },
  GET: {
    url: generateAdminApiUrl(':id', ModelPath.News),
    method: 'GET',
  },
  UPDATE: {
    url: generateAdminApiUrl(':id', ModelPath.News),
    method: 'PATCH',
  },
  DELETE: {
    url: generateAdminApiUrl(':id', ModelPath.News),
    method: 'DELETE',
  },
} as const;

export default News;
