import { getClientId } from 'utils/storageUtils';
import AdminApi from 'modules/admin/api';
import PartnerApi from 'modules/partner/api';
import AdvertiserApi from 'modules/advertiser/api';
import { ClientId } from 'constants/ClientId';

export type AdminApiType = typeof AdminApi;
export type AdvertiserApiType = typeof AdvertiserApi;
export type PartnerApiType = typeof PartnerApi;

/**
 * Return API configuration for user type
 * @param clientId - user type
 *
 * @throws {@link Error}
 * This exception is thrown if `clientId` not fount
 *
 * @return API configuration
 */
export const getApi = (
  clientId = getClientId(),
): AdminApiType | AdvertiserApiType | PartnerApiType => {
  switch (clientId) {
    case ClientId.PARTNER:
      return PartnerApi;
    case ClientId.ADVERTISER:
      return AdvertiserApi;
    case ClientId.ADMIN:
      return AdminApi;
    default:
      throw new Error('Api not found');
  }
};
