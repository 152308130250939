export default {
  AB: 'Abjasio',
  AA: 'Afar',
  AF: 'Africano',
  AK: 'Akán',
  SQ: 'Albanés',
  AM: 'Amhárico',
  AR: 'Árabe',
  AN: 'Aragonés',
  HY: 'Armenio',
  AS: 'Asamés',
  AV: 'Ávaro',
  AE: 'Avtista',
  AY: 'Aymar',
  AZ: 'Azerbaiyano',
  BM: 'Bambara',
  BA: 'Bashkir',
  EU: 'Euskera',
  BE: 'Bielorruso',
  BN: 'Bengalí',
  BI: 'Bislama',
  BS: 'Bosnio',
  BR: 'Bretón',
  BG: 'Búlgaro',
  MY: 'Birmano',
  CA: 'Catalán',
  CH: 'Chamorro',
  CE: 'Checheno',
  NY: 'Nyanja',
  ZH: 'Chino',
  CV: 'Chuwash',
  KW: 'Cornés',
  CO: 'Córcega',
  CR: 'Cree',
  HR: 'Croata',
  CS: 'Checo',
  DA: 'Danés',
  DV: 'Divehi (Maldivas)',
  NL: 'Holandés',
  DZ: 'Bhután',
  EN: 'Inglés',
  EO: 'Esperanto',
  ET: 'Estonio',
  EE: 'Eva.',
  FO: 'Faroe',
  FJ: 'Fiji',
  FI: 'Finlandés',
  FR: 'Francés',
  FF: 'Fulah',
  GL: 'Gallego',
  KA: 'Georgiano',
  DE: 'Alemán',
  EL: 'Griego',
  GN: 'Guaraní',
  GU: 'Gujarati',
  HT: 'Criollo haitiano',
  HA: 'Casa',
  HE: 'Judío',
  HZ: 'Guerero',
  HI: 'Hindi',
  HO: 'Hirimoto',
  HU: 'Húngaro',
  IA: 'Interlingua',
  ID: 'Indonesio',
  IE: 'Interlingua',
  GA: 'Irlandés',
  IG: 'Igbo',
  IK: 'Inupiaco',
  IO: 'Ido',
  IS: 'Islandés',
  IT: 'Italiano',
  IU: 'Inuctilidad',
  JA: 'Japonés',
  JV: 'Javanés',
  KL: 'Groenlandia',
  KN: 'Canadá',
  KR: 'Kanuri',
  KS: 'Cachemira (Cachemira)',
  KK: 'Kazajo',
  KM: 'Camboyano',
  KI: 'Kikuyu',
  RW: 'Kinyarwanda (Rwanda)',
  KY: 'Kirguistán',
  KV: 'Comi',
  KG: 'Congo',
  KO: 'Coreano',
  KU: 'Kurdo',
  KJ: 'Kinyama',
  LA: 'Latín',
  LB: 'Luxemburgués',
  LG: 'Ganda',
  LI: 'Limburgo (Limburger)',
  LN: 'Lingala',
  LO: 'Laosiano',
  LT: 'Lituano',
  LU: 'Luba Katanga',
  LV: 'Letón (Letón)',
  GV: 'Gaélico (idioma de la Isla de Man)',
  MK: 'Macedonio',
  MG: 'Malgache',
  MS: 'Malayo',
  ML: 'Malayalam',
  MT: 'Maltés',
  MI: 'Maorí',
  MR: 'Marathi',
  MH: 'Marshall',
  MN: 'Mongol',
  NA: 'Nauruano',
  NV: 'Navajo',
  ND: 'Ndebele del Norte',
  NE: 'Nepalés',
  NG: 'Ndunga',
  NB: 'Bokmol',
  NN: 'Nynorsk (nuevo noruego)',
  NO: 'Noruego',
  II: 'Sichuan Y',
  NR: 'Ndebele del Sur',
  OC: 'Oxitano',
  OJ: 'Ojibwe',
  CU: 'Eslavo eclesiástico (antiguo eslavo)',
  OM: 'Oromo (Afan, Galla)',
  OR: 'Oria',
  OS: 'Osetia',
  PA: 'Punjabí (Panjabí)',
  PI: 'Pali',
  FA: 'Farsi',
  PL: 'Polaco',
  PS: 'Pashto (Pushto)',
  PT: 'Portugués',
  QU: 'Quechua',
  RM: 'Retrorománico',
  RN: 'Kirundi (Rundy)',
  RO: 'Rumano',
  RU: 'Ruso',
  SA: 'Sánscrito',
  SC: 'Cerdeño',
  SD: 'Sindhi',
  SE: 'Sami del Norte',
  SM: 'Samoano',
  SG: 'Sangro',
  SR: 'Serbio',
  GD: 'Galés (Escocés)',
  SN: 'Shauna',
  SI: 'Cingalés (Singalés)',
  SK: 'Eslovaco',
  SL: 'Esloveno',
  SO: 'Somalí',
  ST: 'Nido del Norte',
  ES: 'Español',
  SU: 'Sudanés',
  SW: 'Swahili',
  SS: 'Swati',
  SV: 'Sueco',
  TA: 'Tamil',
  TE: 'Telugu',
  TG: 'Tayikistán',
  TH: 'Tailandés',
  TI: 'Tigrina',
  BO: 'Tibetano',
  TK: 'Turcomano',
  TL: 'Tagalo',
  TN: 'Tswana (Setswana)',
  TO: 'Tonga (tongano)',
  TR: 'Turco',
  TS: 'Tsonga',
  TT: 'Tártaro',
  TW: 'Twi (Twi)',
  TY: 'Tahitiano',
  UG: 'Uigur',
  UK: 'Ucraniano',
  UR: 'Urdu',
  UZ: 'Uzbekistán',
  VE: 'Vendida',
  VI: 'Vietnamita',
  VO: 'Volapyuk',
  WA: 'Valón',
  CY: 'Galés (Gales)',
  WO: 'Wolof',
  FY: 'Frisón',
  XH: 'Kos',
  YI: 'Yiddish',
  YO: 'Yoruba',
  ZA: 'Zhuang',
  ZU: 'Zulú',
  BH: 'Bihariano',
  MO: 'Moldavo',
  SH: 'Serbio-Croata',
};
