export default {
  partners: 'Socios',
  'create partner': 'Crear un socio',
  'partner creation': 'Creación de un socio',
  email: 'Email',
  password: 'Contraseña',
  'partner successfully created': 'Socio creado con éxito',
  no_partners: 'No hay datos de socios',
  id_user: 'ID de usuario',
  telegram: 'Telegrama',
  status: 'Estado',
  creation_date: 'Fecha de creación',
  id: 'ID',
  created: 'Creado',
  edit_profile: 'Editar perfil',
  offers: 'Ofertas',
  incomplete_registration: 'Inscripción incompleta',
  active: 'Activo',
  partner_successfully_updated: 'Socio actualizado con éxito',
  banned: 'Prohibido',
  tied_manager: 'Gerente atado',
  balance: 'Equilibrio',
  hold: 'Hold',
  referral_balance: 'Balance de referencia',
  referral_hold_balance: 'Hold ref.equilibrio',
  list_of_partners: 'Lista de socios',
  individual_referral_percent: 'Porcentaje de referencia individual',
  referral_percent: 'Porcentaje de referencia',
  revshare_balance: 'Balance de revólveres',
  revshare_hold_balance: 'Balance Hold Revólveres',
  tags: 'Etiquetas',
  note: 'Nota interna',
  source_of_registration: 'Fuente de registro',
  administration: 'Actividad',
  registration: 'Registro',
  contacts: 'Contactos',
  addition_info: 'Información adicional',
  link_generator: 'Generador de enlaces',
  link_statistics: 'Estadísticas',
  link_conversion: 'Conversiónes',
  link_promocode: 'Códigos promocionales',
  link_cohort_analysis: 'Análisis de cohortes',
  payment_method: 'Método de pago',
  no_payment_methods: 'No hay métodos de pago',
  remove_wallet: '¿Eliminar método de pago?',
  remove_wallet_confirm: '¿Seguro que desea eliminar el método de pago?',
  balance_title: 'Saldo',
  balance_all_currencies: 'Todas las monedas',
  balance_all: 'Total',
  balance_all_tooltip: 'La cantidad total de fondos disponibles',
  balance_revshare: 'Saldo de Revshare',
  balance_revshare_tooltip:
    'El saldo ganado de acuerdos de ingresos compartidos',
  balance_referral: 'Saldo de referidos',
  balance_referral_tooltip: 'El saldo ganado por referir a otros usuarios',
  balance_cpa: 'CPA',
  balance_cpa_tooltip:
    'El saldo ganado por acciones específicas de usuarios que llevan a conversiones',
  balance_in_hold: 'en espera',
  referral: 'Referido',
  referral_id: 'ID de socios',
  referral_commission: 'Comisión de subafiliados',
  referral_email: 'E-mail del socio',
  referral_registration: 'Registro',
  no_referrals: 'Sin remisiones',
  invoice: 'Factura',
  postback: 'Postback',
  postback_global: 'Global',
  postback_local: 'Privado',
  postback_global_label: 'Nivel',
  postback_filter: 'ID o nombre de oferta',
};
