export default {
  add_tracking_domain: 'Add domain for tracking',
  no_tracking_domains: 'No domains for tracking',
  name: 'Domain for tracking',
  protocol: 'Protocol',
  default_tracking_domain: 'Default domain',
  status: 'Status',
  status_checked: 'Passed verification',
  status_not_checked: 'Not passed verification',
  verify: 'Verify',
  tracking_domain_successfully_created: 'Domain successfully added',
  tracking_domain_successfully_updated: 'Domain successfully updated',
  tracking_domain_adding: 'Adding domain',
  tracking_domain_editing: 'Editing domain',
  tracking_domain_instructions_do:
    "In the domain's DNS record settings, add a CNAME record of the form:",
  tracking_domain_instructions_after:
    'After the CNAME records are updated, click the “Verify” button and the domain will be ready for use in offers',
  tracking_domain_name: 'Domain name',
  tracking_domain_name_placeholder: 'Enter the domain name',
  tracking_domain_remove_confirm: 'Do you want to delete the domain?',
  tracking_domain_verify_pass: 'The domain successfully verified',
  tracking_domain_verify_not_pass: 'The domain failed verification',
  tracking_domain_changed_default: 'Default domain has been changed',
  tracking_domain_replace: 'Replace domains for referral links',
  tracking_domain_replace_in_offers: 'Replace domain in offers',
  tracking_domain_replaced_in_offers:
    'Tracking domain in offers successfully replaced',
  tracking_domain_replace_offers: 'Offers',
  tracking_domain_replace_domain: 'Tracking domain',
  tracking_domain_replace_domain_tooltip: 'No verified domains',
  tracking_domain_replace_in_offers_help:
    'The current tracking domain of selected offers will be replaced with the domain from the "Tracking domain" field',
  tracking_domain_target: 'Replace domains in target links',
  tracking_domain_target_offers: 'Offers',
  tracking_domain_target_old_domain: 'Old domain',
  tracking_domain_target_new_domain: 'New domain',
  tracking_domain_target_new_domain_hint:
    'You can specify a domain of any level. The entire domain in the link will be completely replaced.',
  tracking_domain_target_domain_placeholder:
    'Enter the domain format: example.com',
  tracking_domain_target_replace_in: 'Replace in',
  tracking_domain_target_link: 'Target link of the offer',
  tracking_domain_target_trafficback: 'Trafficback URL of the offer',
  tracking_domain_target_landings: 'Landings',
  tracking_domain_target_landings_all:
    'Landings belonging to product and offer',
  tracking_domain_target_landings_offers: 'Landings belonging to offer',
  tracking_domain_target_landings_help: `Select landings where you want to replace the domain
    <ul>
      <li>
        When choosing <strong>$t(trackingDomains:tracking_domain_target_landings_all)</strong> the domain will be replaced in the web pages belonging to the products of the selected providers, as well as in the web pages belonging to the selected providers.
      </li>
      <li>
        When choosing <strong>$t(trackingDomains:tracking_domain_target_landings_offers)</strong> domains in the product-linked webpages will be replaced, which are linked only to the offer. The domains of product-linked webpages available in the selected offerers will remain unchanged.
      </li>
    </ul>
    `,
  tracking_domain_target_replace_confirm:
    'Do you confirm domain replacement in target links?',
  tracking_domain_target_replace_confirm_hint:
    'After the replacement, check that the target domain has been replaced correctly and that the referral links are working correctly. Changes can be canceled within 7 minutes on this page.',
  tracking_domain_target_report_successful: 'Successful replacements',
  tracking_domain_target_report_unsuccessful: 'Unsuccessful',
  tracking_domain_target_report_ids:
    'IDs of offers where the target domain could not be changed',
  tracking_domain_target_cancel: 'Cancel target links replacement',
  tracking_domain_target_cancel_confirm:
    'Are you sure you want to cancel the action?',
  tracking_domain_target_cancel_success:
    'Are you sure you want to cancel the action?',
  tracking_domain_target_report: 'Report',
};
