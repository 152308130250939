export default {
  add_goal_groups: 'Добавить группу целей',
  no_goal_groups: 'Нет группы целей',
  name: 'Название группы целей',
  name_of_goal: 'Название цели',
  key_of_goal: 'Ключ',
  key_of_goal_hint:
    'Ключ группы целей будет использоваться как значение по умолчанию для макроса {goal} в глобальных постбеках у партнера. Значение будет приведено в нижний регистр.',
  product: 'Продукт',
  goal_group_editing: 'Редактирование группы целей',
  goal_group_adding: 'Добавление группы целей',
  goal_group_name: 'Название группы целей',
  goal_group_key: 'Ключ группы целей',
  goal_group_name_placeholder: 'Введите название группы целей',
  goal_group_key_placeholder: 'Введите ключ группы целей',
  goal_group_successfully_created: 'Группа целей успешно добавлена',
  goal_group_successfully_updated: 'Группа целей успешно изменена',
  goal_group_remove_confirm: 'Вы хотите удалить группу целей?',
};
