import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const GoalGroup = {
  GET_GOAL_GROUPS: {
    url: generatePartnerApiUrl(ModelPath.GoalGroup),
    method: 'GET',
  },
} as const;

export default GoalGroup;
