export default {
  payments: 'Pagos',
  email: 'Email',
  amount: 'Cantidad',
  payment_system: 'Sistema de pago',
  wallet: 'Monedero',
  creation_date: 'Fecha',
  status: 'Estado',
  approve: 'Aprobar',
  pay: 'Pagar',
  reject: 'Rechazar',
  paid_in_part: 'Pagado parcialmente',
  corrections_one: 'corrección',
  corrections_other: 'correcciones',
  paid: 'Pagado',
  details: 'Más detalles',
  revshare: 'Revshare',
  payment_status_successfully_updated: 'Estado de pago actualizado con éxito',
  payment_successfully_deleted: 'Pago eliminado con éxito',
  payment_successfully_rejected: 'Pago rechazado con éxito',
  comment: 'Comentario',
  internal_note: 'Nota interna',
  comment_for_partner: 'Comentario para el socio',
  write_your_comment_for_partner: 'Escribe tu comentario para un socio',
  no_payments: 'Sin datos de pago',
  partner_id: 'ID de socio',
  partner: 'Socio',
  currency_any: 'Cualquiera',
  payment_systems: 'Sistemas de pago',
  payment_system_name: 'Nombre del sistema de pago',
  add_payment_system: 'Añadir sistema de pago',
  no_payment_systems: 'Sin sistemas de pago',
  payment_system_editing: 'Editar el sistema de pago',
  payment_system_adding: 'Agregar un sistema de pago',
  payment_system_add_wallet_field: 'Añadir campo',
  payment_system_wallet_fields: 'Campos de billetera',
  payment_system_wallet_field_name: 'Nombre',
  payment_system_wallet_field_required: 'Campo obligatorio',
  payment_system_successfully_created: 'Sistema de pago añadido con éxito',
  payment_system_successfully_updated: 'Sistema de pago cambiado con éxito',
  payment_system_remove_confirm:
    '¿Desea eliminar el sistema de pago "{{name}}"?',
  generating_now:
    'El proceso de elaboración de las cuentas está en marcha. Esto puede llevar algún tiempo.',
  tab_pending: 'Esperando',
  tab_approved: 'Aprobados',
  tab_payed: 'Pagados',
  tab_rejected: 'Rechazados',
  generate: 'Generar',
  generate_new_invoices: 'Generar nuevas cuentas',
  generate_invoices: 'Generar cuentas',
  generate_offer: 'Oferta',
  invoice: 'Cuenta',
  wallet_details: 'Accesorios',
  offer_conversions: 'Conversiones',
  offer_operation: 'Operación',
  offer_description: 'Descripción',
  offer_conversions_amount: 'Cantidad',
  correction: 'Corrección',
  correction_help:
    'El ajuste permite reducir o aumentar el monto de la cuenta. Cuando se agrega un ajuste, se indica la cantidad en la que se debe cambiar la cuenta. El importe del ajuste puede indicarse con un signo más o menos. Si el importe se indica con un signo más (no se mostrará), el importe total de la cuenta aumentará. Si el importe se indica con un signo menos, el importe total de la cuenta disminuirá. También se puede añadir un comentario explicativo para hacer ajustes.',
  total_conversions: 'Total de conversiones de CPA',
  total_corrections: 'Total de correcciones',
  total_referral_balance: 'Balance de referencia',
  total_revshare_balance: 'Balance de revólveres',
  total_cpm_balance: 'Total de conversiones de CPM',
  total: 'Total',
  prepayment: 'Pago por adelantado',
  conversions_date_offer: 'Fecha & Oferta',
  conversions_conversion: 'Conversión',
  conversions_payment: 'Pago',
  threshold: 'Cantidad mínima de retiro',
  generate_form_success:
    'Las cuentas se están formando y se mostrarán en la pestaña Pendiente. Actualice la página para obtener datos',
  generate_form_partner_placeholder:
    'Introduzca el ID o el correo electrónico del socio',
  generate_form_offer_placeholder: 'Introduzca el id o el nombre de la oferta',
  generate_form_product_placeholder:
    'Introduzca el id o el nombre de la producto',
  generate_form_threshold_help:
    'Si se marca, la cuenta se formará si el monto total es mayor que el monto mínimo establecido para el retiro. De lo contrario, la cuenta puede ser formada con cualquier cantidad, incluso negativa.',
  generate_form_use_threshold: 'Considerar la cantidad mínima de retiro',
  invoice_to_approved: 'Aprobar',
  invoice_to_payed: 'Pagar en su totalidad',
  invoice_to_rejected: 'Rechazar',
  invoice_to_pending: 'En espera',
  invoice_to_cancel: 'Cancelar',
  invoice_to_end_status_confirm:
    '¿Confirma el cambio de estado? No será posible deshacer la acción.',
  add_internal_note: 'Añadir una nota interna',
  add_comment_for_partner: 'Añadir un comentario para un afiliado',
  make_prepayment: 'Realizar un prepago',
  prepay: 'Pago por adelantado',
  available: 'Disponible',
  change: 'Modificación',
  current_value: 'Valor actual',
  add_correction: 'Añadir corrección',
  description_optional: 'Descripción (opcional)',
  conversion_to_reject: 'Rechazar',
  undated: 'Ilimitado',
  undated_help:
    'Si se marca, todas las conversiones no pagadas antes de la fecha especificada en el campo de la derecha se incluirán en la factura.',
  no_withdrawals_with_selected_params:
    'No tiene retiros de fondos con los parámetros seleccionados',
  invoice_error: 'Se produjo un error al intentar obtener una cuenta',
  invoice_delete_confirm:
    '¿Confirmas la eliminación de la factura? La acción no se puede deshacer.',
  invoice_delete_button_tooltip: 'La factura debe ser rechazado.',
  period: 'Período',
};
