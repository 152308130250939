import { generateAdvertiserApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Product = {
  GET_PRODUCT_LIST: {
    url: generateAdvertiserApiUrl(ModelPath.Product),
    method: 'GET',
  },
} as const;

export default Product;
