import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Profile = {
  GET_PROFILE: {
    url: generatePartnerApiUrl(ModelPath.Profile),
    method: 'GET',
  },
  UPDATE_PROFILE: {
    url: generatePartnerApiUrl(ModelPath.Profile),
    method: 'PATCH',
  },
  UPDATE_PROFILE_SETTINGS: {
    url: generatePartnerApiUrl('settings', ModelPath.Profile),
    method: 'PATCH',
  },
  CHANGE_PASSWORD: {
    url: generatePartnerApiUrl('password/change'),
    method: 'PATCH',
    bodyMapping: [
      'old_password',
      'new_password',
      'new_password_confirmation',
      {
        key: '2fa_code',
        optional: true,
      },
    ],
    twoFactorAuth: true,
  },
  UPDATE_API_KEY: {
    url: generatePartnerApiUrl('refresh_api_key'),
    method: 'PATCH',
  },
  LOGOUT: {
    url: generatePartnerApiUrl('logout'),
    method: 'POST',
  },
} as const;

export default Profile;
