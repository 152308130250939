export enum Widget {
  User = 'USER',
  CommonStatisticsTable = 'COMMON_STATISTICS_TABLE',
  CustomersStatisticsTable = 'CUSTOMERS_STATISTICS_TABLE',
  DashboardStatisticsTable = 'DASHBOARD_STATISTICS_TABLE',
  CommonStatisticsTableInnerGroup = 'COMMON_STATISTICS_TABLE_INNER_GROUP',
  CommonStatisticsTableTableSnapshot = 'COMMON_STATISTICS_TABLE_SNAPSHOT',
  CustomTableSchemes = 'CUSTOM_TABLE_SCHEMES',
  ConversionsStatisticsTable = 'CONVERSIONS_STATISTICS_TABLE',
  ExportServiceWidget = 'EXPORT_SERVICE_WIDGET',
  EventStatisticsTable = 'EVENT_STATISTICS_TABLE',
  QualificationsStatisticsTable = 'QUALIFICATIONS_STATISTICS_TABLE',
  CohortAnalysisStatisticsTable = 'COHORT_ANALYSIS_STATISTICS_TABLE',
  OffersList = 'OFFERS_LIST',
  ProductsList = 'PRODUCTS_LIST',
  ProductOffersTable = 'PRODUCT_OFFERS_TABLE',
  TagsList = 'TAGS_LIST',
  GoalsTable = 'GOALS_TABLE',
  EventsTable = 'EVENTS_TABLE',
  NewsForm = 'NEWS_FORM',
  NewsTable = 'NEWS_TABLE',
  StatisticsFilterAndQuery = 'STATISTICS_FILTER_AND_QUERY',
  StatisticsQuery = 'STATISTICS_QUERY',
  PromoMaterialsTable = 'PROMO_MATERIALS_TABLE',
  OfferPromoMaterialsTable = 'OFFER_PROMO_MATERIALS_TABLE',
  PromoMaterialForm = 'PROMO_MATERIAL_FORM',
  PromoCodeTypesTable = 'PROMO_CODE_TYPES_TABLE',
  PromoCodeTypeForm = 'PROMO_CODE_TYPE_FORM',
  PromoCodesTable = 'PROMO_CODES_TABLE',
  OsVersionsList = 'OS_VERSIONS_LIST',
  InvoicesTable = 'INVOICES_TABLE',
  InvoicesGeneratingCount = 'INVOICES_GENERATING_COUNT',
  InvoicesFilter = 'INVOICES_FILTER',
  InvoiceForm = 'INVOICE_FORM',
  InvoiceConversionsTable = 'INVOICE_CONVERSIONS_TABLE',
  OfferPromoCodesTable = 'OFFER_PROMO_CODES_TABLE',
  PromoCodeForm = 'PROMO_CODE_FORM',
  GenerateTrackingLinkForm = 'GENERATE_TRACKING_LINK_FORM',
  AdvertisersList = 'ADVERTISERS_LIST',
  CategoriesList = 'CATEGORIES_LIST',
  OffersTable = 'OFFERS_LIST',
  ExportsTable = 'EXPORTS_LIST',
  ReceivedPostbackLogsTable = 'RECEIVED_POSTBACK_LOGS_TABLE',
  SentPostbackLogsTable = 'SENT_POSTBACK_LOGS_TABLE',
  ClickLogsTable = 'CLICK_LOGS_TABLE',
  LegalDocuments = 'LEGAL_DOCUMENTS',
  TermsOfUse = 'TERMS_OF_USE',
  PrivacyPolicy = 'PRIVACY_POLICY',
  PostbackForm = 'POSTBACK_FORM',
  PostbackTable = 'POSTBACK_TABLE',
  ReferralsTable = 'REFERRALS_TABLE',
  PartnerPostbackTable = 'PARTNER_POSTBACK_TABLE',
  SecuritySettingsGa = 'SECURITY_GA',
  OfferPostbackForm = 'OFFER_POSTBACK_FORM',
  OfferPostbackTable = 'OFFER_POSTBACK_TABLE',
  IntegrationForm = 'INTEGRATION_FORM',
  Wallets = 'WALLETS',
  WalletForm = 'WALLET_FORM',
  DashboardForm = 'DASHBOARD_FORM',
  DashboardsList = 'DASHBOARD_LIST',
  LandingsList = 'LANDINGS_LIST',
  GeneralMenuSettings = 'GENERAL_MENU_SETTINGS',
  AdvertiserClientKey = 'ADVERTISER_CLIENT_KEY',
  AdvertiserSecretKey = 'ADVERTISER_SECRET_KEY',
}
