import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Dashboard = {
  GET_ALL: {
    url: generatePartnerApiUrl(ModelPath.Dashboard),
    method: 'GET',
  },
} as const;

export default Dashboard;
