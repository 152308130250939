export default {
  offers: 'Ofertas',
  offer: 'Oferta',
  new_offer: 'Nueva oferta',
  edit_offer: 'Editar la oferta',
  edit: 'Editar',
  general_data: 'Datos generales',
  offer_name: 'Nombre de la oferta',
  status_name: 'Nombre del estado',
  product: 'Producto',
  product_hint: 'Producto para el que se crea esta oferta',
  unlimited: 'Ilimitado',
  use_hold_period: 'Período de retención',
  percent: 'Porcentaje',
  fixed: 'Cantidad fija',
  days: 'días',
  to_days: 'días',
  offer_successfully_created: 'Oferta creada con éxito',
  offer_successfully_updated: 'Oferta modificada con éxito',
  select_product_from_the_list:
    'Seleccione un producto creado previamente de la lista',
  set_limit_of_each_condition_performance_per_day_the_minimum_value_is_10_the_maximum_values_is_1_000_000_you_can_also_set_unlimited_value_by_checkbox:
    'Establezca un límite de tiempo para cada condición por día. El valor mínimo es 10, el valor máximo es 1,000,000. También puede elegir un límite ilimitado.',
  set_day_count_to_hold_period_the_minimum_values_is_0_days_the_maximum_values_is_60_days:
    'Especifique el número de días para el período de retención. El valor mínimo es de 0 días, el valor máximo es de 60 días.',
  no_offers: 'No hay ofertas',
  no_active_offers: 'No hay ofertas activas',
  no_active_goals: 'No hay objetivos activos.',
  no_goals_groups: 'No hay grupos de objetivos',
  please_create_goal: 'Por favor, cree un objetivo.',
  table_name: 'Oferta',
  table_product: 'Producto',
  table_category: 'Categoría',
  table_advertiser: 'Anunciante',
  table_status: 'Estado',
  table_privacy: 'Privacidad',
  table_hold: 'Hold',
  table_creation_date: 'Fecha de creación',
  status_active: 'Activo',
  status_inactive: 'Inactivo',
  event: 'Evento',
  range: 'Rango',
  model: 'Modelo',
  values: 'Valores',
  name_of_goal: 'Nombre del objetivo',
  id_of_goal: 'Identificador del objetivo',
  id: 'ID',
  from_3_to_30_Latin_letters_and_symbols:
    'De 3 a 30 letras, números y caracteres latinos',
  condition: 'Condición',
  individual: 'Individual',
  individual_conditions: 'Condiciones individuales',
  no_goals: 'Sin objetivos',
  no_formulas: 'Sin fórmulas',
  conditions: 'Condiciones',
  range_help:
    'Especifique el rango de valores para el recuento de objetivos cumplidos en formato [X,Y]. X e Y deben ser números positivos. También puede usar el signo @, que indica infinito. Por ejemplo [10, @], [5, 15], @',
  value_help:
    'Especifique un rango de valores en formato [X,Y] que defina la suma del evento realizado para este propósito. X e Y deben ser números. También puede usar el signo @, que indica infinito. Por ejemplo, [10, @], [5, 15], @. También, X puede ser igual a -@, por ejemplo [-@, 100], [-@, @].',
  model_help:
    'Especifique el modelo de conteo:\n' +
    'Sum - los valores de los postbacks del producto se sumarán y la suma se comparará con el rango en el campo Valores\n' +
    'Between - el valor del postback del producto se comparará con el rango en el campo Valores',
  basic: 'Básicos',
  goals: 'Objetivos',
  goal: 'Objetivo',
  conversion_statuses: 'Estado de conversiones',
  benefit: 'Remuneración',
  countries: 'Países',
  cities: 'Ciudades',
  operating_systems: 'Sistemas Operativos',
  operating_systems_short: 'OS',
  device_types: 'Tipos de dispositivos',
  device_types_short: 'Dispositivo',
  type: 'Tipo',
  benefit_type: 'Tipo de pago',
  value: 'Significado',
  cannot_delete_active_offer: 'No se puede eliminar la oferta activa',
  qualification_mode: 'Modo Calificación',
  qualification_mode_help:
    'La calificación es una conversión que solo se crea cuando se cumplen todos los objetivos especificados en este bloque.',
  qualification_type_help:
    'La conversión solo se crea cuando se cumplen todos los objetivos especificados en este bloque.',
  add_first_condition: 'Agregue la primera condición para esta oferta',
  add_first_individual_condition:
    'Agregue la primera condición personalizada para esta oferta',
  frequency_of_payments: 'Frecuencia de pago',
  add_goal: 'Añadir objetivo',
  add_goals: 'Añadir objetivos',
  edit_goal: 'Editar objetivo',
  add_landing: 'Añadir landing',
  offer_tracking_target_link: 'Enlace de destino',
  offer_tracking_target_link_hint:
    '{click_id} - ID de clic único;\n{partner_id} - ID de socio;\n{offer_id} - ID de oferta;\n{creative_id} - ID de material promocional;\n{sub_id1..10} - SubId #1 - SubId #10.',
  offer_trafficback_url: 'URL de Trafficback',
  offer_trafficback_url_hint:
    'Si el tráfico no coincide con los parámetros, se redirigirá a la URL especificada. El enlace puede especificar macros:\n\n{click_id} - ID de clic único;\n{partner_id} - ID de socio;\n{sub_id1} - sub_id #1, puede agregar hasta 10 sub_id;\n{offer_id} - ID de oferta;\n{creative_id} - ID de material promocional.',
  offer_tracking_tracking_domain: 'Dominio de seguimiento',
  condition_successfully_created: 'Condición creada con éxito',
  condition_successfully_updated: 'Condición actualizada con éxito',
  conditions_successfully_updated: 'Términos actualizados con éxito',
  condition_successfully_deleted: 'Condición eliminada con éxito',
  an_error_occurred_while_deleting_a_condition:
    'Se produjo un error al eliminar la condición',
  show_all_partners: 'Mostrar todos los {{count}} afiliados',
  something_went_wrong: 'Algo ha ido no tan',
  form_product: 'Producto',
  form_advertiser: 'Anunciante',
  form_category: 'Categoría',
  form_type: 'Tipo',
  form_day_limit: 'Límite diario',
  form_hold_period: 'Período de retención',
  form_postclick: 'Postclick',
  form_postclick_from: 'Contar postclick de',
  form_target_link: 'Target-enlace',
  my_referral_link: 'Mi enlace de referencia',
  partners: 'Socios',
  partner: 'Socio',
  add_partner: 'Añadir socio',
  exclude_partners: 'Excluir socios',
  excluded_partners: 'Socios excluidos',
  add_condition: 'Añadir condición',
  add_individual_conditions: 'Añadir condición personalizada',
  edit_conditions: 'Editar condiciones',
  edit_individual_conditions: 'Editar condiciones personalizadas',
  day_limit: 'Límite diario',
  no_limit: 'Sin límite',
  no_conditions_created: 'No se han creado condiciones',
  view_form_table_countries: 'Países',
  view_form_table_day_limit: 'Límite diario',
  view_form_table_hold: 'Hold',
  view_form_table_benefit: 'Socio',
  view_form_table_goals: 'Objetivos',
  view_form_table_name_id: 'Nombre / ID',
  view_form_table_event: 'Evento',
  view_form_table_range: 'Rango',
  view_form_table_model: 'Modelo',
  view_form_table_value: 'Significado',
  choose_partners: 'Seleccione socios...',
  get_referral_link: 'Generador de enlaces',
  get_tracking_link: 'Obtener el enlace de seguimiento',
  hold_period: 'Período de retención',
  postbacks: 'Postbacks',
  rule: 'Regla',
  frequency: 'Frecuencia',
  referral_link: 'Enlace de referencia',
  copy_link: 'Copiar enlace',
  get_qr_code: 'Obtener código QR',
  save_as_png: 'Guardar como PNG',
  save_as_svg: 'Guardar como SVG',
  landing: 'Landing',
  sub_id: 'Sub id',
  add_sub_id: 'Añadir Sub id',
  maximum_10_sub_id: 'Máximo 10 Sub_id',
  from_3_to_30_characters: 'De 3 a 30 caracteres',
  from_1_to_60_characters: 'De 1 a 60 caracteres',
  from_3_to_60_characters: 'De 3 a 60 caracteres',
  are_you_sure_you_want_to_delete_the_condition:
    '¿Seguro que desea eliminar la condición?',
  are_you_sure_you_want_to_delete_the_goal:
    '¿Seguro que quieres borrar el objetivo?',
  are_you_sure_you_want_to_delete_the_formula:
    '¿Seguro que desea eliminar la fórmula?',
  goal_successfully_deleted: 'Objetivo eliminado con éxito',
  goal_successfully_created: 'Objetivo creado con éxito',
  goal_successfully_updated: 'Objetivo actualizado con éxito',
  unable_delete_formula:
    'No se puede eliminar la fórmula porque se utiliza en los términos de la oferta.',
  formula_successfully_deleted: 'Fórmula eliminada con éxito',
  formula_successfully_updated: 'Fórmula actualizada con éxito',
  enabled_offer_tooltip: 'Activar la oferta.',
  disable_offer_tooltip:
    'Desactivar la oferta. Si la oferta se desactiva, no estará disponible para el afiliado.',
  more_countries: 'países',
  more_partners: 'socios',
  tracking: 'Trekking',
  'Errors were made in creating the condition.':
    'Se han producido errores al crear la condición.',
  'Please correct them.': 'Por favor, corríjalos.',
  hidden: 'Oculto',
  hidden_from: 'Oculto de',
  hide_condition_from_partner: 'De un socio',
  hide_condition_from_advertiser: 'De un anunciante',
  hide_condition_partner: 'Socios',
  hide_condition_advertiser: 'Anunciante',
  show_to_partner: 'Mostrar a un socio',
  show_to_advertiser: 'Mostrar al anunciante',
  show_individual_tag: 'Mostrar la etiqueta Personalizado',
  hide_condition_in_offer:
    'Ocultar la condición al socio o anunciante. Si oculta una condición, no aparecerá en la lista de condiciones de la oferta del socio o anunciante. Además, las conversiones y las calificaciones no se mostrarán en las estadísticas del afiliado o anunciante con la condición oculta.',
  do_you_want_to_delete: '¿Quieres eliminar',
  condition_is_hidden: 'condición\nOculto',
  from_advertiser: 'De un anunciante',
  to_partner: 'Socio',
  payout_value_help:
    'La cantidad que usted recibe del anunciante por la calificación. Si el tipo de pago es "Porcentaje", se indica el porcentaje del valor de la calificación.',
  reward_value_help:
    'La cantidad que le pagas al socio. Si el tipo de pago es "Porcentaje", se indica el porcentaje del valor de la calificación. De forma predeterminada, el pago es cero.',
  public: 'Público',
  private: 'Privado',
  premoderate: 'Pre-moderación',
  available: 'Disponible',
  hide_unavailable: 'Ocultar inaccesibles',
  tags: 'Etiquetas',
  partners_for_private_offer: 'Socios para una oferta privada',
  choosed: 'Seleccionado',
  no_partners_selected: 'No hay socios seleccionados',
  partners_successfully_saved: 'Socios guardados con éxito',
  all_countries: 'Todos los países',
  postback_successfully_updated: 'Postback actualizado con éxito',
  add_postback: 'Añadir un postback',
  edit_postback: 'Editar un postback',
  postback_URL: 'URL del postback',
  max_1024_symbols: 'Máximo 1024 caracteres',
  conversion: 'Conversión',
  qualification: 'Calificación',
  conversion_name: 'Nombre de la conversión',
  the_following_data_will_be_sent: 'Se pueden enviar los siguientes datos:',
  postback_url_help_conversion:
    '{status} - el estado de la conversión;\n{goal} - la clave del objetivo para el que se realizó la conversión;\n{revenue} - el monto de la recompensa;\n{currency} - la moneda del pago al socio (de la conversión);\n{promocode} - el código promocional;\n{country} - el país;\n{sub_id1..sub_id10} - SubId #1 - SubId #10 (sustituido por sub_id del clic);\n{id} - ID de conversión;',
  revshare_postback_url_help: `{id} - ID de conversión;
{revenue} - el monto de los pagos al socio en la reversa;
{currency} - la moneda del pago al afiliado;
{sub_id1..sub_id10} - SubId #1 - SubId #10 (sub_id reemplazado por un clic);`,
  postback_url_help_value: `{value} - valor de conversión;
{value_currency} - la moneda del valor de conversión;`,
  revshare_postback_url_help_value: `{value} - el resultado del cálculo de la fórmula (ingreso);
{value_currency} - la moneda de la condición con la fórmula;`,
  global_postback_url_help: `{status} - el estado de la conversión;
{goal} - la clave del objetivo;
{offer_id} - ID de la oferta;
{revenue} - el monto del pago al socio;
{currency} - la moneda del pago al afiliado;
{sub_id1..sub_id10} - SubId #1 - SubId #10 (sustituido por clic);
{id} - ID de conversión;
{country} - país;
{landing_id} - ID de aterrizaje;
{click_date} - fecha y hora del clic en formato AAAA.MM.DD HH:mm:ss;
{conversion_date} - fecha y hora de la conversión en formato AAAA.MM.DD HH:mm:ss;
{ip} - la dirección IP del clic;
{promocode} - Código promocional;`,
  global_admin_postback_url_help: `{status} - el estado de la conversión;
{goal} - la clave del grupo de objetivos;
{advertiser_id} - ID del anunciante;
{partner_id} - ID de socio;
{offer_id} - ID de la oferta;
{revenue} - el monto del pago al socio;
{currency} - la moneda del pago al afiliado;
{value} - cantidad de conversión;
{value_currency} - moneda de conversión;
{sub_id1..sub_id10} - SubId #1 - SubId #10 (sustituido por clic);
{id} - ID de conversión;
{country} - país;
{landing_id} - ID de aterrizaje;
{click_date} - fecha y hora del clic en formato AAAA.MM.DD HH:mm:ss;
{conversion_date} - fecha y hora de la conversión en formato AAAA.MM.DD HH:mm:ss;
{ip} - la dirección IP del clic;
{click_id} - ID del clic;
{tid} - ID de transacción;
{promocode} - Código promocional;
{useragent} - valor de UserAgent;`,
  do_you_really_want_to_delete_postback:
    '¿Seguro que desea eliminar el postback?',
  an_error_occurred_while_deleting: 'Ocurrió un error al borrar',
  postback_successfully_created: 'Postback creado con éxito',
  postback_successfully_deleted: 'Postback eliminado con éxito',
  no_postbacks: 'Sin postbacks',
  access_request_sent: 'Solicitud enviada',
  access_request_denied: 'Solicitud denegada',
  request_access: 'Solicitar acceso',
  request_again: 'Solicitar de nuevo',
  requests: 'Peticiones',
  save: 'Guardar',
  create: 'Crear',
  description: 'Descripción',
  add_partners: 'Añadir socios',
  unique_goals: 'Objetivos únicos',
  poster: 'Póster',
  special_payout: 'Pago especial',
  special_payouts: 'Pagos especiales',
  payouts: 'Pagos',
  revshare: 'Revshare',
  revshare_description:
    'Pago de intereses sobre las ganancias netas del producto (NGR, etc.)',
  cpm: 'CPM',
  cpm_description: 'Pago fijo por 1000 acciones de usuario',
  default_value: 'Valor predeterminado',
  overridden: 'Sobredefinido',
  goals_not_found: 'No hay objetivos creados.',
  create_goal_in_product_form: 'Usted puede agregar un objetivo a',
  product_page: 'página del producto',
  formulas_not_found: 'No se han creado fórmulas.',
  add_new_goal: 'Añadir nuevo objetivo',
  create_formula_in_product_form: 'Puede agregar una fórmula en',
  can_not_edit_revshare_condition:
    'No se puede cambiar la condición porque la función de reversa está desactivada',
  cost_per_action: 'CPA',
  per_conversion: 'Por conversión',
  select_type: 'Seleccione el tipo',
  convert_to_qualification: 'Convertir en calificación',
  convert_to_goal: 'Convertir en un objetivo simple',
  offer_form_special_payouts_one: '{{count}} pago especial',
  offer_form_special_payouts_other: '{{count}} pagos especiales',
  special_payout_is_empty: 'Parámetros de sub vacíos',
  payout: 'Pago',
  payout_number: 'Pago especial {{number}}',
  payout_from_advertiser: 'Pago del anunciante',
  payout_from_partner: 'Pago del socio',
  add_payout: 'Pago especial',
  the_amount_of_payments_can_be_specified_as_a_fractional_number:
    'El monto de los pagos se puede especificar en números fraccionales.',
  name: 'Nombre',
  formula: 'Fórmula',
  formulas: 'Fórmulas',
  no_conditions_yet: 'Todavía no hay condiciones.',
  add_new_condition: 'Añadir nuevo',
  privacy_level: 'Nivel de privacidad',
  request_type: 'Tipo de solicitud',
  special_settings: 'Configuración avanzada',
  back_to_offer: 'Volver a la oferta',
  no_active_conditions: 'No hay condiciones activas.',
  please_create_condition:
    'Por favor, agregue una condición regular o personalizada.',
  payment_currency: 'Moneda de pago',
  payout_currency_from_advertiser: 'Moneda de pago del anunciante',
  partner_payout_currency: 'Moneda de pago al socio',
  goal_by_event: 'Objetivo por evento de producto',
  goal_by_event_help:
    'Agregar un evento al objetivo para que la red de afiliados maneje las acciones del usuario por sí misma',
  goal_by_event_cpm_help:
    'Si el objetivo participa en una condición con un modelo CPM, no se puede especificar un evento para el objetivo.',
  field_macros_goal: 'El valor se asignará a la macro {goal}',
  field_macros_status: 'El valor se asignará a la macro {status}',
  postclick_help:
    'Post-clic es el tiempo (en días) durante el cual se considera que un usuario está vinculado a un socio.',
  group: 'Grupo',
  request_date: 'Fecha de solicitud',
  requests_for_access: 'Solicitudes de acceso',
  offer_statistics: 'Estadísticas de oferta',
  duplicate_label: 'Duplicar oferta',
  duplicate_form_title: 'Duplicar oferta',
  duplicate_form_name_field: 'Nombre de la nueva oferta',
  duplicate_form_submit: 'Duplicar',
  to_duplicate: 'Duplicar',
  s2s_postback: 'S2S-postback',
  note: 'Nota interna',
  card_note: 'Nota',
  limit: 'Límite',
  limit_conversions: 'Límite de conversión',
  limit_period: 'Período',
  limit_period_1: 'Por día',
  limit_period_2: 'Por semana',
  limit_period_3: 'Por mes',
  limit_period_4: 'Total',
  limit_period_label_1: '1 día',
  limit_period_label_2: '1 semana',
  limit_period_label_3: '1 mes',
  limit_period_label_4: 'Total',
  limit_action: 'Acción cuando se excede',
  limit_action_1: 'Rechazar conversión',
  limit_action_2: 'Cambiar pago a socio',
  limit_action_3: 'Cambiar los ingresos del anunciante',
  limit_action_4: 'Cambiar el pago de socio y los ingresos del anunciante',
  partner_select_label: 'Para un socio',
  excluded_partners_label: 'Excluir a los socios',
  excluded_partners_placeholder: 'Socios para la exclusión (ID)',
  need_to_add_partners: 'Necesidad de agregar socios',
  referral_link_tooltip: 'Elija un socio de la siguiente lista',
  referral_link_tooltip_offer: 'Elija una oferta de la siguiente lista',
  partner_placeholder: 'Introducir id o Email para el socio',
  offer_placeholder: 'Introducir id o nombre de la oferta',
  landing_placeholder: 'Título de aterrizaje',
  goal_presets: 'Preajustes de objetivos',
  trigger: 'Disparador',
  trigger_creating: 'Al crear una conversión',
  trigger_status_change: 'Al cambiar el estado',
  trigger_payout_change: 'Al cambiar el pago ',
  is_use_in_app: 'Utilizado para aplicaciones',
  is_use_in_app_hint:
    'Una marca que indique que esta oferta está destinada a los usuarios de la aplicación. Al recibir un clic en una oferta de este tipo, se añadirá la marca correspondiente al clic.',
  conditions_search_label: 'ID de socio',
  copy_offer: 'Copiar oferta',
  copy_offer_form_title: 'Copiar oferta',
  copy_offer_alert:
    'No tendrá alcance: Las condiciones de los socios, los préstamos, el material promocional y las devoluciones.',
  copy_form_submit: 'Copia',
};
