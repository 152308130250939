import isEmpty from 'lodash/isEmpty';
import { ModelPath } from 'routes/ModelPath';
import { Path } from 'routes/Path';
import { ClientId } from 'constants/ClientId';
import { getClientId } from './storageUtils';
import { checkUrl } from 'core/validations/commonValidations';

/**
 * Adds a divider to the given URL part.
 *
 * @param {string} part - The URL part to which the divider should be added.
 *
 * @returns {string} The URL part with the divider added.
 */
function addDividerToUrlPart(part?: string): string {
  if (!part || isEmpty(part)) {
    return '';
  }
  return `${part.startsWith('/') ? '' : '/'}${part}`;
}

/**
 * Generates the API URL based on the provided parameters.
 *
 * @param {string | ModelPath} [path] - The path of the API endpoint.
 * @param {string | ModelPath} [model] - The model name or path.
 * @param {string} [module] - The module name.
 * @param {string} [version] - The API version. Defaults to the value of REACT_APP_API_VERSION from the environment variables.
 *
 * @returns {string} The generated API URL.
 */
export function generateApiUrl(
  path?: string | ModelPath,
  model?: string | ModelPath,
  module?: string,
  version = process.env.REACT_APP_API_VERSION,
): string {
  const func = addDividerToUrlPart;
  return `${func(version)}${func(module)}${func(model)}${func(path)}`;
}

/**
 * Generate the URL for the admin API.
 *
 * @param {string|ModelPath} [path] - The path of the API endpoint.
 * @param {string|ModelPath} [model] - The model of the API endpoint.
 * @param {string} [version] - The version of the API endpoint.
 *
 * @returns {string} The generated admin API URL.
 */
export function generateAdminApiUrl(
  path?: string | ModelPath,
  model?: string | ModelPath,
  version?: string,
): string {
  return generateApiUrl(path, model, 'admin', version);
}

/**
 * Generate the partner API URL for the given path, model, and version.
 *
 * @param {string} [path] - The path for the API URL.
 * @param {string | ModelPath} [model] - The model for the API URL.
 * @param {string} [version] - The version of the API URL.
 *
 * @returns {string} The generated partner API URL.
 */
export function generatePartnerApiUrl(
  path?: string | ModelPath,
  model?: string | ModelPath,
  version?: string,
): string {
  return generateApiUrl(path, model, 'partner', version);
}

/**
 * Generates the URL for the advertiser API.
 *
 * @param {string | ModelPath} [path] - The path or model path of the API endpoint.
 * @param {string | ModelPath} [model] - The model or model path of the API endpoint.
 * @param {string} [version] - The version of the API endpoint.
 *
 * @returns {string} The generated advertiser API URL.
 */
export function generateAdvertiserApiUrl(
  path?: string | ModelPath,
  model?: string | ModelPath,
  version?: string,
): string {
  return generateApiUrl(path, model, 'inserent', version);
}

const DefaultPath = {
  [ClientId.ADMIN]: Path.Dashboard,
  [ClientId.ADVERTISER]: Path.Profile,
  [ClientId.PARTNER]: Path.Dashboard,
};

/**
 * Get the default path based on the client ID.
 * If the client ID is not available, return the default dashboard path.
 *
 * @return {string} - The default path.
 */
export function getDefaultPath(): string {
  const clientId = getClientId();

  if (!clientId) {
    return Path.Dashboard;
  }

  return DefaultPath[clientId] ?? Path.Dashboard;
}
