import { createSelector } from 'reselect';
import get from 'lodash/get';

import { IReduxState } from 'models/IReduxState';
import { Dicts } from 'constants/enums/Dicts';

export const getDicts = (state: IReduxState) => state.dicts || {};

export const getDict = (name: Dicts) =>
  createSelector(getDicts, (dicts) => get(dicts, name));
