import assign from 'lodash/assign';
import map from 'lodash/map';
import some from 'lodash/some';

import { OfferPartnersActions } from 'core/redux/actions/types';
import { IOfferPartnersState } from 'models/IOfferPartnersState';
import { OfferPartnersActionTypes } from 'core/redux/constants/OfferPartnersActionTypes';
import { IOfferPartnerRequest } from 'models/IOfferPartnerRequest';
import { PreModerationStatus } from 'constants/enums/PreModerationStatus';

export const initialOfferPartnersState: IOfferPartnersState = {
  requests: [],
  isLoading: false,
};

const changeRequestsState = (
  requestIds: number[],
  status: PreModerationStatus | null,
  requests?: IOfferPartnerRequest[] | null,
): IOfferPartnerRequest[] => {
  const changedRequests = map(requests, (request) => {
    const exist = some(requestIds, (id) => id === request.id);
    if (exist) {
      return {
        ...request,
        premoderate_status: status,
      };
    }
    return request;
  });
  return changedRequests;
};

const changeRequestsLoading = (
  requestIds: number[],
  loading: boolean,
  requests?: IOfferPartnerRequest[] | null,
): IOfferPartnerRequest[] => {
  const changedRequests = map(requests, (request) => {
    const exist = some(requestIds, (id) => id === request.id);
    if (exist) {
      return {
        ...request,
        isLoading: loading,
      };
    }
    return request;
  });
  return changedRequests;
};

export default function offerPartnersReducer(
  state: IOfferPartnersState = initialOfferPartnersState,
  action: OfferPartnersActions,
): typeof state {
  switch (action.type) {
    case OfferPartnersActionTypes.FetchRequests:
    case OfferPartnersActionTypes.SetLoading:
    case OfferPartnersActionTypes.SetRequests:
      return assign({}, state, action.payload);
    case OfferPartnersActionTypes.SetRequestsLoading:
      return assign({}, state, {
        requests: changeRequestsLoading(
          action.payload.ids,
          action.payload.isLoading,
          state.requests,
        ),
      });
    case OfferPartnersActionTypes.SetRequestsStatus:
      return assign({}, state, {
        requests: changeRequestsState(
          action.payload.ids,
          action.payload.status,
          state.requests,
        ),
      });
    default:
      return state;
  }
}
