import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Advertiser = {
  GET_ADVERTISERS_LIST: {
    url: generateAdminApiUrl(ModelPath.Advertiser),
    method: 'GET',
  },
  CREATE_ADVERTISER: {
    url: generateAdminApiUrl(ModelPath.Advertiser),
    method: 'POST',
    bodyMapping: [
      'email',
      {
        key: 'admin_id',
        optional: true,
      },
      {
        key: 'profile_fields',
        optional: true,
      },
      {
        key: 'password',
        optional: true,
      },
      {
        key: 'status',
        optional: true,
      },
    ],
  },
  GET_ADVERTISER: {
    url: generateAdminApiUrl(':id', ModelPath.Advertiser),
    method: 'GET',
  },
  BAN_ADVERTISER: {
    url: generateAdminApiUrl(':id/ban', ModelPath.Advertiser),
    method: 'PATCH',
  },
  UNBAN_ADVERTISER: {
    url: generateAdminApiUrl(':id/unban', ModelPath.Advertiser),
    method: 'PATCH',
  },
  UPDATE_ADVERTISER_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Advertiser),
    method: 'PATCH',
    bodyMapping: [
      'email',
      {
        key: 'admin_id',
        optional: true,
      },
      {
        key: 'profile_fields',
        optional: true,
      },
      {
        key: 'password',
        optional: true,
      },
      {
        key: 'status',
        optional: true,
      },
    ],
  },
  RESEND_EMAIL: {
    url: generateAdminApiUrl(':id/resend-email', ModelPath.Advertiser),
    method: 'POST',
  },
  APPROVE_PARTNER: {
    url: generateAdminApiUrl(':id/approve', ModelPath.Advertiser),
    method: 'PATCH',
  },
  REJECT_PARTNER: {
    url: generateAdminApiUrl(':id/reject', ModelPath.Advertiser),
    method: 'PATCH',
  },
} as const;

export default Advertiser;
