export default {
  add_categories: 'Добавить новую категорию',
  no_categories: 'Нет созданных категорий',
  name: 'Название категории',
  category_editing: 'Редактирование категории',
  category_adding: 'Добавление категории',
  category_name: 'Название категории',
  category_name_placeholder: 'Введите название категории',
  category_successfully_created: 'Категория успешно добавлена',
  category_successfully_updated: 'Категория успешно изменена',
  category_remove_confirm: 'Вы хотите удалить категорию?',
};
