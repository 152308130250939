import IBreadcrumb from 'models/IBreadcrumb';
import { Path } from './Path';

const Breadcrumbs = {
  HOME: {
    label: 'common:main_page',
    path: Path.Dashboard,
  } as IBreadcrumb,
  HOME_MAIN: {
    label: 'common:main_page',
    path: Path.Main,
  } as IBreadcrumb,
  MY_PROFILE: {
    label: 'common:my profile',
    path: Path.Profile,
  } as IBreadcrumb,
  PRODUCTS: {
    label: 'common:products_list',
    path: Path.Products,
  } as IBreadcrumb,
  PRODUCT: {
    label: 'common:paste_product_name',
    path: Path.Product,
    paramNames: ['product_name', 'id'],
  } as IBreadcrumb,
  PRODUCT_ID: {
    label: 'common:paste_product_name',
    path: Path.ProductId,
    paramNames: ['product_name', 'productId'],
  } as IBreadcrumb,
  PRODUCT_CREATE: {
    label: 'common:product_new',
    path: Path.ProductCreate,
  } as IBreadcrumb,
  PRODUCT_EDIT: {
    label: 'common:product_editing',
    path: Path.ProductEdit,
    paramNames: ['id', 'product_name'],
  } as IBreadcrumb,
  NEWS: {
    label: 'common:news',
    path: Path.News,
  } as IBreadcrumb,
  NEWS_SHOW: {
    label: 'common:paste_news_name',
    path: Path.NewsForm,
    paramNames: ['id', 'news_name'],
  } as IBreadcrumb,
  NEWS_CREATE: {
    label: 'common:add_news',
    path: Path.NewsCreate,
  } as IBreadcrumb,
  NEWS_EDIT: {
    label: 'common:edit_news',
    path: Path.NewsEdit,
    paramNames: ['id'],
  } as IBreadcrumb,
  OFFERS: {
    label: 'common:offers_list',
    path: Path.Offers,
  } as IBreadcrumb,
  EXPORTS: {
    label: 'common:export',
    path: Path.Exports,
  } as IBreadcrumb,
  OFFER: {
    label: 'common:paste_offer_name',
    path: Path.Offer,
    paramNames: ['offer_name', 'id'],
  } as IBreadcrumb,
  OFFER_ACCESS_REQUESTS: {
    label: 'common:requests_for_access',
    path: Path.OfferAccessRequests,
  } as IBreadcrumb,
  PRODUCT_OFFER: {
    label: 'common:paste_offer_name',
    path: Path.ProductOffer,
    paramNames: ['offer_name', 'id', 'productId'],
  } as IBreadcrumb,
  OFFER_CREATE: {
    label: 'common:offer_new',
    path: Path.OfferCreate,
  } as IBreadcrumb,
  OFFER_EDIT: {
    label: 'common:offer_editing',
    path: Path.OfferEdit,
    paramNames: ['id', 'offer_name'],
  } as IBreadcrumb,
  OFFER_CONDITIONS_EDIT: {
    label: 'common:offer_conditions_editing',
    path: Path.OfferConditions,
  } as IBreadcrumb,
  ADMINS: {
    label: 'common:admins_list',
    path: Path.Admins,
  } as IBreadcrumb,
  ADMIN: {
    label: 'common:paste_user_name',
    path: Path.Admin,
    paramNames: ['user_name', 'id'],
  } as IBreadcrumb,
  PARTNERS: {
    label: 'common:partners_list',
    path: Path.Partners,
  } as IBreadcrumb,
  PARTNER: {
    label: 'common:paste_user_email',
    path: Path.Partner,
    paramNames: ['user_email', 'id'],
  } as IBreadcrumb,
  ADVERTISERS: {
    label: 'common:advertisers_list',
    path: Path.Advertisers,
  } as IBreadcrumb,
  ADVERTISER: {
    label: 'common:paste_user_email',
    path: Path.Advertiser,
    paramNames: ['user_email', 'id'],
  } as IBreadcrumb,
  STATISTICS: {
    label: 'common:statistics',
    path: Path.Statistics,
  } as IBreadcrumb,
  CUSTOMERS: {
    label: 'common:customers',
    path: Path.Customers,
  } as IBreadcrumb,
  SETTINGS: {
    label: 'common:common_settings',
    path: Path.Settings,
  } as IBreadcrumb,
  INVOICES: {
    label: 'common:invoices',
    path: Path.Payments,
  } as IBreadcrumb,
  WALLET: {
    label: 'common:finance',
    path: Path.Wallet,
  } as IBreadcrumb,
  REFERRALS: {
    label: 'common:referrals',
    path: Path.Referrals,
  } as IBreadcrumb,
  PROMO_MATERIALS: {
    label: 'common:promo',
    path: Path.PromoMaterials,
  } as IBreadcrumb,
  TERMS_OF_USE: {
    label: 'common:terms_of_use',
    path: Path.TermsOfUse,
  } as IBreadcrumb,
  PRIVACY_POLICY: {
    label: 'common:privacy_policy',
    path: Path.PrivacyPolicy,
  } as IBreadcrumb,
  PROMO_CODES: {
    label: 'common:promo_codes',
    path: Path.PromoCodes,
  } as IBreadcrumb,
  DASHBOARD: {
    label: 'common:dashboard',
    path: Path.Dashboard,
  } as IBreadcrumb,
} as const;

export default Breadcrumbs;
