export default {
  contact: 'Контакт',
  add_contact: 'Добавить контакт',
  add_partner_contact: 'Добавить контакт партнёра',
  admin_contacts: 'Контакты администратора',
  partner_contacts: 'Контакты партнёра',
  no_contacts: 'Нет созданных контактов',
  contact_remove_confirm: 'Вы хотите удалить контакт?',
  admin_contact_remove_confirm:
    'Вы уверены, что хотите удалить контакт? Все сохраненные контактные данные администраторов также будут удалены.',
  contact_successfully_created: 'Контакт успешно добавлен',
  contact_successfully_updated: 'Контакт успешно обновлен',
  contact_adding: 'Добавление контакта',
  contact_editing: 'Редактирование контакта',
  contact_name: 'Название контакта',
  contact_requirements: 'Требования',
  contact_name_placeholder: 'Введите название контакта',
  contact_required_parameter: 'Обязательный параметр',
  show_in_registration_form: 'Показывать в форме регистрации',
  field_input: 'Текст',
  field_select: 'Выпадающий список',
  field_checkbox: 'Чекбоксы',
  field_radio: 'Переключатели',
  field_textarea: 'Многострочный текст',
  field_type: 'Тип поля',
  field_values: 'Значения',
  add_field_value: 'Добавить значение',
  admin_visible_contacts:
    'Для партнера в блоке с менеджером будут показаны только поля с типом Текст.',
};
