export default {
  offer: 'Offer',
  email: 'Email',
  telegram: 'Telegram',
  roles: 'Roles',
  status: 'Status',
  'creation date': 'Creation date',
  admins: 'Admins',
  admin: 'Admin',
  'new admin': 'New admin',
  'edit admin': 'Edit admin',
  name: 'Name',
  surname: 'Surname',
  telegram_name: 'Telegram',
  'current password': 'Current password',
  'new password': 'New password',
  'repeat new password': 'Repeat New password',
  'change password': 'Change password',
  'password was changed': 'Password was changed',
  'from 8 to 28 Latin letters and symbols':
    'From 8 to 28 Latin letters and symbols',
  'no admins': 'No admins',
  activated: 'Activated',
  inactive: 'Inactive',
  registration_not_completed: 'Registration not completed',
  'profile successfully updated': 'Profile successfully updated',
  admin_successfully_updated: 'Admin successfully updated',
};
