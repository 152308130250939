import { IApiDto } from '../models/IApiDto';

interface ResponseType {
  status: number;
  statusText: string;
  body: string;
}

function parseResponse(response: Response): Promise<ResponseType> {
  return response.text().then((text: string) => ({
    status: response.status,
    statusText: response.statusText,
    body: text,
  }));
}

function checkStatus(response: ResponseType): IApiDto | Promise<any> {
  let json;

  try {
    json = JSON.parse(response.body);
  } catch (e) {
    json = response.body;
  }

  if (
    response.status < 200 ||
    response.status >= 300 ||
    response.status === 422
  ) {
    return Promise.reject({ ...response, json });
  }

  return json;
}

/**
 * Обрабытываемый запрос по URL через fetch.
 * Через этот метод запросы проверяются на ошибки и преобразуют response в json.
 * @param  {string} url       Куда слать запрос
 * @param  {object} [options] Настройки, которые пробросятся для "fetch"
 *
 * @return {object} Ответ на запрос в виде JSON
 */
export default function request(url: string, options = {}): Promise<IApiDto | any> {
  return fetch(url, options).then(parseResponse).then(checkStatus);
}
