import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Landing = {
  GET_LANDING_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Landing),
    method: 'GET',
  },
  CREATE_LANDING: {
    url: generateAdminApiUrl(':id/landings', ModelPath.Product),
    method: 'POST',
    bodyMapping: [
      'name',
      'is_enabled',
      'url',
      'target_link',
      { key: 'date_from', optional: true },
      { key: 'date_to', optional: true },
      { key: 'partner_ids', optional: true },
    ],
  },
  CREATE_OFFER_LANDING: {
    url: generateAdminApiUrl(':id/landings', ModelPath.Offer),
    method: 'POST',
    bodyMapping: [
      'name',
      'is_enabled',
      'url',
      'target_link',
      { key: 'date_from', optional: true },
      { key: 'date_to', optional: true },
      { key: 'partner_ids', optional: true },
    ],
  },
  DELETE_LANDING_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Landing),
    method: 'DELETE',
  },
  UPDATE_LANDING_BY_ID: {
    url: generateAdminApiUrl(':landing_id', ModelPath.Landing),
    method: 'PATCH',
    bodyMapping: [
      'name',
      'is_enabled',
      'url',
      'target_link',
      { key: 'date_from', optional: true },
      { key: 'date_to', optional: true },
      { key: 'partner_ids', optional: true },
    ],
  },
  GET_LANDINGS: {
    url: generateAdminApiUrl(ModelPath.Landing),
    method: 'GET',
  },
} as const;

export default Landing;
