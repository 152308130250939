import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Category = {
  GET_CATEGORIES: {
    url: generateAdminApiUrl(ModelPath.Category),
    method: 'GET',
  },
  CREATE_CATEGORY: {
    url: generateAdminApiUrl(ModelPath.Category),
    method: 'POST',
    bodyMapping: [{ key: 'name', optional: false }],
  },
  GET_CATEGORY_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Category),
    method: 'GET',
  },
  UPDATE_CATEGORY_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Category),
    method: 'PATCH',
    bodyMapping: [{ key: 'name', optional: false }],
  },
  DELETE_CATEGORY_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Category),
    method: 'DELETE',
  },
} as const;

export default Category;
