export enum Constants {
  MobileMaxWidth = 992,
  IntMax = 2147483647,
  FloatMax = 2147483,
  IntMin = -2147483647,
  FloatMin = -2147483,
  Unlimited = '@',
  NegativeUnlimited = '-@',
  MaxSubIdsLength = 10,
  MaxCustomFieldsLength = 5,
  CustomFieldLabel = 'custom',
  ProductUnlimited = -1,
  PartnerPostbacksLimit = 5,
  SelectAllOption = 'SELECT_ALL',
  OfferIsNewInDays = 7,
  MaxCountriesShowed = 10,
  PagingDefault = 50,
  PagingMin = 1,
  PagingMax = 250,
  DefaultSorting = 'ASC',
  DescSortingPrefix = '-',
  SelectMaxValueShowed = 10,
  ConditionLimitMin = 1,
  ConditionLimitMax = 1000000,
  CsvSeparator = ';',
  CsvDecimalSeparator = ',',
  StatisticsMaxPeriodDays = 180,
  CohortAnalysisMaxPeriodDays = 90,
  StatisticsMaxGrouping = 3,
  StatisticsCustomTablesMax = 10,
  StatisticsCustomCustomerTablesMax = 1,
  MaxPromoCodeCountDefault = 3,
  DashboardColumns = 24,
  DashboardRowHeight = 48,
  DashboardRowsMin = 15,
  DashboardRowsPadding = 3,
  DashboardWidgetPadding = 8,
  TableSelectAllMax = 5000,
  FieldExcludingEnablePrefix = 'excluding_',
  FieldExcludingPrefix = 'excluded_',
  CustomStatisticsMaxPaging = 500,
  ConversionsColumnsKey = 'conversion_columns',
  StorageEvent = 'alanbaseStorage',
  LandingsCacheTime = 3600000, // 1 hour in milliseconds
}
