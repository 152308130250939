import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';
import isPlainObject from 'lodash/isPlainObject';
import assign from 'lodash/assign';

import { flattenNestedObject } from 'utils/collections';
import getObjectOnlyValidValues from 'utils/getObjectOnlyValidValues';

export function getFlattenQueryMapping(queryMapping = {}) {
  if (!isEmpty(queryMapping)) {
    const queryPlain: Record<string, any> = {};
    let queryNestedObject: Record<string, any> = {};
    forOwn(queryMapping, (value, key: string) => {
      if (value && isPlainObject(value)) {
        queryNestedObject = {
          ...queryNestedObject,
          ...flattenNestedObject(key, value),
        };
      } else {
        queryPlain[key] = value;
      }
    });
    return {
      ...queryPlain,
      ...queryNestedObject,
    };
  }
  return {};
}

export function collectFlattenQueryMapping(queryMapping = {}) {
  if (!isEmpty(queryMapping)) {
    const query: Record<string, any> = {};
    const keyRegexp = /^(?<name>.+)\[(?<field>.*)]$/;
    forOwn(queryMapping, (value, key: string) => {
      const match = key.match(keyRegexp);
      if (match && match.groups) {
        const field = match.groups?.field;
        const name = match.groups?.name;
        if (isEmpty(field)) {
          if (!query[name]) {
            query[name] = [];
          }
          query[name].push(value);
        } else {
          if (!query[name]) {
            query[name] = {};
          }
          query[name][field] = value;
        }
      } else {
        query[key] = value;
      }
    });
    return query;
  }
  return {};
}

export function stringifyQuery(query: Record<any, any>): string {
  const objectOnlyValidValues = getObjectOnlyValidValues(assign({}, query));
  const flattenQueryMapping = getFlattenQueryMapping(objectOnlyValidValues);
  const urlQuery = queryString.stringify(flattenQueryMapping, {
    encode: true,
    skipEmptyString: true,
    arrayFormat: 'bracket',
  });
  return urlQuery;
}
