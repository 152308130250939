export default {
  roles: 'Roles',
  role: 'Role',
  'new role': 'New role',
  'removal was successful': 'Removal was successful',
  'an error has occurred': 'An error has occurred',
  'do you want to delete role': 'Do you want to delete role',
  name: 'Name',
  rights: 'Rights',
  show: 'Show',
  'name of role': 'Name of role',
  'edit role': 'Edit Role',
  'role successfully created': 'Role successfully created',
  'role successfully updated': 'Role successfully updated',
  'no roles': 'No roles',
  accesses: 'Accesses',
  role_rights: 'Role rights',
};
