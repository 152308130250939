import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Goal = {
  GET_GOALS: {
    url: generatePartnerApiUrl(':id/goals', ModelPath.Product),
    method: 'GET',
  },
} as const;

export default Goal;
