import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Event = {
  GET_EVENTS_BY_PRODUCT_ID: {
    url: generateAdminApiUrl(':product_id/events', ModelPath.Product),
    method: 'GET',
  },
  CREATE_EVENT: {
    url: generateAdminApiUrl(':product_id/events', ModelPath.Product),
    method: 'POST',
    bodyMapping: [
      'name',
      'key',
      {
        key: 'event_group_id',
        optional: true,
      },
    ],
  },
  DELETE_EVENT_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Event),
    method: 'DELETE',
  },
  UPDATE_EVENT_BY_ID: {
    url: generateAdminApiUrl(':event_id', ModelPath.Event),
    method: 'PATCH',
    bodyMapping: [
      'name',
      'key',
      {
        key: 'event_group_id',
        optional: true,
      },
    ],
  },
  GET_EVENT_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Event),
    method: 'GET',
  },
} as const;

export default Event;
