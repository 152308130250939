import Profile from './Profile';
import Admin from './Admin';
import Advertiser from './Advertiser';
import Event from './Event';
import Landing from './Landing';
import Partner from './Partner';
import Product from './Product';
import Role from './Role';
import Offer from './Offer';
import Invoice from './Invoice';
import TrackingDomain from './TrackingDomain';
import Dict from './Dict';
import Category from './Category';
import Condition from './Condition';
import Statistics from './Statistics';
import Documents from './Documents';
import PaymentThreshold from './PaymentThreshold';
import Contact from './Contact';
import Tag from './Tag';
import Settings from './Settings';
import Cdn from './Cdn';
import Goal from './Goal';
import Formula from './Formula';
import Currency from './Currency';
import PaymentSystem from './PaymentSystem';
import PromoMaterials from './PromoMaterials';
import EventGroup from './EventGroup';
import GoalGroup from './GoalGroup';
import Exports from './Exports';
import PromoCode from './PromoCode';
import Postback from './Postback';
import Log from './Log';
import Integration from './Integration';
import News from './News';
import Dashboard from './Dashboard';
import ReferralLink from './ReferralLink';

const Api = {
  Admin,
  Profile,
  Advertiser,
  News,
  Event,
  Landing,
  Partner,
  Product,
  Role,
  Offer,
  TrackingDomain,
  Dict,
  Category,
  EventGroup,
  GoalGroup,
  Contact,
  Statistics,
  PromoMaterials,
  Tag,
  Invoice,
  Settings,
  PaymentThreshold,
  Documents,
  Cdn,
  Goal,
  Formula,
  Currency,
  PaymentSystem,
  Exports,
  PromoCode,
  Postback,
  Condition,
  Integration,
  Log,
  Dashboard,
  ReferralLink,
};

export default Api;
