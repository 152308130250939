import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const GoalGroup = {
  GET_GOAL_GROUPS: {
    url: generateAdminApiUrl(ModelPath.GoalGroup),
    method: 'GET',
  },
  CREATE_GOAL_GROUP: {
    url: generateAdminApiUrl(ModelPath.GoalGroup),
    method: 'POST',
    bodyMapping: [
      { key: 'name', optional: false },
      { key: 'key', optional: false },
    ],
  },
  GET_GOAL_GROUP_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.GoalGroup),
    method: 'GET',
  },
  UPDATE_GOAL_GROUP_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.GoalGroup),
    method: 'PATCH',
    bodyMapping: [
      { key: 'name', optional: false },
      { key: 'key', optional: false },
    ],
  },
  DELETE_GOAL_GROUP_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.GoalGroup),
    method: 'DELETE',
  },
} as const;

export default GoalGroup;
