export default {
  yes: 'Yes',
  no: 'No',
  delete: 'Delete',
  log_in: 'Log&nbsp;in',
  'log out': 'Log out',
  'my profile': 'My profile',
  offer: 'Offer',
  product: 'Product',
  active: 'Active',
  update: 'Update',
  replace: 'Replace',
  inactive: 'Inactive',
  cancel: 'Cancel',
  to_cancel: 'Cancel',
  duplicate: 'Duplicate',
  partners: 'Partners',
  dashboard: 'Dashboard',
  'oops something went wrong here': 'OOPS! Something went wrong here',
  'do you want to log out of your profile':
    'Do you want to log out of your profile?',
  'save changes': 'Save changes',
  save: 'Save',
  slug: 'Slug',
  settings: 'Settings',
  create: 'Create',
  add: 'Add',
  'an error has occurred': 'An error has occurred',
  enabled: 'Enabled',
  disabled: 'Disabled',
  error: 'Error',
  access_denied: 'Access denied',
  continue: 'Continue',
  apply_changes: 'Apply changes',
  'removal was successful': 'Removal was successful',
  cancellation_successful: 'Сancellation was successful',
  copy: 'Copy',
  copy_success: 'Text successfully copied',
  copy_success_any: 'Successfully copied',
  open: 'Open',
  min: 'min',
  max: 'max',
  edit_profile: 'Edit profile',
  maximum: 'Maximum',
  edit: 'Edit',
  edit2: 'Edit',
  date: 'Date',
  id: 'ID',
  email: 'Email',
  status: 'Status',
  count: 'Count',
  count_short: 'Count',
  amount: 'Amount',
  total: 'Total',
  supported_file_type: 'Supported file type',
  image_dimensions: 'Maximum dimensions: {{width}}*{{height}} (width*height)',
  image_min_dimensions:
    'Minimum dimensions: {{width}}*{{height}} (width*height)',
  image_size: 'Maximum image size: {{size}}',
  image_uploading_error: 'Image uploading error',
  role: 'Role',
  manager: 'Manager',
  on_moderation: 'On moderation',
  date_or_period: 'Date or period',
  main_page: 'Main page',
  products_list: 'List of products',
  offers_list: 'List of offers',
  admins_list: 'List of admins',
  partners_list: 'List of partners',
  advertisers_list: 'List of advertisers',
  paste_user_name: '{{user_name}}',
  paste_user_email: '{{user_email}}',
  paste_name: '{{name}}',
  paste_news_name: '{{news_name}}',
  paste_offer_name: '{{offer_name}}',
  paste_product_name: '{{product_name}}',
  product_new: 'New product',
  product_editing: 'Edit {{product_name}}',
  offer_new: 'New offer',
  offer_editing: 'Edit {{offer_name}}',
  offer_conditions_editing: 'Edit conditions',
  customers: 'Customers',
  statistics: 'Statistics',
  statistics_common: 'Common statistics',
  statistics_event: 'Event statistics',
  statistics_conversion: 'Conversion statistics',
  statistics_qualification: 'Qualification statistics',
  statistics_cohort: 'Cohort analysis',
  paste_statistics_name: '{{statistics_name}}',
  withdrawal_requests: 'Withdrawal requests',
  invoices: 'Invoices',
  common_settings: 'Common settings',
  finance: 'Wallet',
  requests_for_access: 'Requests for access',
  approve: 'Approve',
  reject: 'Reject',
  referrals: 'Referrals',
  currency: 'Currency',
  actions: 'Actions',
  close: 'Close',
  per_1000: 'per 1000',
  no_data: 'There is no data',
  confirmation: 'Confirmation',
  accept: 'Accept',
  accepted: 'Accepted',
  declined: 'Declined',
  ok: 'OK',
  logs: 'Logs',
  reload: 'Reload',
  load_more: 'Load more',
  export: 'Export',
  default: 'Default',
  set_default: 'Set default',
  click: 'Click',
  confirm: 'Confirm',
  promo: 'Promotional materials',
  promo_codes: 'Promo codes',
  download: 'Download',
  add_new_group: 'Add new group',
  and: 'and',
  to_export: 'Export',
  to_import: 'Import',
  terms_of_use: 'Terms of Use',
  privacy_policy: 'Privacy Policy',
  tags: 'Tags',
  news: 'News',
  add_news: 'Add news',
  edit_news: 'Edit news',
  info: 'Info',
};
