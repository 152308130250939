import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Admin = {
  GET_ADMINS: {
    url: generateAdminApiUrl(ModelPath.Admin),
    method: 'GET',
  },
  GET_RULES: {
    url: generateAdminApiUrl('rules'),
    method: 'GET',
  },
  CREATE_ADMIN: {
    url: generateAdminApiUrl(ModelPath.Admin),
    method: 'POST',
    bodyMapping: ['email', 'name', 'surname', 'profile_fields'],
  },
  GET_ADMIN_BY_ID: {
    url: generateAdminApiUrl(':adminId', ModelPath.Admin),
    method: 'GET',
  },
  UPDATE_ADMIN_BY_ID: {
    url: generateAdminApiUrl(':adminId', ModelPath.Admin),
    method: 'PATCH',
    bodyMapping: [
      'email',
      'name',
      'surname',
      'profile_fields',
      { key: 'roles', optional: true },
    ],
  },
  UPDATE_ADMIN_ROLES: {
    url: generateAdminApiUrl(':adminId/roles', ModelPath.Admin),
    method: 'PATCH',
    bodyMapping: ['roles'],
  },
  DISABLE_ADMIN: {
    url: generateAdminApiUrl(':id/disable', ModelPath.Admin),
    method: 'PATCH',
  },
  ENABLE_ADMIN: {
    url: generateAdminApiUrl(':id/enable', ModelPath.Admin),
    method: 'PATCH',
  },
  RESEND_EMAIL: {
    url: generateAdminApiUrl(':id/resend-email', ModelPath.Admin),
    method: 'POST',
  },
  LOGIN_BY_PARTNER: {
    url: generateAdminApiUrl('/login/:id'),
    method: 'POST',
  },
} as const;

export default Admin;
