export default {
  add_event_groups: 'Añadir grupo de eventos',
  no_event_groups: 'Ningún grupo de eventos',
  name: 'Nombre del grupo de eventos',
  event_group_editing: 'Editar un grupo de eventos',
  event_group_adding: 'Agregar un grupo de eventos',
  event_group_name: 'Nombre del grupo de eventos',
  event_group_name_placeholder: 'Introduzca el nombre del grupo de eventos',
  event_group_successfully_created: 'Grupo de eventos agregado con éxito',
  event_group_successfully_updated: 'Grupo de eventos cambiado con éxito',
  event_group_remove_confirm: '¿Desea eliminar un grupo de eventos?',
  product: 'Producto',
};
