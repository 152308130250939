import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Postback = {
  GET_POSTBACKS: {
    url: generateAdminApiUrl(ModelPath.Postback),
    method: 'GET',
  },
  GET_POSTBACK_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Postback),
    method: 'GET',
  },
  UPDATE_POSTBACK_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Postback),
    method: 'PATCH',
  },
  CREATE_POSTBACK: {
    url: generateAdminApiUrl(ModelPath.Postback),
    method: 'POST',
  },
  DELETE_POSTBACK_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Postback),
    method: 'DELETE',
  },
  GET_IP_WHITELIST: {
    url: generateAdminApiUrl('whitelist', ModelPath.Postback),
    method: 'GET',
  },
  UPDATE_IP_WHITELIST: {
    url: generateAdminApiUrl('whitelist', ModelPath.Postback),
    method: 'PATCH',
  },
} as const;

export default Postback;
