export default {
  terms_of_use: 'Condiciones de uso',
  privacy_policy: 'Política de privacidad',
  privacy_policy_accusative_case: 'Política de privacidad',
  legal_documents_agreement: 'Consentimiento con los instrumentos legales',
  legal_documents_agreement_text: 'Se han introducido cambios en',
  legal_documents_agreement_text_accept:
    'Para continuar trabajando con el sistema, es necesario leer la nueva versión del documento y confirmar su consentimiento.',
  legal_documents_agreement_text_accept_plural:
    'Para continuar trabajando con el sistema, es necesario leer la nueva versión de los documentos y confirmar su consentimiento.',
  legal_documents_agreement_agree: 'Acepto',
};
