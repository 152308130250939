export default {
  AA: 'Афарский',
  AB: 'Абхазский',
  AF: 'Африкаанс',
  AM: 'Амхарский',
  AN: 'Арагонский',
  AR: 'Арабский',
  AS: 'Ассамский',
  AY: 'Аймарский',
  AZ: 'Азербайджанский',
  BA: 'Башкирский',
  BE: 'Белорусский',
  BG: 'Болгарский',
  BH: 'Бихарский',
  BI: 'Бислама',
  BN: 'Бенгальский',
  BO: 'Тибетский',
  BR: 'Бретонский',
  CA: 'Каталонский',
  CO: 'Корсиканский',
  CS: 'Чешский',
  CY: 'Валлийский (Уэльский)',
  DA: 'Датский',
  DE: 'Немецкий',
  DZ: 'Бхутани',
  EL: 'Греческий',
  EN: 'Английский',
  EO: 'Эсперанто',
  ES: 'Испанский',
  ET: 'Эстонский',
  EU: 'Баскский',
  FA: 'Фарси',
  FI: 'Финский',
  FJ: 'Фиджи',
  FO: 'Фарерский',
  FR: 'Французский',
  FY: 'Фризский',
  GA: 'Ирландский',
  GD: 'Гэльский (Шотландский )',
  GL: 'Галисийский',
  GN: 'Гуарани',
  GU: 'Гуджарати',
  GV: 'Гэльский (язык жителей острова Мэн)',
  HA: 'Хауса',
  HE: 'Еврейский',
  HI: 'Хинди',
  HR: 'Хорватский',
  HT: 'Гаитянский Креольский',
  HU: 'Венгерский',
  HY: 'Армянский',
  IA: 'Интерлингва',
  ID: 'Индонезийский',
  IE: 'Интерлингва',
  II: 'Сычуань И',
  IK: 'Инупиак',
  IO: 'Идо',
  IS: 'Исландский',
  IT: 'Итальянский',
  IU: 'Инуктитут',
  JA: 'Японский',
  JV: 'Яванский',
  KA: 'Грузинский',
  KK: 'Казахский',
  KL: 'Гренландский',
  KM: 'Камбоджийский',
  KN: 'Каннада',
  KO: 'Корейский',
  KS: 'Кашмирский (Кашмири)',
  KU: 'Курдский',
  KY: 'Киргизский',
  LA: 'Латинский',
  LI: 'Лимбургский (Лимбургер)',
  LN: 'Лингала',
  LO: 'Лаосский',
  LT: 'Литовский',
  LV: 'Латвийский (Латышский )',
  MG: 'Малагасийский',
  MI: 'Маорийский',
  MK: 'Македонский',
  ML: 'Малаялам',
  MN: 'Монгольский',
  MO: 'Молдавский',
  MR: 'Маратхский',
  MS: 'Малайский',
  MT: 'Мальтийский',
  MY: 'Бирманский',
  NA: 'Науруанский',
  NE: 'Непальский',
  NL: 'Нидерландский',
  NO: 'Норвежский',
  OC: 'Окситанский',
  OM: 'Оромо (Афан, Галла)',
  OR: 'Ория',
  PA: 'Пенджабский (Панджабский)',
  PL: 'Польский',
  PS: 'Пушту (Пушто)',
  PT: 'Португальский',
  QU: 'Кечуа',
  RM: 'Ретороманский',
  RN: 'Кирунди (Рунди)',
  RO: 'Румынский',
  RU: 'Русский',
  RW: 'Киняруанда (Руанда)',
  SA: 'Санскритский',
  SD: 'Синдхи',
  SG: 'Сангро',
  SH: 'Сербо-Хорватский',
  SI: 'Сингальский (Сингалезский)',
  SK: 'Словацкий',
  SL: 'Словенский',
  SM: 'Самоанский',
  SN: 'Шона',
  SO: 'Сомалийский',
  SQ: 'Албанский',
  SR: 'Сербский',
  SS: 'Свати',
  ST: 'Северный сото',
  SU: 'Сунданский',
  SV: 'Шведский',
  SW: 'Суахили',
  TA: 'Тамильский',
  TE: 'Телугу',
  TG: 'Таджикский',
  TH: 'Тайский',
  TI: 'Тигринья',
  TK: 'Туркменский',
  TL: 'Тагальский',
  TN: 'Тсвана (Сетсвана)',
  TO: 'Тонга (Тонганский)',
  TR: 'Турецкий',
  TS: 'Тсонга',
  TT: 'Татарский',
  TW: 'Чви (Тви)',
  UG: 'Уйгурский',
  UK: 'Украинский',
  UR: 'Урду',
  UZ: 'Узбекский',
  VI: 'Вьетнамский',
  VO: 'Волапюк',
  WA: 'Валлон',
  WO: 'Волоф',
  XH: 'Коса',
  YI: 'Идиш',
  YO: 'Йоруба',
  ZH: 'Китайский',
  ZU: 'Зулусский',
  AK: 'Акан',
  AV: 'Аварский',
  AE: 'Авестийский',
  BM: 'Бамбара',
  BS: 'Боснийский',
  CH: 'Чаморро',
  CE: 'Чеченский',
  NY: 'Ньянджа',
  CV: 'Чувашский',
  KW: 'Корнский',
  CR: 'Кри',
  DV: 'Дивехи (Мальдивский)',
  EE: 'Эве',
  FF: 'Фулах',
  HZ: 'Гереро',
  HO: 'Хиримоту',
  IG: 'Игбо',
  KR: 'Канури',
  KI: 'Кикуйю',
  KV: 'Коми',
  KG: 'Конго',
  KJ: 'Киньяма',
  LB: 'Люксембургский',
  LG: 'Ганда',
  LU: 'Луба-катанга',
  MH: 'Маршалльский',
  NV: 'Навахо',
  ND: 'Ндебеле северный',
  NG: 'Ндунга',
  NB: 'Букмол',
  NN: 'Нюнорск (новонорвежский)',
  NR: 'Ндебеле южный',
  OJ: 'Оджибве',
  CU: 'Церковнославянский (Старославянский)',
  OS: 'Осетинский',
  PI: 'Пали',
  SC: 'Сардинский',
  SE: 'Северносаамский',
  TY: 'Таитянский',
  VE: 'Венда',
  ZA: 'Чжуанский',
};
