export default {
  product: 'Product',
  offer: 'Offer',
  country: 'Country',
  preview: 'Preview',
  size: 'Size',
  img_type: 'Type',
  language: 'Language',
  currency: 'Currency',
  width_min: 'Min width',
  height_min: 'Min height',
  width_max: 'Max width',
  height_max: 'Max height',
  no_data: 'No promotional materials',
  more_countries: 'more countries',
  name: 'Name',
  promo_editing: 'Editing the promo',
  promo_adding: 'Addition a promo',
  promo_successfully_created: 'The promotional material successfully added',
  promo_successfully_updated: 'The promotional material successfully updated',
  promo_remove_confirm: 'Do you want to delete the promotional material?',
  add_new_promo: 'New promo',
  iframe: 'IFrame',
  embed_on_site: 'Embed on a site',
};
