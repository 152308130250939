import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { IDictState } from 'models/IDictState';
import { Dicts } from 'constants/enums/Dicts';
import { IDataProvider } from 'models/IDataProvider';
import { IOptions } from 'models/IOptions';

export const dictInitialState: IDictState = {
  loading: false,
  value: [],
  initialized: false,
};

type DictsState = { [key: string]: IDictState };

const initializeDictState = (state: DictsState, name: Dicts) => {
  if (!state[name]) {
    state[name] = cloneDeep(dictInitialState);
  }
};

const dicts = createSlice({
  name: 'dicts',
  initialState: {} as DictsState,
  reducers: {
    requestDict(
      state,
      action: PayloadAction<{
        name: Dicts;
        dataProvider: IDataProvider;
        options: IOptions;
        localizeFn?: (data: any) => any;
        processingFns?: Array<(data: any) => any>;
      }>,
    ) {},
    setLoading(
      state,
      action: PayloadAction<{ name: Dicts; loading: boolean }>,
    ) {
      const { name, loading } = action.payload;
      initializeDictState(state, name);
      state[name].loading = loading;
    },
    setInitialized(
      state,
      action: PayloadAction<{ name: Dicts; initialized: boolean }>,
    ) {
      const { name, initialized } = action.payload;
      initializeDictState(state, name);
      state[name].initialized = initialized;
    },
    setError(state, action: PayloadAction<{ name: Dicts; error?: string }>) {
      const { name, error } = action.payload;
      initializeDictState(state, name);
      state[name].error = error;
    },
    setLocalizeFn(
      state,
      action: PayloadAction<{ name: Dicts; localizeFn?: (data: any) => any }>,
    ) {
      const { name, localizeFn } = action.payload;
      initializeDictState(state, name);
      state[name].localizeFn = localizeFn;
    },
    setDictValue(state, action: PayloadAction<{ name: Dicts; value: any[] }>) {
      const { name, value } = action.payload;
      initializeDictState(state, name);
      state[name].value = value;
    },
    setDictLocalizedValue(
      state,
      action: PayloadAction<{ name: Dicts; localizedValue: any[] }>,
    ) {
      const { name, localizedValue } = action.payload;
      initializeDictState(state, name);
      state[name].localizedValue = localizedValue;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = dicts;
// Extract and export each action creator by name
export const {
  requestDict,
  setError,
  setInitialized,
  setLoading,
  setDictLocalizedValue,
  setDictValue,
  setLocalizeFn,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
