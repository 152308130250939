export default {
  add_goal_groups: 'Add new goal group',
  no_goal_groups: 'No goal groups',
  name: 'Goal group name',
  name_of_goal: 'Goal name',
  key_of_goal: 'Key',
  key_of_goal_hint:
    'The goal group key will be used as the default value for the {goal} macro in partner global postbacks. The value will be converted to lowercase.',
  product: 'Product',
  goal_group_editing: 'Editing the goal group',
  goal_group_adding: 'Addition a goal group',
  goal_group_name: 'Goal group name',
  goal_group_key: 'Goal group key',
  goal_group_name_placeholder: 'Enter goal group name',
  goal_group_key_placeholder: 'Enter goal group key',
  goal_group_successfully_created: 'Goal group successfully added',
  goal_group_successfully_updated: 'Goal group successfully updated',
  goal_group_remove_confirm: 'Do you want to delete the goal group?',
};
