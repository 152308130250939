export default {
  payments: 'Выплаты',
  email: 'Email',
  amount: 'Сумма',
  payment_system: 'Платежная система',
  wallet: 'Кошелек',
  creation_date: 'Дата',
  status: 'Статус',
  approve: 'Одобрить',
  pay: 'Выплатить',
  reject: 'Отклонить',
  paid_in_part: 'Частично выплачено',
  corrections_one: 'коррекция',
  corrections_few: 'коррекции',
  corrections_many: 'коррекций',
  paid: 'Выплачено',
  details: 'Подробнее',
  revshare: 'Ревшара',
  payment_status_successfully_updated: 'Статус платежа успешно обновлен',
  payment_successfully_deleted: 'Платеж успешно удален',
  payment_successfully_rejected: 'Платеж успешно отклонен',
  comment: 'Комментарий',
  internal_note: 'Внутренняя заметка',
  comment_for_partner: 'Комментарий для партнёра',
  write_your_comment_for_partner: 'Напишите свой комментарий для партнера',
  no_payments: 'Нет данных выплат',
  partner_id: 'ID партнера',
  partner: 'Партнер',
  currency_any: 'Любая',
  payment_systems: 'Платежные системы',
  payment_system_name: 'Наименование платежной системы',
  add_payment_system: 'Добавить платежную систему',
  no_payment_systems: 'Нет платежных систем',
  payment_system_editing: 'Редактирование платежной системы',
  payment_system_adding: 'Добавление платежной системы',
  payment_system_add_wallet_field: 'Добавить поле',
  payment_system_wallet_fields: 'Поля кошелька',
  payment_system_wallet_field_name: 'Наименование',
  payment_system_wallet_field_required: 'Обязательное поле',
  payment_system_successfully_created: 'Платежная система успешно добавлена',
  payment_system_successfully_updated: 'Платежная система успешно изменена',
  payment_system_remove_confirm:
    'Вы хотите удалить платежную систему "{{name}}"?',
  generating_now:
    'Идет процесс формирования счетов. Это может занять некоторое время.',
  tab_pending: 'Ожидающие',
  tab_approved: 'Одобренные',
  tab_payed: 'Выплаченные',
  tab_rejected: 'Отклоненные',
  generate: 'Сгенерировать',
  generate_new_invoices: 'Сгенерировать новые счета',
  generate_invoices: 'Сгенерировать счета',
  invoice: 'Счёт',
  wallet_details: 'Реквизиты',
  offer_conversions: 'Конверсии',
  offer_operation: 'Операция',
  offer_description: 'Описание',
  offer_conversions_amount: 'Сумма',
  correction: 'Коррекция',
  correction_help:
    'Корректировка позволяет уменьшить или увеличить сумму счета. При добавления корректировки указывается сумма, на которую нужно изменить счет. Сумма корректировки может быть указана со знаком плюс или минус. Если сумма указана со знаком плюс (не будет отображаться), то итоговая сумма счета увеличится. Если же сумма указана со знаком минус, то итоговая сумма счета уменьшится. Также для корректировки можно добавить поясняющий комментарий.',
  total_conversions: 'Всего по CPA конверсиям',
  total_corrections: 'Всего коррекций',
  total_referral_balance: 'Реферальный баланс',
  total_revshare_balance: 'Баланс ревшары',
  total_cpm_balance: 'Всего по CPM конверсиям',
  total: 'Всего',
  prepayment: 'Предоплата',
  conversions_date_offer: 'Дата & Оффер',
  conversions_conversion: 'Конверсия',
  conversions_payment: 'Выплата',
  threshold: 'Минимальная сумма вывода средств',
  generate_form_success:
    'Счета формируются и будут отображены на вкладке В ожидании. Обновите страницу для получения данных',
  generate_form_partner_placeholder: 'Введите id или email партнёра',
  generate_form_offer_placeholder: 'Введите id или название оффера',
  generate_form_product_placeholder: 'Введите id или название продукта',
  generate_form_threshold_help:
    'Если отмечено, то счет будет сформирован, если общая сумма больше установленной минимальной суммы для вывода средств. Иначе, счет может быть сформирован с любой суммой, в том числе отрицательной.',
  generate_form_use_threshold: 'Учитывать минимальную сумму вывода средств',
  invoice_to_approved: 'Одобрить',
  invoice_to_payed: 'Выплатить полностью',
  invoice_to_rejected: 'Отклонить',
  invoice_to_pending: 'В ожидание',
  invoice_to_cancel: 'Отменить',
  invoice_to_end_status_confirm:
    'Вы подтверждаете изменение статуса? Отменить действие будет невозможно.',
  add_internal_note: 'Добавить внутреннюю заметку',
  add_comment_for_partner: 'Добавить комментарий для партнера',
  make_prepayment: 'Выполнить предоплату',
  prepay: 'Предоплата',
  available: 'Доступно',
  change: 'Изменение',
  current_value: 'Текущее значение',
  add_correction: 'Добавить коррекцию',
  description_optional: 'Описание (необязательно)',
  conversion_to_reject: 'Отклонить',
  undated: 'Неограниченно',
  undated_help:
    'Если отмечено, то в счет будут включены все неоплаченные конверсии до даты, заданной в правом поле.',
  no_withdrawals_with_selected_params:
    'У вас нет выводов средств с выбранными параметрами',
  invoice_error: 'Возникла ошибка при попытке получения счёта',
  invoice_delete_confirm:
    'Вы подтверждаете удаление счёта? Отменить действие будет невозможно',
  invoice_delete_button_tooltip: 'Счёт должен быть отменён',
  period: 'Период',
};
