export default {
  logs: 'Logs',
  received_postbacks: 'Received postbacks',
  sent_postbacks: 'Sent postbacks',
  clicks: 'Clicks',
  click_id: 'Click ID',
  no_logs: 'No logs',
  message: 'Message',
  postback: 'Postback',
  conversion_id: 'Conversion ID',
  response: 'Response',
  date_or_period: 'Date or period',
  log_date: 'Date and time',
  search: 'Search',
  ip: 'IP',
  os: 'OS',
  browser: 'Browser',
  offer: 'Offer',
  partner: 'Partner',
  destination: 'Destination',
};
