import React from 'react';
import BsModal, { ModalProps } from 'react-bootstrap/Modal';
import BsModalHeader from 'react-bootstrap/ModalHeader';
import BsModalTitle from 'react-bootstrap/ModalTitle';
import BsModalBody from 'react-bootstrap/ModalBody';
import BsModalFooter from 'react-bootstrap/ModalFooter';
import cn from 'classnames';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { Color } from 'constants/enums/Color';

export interface IModalProps extends ModalProps {
  footerClassName?: string;
  isLoading?: boolean;
  closeButton?: boolean;
  title?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  color?: Color;
}

const Modal = React.forwardRef<ModalProps, IModalProps>(
  (
    {
      className,
      footerClassName,
      isLoading,
      closeButton = true,
      show,
      title,
      children,
      footer,
      color,
      onHide,
      ...rest
    }: IModalProps,
    ref,
  ) => {
    return (
      <BsModal
        ref={ref}
        className={cn(
          'modal',
          {
            [`modal--${color}`]: !!color,
          },
          className,
        )}
        show={show}
        onHide={onHide}
        centered
        {...rest}
      >
        <BsModalHeader className={cn('modal__header')}>
          {closeButton && (
            <Button
              className="modal__close"
              onClick={onHide}
              color="transparent"
              icon={<Icon src="remove" />}
            />
          )}
          <BsModalTitle className={cn('modal__title')}>{title}</BsModalTitle>
        </BsModalHeader>
        <BsModalBody className={cn('modal__body')}>
          {isLoading && <Loading withoutLogo={true} translucent={true} />}
          {children}
        </BsModalBody>
        {footer && (
          <BsModalFooter className={cn('modal__footer', footerClassName)}>
            {footer}
          </BsModalFooter>
        )}
      </BsModal>
    );
  },
);

export default Modal;
