import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Referrals = {
  GET_REFERRALS: {
    url: generatePartnerApiUrl(ModelPath.Referrals),
    method: 'GET',
  },
};

export default Referrals;
