import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';
import {
  cohortAnalysisFilterKeys,
  commonFilterKeys,
  conversionsExportFilterKeys,
  conversionsFilterKeys,
  eventsFilterKeys,
  customersFilterKeys,
  eventsExportFilterKeys,
} from 'pages/StatisticsPage/keys';

const Statistics = {
  GET_COMMON_STATISTICS: {
    url: generateAdminApiUrl('commons', ModelPath.Statistic),
    method: 'GET',
    queryMapping: commonFilterKeys,
  },
  CREATE_TABLE_SNAPSHOT: {
    url: generateAdminApiUrl('commons/snapshot', ModelPath.Statistic),
    method: 'POST',
  },
  GET_CUSTOMERS_STATISTICS: {
    url: generateAdminApiUrl('customers', ModelPath.Statistic),
    method: 'GET',
    queryMapping: customersFilterKeys,
  },
  GET_DASHBOARD_STATISTICS: {
    url: generateAdminApiUrl('dashboards', ModelPath.Statistic),
    method: 'GET',
    queryMapping: commonFilterKeys,
  },
  GET_CONVERSIONS_STATISTICS: {
    url: generateAdminApiUrl('conversions', ModelPath.Statistic),
    method: 'GET',
    queryMapping: conversionsFilterKeys,
  },
  EXPORT_CONVERSIONS_STATISTICS: {
    url: generateAdminApiUrl('conversions/export', ModelPath.Statistic),
    method: 'POST',
    queryMapping: conversionsExportFilterKeys,
  },
  GET_EVENTS_STATISTICS: {
    url: generateAdminApiUrl('events', ModelPath.Statistic),
    method: 'GET',
    queryMapping: eventsFilterKeys,
  },
  EXPORT_EVENTS_STATISTICS: {
    url: generateAdminApiUrl('events/export', ModelPath.Statistic),
    method: 'POST',
    queryMapping: eventsExportFilterKeys,
  },
  GET_COHORT_ANALYSIS: {
    url: generateAdminApiUrl('cohort', ModelPath.Statistic),
    method: 'GET',
    queryMapping: cohortAnalysisFilterKeys,
  },
  GET_STATISTICS_VARIABLES: {
    url: generateAdminApiUrl('columns', ModelPath.Statistic),
    method: 'GET',
  },
  GET_TABLE_SCHEMES: {
    url: generateAdminApiUrl('schemes', ModelPath.Statistic),
    method: 'GET',
  },
  CREATE_TABLE_SCHEME: {
    url: generateAdminApiUrl('schemes', ModelPath.Statistic),
    method: 'POST',
  },
  UPDATE_TABLE_SCHEME: {
    url: generateAdminApiUrl('schemes/:id', ModelPath.Statistic),
    method: 'PATCH',
  },
  DELETE_TABLE_SCHEME: {
    url: generateAdminApiUrl('schemes/:id', ModelPath.Statistic),
    method: 'DELETE',
  },
  CHANGE_CONVERSIONS: {
    url: generateAdminApiUrl(ModelPath.Conversions),
    method: 'PATCH',
  },
  CHANGE_CONVERSIONS_CSV: {
    url: generateAdminApiUrl('csv', ModelPath.Conversions),
    method: 'POST',
  },
  GET_BROWSER_LIST: {
    url: generateAdminApiUrl('browsers', ModelPath.Statistic),
    method: 'GET',
  },
  GET_OS_LIST: {
    url: generateAdminApiUrl('os', ModelPath.Statistic),
    method: 'GET',
  },
  GET_OS_VERSIONS_LIST: {
    url: generateAdminApiUrl('os_versions', ModelPath.Statistic),
    method: 'GET',
  },
  GET_DEVICE_TYPE_LIST: {
    url: generateAdminApiUrl('device_types', ModelPath.Statistic),
    method: 'GET',
  },
} as const;

export default Statistics;
