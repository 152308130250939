import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Modal from 'components/Modal';
import { Color } from 'constants/enums/Color';
import { IModalProps } from 'components/Modal/Modal';
import { Size } from 'constants/enums/Size';

export interface IConfirmModalProps extends IModalProps {
  cancelText?: string;
  confirmText?: string;
  onCancel: () => void;
  onConfirm: () => void;
  hideCancelButton?: boolean;
  hideFooter?: boolean;
  confirmButtonDisabled?: boolean;
}

const ConfirmModal: React.FC<IConfirmModalProps> = ({
  title,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  children,
  hideCancelButton,
  hideFooter,
  confirmButtonDisabled,
  color,
  ...rest
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Modal
      {...rest}
      className={cn('confirm-modal', rest.className)}
      backdropClassName={cn('confirm-modal-backdrop', rest.backdropClassName)}
      color={color}
      title={title || `${t('common:delete')}?`}
      onHide={onCancel}
      footer={
        hideFooter
          ? undefined
          : [
              !hideCancelButton ? (
                <Button
                  className="confirm-modal__btn"
                  bold
                  size={Size.LG}
                  color={Color.FIELD}
                  key="delete-role-no"
                  onClick={onCancel}
                >
                  {cancelText || t('common:no')}
                </Button>
              ) : null,
              <Button
                className="confirm-modal__btn"
                bold
                size={Size.LG}
                key="delete-role-yes"
                color={color ?? Color.PRIMARY}
                onClick={onConfirm}
                disabled={confirmButtonDisabled}
              >
                {confirmText || t('common:yes')}
              </Button>,
            ]
      }
    >
      {children}
    </Modal>
  );
};

export default ConfirmModal;
