export default {
  'no advertisers': 'Sin anunciantes',
  id: 'ID',
  email: 'Email',
  password: 'Contraseña',
  manager: 'Gerente',
  'offers count': 'Número de ofertas',
  'registration date': 'Fecha de registro',
  creation_date: 'Fecha de creación',
  status: 'Estado',
  actions: 'Acciones',
  'new advertiser': 'Nuevo anunciante',
  rejected: 'Rechazado',
  banned: 'Prohibido',
  moderation: 'En la moderación',
  active: 'Activo',
  accept: 'Aprobar',
  reject: 'Rechazar',
  advertisers: 'Anunciantes',
  new_advertiser: 'Nuevo anunciante',
  creation_advertiser: 'Creación de un anunciante',
  advertiser_successfully_created: 'Anunciante creado con éxito',
  created: 'Creado',
  edit_profile: 'Editar perfil',
  offers: 'Ofertas',
  incomplete_registration: 'Inscripción incompleta',
  advertiser_successfully_updated: 'Anunciante actualizado con éxito',
  list_of_advertisers: 'Lista de anunciantes',
};
