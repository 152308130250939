const DEFAULT_ADMIN_CLIENT_ID = 1;
const DEFAULT_ADVERTISER_CLIENT_ID = 2;
const DEFAULT_PARTNER_CLIENT_ID = 3;

export const ClientId = {
  ADMIN:
    process.env.REACT_APP_ADMIN_CLIENT_ID ||
    process.env.NEXT_PUBLIC_ADMIN_CLIENT_ID ||
    DEFAULT_ADMIN_CLIENT_ID,
  ADVERTISER:
    process.env.REACT_APP_ADVERTISER_CLIENT_ID ||
    process.env.NEXT_PUBLIC_ADVERTISER_CLIENT_ID ||
    DEFAULT_ADVERTISER_CLIENT_ID,
  PARTNER:
    process.env.REACT_APP_PARTNER_CLIENT_ID ||
    process.env.NEXT_PUBLIC_PARTNER_CLIENT_ID ||
    DEFAULT_PARTNER_CLIENT_ID,
};
