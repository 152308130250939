import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Offer = {
  CREATE_OFFER: {
    url: generateAdminApiUrl(':product_id/offers', ModelPath.Product),
    method: 'POST',
  },
  GET_OFFER_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Offer),
    method: 'GET',
  },
  ENABLE_OFFER_BY_ID: {
    url: generateAdminApiUrl(':id/enable', ModelPath.Offer),
    method: 'PATCH',
  },
  DISABLE_OFFER_BY_ID: {
    url: generateAdminApiUrl(':id/disable', ModelPath.Offer),
    method: 'PATCH',
  },
  GET_OFFERS: {
    url: generateAdminApiUrl(ModelPath.Offer),
    method: 'GET',
  },
  UPDATE_OFFER_BY_ID: {
    url: generateAdminApiUrl(':offer_id', ModelPath.Offer),
    method: 'PATCH',
    body: ['name', 'hold_period', 'tags', 'privacy_level'],
  },
  UPDATE_OFFERS: {
    url: generateAdminApiUrl(ModelPath.Offer),
    method: 'PATCH',
  },
  DELETE_OFFER: {
    url: generateAdminApiUrl(':offer_id', ModelPath.Offer),
    method: 'DELETE',
  },
  DUPLICATE_OFFER_BY_ID: {
    url: generateAdminApiUrl(':offer_id/duplicate', ModelPath.Offer),
    method: 'POST',
  },
  CREATE_CONDITION: {
    url: generateAdminApiUrl(':offer_id/conditions', ModelPath.Offer),
    method: 'POST',
  },
  CREATE_INDIVIDUAL_CONDITION: {
    url: generateAdminApiUrl(
      ':offer_id/individual_conditions',
      ModelPath.Offer,
    ),
    method: 'POST',
  },
  UPDATE_CONDITION_BY_OFFER_ID: {
    url: generateAdminApiUrl(':condition_id', ModelPath.Condition),
    method: 'PATCH',
  },
  UPDATE_INDIVIDUAL_CONDITION_BY_OFFER_ID: {
    url: generateAdminApiUrl(':condition_id', ModelPath.IndividualCondition),
    method: 'PATCH',
  },
  UPDATE_CONDITIONS_BY_OFFER_ID: {
    url: generateAdminApiUrl(':offer_id/conditions', ModelPath.Offer),
    method: 'PATCH',
  },
  DELETE_CONDITION_BY_ID: {
    url: generateAdminApiUrl(':condition_id', ModelPath.Condition),
    method: 'DELETE',
  },
  DELETE_INDIVIDUAL_CONDITION: {
    url: generateAdminApiUrl(':condition_id', ModelPath.IndividualCondition),
    method: 'DELEtE',
  },
  SET_TRACKING_PARAMETERS: {
    url: generateAdminApiUrl(':offer_id/target-link', ModelPath.Offer),
    method: 'PATCH',
    body: ['target_link', 'tracking_domain_id'],
  },
  SET_PARTNERS: {
    url: generateAdminApiUrl(':offer_id/partners', ModelPath.Offer),
    method: 'PATCH',
  },
  SET_EXCLUDED_PARTNERS: {
    url: generateAdminApiUrl(':offer_id/partners/excluded', ModelPath.Offer),
    method: 'PATCH',
  },
  GET_ALL_PARTNER_REQUESTS: {
    url: generateAdminApiUrl('requests', ModelPath.Offer),
    method: 'GET',
  },
  FETCH_PARTNER_REQUESTS: {
    url: generateAdminApiUrl(':offer_id/requests', ModelPath.Offer),
    method: 'GET',
  },
  APPROVE_PARTNER_REQUESTS: {
    url: generateAdminApiUrl(':offer_id/approve', ModelPath.Offer),
    method: 'PATCH',
  },
  REJECT_PARTNER_REQUESTS: {
    url: generateAdminApiUrl(':offer_id/reject', ModelPath.Offer),
    method: 'PATCH',
  },
} as const;

export default Offer;
