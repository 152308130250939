import { createSelector } from 'reselect';
import { IReduxState } from 'models/IReduxState';
import { IGlobals } from 'models/IGlobals';

export const getGlobals = (state: IReduxState): IGlobals => state.globals || {};
export const getTitle = () =>
  createSelector(getGlobals, (globals) => globals.title);
export const getGlobalLoading = () =>
  createSelector(getGlobals, (globals) => globals.loading);
export const getLang = () =>
  createSelector(getGlobals, (globals) => globals.lang);
