export default {
  contact: 'Contacto',
  add_contact: 'Añadir contacto',
  add_partner_contact: 'Añadir contacto de afiliado',
  admin_contacts: 'Contactos del administrador',
  partner_contacts: 'Contactos de socios',
  no_contacts: 'No se han creado contactos',
  contact_remove_confirm: '¿Desea eliminar un contacto?',
  admin_contact_remove_confirm:
    '¿Seguro que quieres eliminar el contacto? También se eliminarán todos los datos de contacto del administrador guardados.',
  contact_successfully_created: 'Contacto añadido con éxito',
  contact_successfully_updated: 'Contacto actualizado con éxito',
  contact_adding: 'Agregar un contacto',
  contact_editing: 'Editar un contacto',
  contact_name: 'Nombre del contacto',
  contact_requirements: 'Requisitos',
  contact_name_placeholder: 'Introduzca el nombre del contacto',
  contact_required_parameter: 'Parámetro obligatorio',
  show_in_registration_form: 'Mostrar en el formulario de registro',
  field_input: 'Texto',
  field_select: 'Lista desplegable',
  field_checkbox: 'Cajas de verificación',
  field_radio: 'Interruptores',
  field_textarea: 'Texto de varias líneas',
  field_type: 'Tipo de campo',
  field_values: 'Valores',
  add_field_value: 'Añadir valor',
  admin_visible_contacts:
    'Para el asociado en el bloque de administrador, solo se mostrarán los campos Texto.',
};
