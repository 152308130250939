import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './locales';
import { Language } from 'constants/enums/Language';

export const DEFAULT_NAME_SPACE = 'common';
export const DEFAULT_LANGUAGE = Language.EN;
export const LANGUAGE_STORAGE_KEY = 'i18nextLng';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    compatibilityJSON: 'v4',
    nsSeparator: ':',
    keySeparator: '..',
    returnNull: false,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: true,
      skipOnVariables: false,
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
  });
