import { generateAdvertiserApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';
import {
  cohortAnalysisFilterKeys,
  commonFilterKeys,
  conversionsFilterKeys,
  eventsFilterKeys,
  customersFilterKeys,
} from 'pages/StatisticsPage/keys';

const Statistics = {
  GET_COMMON_STATISTICS: {
    url: generateAdvertiserApiUrl('commons', ModelPath.Statistic),
    method: 'GET',
    queryMapping: commonFilterKeys,
  },
  GET_CUSTOMERS_STATISTICS: {
    url: generateAdvertiserApiUrl('customers', ModelPath.Statistic),
    method: 'GET',
    queryMapping: customersFilterKeys,
  },
  GET_CONVERSIONS_STATISTICS: {
    url: generateAdvertiserApiUrl('conversions', ModelPath.Statistic),
    method: 'GET',
    queryMapping: conversionsFilterKeys,
  },
  GET_EVENTS_STATISTICS: {
    url: generateAdvertiserApiUrl('events', ModelPath.Statistic),
    method: 'GET',
    queryMapping: eventsFilterKeys,
  },
  GET_COHORT_ANALYSIS: {
    url: generateAdvertiserApiUrl('cohort', ModelPath.Statistic),
    method: 'GET',
    queryMapping: cohortAnalysisFilterKeys,
  },
  GET_TABLE_SCHEMES: {
    url: generateAdvertiserApiUrl('schemes', ModelPath.Statistic),
    method: 'GET',
  },
} as const;

export default Statistics;
