import admins from './admins';
import aside from './aside';
import common from './common';
import components from './components';
import profile from './profile';
import roles from './roles';
import rules from './rules';
import validations from './validations';
import product from './product';
import advertiser from './advertiser';
import event from './event';
import partner from './partner';
import offer from './offer';
import landing from './landing';
import settings from './settings';
import trackingDomains from './trackingDomains';
import countries from './countries';
import payments from './payments';
import wallet from './wallet';
import categories from './categories';
import statistics from './statistics';
import contacts from './contacts';
import tags from './tags';
import referrals from './referrals';
import languages from './languages';
import promo from './promo';
import eventGroups from './eventGroups';
import goalGroups from './goalGroups';
import exports from './exports';
import promoCodes from './promoCodes';
import legalDocuments from './legalDocuments';
import integrations from './integrations';
import news from './news';
import log from './log';
import dashboards from './dashboards';

export default {
  admins,
  aside,
  common,
  components,
  profile,
  roles,
  rules,
  validations,
  product,
  advertiser,
  event,
  eventGroups,
  goalGroups,
  partner,
  offer,
  landing,
  settings,
  trackingDomains,
  countries,
  payments,
  wallet,
  categories,
  statistics,
  contacts,
  tags,
  referrals,
  languages,
  promo,
  exports,
  promoCodes,
  legalDocuments,
  integrations,
  news,
  log,
  dashboards,
};
