export default {
  dashboard: 'Tablero de instrumentos',
  products: 'Productos',
  product: 'Producto',
  offers: 'Ofertas',
  offers_all: 'Todo',
  offers_available: 'Disponible',
  statistics: 'Estadísticas',
  customers: 'Clientes',
  promo: 'Materiales promocionales',
  settings: 'Ajustes',
  payments: 'Pago',
  admins: 'Administradores',
  advertisers: 'Anunciantes',
  partners: 'Socios',
  wallet: 'Finanzas',
  your_manager: 'Su manager',
  support: 'Apoyo',
  referrals: 'Referencias',
  members: 'Usuarios',
  api_doc: 'Documentación API',
};
