import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const EventGroup = {
  GET_EVENT_GROUPS: {
    url: generateAdminApiUrl(ModelPath.EventGroup),
    method: 'GET',
  },
  CREATE_EVENT_GROUP: {
    url: generateAdminApiUrl(ModelPath.EventGroup),
    method: 'POST',
    bodyMapping: [{ key: 'name', optional: false }],
  },
  GET_EVENT_GROUP_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.EventGroup),
    method: 'GET',
  },
  UPDATE_EVENT_GROUP_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.EventGroup),
    method: 'PATCH',
    bodyMapping: [{ key: 'name', optional: false }],
  },
  DELETE_EVENT_GROUP_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.EventGroup),
    method: 'DELETE',
  },
  GET_LIST: {
    url: generateAdminApiUrl('event', ModelPath.Groups),
    method: 'GET',
  },
} as const;

export default EventGroup;
