import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const News = {
  GET_ALL: {
    url: generatePartnerApiUrl(ModelPath.News),
    method: 'GET',
  },
  GET: {
    url: generatePartnerApiUrl(':id', ModelPath.News),
    method: 'GET',
  },
} as const;

export default News;
