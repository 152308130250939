import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Advertiser = {
  GET_ADVERTISERS_LIST: {
    url: generatePartnerApiUrl(ModelPath.Advertiser),
    method: 'GET',
  },
} as const;

export default Advertiser;
