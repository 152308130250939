import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Role = {
  GET_ROLES: {
    url: generateAdminApiUrl(ModelPath.Role),
    method: 'GET',
  },
  CREATE_ROLE: {
    url: generateAdminApiUrl(ModelPath.Role),
    method: 'POST',
    bodyMapping: ['name', { key: 'rules', optional: true }],
  },
  GET_ROLE_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Role),
    method: 'GET',
  },
  UPDATE_ROLE_BY_ID: {
    url: generateAdminApiUrl(':roleId', ModelPath.Role),
    method: 'PATCH',
    bodyMapping: [
      { key: 'name', optional: true },
      {
        key: 'rules',
        optional: true,
      },
    ],
  },
  DELETE_ROLE_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Role),
    method: 'DELETE',
  },
} as const;

export default Role;
