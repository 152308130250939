export default {
  logs: 'Логи',
  received_postbacks: 'Полученные постбеки',
  sent_postbacks: 'Отправленные постбеки',
  clicks: 'Клики',
  click_id: 'ID клика',
  no_logs: 'Нет логов',
  message: 'Сообщение',
  postback: 'Постбек',
  conversion_id: 'ID конверсии',
  response: 'Ответ',
  date_or_period: 'Дата или период',
  log_date: 'Дата и время',
  search: 'Поиск',
  ip: 'IP',
  os: 'OC',
  browser: 'Браузер',
  offer: 'Оффер',
  partner: 'Партнёр',
  destination: 'Направление',
};
