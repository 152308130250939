export default {
  promo_codes: 'Códigos promocionales',
  promo_types: 'Tipos',
  add_new_type: 'Nuevo tipo',
  add_new_promo_code: 'Nuevo Código Promocional',
  delete_type: 'Eliminar tipo',
  add_type: 'Añadir tipo',
  edit_type_properties: 'Editar parámetros de tipo',
  type_editing: 'Edición de tipo',
  type_adding: 'Añadir un tipo',
  type_successfully_created: 'Tipo añadido con éxito',
  type_successfully_updated: 'Tipo actualizado con éxito',
  type_form_product: 'Producto',
  type_form_name: 'Nombre',
  type_form_description: 'Descripción',
  type_form_poster: 'Póster',
  type_form_upload: 'Descargar',
  type_delete_title: '¿Eliminar tipo?',
  type_delete_message:
    '¿Seguro que desea eliminar el tipo de código promocional? Junto con el tipo, se eliminarán todos sus códigos de promoción, incluidos los activos.',
  type_delete_button: 'Sí, eliminar',
  column_promo_code: 'Código Promocional',
  column_limit: 'Usos / Total',
  column_partner_offer: 'Socio & Oferta',
  column_offer: 'Oferta',
  column_type_product: 'Tipo & Producto',
  column_description: 'Descripción',
  promo_code_personal: 'Personal',
  promo_code_end_limit: 'Se ha alcanzado el límite de uso',
  promo_code_expired: 'Caducado',
  promo_code_assign: 'Enlazar',
  promo_code_unlimited: 'Ilimitado',
  promo_code_import_csv: 'Importar desde CSV',
  promo_code_create_personal: 'Crear un personal',
  promo_code_filter_promo_code: 'Código Promocional',
  promo_code_filter_offer: 'Oferta',
  promo_code_filter_type: 'Tipo',
  promo_code_filter_partner: 'Socio',
  promo_code_filter_product: 'Producto',
  promo_code_filter_assignment: 'Vinculación',
  promo_code_filter_reset: 'Limpiar filtros',
  promo_code_no_data: 'Sin datos',
  promo_code_successfully_created: 'Código promocional añadido con éxito',
  promo_code_successfully_updated: 'Código promocional actualizado con éxito',
  promo_code_successfully_assigned: 'Código promocional vinculado con éxito',
  promo_code_delete_title: '¿Eliminar el código promocional?',
  promo_code_form_title_add: 'Código promocional personal',
  promo_code_form_title_edit: 'Editar código promocional',
  promo_code_form_title_assign: 'Vincular código promocional',
  promo_code_form_active_from: 'Activo con',
  promo_code_form_active_to: 'por',
  promo_code_form_conversions_limit: 'Límite de usos',
  promo_code_form_type_placeholder: 'Seleccione el tipo...',
  promo_code_form_period: 'Período',
  promo_code_form_conversions: 'Usos',
  promo_code_form_assign_code: 'Vincular el código',
  promo_code_form_import_title: 'Importar códigos promocionales',
  promo_code_form_import_codes: 'Importar códigos',
  promo_code_form_template: 'Obtener plantilla',
  promo_code_form_import_upload_csv: 'Descargar CSV',
  promo_code_form_import_help_1:
    'Se añadirán nuevos códigos a la lista de códigos disponibles de este tipo.',
  promo_code_form_import_help_2:
    'Se aplicarán nuevos parámetros (límite y periodo de validez) a los códigos existentes.',
  promo_code_form_import_help_3:
    'Los cambios se aplicarán tanto a los códigos promocionales gratuitos como a los emitidos.',
  promo_code_form_import_success: 'Importación completada',
  promo_code_form_import_fail: 'Importación rechazada',
  promo_code_form_go_to_list: 'Volver a la lista',
  promo_code_form_try_again: 'Para volver a intentarlo',
  promo_code_form_imported: 'importado',
  promo_code_form_processing_errors: 'errores de procesamiento',
  promo_code_form_fail_message:
    'Se han encontrado errores críticos en el archivo. \nLos códigos promocionales con estos errores no se pueden descargar.',
  promo_code_import_table_number: 'Número de línea',
  promo_code_import_table_field: 'Campo',
  promo_code_import_table_message: 'Mensaje de error',
  promo_code_copied: 'Código promocional copiado',
  promo_code_search_promo_code: 'Buscando un código promocional',
  promo_code_dont_have_promo_codes:
    'No tiene ningún código promocional con los parámetros indicados',
  promo_code_to_get_new_promo_code: 'Para obtener un nuevo código promocional,',
  promo_code_choose_offer: 'seleccione una oferta',
  promo_code_open_tab: 'y abre la pestaña Códigos promocionales',
  promo_code_why: '¿Por qué necesita códigos promocionales?',
  promo_code_why_place:
    'El código promocional se puede utilizar donde no hay oportunidad de colocar enlaces de referencia y anunciar productos y servicios (instagram, vídeo, publicidad offline).',
  promo_code_why_registration:
    'El usuario puede introducir un código promocional al registrarse en el sitio, lo que le permite vincularse automáticamente a usted. En este caso, no es necesario que un nuevo cliente acceda al sitio a través de un enlace de afiliado.',
  promo_code_why_bonus:
    'Al registrarse con un código promocional, el usuario recibe una bonificación mayor, por lo que le interesa utilizarlo. Las condiciones de acumulación de bonificaciones al registrarse mediante código promocional pueden aclararse con el gestor.',
  promo_code_your_promo_code: 'Su código promocional',
  promo_code_copy_code: 'Copie el código',
  promo_code_get_new_code: 'Obtener un nuevo código',
  promo_code_maximum: 'Un máximo de {{count}} códigos para este ofertante',
  promo_code_reach_maximum:
    'Ha alcanzado el límite de códigos {{count}} para esta oferta',
  promo_code_also_you_can: 'También puede',
  promo_code_ask_code: 'solicite un código promocional personalizado',
  promo_code_active_codes: '{{count}} de códigos activos',
  promo_code_maximum_tooltip_1:
    'Este límite se aplica a todos los códigos promocionales (personales y normales), pero el administrador de la red puede emitir códigos adicionales por encima del límite en casos especiales.',
  promo_code_maximum_tooltip_2:
    'Para obtener un código adicional o personalizado (con un nombre y parámetros únicos), haga clic en "Solicitar código promocional personalizado".',
  promo_code_request: 'Solicitar un código promocional',
  promo_code_request_admin: 'Escriba a su administrador',
  promo_code_to_telegram: 'en telegramas',
  promo_code_request_message:
    'solicitud de un código promocional adicional. En el mensaje, especifique:',
  promo_code_request_type_name: 'título promocional',
  promo_code_request_period: 'período de validez',
  promo_code_request_conversions: 'límite de uso',
  promo_code_request_personal:
    'Si necesita un código promocional personalizado, especifique también la palabra promocional deseada.',
  promo_code_partner_empty_types: 'No hay códigos promocionales disponibles',
  promo_code_partner_empty_types_extra:
    'Este ofertante no tiene promociones activas',
  promo_code_partner_offer_empty_promo_codes:
    'No tienes códigos promocionales activos',
  promo_code_partner_offer_empty_promo_codes_extra:
    'Para obtener el código promocional, haga clic en el botón "Obtener nuevo código".',
};
