import { generateAdvertiserApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Settings = {
  GET_GENERAL_SETTINGS: {
    url: generateAdvertiserApiUrl('common', ModelPath.Settings),
    method: 'GET',
  },
} as const;

export default Settings;
