export default {
  settings: 'Ajustes',
  domains: 'Dominios',
  tracking_domains: 'Dominios de rastreo',
  categories: 'Categorías',
  trafficback: 'Trafficback',
  offers: 'Ofertas',
  event_group: 'Grupo de eventos',
  event_groups: 'Grupos de eventos',
  goal_group: 'Grupo de objetivos',
  goal_groups: 'Grupos de objetivos',
  security: 'Seguridad',
  security_use_whitelist:
    'Usar una lista blanca de direcciones IP para los postbacks',
  security_use_whitelist_help_1:
    'Se permitirán los postbacks desde estas direcciones IP. Introduzca una dirección IP por línea.',
  security_use_whitelist_help_2: 'Se permiten direcciones IP únicas. Ejemplo:',
  security_2fa_enable: 'Habilitar verificación de dos factores',
  security_2fa_admin:
    'Habilitar la verificación de dos factores para el panel de administración',
  security_2fa_partner:
    'Habilitar la verificación de dos factores para el panel del socio',
  two_factor_auth_enter_code: 'Introduzca el código',
  two_factor_auth_enter_code_hint: 'Código de confirmación enviado por correo',
  two_factor_auth_enter_get_new_code:
    'Puede obtener el nuevo código a través de',
  two_factor_auth_enter_send_new_code: 'Enviar nuevo código',
  contacts: 'Agregar contactos de administrador',
  partner_advertiser_contacts: 'Contacto del socio/anunciante',
  integrations: 'Integraciones',
  tags: 'Etiquetas',
  general_settings: 'Configuración básica',
  settings_successfully_updated: 'Configuración actualizada con éxito',
  settings_invoices_successfully_updated:
    'Configuraciones de generación automática de cuentas actualizadas con éxito',
  general_settings_name: 'Nombre de la red de afiliados',
  general_settings_logo: 'Logotipo de la red de afiliados',
  general_settings_favicon: 'Icono de red de afiliados',
  general_contacts: 'Contactos generales de la red de socios',
  general_contacts_help:
    'Los datos de contacto se muestran en la página de inicio de sesión de los usuarios no habilitados',
  general_contacts_limit: 'Has alcanzado el límite de {{count}} contactos',
  technical_support_email: 'Correo electrónico del soporte técnico',
  users: 'Registro de usuarios',
  default_manager: 'Gestor predeterminado',
  default_manager_empty: 'No especificado',
  users_sending_email:
    'Confirmación obligatoria del correo electrónico por parte del anunciante y el socio',
  users_moderation: 'Moderación de anunciante y socio',
  recommendation_horizontal_logo:
    'Recomendamos descargar logotipos horizontales con una relación de aspecto de 3:1',
  currencies: 'Monedas',
  no_currencies: 'No hay monedas activas',
  currency_code: 'Código de moneda',
  rate: 'Curso',
  is_enabled: 'Activo',
  currency_successfully_updated: 'Moneda actualizada con éxito',
  main_domains: 'Dominios principales',
  admin_panel_url: 'URL del panel de administración',
  partner_panel_url: 'URL del panel de socios',
  advertiser_panel_url: 'URL del panel del anunciante',
  documents: 'Documentos',
  terms_of_use: 'Condiciones de uso',
  privacy_policy: 'Política de privacidad',
  show_terms_of_use: '¿Mostrar los términos de uso de los socios?',
  show_privacy_policy: '¿Mostrar la política de privacidad de los socios?',
  save_with_partners_acceptance:
    'Guardar con la aceptación obligatoria de los socios',
  save_with_partners_acceptance_help:
    'Una vez guardado, todos los socios deberán aceptar los términos de uso y la política de privacidad actualizados',
  save_with_partners_acceptance_confirm:
    '¿Está seguro de que desea ahorrar con la aceptación obligatoria de los socios de los documentos actualizados?',
  resale_period: 'Período de inactividad (días)',
  referral_program: 'Programa de referencia',
  referral_percent: 'Porcentaje del programa de referencia',
  payment_systems: 'Sistemas de pago',
  payments: 'Pagos',
  thresholds: 'Cantidad mínima de retiro',
  thresholds_hint:
    'Cantidad mínima para la cuenta. Para cada moneda su umbral mínimo.',
  invoices: 'Generación de cuentas',
  invoices_hint:
    'Cualquier cuenta puede ser creada manualmente por el administrador',
  invoices_partner: 'El afiliado puede crear una cuenta por sí mismo',
  invoices_partner_hint:
    'El afiliado podrá crear sus propias cuentas para retirar fondos desde su cuenta personal.',
  invoice_generation_method: 'Métodos de generación',
  invoices_autogenerate: 'Autogeneración',
  invoices_autogenerate_help:
    'Las cuentas se crearán automáticamente para todos los socios activos en función de la configuración de la frecuencia y las ofertas seleccionadas.',
  invoices_autogenerate_week: 'Una vez por semana',
  invoices_autogenerate_month: 'Una vez al mes',
  invoices_autogenerate_month_twice: 'Dos veces al mes',
  invoices_autogenerate_partners: 'Socios',
  invoices_autogenerate_offers: 'Ofertas',
  invoices_autogenerate_week_day_1: 'Lunes',
  invoices_autogenerate_week_day_2: 'Martes',
  invoices_autogenerate_week_day_3: 'Miércoles',
  invoices_autogenerate_week_day_4: 'Jueves',
  invoices_autogenerate_week_day_5: 'Viernes',
  invoices_autogenerate_week_day_6: 'Sábado',
  invoices_autogenerate_week_day_7: 'Domingo',
  invoices_autogenerate_week_every_day_1: 'Todos los lunes',
  invoices_autogenerate_week_every_day_2: 'Todos los Martes',
  invoices_autogenerate_week_every_day_3: 'Todos los Miércoles',
  invoices_autogenerate_week_every_day_4: 'Todos los Jueves',
  invoices_autogenerate_week_every_day_5: 'Todos los viernes',
  invoices_autogenerate_week_every_day_6: 'Todos los sábados',
  invoices_autogenerate_week_every_day_7: 'Todos los domingos',
  invoices_day_picker_help:
    'Si el mes no tiene un número seleccionado, la factura se generará el último día del mes.',
  invoices_on_nth_day_one: 'Día {{day}}',
  invoices_on_nth_day_two: 'Día {{day}}',
  invoices_on_nth_day_few: 'Día {{day}}',
  invoices_on_nth_day_other: 'Día {{day}}',
  active_user_criteria: 'Criterio de usuario activo',
  active_user_help:
    'Un usuario activo es un usuario que ha realizado una acción objetivo de un grupo de objetivos seleccionado durante el período de tiempo especificado. Los usuarios activos se definirán en función del grupo de objetivos seleccionado. Se utiliza para el análisis de cohortes.',
  trafficback_url: 'Trafficback URL',
  trafficback_url_hint:
    'Si el tráfico no coincide con los parámetros, se redirigirá a la URL especificada. El enlace puede especificar macros:\n\n{click_id} - ID de clic único;\n{partner_id} - ID de socio;\n{sub_id1} - sub_id #1, puede agregar hasta 10 sub_id;\n{offer_id} - ID de oferta;\n{creative_id} - ID de material promocional.',
  promocodes: 'Códigos promocionales',
  max_promocode_count:
    'El número de códigos promocionales gratuitos en la oferta que el afiliado puede obtener por sí mismo',
  promo_code_postback_priority: 'Prioridad de procesamiento de postback',
  conversion_settings: 'Trabajar con conversiones',
  conversion_settings_partners_label:
    'Hacer que las macros {value} y {value_currency} estén disponibles para los siguientes socios',
  conversion_settings_partners_help:
    'El envío del valor del campo value y su moneda estará disponible para los socios seleccionados en los postbacks locales y globales',
  conversion_settings_hide_reasons_title:
    'Ocultar a los socios las conversiones rechazadas:',
  conversion_settings_not_hide: 'No ocultar',
  conversion_settings_hide_all: 'Todo',
  conversion_settings_hide_reasons: 'Con las razones:',
  show_custom_fields_for_partner:
    'Mostrar a los socios las siguientes opciones:',
  setting_name: 'Nombre',
  global_postbacks: 'Postbacks globales',
  add_global_postback: 'Añadir un postback global',
  global_postback_always_send: 'Enviar con un postback local en la oferta',
  is_conditions_hidden_to_partner:
    'Ocultar las condiciones generales del socio si hay al menos una condición individual',
  conversions_by_hidden_conditions:
    'Utilizar conversiones generadas bajo condiciones ocultas para calcular y generar estadísticas generales',
  is_referral_email_hidden_to_partner:
    'Ocultar las referencias de correo electrónico de los socios',
  cookies: 'Trabajando con Cookies',
  cookie_lifetime: 'Duración de la cookie en días',
  cookies_help:
    'Para activar la duración de las cookies, copie y pegue el js-script anterior en el sitio. El código debe colocarse en la sección <body></body> de su sitio web.',
  add_currency: 'Añadir moneda',
  ga_qr_text:
    'Para habilitar la autenticación de dos factores Google Authenticator, instale la aplicación a través de AppStore o GoogleMarket y escanee el código QR.',
  ga_text: 'Para agregar manualmente, proporcione la siguiente información:',
  ga_connected_label: 'Dispositivo conectado',
  ga_connected_text:
    'Para cambiar el tipo de verificación de dos factores, apague el dispositivo.',
  ga_secret_key: 'la clave secreta',
  timezone: 'Zona horaria',
  menu_settings_title: 'Enlaces adicionales en el menú de afiliados',
  menu_settings_title_help: 'Estos enlaces aparecerán en el menú del socio',
  add_menu_item: 'Agregar elemento de menú',
  menu_setting_adding_modal_title: 'Agregar elemento de menú',
  menu_setting_editing_modal_title: 'Editar elemento del menú',
  no_menu_items: 'Sin elementos de menú',
  menu_settings_remove_confirm:
    '¿Está seguro de que desea eliminar el elemento del menú?',
  menu_settings_name: 'Nombre del menú',
  menu_settings_link: 'Enlace',
  menu_settings_color: 'Color',
  menu_settings_successfuly_created: 'Elemento de menú añadido con éxito',
  menu_settings_successfuly_updated: 'Elemento de menú actualizado con éxito',
  menu_settings_limit:
    'Has alcanzado el límite de {{count}} elementos del menú.',
  color_black: 'Negro',
  color_white: 'Blanco',
  color_red: 'Rojo',
  color_orange: 'Naranja',
  color_green: 'Verde',
  'color_blue-green': 'Azul-verde',
  color_purple: 'Violeta',
  'color_light-red': 'Luz rojo',
  'color_light-orange': 'Luz naranja',
  'color_light-green': 'Luz verde',
  'color_light-blue-green': 'Luz azul-verde',
  'color_light-purple': 'Luz violeta',
  color_blue: 'Azul',
  color_yellow: 'Amarillo',
  color_gray: 'Gris',
  is_hmac_activated:
    'Habilitar verificación de firma en devoluciones de datos basadas en el HMAC',
};
