import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Condition = {
  GET: {
    url: generateAdminApiUrl(':id', ModelPath.Condition),
    method: 'GET',
  },
  DELETE: {
    url: generateAdminApiUrl(':id', ModelPath.Condition),
    method: 'DELETE',
  },
  UPDATE: {
    url: generateAdminApiUrl(':id', ModelPath.Condition),
    method: 'PATCH',
  },
} as const;

export default Condition;
