export default {
  wallet: 'Finanzas',
  history: 'Historia',
  no_withdrawals: 'Sin conclusiones',
  table_withdrawals_id: 'ID',
  table_withdrawals_date: 'Fecha',
  table_withdrawals_id_date: 'ID & Fecha',
  table_withdrawals_system: 'Sistema de pago',
  table_withdrawals_wallet: 'Monedero',
  table_withdrawals_amount: 'Cantidad',
  table_withdrawals_status: 'Estado',
  table_withdrawals_comment: 'Comentario',
  status_pending: 'En espera',
  status_rejected: 'Rechazado',
  status_approved: 'Aprobado',
  status_canceled: 'Cancelado',
  status_payed: 'Pagado',
  money_hold: 'Fondos en Hold',
  free_for_withdrawal: 'Disponible para salida',
  withdrawal_cancel_confirm: '¿Desea cancelar el retiro?',
  withdrawal_successfully_created:
    'Solicitud agregada con éxito. Es posible que los datos de la tabla aparezcan con un ligero retraso.',
  withdrawal: 'Conclusión',
  withdrawals: 'Retiros de fondos',
  to_withdrawal: 'Salir',
  payment_method: 'Método de pago',
  payment_methods: 'Métodos de pago',
  add_wallet: 'Añadir monedero',
  edit_wallet: 'Editar monedero',
  wallet_number: 'Número de billetera',
  webmoney_wallet_number: 'Número de billetera Webmoney',
  webmoney_wallet_number_start_symbol:
    'El número de billetera de Webmoney debe comenzar con una letra',
  webmoney_wallet_number_number_count:
    'El número de billetera de Webmoney debe contener doce dígitos',
  webmoney_wallet_number_error:
    'El número de billetera de Webmoney debe contener solo números y una letra al principio',
  wallet_successfully_created: 'Monedero añadido con éxito',
  wallet_successfully_edited: 'Monedero cambiado con éxito',
  wallet_successfully_deleted: 'Monedero eliminado con éxito',
  wallet_remove_confirm: '¿Desea eliminar este monedero?',
  no_wallets: 'No hay billeteras.',
  please_create_wallet: 'Por favor, cree su primer monedero.',
  main_balance: 'Balance principal',
  hold: 'Saldo de retención',
  referral_balance: 'Balance de referencia',
  referral_hold_balance: 'Hold ref.equilibrio',
  referral_hold_balance_help:
    'Saldo de referencia de conversiones impagadas. Estará disponible para el retiro cuando se verifiquen y paguen las conversiones relevantes.',
  revshare_balance: 'Balance de revólveres',
  revshare_hold_balance: 'Balance Hold Revólveres',
  revshare_balance_help: 'Balance de conversión con el modelo de pago Revshare',
  payment_system: 'Sistema de pago',
  available_for_withdrawal: 'Disponible',
  table_history_empty: 'El historial está vacío, no tiene pagos en efectivo',
  cancel_withdrawal: '¿Cancelar el retiro?',
  cancel_withdrawal_confirm: '¿Estás seguro de que quieres cancelar el retiro?',
  remove_wallet: '¿Eliminar método de salida?',
  remove_wallet_confirm: '¿Seguro que desea eliminar el método de salida?',
  cancel_withdrawal_request: 'Cancelar salida',
  cannot_cancel_withdrawal:
    'Las solicitudes aprobadas no pueden cancelarse. Pronto se pagará.',
  set_default_method: 'Establecer como método predeterminado',
  withdrawal_methods: 'Métodos de retiro',
  no_withdrawal_methods: 'No hay métodos de retiro',
  add_method: 'Añadir método',
  available: 'Disponible',
  please_add_method: 'Por favor, agregue un método de retiro',
  please_set_default_method:
    'Por favor, establezca uno de sus métodos de retiro como el método predeterminado',
  default_method: 'Por defecto',
  default_method_help:
    'De forma predeterminada, se utiliza esta cartera para retirar fondos. Todos los fondos disponibles para retirar se convertirán a la divisa de la cartera.',
  min_withdraw_amount: 'Cantidad mínima para retirar fondos',
};
