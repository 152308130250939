import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Settings = {
  GET_GENERAL_SETTINGS: {
    url: generateAdminApiUrl('common', ModelPath.Settings),
    method: 'GET',
  },
  GET_INVOICES_SETTINGS: {
    url: generateAdminApiUrl('auto', ModelPath.Invoice),
    method: 'GET',
  },
  UPDATE_GENERAL_SETTINGS: {
    url: generateAdminApiUrl('common', ModelPath.Settings),
    method: 'PATCH',
  },
  UPDATE_INVOICES_SETTINGS: {
    url: generateAdminApiUrl('auto', ModelPath.Invoice),
    method: 'PATCH',
  },
  GET_GENERAL_MENU_SETTINGS: {
    url: generateAdminApiUrl('menu/links', ModelPath.Settings),
    method: 'GET',
  },
  CREATE_GENERAL_MENU_SETTING: {
    url: generateAdminApiUrl('menu/links', ModelPath.Settings),
    method: 'POST',
  },
  UPDATE_GENERAL_MENU_SETTINGS: {
    url: generateAdminApiUrl('menu/links/:id', ModelPath.Settings),
    method: 'PATCH',
  },
  DELETE_GENERAL_MENU_SETTING: {
    url: generateAdminApiUrl('menu/links/:id', ModelPath.Settings),
    method: 'DELETE',
  },
} as const;

export default Settings;
