import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Tag = {
  GET_TAGS: {
    url: generateAdminApiUrl(ModelPath.Tag, ModelPath.Offer),
    method: 'GET',
  },
  CREATE_TAG: {
    url: generateAdminApiUrl(ModelPath.Tag, ModelPath.Offer),
    method: 'POST',
    bodyMapping: ['name'],
  },
  GET_TAG_BY_ID: {
    url: generateAdminApiUrl(`${ModelPath.Tag}/:id`, ModelPath.Offer),
    method: 'GET',
  },
  UPDATE_TAG_BY_ID: {
    url: generateAdminApiUrl(`${ModelPath.Tag}/:id`, ModelPath.Offer),
    method: 'PATCH',
    bodyMapping: ['name'],
  },
  DELETE_TAG_BY_ID: {
    url: generateAdminApiUrl(`${ModelPath.Tag}/:id`, ModelPath.Offer),
    method: 'DELETE',
  },
  GET_PARTNER_TAGS: {
    url: generateAdminApiUrl(ModelPath.Tag, ModelPath.Partner),
    method: 'GET',
  },
  CREATE_PARTNER_TAG: {
    url: generateAdminApiUrl(ModelPath.Tag, ModelPath.Partner),
    method: 'POST',
    bodyMapping: ['name'],
  },
  GET_PARTNER_TAG_BY_ID: {
    url: generateAdminApiUrl(`${ModelPath.Tag}/:id`, ModelPath.Partner),
    method: 'GET',
  },
  UPDATE_PARTNER_TAG_BY_ID: {
    url: generateAdminApiUrl(`${ModelPath.Tag}/:id`, ModelPath.Partner),
    method: 'PATCH',
    bodyMapping: ['name'],
  },
  DELETE_PARTNER_TAG_BY_ID: {
    url: generateAdminApiUrl(`${ModelPath.Tag}/:id`, ModelPath.Partner),
    method: 'DELETE',
  },
} as const;

export default Tag;
