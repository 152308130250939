import uniqid from 'uniqid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import ICommonModalState from 'models/ICommonModalState';

export type ModalsState = { modals: ICommonModalState[] };

const modals = createSlice({
  name: 'modals',
  initialState: { modals: [] } as ModalsState,
  reducers: {
    createModal(state, action: PayloadAction<{ options: ICommonModalState }>) {
      const { options } = action.payload;
      state.modals.push({
        ...options,
        id: uniqid(),
      } as ICommonModalState);
    },
    deleteModal(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.modals = state.modals.filter((m) => m.id !== id);
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = modals;
// Extract and export each action creator by name
export const { createModal, deleteModal } = actions;
export default reducer;
