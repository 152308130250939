export default {
  partners: 'Partners',
  'create partner': 'Create partner',
  'partner creation': 'Partner creation',
  email: 'Email',
  password: 'Password',
  'partner successfully created': 'Partner successfully created',
  no_partners: 'No partners',
  id_user: 'id user',
  telegram: 'Telegram',
  status: 'Status',
  creation_date: 'Creation date',
  id: 'ID',
  created: 'Created',
  edit_profile: 'Edit profile',
  offers: 'Offers',
  incomplete_registration: 'Incomplete registration',
  active: 'Active',
  partner_successfully_updated: 'Partner successfully updated',
  banned: 'Banned',
  tied_manager: 'Tied manager',
  balance: 'Balance',
  hold: 'Hold',
  referral_balance: 'Referral balance',
  referral_hold_balance: 'Hold referral balance',
  list_of_partners: 'List of partners',
  individual_referral_percent: 'Individual referral percent',
  referral_percent: 'Referral percent',
  revshare_balance: 'Revshare balance',
  revshare_hold_balance: 'Hold revshare balance',
  tags: 'Tags',
  note: 'Internal note',
  manager: 'Manager',
  confirm_modal_title: 'Change status?',
  confirm_modal_text:
    'Are you sure you want to change the status? Partner will be disconnected from all offers.',
  manager_limit_tooltip: 'You can select no more than 5 managers',
  source_of_registration: 'Source of registration',
  administration: 'Activity',
  registration: 'Registration',
  contacts: 'Contacts',
  addition_info: 'Additional info',
  link_generator: 'Link generator',
  link_statistics: 'Statistics',
  link_conversion: 'Conversions',
  link_promocode: 'Promo codes',
  link_cohort_analysis: 'Cohort analysis',
  payment_method: 'Payment method',
  no_payment_methods: 'No payment methods',
  remove_wallet: 'Remove payment method?',
  remove_wallet_confirm: 'Are you sure you want to remove the payment method?',
  balance_title: 'Balance',
  balance_all_currencies: 'All currencies',
  balance_all: 'Total',
  balance_all_tooltip: 'The total amount of funds available',
  balance_revshare: 'Revshare balance',
  balance_revshare_tooltip: 'The balance earned from shared revenue agreements',
  balance_referral: 'Referral balance',
  balance_referral_tooltip: 'The balance earned from referring other users',
  balance_cpa: 'CPA',
  balance_cpa_tooltip:
    'The balance earned from specific user actions that lead to conversions',
  balance_in_hold: 'in hold',
  referral: 'Referral',
  referral_id: 'ID partners',
  referral_commission: 'Subpartner commission',
  referral_email: 'E-mail partner',
  referral_registration: 'Registration',
  no_referrals: 'No referrals',
  invoice: 'Invoice',
  postback: 'Postback',
  postback_global: 'Global',
  postback_local: 'Private',
  postback_global_label: 'Level',
  postback_filter: 'Offer ID or Name',
};
