import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Product = {
  GET_PRODUCT_LIST: {
    url: generateAdminApiUrl(ModelPath.Product),
    method: 'GET',
  },
  GET_PRODUCT_BY_ID: {
    url: generateAdminApiUrl(':product_id', ModelPath.Product),
    method: 'GET',
  },
  ENABLE_PRODUCT: {
    url: generateAdminApiUrl(':product_id/enable', ModelPath.Product),
    method: 'PATCH',
  },
  DISABLE_PRODUCT: {
    url: generateAdminApiUrl(':product_id/disable', ModelPath.Product),
    method: 'PATCH',
  },
  CREATE_PRODUCT: {
    url: generateAdminApiUrl(':advertiser_id/products', ModelPath.Advertiser),
    method: 'POST',
    bodyMapping: [
      'name',
      { key: 'url', optional: true },
      { key: 'product_category_id', optional: true },
      { key: 'click_ttl', optional: true },
      { key: 'note', optional: true },
      { key: 'admin_ids', optional: true },
    ],
  },
  UPDATE_PRODUCT: {
    url: generateAdminApiUrl(':product_id', ModelPath.Product),
    method: 'PATCH',
    bodyMapping: [
      'name',
      { key: 'url', optional: true },
      { key: 'product_category_id', optional: true },
      { key: 'click_ttl', optional: true },
      { key: 'note', optional: true },
      { key: 'admin_ids', optional: true },
    ],
  },
  GET_OFFERS: {
    url: generateAdminApiUrl(':product_id/offers', ModelPath.Product),
    method: 'GET',
  },
} as const;

export default Product;
