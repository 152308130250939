export default {
  'name of event': 'Название события',
  'create event': 'Создать событие',
  'edit event': 'Редактировать событие',
  id: 'ID',
  key: 'Ключ',
  monetary: 'Денежное',
  monetary_event_hint:
    'Если событие подразумевает работу с денежными или иными суммами, то необходимо включить этот режим.\nИначе вы не сможете принимать значения событий.\nПримеры денежных событий: покупка, депозит, вывод и т.п.\nПримеры неденежного события: регистрация, прохождение уровня в игре, привлечение пользователя и т.п.\nПосле создания события изменить его вид будет невозможно.',
  'no events list': 'Нет списка событий',
  'from 3 to 30 characters': 'От 3 до 30 символов',
  'event successfully created': 'Событие успешно создано',
  'event successfully deleted': 'Событие успешно удалено',
  'event successfully updated': 'Событие успешно обновлено',
  'Are you sure you want to delete the event':
    'Вы уверены, что хотите удалить событие?',
  event_cannot_be_deleted:
    'Событие нельзя удалить, так как оно используется в целях продукта.',
  event_key_help:
    'Идентификатор события, который будет приходить в постбеке в параметре {event}. Значение будет приведено в нижний регистр.',
  enter_to_search_or_add: 'Введите для поиска или добавления...',
  group: 'Группа',
};
