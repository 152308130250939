/**
 * Represents an empty object.
 *
 * Used to initialize empty object variables and reduce number of renders if the variables used as dependencies in useEffect, useCallback, useMemo etc.
 *
 * @type {Array}
 * @default []
 */
const emptyObject = {};

export default emptyObject;
