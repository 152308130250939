export default {
  add_event_groups: 'Add new event group',
  no_event_groups: 'No event groups',
  name: 'Event group name',
  event_group_editing: 'Editing the event group',
  event_group_adding: 'Addition a event group',
  event_group_name: 'Event group name',
  event_group_name_placeholder: 'Enter event group name',
  event_group_successfully_created: 'Event group successfully added',
  event_group_successfully_updated: 'Event group successfully updated',
  event_group_remove_confirm: 'Do you want to delete the event group?',
  product: 'Product',
};
