import { generatePartnerApiUrl } from 'utils/apiUtils';

const Withdrawal = {
  GET_BALANCE: {
    url: generatePartnerApiUrl('balance'),
    method: 'GET',
  },
} as const;

export default Withdrawal;
