import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes';

const TwoFactorAuth = {
  SEND_CODE: {
    url: generatePartnerApiUrl('send', ModelPath.TwoFactorAuth),
    method: 'POST',
  },
  GET_OTP_SECRET: {
    url: generatePartnerApiUrl('otp/secret', ModelPath.TwoFactorAuth),
    method: 'GET',
  },
  CONNECT: {
    url: generatePartnerApiUrl('connect', ModelPath.TwoFactorAuth),
    method: 'POST',
  },
  DISCONNECT: {
    url: generatePartnerApiUrl('disconnect', ModelPath.TwoFactorAuth),
    method: 'POST',
  },
} as const;

export default TwoFactorAuth;
