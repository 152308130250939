export default {
  logs: 'Logis',
  received_postbacks: 'Postbacks recibidos',
  sent_postbacks: 'Postbacks enviados',
  clicks: 'Clics',
  click_id: 'ID de clic',
  no_logs: 'Sin registros',
  message: 'Mensaje',
  postback: 'Postback',
  conversion_id: 'ID de conversión',
  response: 'Respuesta',
  date_or_period: 'Fecha o período',
  log_date: 'Fecha y hora',
  search: 'Búsqueda',
  ip: 'IP',
  os: 'OC',
  browser: 'Navegador',
  offer: 'Oferta',
  partner: 'Socio',
  destination: 'Dirección',
};
