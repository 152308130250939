import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Invoices = {
  GET_ALL: {
    url: generatePartnerApiUrl(ModelPath.Invoice),
    method: 'GET',
  },
  GET: {
    url: generatePartnerApiUrl(':id', ModelPath.Invoice),
    method: 'GET',
  },
  CREATE: {
    url: generatePartnerApiUrl(ModelPath.Invoice),
    method: 'POST',
    twoFactorAuth: true,
  },
  CANCEL: {
    url: generatePartnerApiUrl(':id/reject', ModelPath.Invoice),
    method: 'PATCH',
  },
  GET_CONVERSIONS: {
    url: generatePartnerApiUrl(':id/conversions', ModelPath.Invoice),
    method: 'GET',
  },
  GENERATE_STATUS: {
    url: generatePartnerApiUrl('generate/status', ModelPath.Invoice),
    method: 'GET',
  },
} as const;

export default Invoices;
