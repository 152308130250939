import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Invoices = {
  GET_ALL_SENT: {
    url: generatePartnerApiUrl('logs/sended', ModelPath.Postback),
    method: 'GET',
  },
} as const;

export default Invoices;
