export default {
  'no advertisers': 'No advertisers',
  id: 'ID',
  email: 'Email',
  password: 'Password',
  manager: 'Manager',
  'offers count': 'Offers count',
  'registration date': 'Registration date',
  creation_date: 'Creation date',
  status: 'Status',
  actions: 'Actions',
  'new advertiser': 'New advertiser',
  rejected: 'Rejected',
  banned: 'Banned',
  moderation: 'Moderation',
  active: 'Active',
  accept: 'Accept',
  reject: 'Reject',
  advertisers: 'Advertisers',
  new_advertiser: 'New Advertiser',
  creation_advertiser: 'Creation advertiser',
  advertiser_successfully_created: 'Advertiser successfully created',
  created: 'Created',
  edit_profile: 'Edit profile',
  offers: 'Offers',
  incomplete_registration: 'Incomplete registration',
  advertiser_successfully_updated: 'Advertiser successfully updated',
  list_of_advertisers: 'List of advertisers',
};
