export default {
  partners: 'Партнеры',
  'create partner': 'Создать партнера',
  'partner creation': 'Создание партнера',
  email: 'Email',
  password: 'Пароль',
  'partner successfully created': 'Парнер успешно создан',
  no_partners: 'Нет данных партнеров',
  id_user: 'ID пользователя',
  telegram: 'Телеграм',
  status: 'Статус',
  creation_date: 'Дата создания',
  id: 'ID',
  created: 'Создан',
  edit_profile: 'Редактировать профиль',
  offers: 'Офферы',
  incomplete_registration: 'Неполная регистрация',
  active: 'Активный',
  partner_successfully_updated: 'Партнер успешно обновлен',
  banned: 'Забанен',
  tied_manager: 'Привязанный менеджер',
  balance: 'Баланс',
  hold: 'Холд',
  referral_balance: 'Реферальный баланс',
  referral_hold_balance: 'Холд реф.баланс',
  list_of_partners: 'Список партнеров',
  individual_referral_percent: 'Индивидуальный реферальный процент',
  referral_percent: 'Реферальный процент',
  revshare_balance: 'Баланс ревшары',
  revshare_hold_balance: 'Баланс Холд ревшары',
  tags: 'Теги',
  note: 'Внутренняя заметка',
  manager: 'Менеджер',
  confirm_modal_title: 'Изменить статус?',
  confirm_modal_text:
    'Вы уверены, что хотите изменить статус? Партнер будет отключен от всех офферов.',
  manager_limit_tooltip: 'Вы можете выбрать не более 5 менеджеров',
  source_of_registration: 'Источник регистрации',
  administration: 'Активность',
  registration: 'Регистрация',
  contacts: 'Контакты',
  addition_info: 'Доп. информация',
  link_generator: 'Генератор ссылок',
  link_statistics: 'Статистика',
  link_conversion: 'Конверсии',
  link_promocode: 'Промокоды',
  link_cohort_analysis: 'Когортный анализ',
  payment_method: 'Способ оплаты',
  no_payment_methods: 'Нет методов оплаты',
  remove_wallet: 'Удалить метод оплаты?',
  remove_wallet_confirm: 'Вы уверены, что хотите удалить метод оплаты?',
  balance_title: 'Баланс',
  balance_all_currencies: 'Все валюты',
  balance_all: 'Общий баланс',
  balance_all_tooltip: 'Общая сумма доступных средств',
  balance_revshare: 'Ревшар баланс',
  balance_revshare_tooltip:
    'Баланс, заработанный от соглашений о совместном доходе',
  balance_referral: 'Реферальный баланс',
  balance_referral_tooltip:
    'Баланс, заработанный за привлечение других пользователей',
  balance_cpa: 'CPA',
  balance_cpa_tooltip:
    'Баланс, заработанный за определенные действия пользователей',
  balance_in_hold: 'на удержании',
  referral: 'Реферал',
  referral_id: 'ID партнеров',
  referral_commission: 'Субпартнерская комиссия',
  referral_email: 'E-mail партнера',
  referral_registration: 'Регистрация',
  no_referrals: 'Нет рефералов',
  invoice: 'Счет-фактура',
  postback: 'Постбэк',
  postback_global: 'Глобальный',
  postback_local: 'Приватный',
  postback_global_label: 'Уровень',
  postback_filter: 'Идентификатор или название оффера',
};
