import { generateAdvertiserApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Security = {
  GET_CLIENT_KEY: {
    url: generateAdvertiserApiUrl('client_key', ModelPath.Security),
    method: 'GET',
  },
  GENERATE_CLIENT_KEY: {
    url: generateAdvertiserApiUrl('client_key', ModelPath.Security),
    method: 'POST',
  },
  GET_SECRET_KEY: {
    url: generateAdvertiserApiUrl('secret_key', ModelPath.Security),
    method: 'GET',
  },
  GENERATE_SECRET_KEY: {
    url: generateAdvertiserApiUrl('secret_key', ModelPath.Security),
    method: 'POST',
  },
} as const;

export default Security;
