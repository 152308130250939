export default {
  add_categories: 'Añadir nueva categoría',
  no_categories: 'No hay categorías creadas',
  name: 'Nombre de la categoría',
  category_editing: 'Editar categoría',
  category_adding: 'Agregar una categoría',
  category_name: 'Nombre de la categoría',
  category_name_placeholder: 'Introduzca el nombre de la categoría',
  category_successfully_created: 'Categoría añadida con éxito',
  category_successfully_updated: 'Categoría cambiada con éxito',
  category_remove_confirm: '¿Desea eliminar una categoría?',
};
