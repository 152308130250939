import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Documents = {
  GET_DOCUMENTS: {
    url: generateAdminApiUrl(ModelPath.Documents),
    method: 'GET',
  },
  UPDATE_DOCUMENTS: {
    url: generateAdminApiUrl(ModelPath.Documents),
    method: 'PATCH',
  },
} as const;

export default Documents;
