export default {
  'admin.list': 'View a list of administrators',
  'admin.create': 'Creating an administrator',
  'admin.enable': 'Enabling an administrator',
  'admin.disable': 'Disabling an administrator',
  'admin.update': 'Editing an administrator',
  'admin.roles': 'Administrator Roles',
  'admin.own_partners_show': 'Access only to own partners',
  'advertiser.list': 'View a list of advertisers',
  'advertiser.approve': 'Approval of an advertiser',
  'advertiser.reject': 'Rejection of an advertiser',
  'advertiser.create': 'Creating an advertiser',
  'advertiser.show': 'View an advertiser',
  'advertiser.update': 'Editing an advertiser',
  'advertiser.ban': 'Banning an advertiser',
  'advertiser.unban': 'Unbanning an advertiser',
  'news.all': 'Working with news',
  'export.all': 'Exporting data',
  'partner.list': 'View a list of partners',
  'partner.approve': 'Approval of a partner',
  'partner.reject': 'Rejection of a partner',
  'partner.create': 'Creating a partner',
  'partner.show': 'View a partner',
  'partner.update': 'Editing a partner',
  'partner.ban': 'Banning a partner',
  'partner.unban': 'Unbanning a partner',
  'partner.auth': 'Authorization as a partner',
  'partner.wallet_add': 'Creating a payment method for a partner',
  'partner.wallet_edit': "Editing partner's payment method",
  'partner.wallet_delete': "Deletion of partner's payment method",
  'partner.wallet_set_default':
    'Selecting the default payment method for a partner',
  'partner.change_password': 'Access to change partner password',
  'product.list': 'View a list of products',
  'product.create': 'Creating a product',
  'product.show': 'View a product',
  'product.update': 'Editing a product',
  'product.enable': 'Enabling a product',
  'product.disable': 'Disabling a product',
  'product.create_events': 'Creating an event',
  'product.update_events': 'Editing an event',
  'product.delete_events': 'Deleting an event',
  'product.create_landings': 'Creating a landing',
  'product.update_landings': 'Editing a landing',
  'product.delete_landings': 'Deleting a landing',
  'product.offers_list': 'View a list of offers',
  'product.show_offer': 'View an offer',
  'product.create_offers': 'Creating an offer',
  'product.update_offers': 'Editing an offer',
  'product.delete_offers': 'Deleting an offer',
  'product.create_goals': 'Creating a goal',
  'product.update_goals': 'Editing a goal',
  'product.delete_goals': 'Deleting a goal',
  'statistics.show': 'View a statistics',
  'statistics.cancel_conversions': 'Cancelling conversions',
  'statistics.edit': 'Editing statistics',
  'statistics.change_qualification': 'Change a conversion',
  'statistics.change_conversion': 'Change a conversion',
  'payments.list': 'View a list of payments',
  'payments.approve': 'Approval of a payment',
  'payments.reject': 'Rejection of a payment',
  'settings.common': 'Access to general settings',
  'settings.moderation': 'Access to moderation settings',
  'settings.contacts': 'Access to contact settings',
  'settings.tracking_domains': 'Access to tracking domain settings',
  'settings.main_domains': 'Access to main domains settings',
  'settings.conditions_hide_to_partner':
    'Access to the setting of hiding common conditions from a partner',
  'settings.categories': 'Access to category settings',
  'settings.tags': 'Access to tags settings',
  'settings.partner_tags': 'Access to partner tags settings',
  'settings.referral': 'Access to referral settings',
  'settings.email_send':
    'Access to settings of sending a confirmation email for self-registration',
  'settings.currency': 'Currencies table',
  'settings.currency_edit': 'Currency edit',
  'settings.payment_systems': 'Access to payments systems settings',
  'settings.default_manager': 'Accessing a default manager setting',
  'settings.auto_invoice': 'Access to settings for auto-generation of invoices',
  'documents.terms_of_use': 'Working with legal documents',
  'role.create': 'Creating a role',
  'role.delete': 'Deleting a role',
  'role.update': 'Editing a role',
  'role.list': 'View a list of roles',
  'role.show': 'View a role',
  'promo.list': 'View a list of promotional materials',
  'promo.create': 'Creating a promotional material',
  'promo.update': 'Editing a promotional material',
  'promo.delete': 'Deleting a promotional material',
  'promo.show': 'View a promotional material',
  'promocode.all': 'Working with promo codes',
  'invoices.all':
    'Access to the functionality of working with withdrawal invoices',
  'revshare.all':
    'Working with revshare (creating, editing and deleting formulas in a product)',
  'logs.list': 'Access to logs',
  'dashboard.all': 'Working with dashboards',
  business_and_above: 'Available on Pro plans and above',
  admin: 'Administrator',
  advertiser: 'Advertiser',
  news: 'News',
  partner: 'Partner',
  product: 'Product',
  statistics: 'Statistics',
  payments: 'Payments',
  settings: 'Settings',
  dashboard: 'Dashboard',
  role: 'Roles',
  promo: 'Promotional materials',
  export: 'Export',
  event_group: 'Event groups',
  promocode: 'Promo codes',
  documents: 'Documents',
  invoices: 'Invoices',
  revshare: 'Revshare',
  logs: 'Logs',
};
