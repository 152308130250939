import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Tag = {
  GET_TAGS: {
    url: generatePartnerApiUrl(ModelPath.Tag, ModelPath.Offer),
    method: 'GET',
  },
} as const;

export default Tag;
