export default {
  no_data: 'Нет результатов экспорта',
  column_date: 'Дата',
  column_type: 'Тип',
  column_status: 'Статус',
  description_1st_line: 'Подготовка файла обычно занимает 1-3 минуты',
  description_2nd_line_1st_part:
    'Если через 3 минуты статус документа не поменялся,',
  description_2nd_line_2nd_part: 'обновите страницу',
  description_3rd_line: 'Готовые файлы хранятся 1 сутки',
  type_conversions: 'Список Конверсий',
  type_partners: 'Партнеры',
  type_advertisers: 'Рекламодатели',
  type_admins: 'Администраторы',
  type_payouts: 'Выплаты',
  type_promos: 'Промоматериалы',
  type_offers: 'Офферы',
  type_products: 'Продукты',
  type_events: 'Список Событий',
  type_cohort_clicks: 'Когортный анализ по кликам',
  type_cohort_events: 'Когортный анализ по событиям',
  type_cohort_conversions: 'Когортный анализ по конверсиям (целям)',
};
