import decodeJwt from 'jwt-decode';
import Cookies from 'js-cookie';

const SESSION_PREFIX = 'session_';
const ACCESS_TOKEN_KEY = 'access_token';
const ACCESS_TOKEN_EXP_KEY = 'token_exp';
const CLIENT_ID_KEY = 'client_id';
const BACK_URL = 'back_url';

/**
 * Removes the authentication parameter from the storage.
 *
 * @param {string} key - The key of the authentication parameter.
 * @param {boolean} [isSession] - Optional. If true, the partner authentication parameter will be removed from cookies. Default is false.
 */
export function removeAuthParam(key: string, isSession?: boolean): void {
  if (isSession) {
    Cookies.remove(SESSION_PREFIX + key);
  } else {
    localStorage.removeItem(key);
  }
}

/**
 * Sets the authentication parameter with the given key and value.
 *
 * @param {string} key - The key of the authentication parameter.
 * @param {string|null} value - The value of the authentication parameter. If null, the parameter with the specified key will be removed.
 * @param {boolean} [isSession] - Optional. Specifies whether the authentication parameter is from partner and stored in cookies. Defaults to false.
 */
export function setAuthParam(
  key: string,
  value: string | null,
  isSession?: boolean,
): void {
  if (value === null) {
    removeAuthParam(key, isSession);
  } else if (isSession) {
    Cookies.set(SESSION_PREFIX + key, value);
  } else {
    localStorage.setItem(key, value);
  }
}

/**
 * Retrieves the authentication parameter based on the key and session flag.
 *
 * @param {string} key - The key to retrieve the authentication parameter.
 * @param {boolean} [isSession] - Optional flag indicating whether to try retrieve the partner's authentication parameter from the cookies (default: true).
 * @return {string|null} - The authentication parameter value or null if not found.
 */
export function getAuthParam(key: string, isSession?: boolean): string | null {
  if (isSession || isSession === undefined) {
    const sessionParam = Cookies.get(SESSION_PREFIX + key) ?? null;
    if (sessionParam !== null) {
      return sessionParam;
    }
  }
  if (isSession === false || isSession === undefined) {
    return localStorage.getItem(key);
  }
  return null;
}

export function getAccessToken(isSession?: boolean): string | null {
  return getAuthParam(ACCESS_TOKEN_KEY, isSession);
}

export function setAccessToken(token: string, isSession?: boolean): void {
  setAuthParam(ACCESS_TOKEN_KEY, token, isSession);
}

export function getClientIdFromStorage(isSession?: boolean): string | null {
  return getAuthParam(CLIENT_ID_KEY, isSession);
}

export function setClientIdToStorage(token: string, isSession?: boolean): void {
  setAuthParam(CLIENT_ID_KEY, token, isSession);
}

export function getBackUrlFromStorage(isSession?: boolean): string | null {
  return getAuthParam(BACK_URL, isSession);
}

export function setBackUrlToStorage(token: string, isSession?: boolean): void {
  setAuthParam(BACK_URL, token, isSession);
}

export function getAccessTokenExpires(isSession?: boolean): string | null {
  return getAuthParam(ACCESS_TOKEN_EXP_KEY, isSession);
}

export function setAccessTokenExpires(
  expires: string,
  isSession?: boolean,
): void {
  setAuthParam(ACCESS_TOKEN_EXP_KEY, expires, isSession);
}

/**
 * Checks if the user is an admin and authenticated as a partner.
 *
 * @returns {boolean}
 */
export function isSessionAuth(): boolean {
  const sessionToken = getAccessToken(true);
  return sessionToken !== null;
}

/**
 * Determines whether the user is authorized based on the access token and session authentication.
 *
 * @returns {boolean} Returns true if the user is authorized, otherwise false.
 */
export function isAuthorized(): boolean {
  let expiredUnixTime;
  const accessTokenStr = getAccessToken();
  const sessionAuth = isSessionAuth();
  if (accessTokenStr) {
    const expiredUnixTimeStr = getAccessTokenExpires(sessionAuth);
    if (expiredUnixTimeStr) {
      expiredUnixTime = Number.parseInt(expiredUnixTimeStr);
    } else {
      const decodedToken = decodeJwt(accessTokenStr) as {
        exp: string;
      };
      setAccessTokenExpires(decodedToken.exp, sessionAuth);
      expiredUnixTime = Number.parseInt(decodedToken.exp);
    }

    if (expiredUnixTime > Math.floor(Date.now() / 1000)) {
      return true;
    }
  }

  return false;
}

export function removeAuthParams(isSession?: boolean): void {
  removeAuthParam(ACCESS_TOKEN_KEY, isSession);
  removeAuthParam(ACCESS_TOKEN_EXP_KEY, isSession);
  removeAuthParam(CLIENT_ID_KEY, isSession);
  removeAuthParam(BACK_URL, isSession);
}
