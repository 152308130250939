import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const PromoCode = {
  GET_PROMO_CODES: {
    url: generateAdminApiUrl('', ModelPath.PromoCode),
    method: 'GET',
  },
  GET_PROMO_CODE: {
    url: generateAdminApiUrl(':id', ModelPath.PromoCode),
    method: 'GET',
  },
  CREATE_PROMO_CODE: {
    url: generateAdminApiUrl('', ModelPath.PromoCode),
    method: 'POST',
  },
  UPDATE_PROMO_CODE: {
    url: generateAdminApiUrl(':id', ModelPath.PromoCode),
    method: 'PATCH',
    bodyMapping: [
      {
        key: 'date_finish',
        optional: true,
      },
      {
        key: 'date_start',
        optional: true,
      },
      {
        key: 'limit',
        optional: true,
      },
    ],
  },
  ASSIGN_PROMO_CODE: {
    url: generateAdminApiUrl(':id/assign', ModelPath.PromoCode),
    method: 'POST',
    bodyMapping: [
      {
        key: 'offer_id',
        optional: true,
      },
      {
        key: 'partner_id',
        optional: true,
      },
    ],
  },
  IMPORT_PROMO_CODES: {
    url: generateAdminApiUrl('types/:id/import', ModelPath.PromoCode),
    method: 'POST',
    bodyMapping: ['file'],
  },
  DELETE_PROMO_CODE: {
    url: generateAdminApiUrl(':id', ModelPath.PromoCode),
    method: 'DELETE',
  },
  GET_TYPES: {
    url: generateAdminApiUrl('types', ModelPath.PromoCode),
    method: 'GET',
  },
  GET_TYPE: {
    url: generateAdminApiUrl('types/:id', ModelPath.PromoCode),
    method: 'GET',
  },
  CREATE_TYPE: {
    url: generateAdminApiUrl('types', ModelPath.PromoCode),
    method: 'POST',
  },
  UPDATE_TYPE: {
    url: generateAdminApiUrl('types/:id', ModelPath.PromoCode),
    method: 'PATCH',
  },
  DELETE_TYPE: {
    url: generateAdminApiUrl('types/:id', ModelPath.PromoCode),
    method: 'DELETE',
  },
} as const;

export default PromoCode;
