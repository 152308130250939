import React, { CSSProperties, useMemo } from 'react';
import { Image } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import useDict from 'core/hooks/useDict';
import { IGeneralSettings } from 'models/IGeneralSettings';
import { Dicts } from 'constants/enums/Dicts';
import { getLogo, setLogo } from 'utils/storageUtils';
import Icon, { IconSrc } from 'components/Icon/Icon';
import { IBrandLogoProps } from './BrandLogo';

const useBrandLogo = ({ maxHeight, maxWidth, variant }: IBrandLogoProps) => {
  const {
    value: settings,
    loading: settingsLoading,
    initialized,
  } = useDict<IGeneralSettings>(Dicts.GeneralSettings);

  const storageLogo = getLogo();
  let logoUrl: string | null | undefined = storageLogo;
  const logoStyle = useMemo(
    (): CSSProperties => ({
      maxWidth: maxWidth,
      maxHeight: maxHeight,
    }),
    [maxHeight, maxWidth],
  );

  const defaultIcon: IconSrc = variant === 'white' ? 'logoWhite' : 'logo';

  if (!settingsLoading && initialized) {
    if (isEmpty(settings?.logo)) {
      if (logoUrl === null || !isEmpty(logoUrl)) {
        setLogo('');
      }
    } else if (logoUrl !== settings?.logo) {
      setLogo(settings?.logo);
    }

    logoUrl = settings?.logo;
  }

  const logo = useMemo(() => {
    let result = null;
    if (!isEmpty(logoUrl)) {
      result = <Image src={logoUrl || ''} style={logoStyle} />;
    } else if ((!settingsLoading && initialized) || storageLogo !== null) {
      const iconWidth = maxHeight ? 'auto' : maxWidth || 'initial';
      result = (
        <Icon
          src={defaultIcon}
          style={logoStyle}
          width={iconWidth}
          height={maxHeight || 'initial'}
        />
      );
    }
    return result;
  }, [
    logoUrl,
    storageLogo,
    logoStyle,
    maxHeight,
    maxWidth,
    settingsLoading,
    initialized,
  ]);

  return {
    settings,
    settingsLoading,
    initialized,
    logoUrl,
    logoStyle,
    logo,
  };
};

export default useBrandLogo;
