import assign from 'lodash/assign';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';
import isPlainObject from 'lodash/isPlainObject';

import getObjectOnlyValidValues from './getObjectOnlyValidValues';

export const mergeObjects = <T>(
  first?: T,
  second?: T,
  innerObjectsField?: string[],
  removeUndefined = false,
): T => {
  const func = (obj?: T) =>
    getObjectOnlyValidValues(obj ?? {}, removeUndefined);
  let result: T = assign({}, func(first), func(second));
  if (innerObjectsField) {
    for (const field of innerObjectsField) {
      const innerObject = assign(
        {},
        func(get(first, field)),
        func(get(second, field)),
      );
      result = assign(result, {
        [field]: isEmpty(innerObject) ? undefined : innerObject,
      });
    }
  }
  return result;
};

/**
 * Return array of paths of all non-object fields of the given object
 * For example for object {
 *   a: {
 *     c: 2,
 *     d: {
 *       e: 'example',
 *     },
 *   },
 *   b: [1, 4],
 * }
 * will be return array ['a.c', 'a.d.e', 'b']
 * @param obj
 */
export const flatKeys = (obj?: Record<any, any>): string[] => {
  if (!obj) {
    return [];
  }
  const findKeys = (value: Record<any, any>, key?: string): string[] => {
    const keys: string[] = [];
    forOwn(value, (innerValue, innerKey) => {
      let innerValuePath = innerKey;
      if (key) {
        innerValuePath = `${key}.${innerKey}`;
      }
      if (isPlainObject(innerValue)) {
        keys.push(...findKeys(innerValue, innerValuePath));
      } else {
        keys.push(innerValuePath);
      }
    });
    return keys;
  };
  return findKeys(obj);
};
