import { NotificationTypes } from 'core/redux/constants/notifications';
import { IAction } from 'models/IAction';
import { INotification } from 'models/INotification';

export default function notificationReducer(state = [], action: IAction) {
  switch (action.type) {
    case NotificationTypes.ADD_NOTIFICATION:
      return [...state, action.payload];
    case NotificationTypes.REMOVE_NOTIFICATION:
      return state.filter(
        (notification: INotification) => notification.id !== action.payload.id,
      );
    default:
      return state;
  }
}
