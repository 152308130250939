export default {
  news: 'News',
  all_news: 'All news',
  add_news: 'Add news',
  edit_news: 'Edit news',
  no_news: 'No news',
  header: 'Header',
  text: 'Text',
  news_remove_confirm: 'Are you sure you want to remove the News?',
};
