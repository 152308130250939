import createActionHelper from './createActionHelper';
import {
  SET_MODEL,
  MODEL_PUSH,
  MODEL_DELETE,
  MODEL_UPDATE,
} from 'core/redux/constants/models';
import { IApiDto } from 'models/IApiDto';
import { IAction } from 'models/IAction';

export type ModelChangeOptionsType = {
  modelFieldId?: string;
  path?: string;
  modelId?: string | number | null;
  place?: 'top' | 'bottom';
};

type ModelChangeType = (
  modelId: string,
  datasource: IApiDto | { [key: string]: any } | undefined,
  options?: ModelChangeOptionsType,
) => IAction;

export const setModel: ModelChangeType = (modelId, datasource, options) =>
  createActionHelper(SET_MODEL)({ modelId, datasource, options });

export const modelPush: ModelChangeType = (modelId, datasource, options) =>
  createActionHelper(MODEL_PUSH)({ modelId, datasource, options });

export const modelDelete: ModelChangeType = (modelId, datasource, options) =>
  createActionHelper(MODEL_DELETE)({ modelId, datasource, options });

export const modelUpdate: ModelChangeType = (modelId, datasource, options) =>
  createActionHelper(MODEL_UPDATE)({ modelId, datasource, options });
