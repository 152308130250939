export default {
  roles: 'Roles',
  role: 'El papel',
  'new role': 'Nueva función',
  'removal was successful': 'Eliminación exitosa',
  'an error has occurred': 'Se ha producido un error',
  'do you want to delete role': 'Desea eliminar un rol',
  name: 'Nombre',
  rights: 'Derechos',
  show: 'Mostrar',
  'name of role': 'Título del rol',
  'edit role': 'Editar roles',
  'role successfully created': 'Función creada con éxito',
  'role successfully updated': 'Rol actualizado con éxito',
  'no roles': 'No hay roles creados',
  accesses: 'Acceso',
  role_rights: 'Derechos de rol',
};
