import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';

import ITableState from 'models/ITableState';

export type TablesState = Record<string, ITableState>;

const tables = createSlice({
  name: 'tables',
  initialState: {} as TablesState,
  reducers: {
    selectRows(
      state: TablesState,
      action: PayloadAction<{ tableId: string; ids: string[] }>,
    ) {
      const { tableId, ids } = action.payload;
      const tableState = state[tableId] ?? {
        selected: {},
        unselected: {},
        selectedAll: false,
      };

      forEach(ids, (id) => {
        if (tableState.selectedAll) {
          tableState.unselected[id] = false;
        } else {
          tableState.selected[id] = true;
        }
      });

      state[tableId] = tableState;
    },
    unselectRows(
      state: TablesState,
      action: PayloadAction<{ tableId: string; ids: string[] }>,
    ) {
      const { tableId, ids } = action.payload;
      const tableState = state[tableId] ?? {
        selected: {},
        unselected: {},
        selectedAll: false,
      };

      forEach(ids, (id) => {
        if (tableState.selectedAll) {
          tableState.unselected[id] = true;
        } else {
          tableState.selected[id] = false;
        }
      });

      state[tableId] = tableState;
    },
    selectAll(
      state: TablesState,
      action: PayloadAction<{ tableId: string; value?: boolean }>,
    ) {
      const { tableId, value } = action.payload;
      const tableState = state[tableId] ?? {
        selected: {},
        unselected: {},
        selectedAll: false,
      };
      tableState.selectedAll = isNil(value) ? !tableState.selectedAll : value;
      tableState.selected = {};
      tableState.unselected = {};

      state[tableId] = tableState;
    },
    clearTableState(
      state: TablesState,
      action: PayloadAction<{ tableId: string }>,
    ) {
      const { tableId } = action.payload;
      delete state[tableId];
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = tables;
// Extract and export each action creator by name
export const { selectRows, unselectRows, clearTableState, selectAll } = actions;
export default reducer;
