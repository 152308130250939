import { IconSrc } from 'components/Icon/Icon';

export enum Language {
  RU = 'ru',
  EN = 'en',
  ES = 'es',
}

export enum LanguageDict {
  'en' = 1,
  'ru' = 2,
  'es' = 55,
}

export const languages: Language[] = [Language.EN, Language.RU, Language.ES];

export const languageIcons: Record<Language, IconSrc> = {
  [Language.EN]: 'languageFlagUs',
  [Language.RU]: 'languageFlagRu',
  [Language.ES]: 'languageFlagEs',
};
