export enum WidgetActionTypes {
  RegisterWidget = 'widgets/REGISTER_WIDGETS',
  UnregisterWidget = 'widgets/UNREGISTER_WIDGETS',
  RequestWidget = 'widgets/REQUEST_WIDGETS',
  SetError = 'widgets/SET_ERROR',
  SetLoading = 'widgets/SET_LOADING',
  SetMeta = 'widgets/SET_META',
  HandleAddInMeta = 'widgets/HANDLE_ADD_IN_META',
  HandleDeleteInMeta = 'widgets/HANDLE_DELETE_IN_META',
  SetFilter = 'widgets/SET_FILTER',
  SetFilterMappingFromUrlDone = 'widgets/SET_FILTER_MAPPING_FROM_URL_DONE',
  RemoveFilterParams = 'widgets/REMOVE_FILTER_PARAMS',
  FilterMapToURL = 'widgets/FILTER_MAP_TO_URL',
}
