export default {
  product: 'Продукт',
  offer: 'Оффер',
  country: 'Страна',
  preview: 'Предпросмотр',
  size: 'Размеры',
  img_type: 'Тип',
  language: 'Язык',
  currency: 'Валюта',
  width_min: 'Мин. ширина',
  height_min: 'Мин. высота',
  width_max: 'Макс. ширина',
  height_max: 'Макс. высота',
  no_data: 'Нет промоматериалов',
  more_countries: 'стран',
  name: 'Наименование',
  promo_editing: 'Редактирование промоматериала',
  promo_adding: 'Добавление промоматериала',
  promo_successfully_created: 'Промоматериал успешно добавлен',
  promo_successfully_updated: 'Промоматериал успешно изменен',
  promo_remove_confirm: 'Вы хотите удалить промоматериал?',
  add_new_promo: 'Новый промоматериал',
  iframe: 'IFrame',
  embed_on_site: 'Встроить на сайт',
};
