import map from 'lodash/map';

import { Color } from 'constants/enums/Color';
import { OfferStatus as OfferStatusEnum } from './OfferStatus';
import { PrivacyStatus as PrivacyStatusEnum } from './PrivacyStatus';
import { ISelectOption } from 'models/ISelectOption';
import ConversionFraudLevel from './ConversionFraudLevel';
import { IBadgeProps } from 'components/Badge/Badge';
import { Size } from './Size';
import { PostbackPaymentModel } from './PostbackPaymentModel';

export type StatusSetting = {
  [key: string]: {
    color: Color;
    label: string;
  } & Partial<IBadgeProps>;
};

export type StatusFieldPostback = {
  name: string;
  value: ConversionStatus;
  label: string;
};

export type PostbackTrigger = {
  value: number;
  label: string;
};

export enum AdminStatus {
  Activated = 1,
  Disabled = 2,
  RegistrationNotCompleted = 3,
}

export enum ProductStatus {
  Activated = 1,
  Disabled = 2,
}

export enum TrackingDomainStatusEnum {
  Checked = 1,
  NotChecked = 0,
}

export const AdminStatusSetting: StatusSetting = {
  '1': {
    color: Color.LIGHT_SUCCESS,
    label: 'components:active',
  },
  '2': {
    color: Color.LIGHT_DANGER,
    label: 'components:inactive',
  },
  '3': {
    color: Color.MUTED,
    label: 'components:registration_not_completed',
  },
};

export const ProductStatusSettings: StatusSetting = {
  true: {
    color: Color.LIGHT_SUCCESS,
    label: 'components:active',
  },
  false: {
    color: Color.LIGHT_DANGER,
    label: 'components:inactive',
  },
};

export const TrackingDomainStatus: StatusSetting = {
  [TrackingDomainStatusEnum.Checked]: {
    color: Color.LIGHT_SUCCESS,
    label: 'trackingDomains:status_checked',
  },
  [TrackingDomainStatusEnum.NotChecked]: {
    color: Color.LIGHT_DANGER,
    label: 'trackingDomains:status_not_checked',
  },
};

export const OfferStatus: StatusSetting = {
  [OfferStatusEnum.Disabled]: {
    color: Color.LIGHT_DANGER,
    label: 'offer:status_inactive',
  },
  [OfferStatusEnum.Activated]: {
    color: Color.LIGHT_SUCCESS,
    label: 'offer:status_active',
  },
};

export const PrivacyOfferStatus: StatusSetting = {
  [PrivacyStatusEnum.Public]: {
    color: Color.LIGHT_SUCCESS,
    label: 'offer:public',
  },
  [PrivacyStatusEnum.Private]: {
    color: Color.LIGHT_CARROT,
    label: 'offer:private',
  },
  [PrivacyStatusEnum.PreModerate]: {
    color: Color.LIGHT_PRIMARY,
    label: 'offer:premoderate',
  },
};

export const PartnerPrivacyOfferStatus: StatusSetting = {
  [0]: {
    color: Color.LIGHT_SECONDARY,
    label: 'offer:premoderate',
  },
  [1]: {
    color: Color.LIGHT_PRIMARY,
    label: 'offer:available',
  },
};

export enum UserStatus {
  Activated = 1,
  RegistrationNotCompleted = 2,
  Moderated = 4,
  Banned = 5,
  Rejected = 6,
  SelfRegistrationNotCompleted = 7,
}

export const UserStatusSettings: StatusSetting = {
  [UserStatus.Activated]: {
    label: 'components:active',
    color: Color.LIGHT_SUCCESS,
  },
  [UserStatus.RegistrationNotCompleted]: {
    label: 'components:registration_not_completed',
    color: Color.LIGHT_SECONDARY,
  },
  [UserStatus.Moderated]: {
    label: 'components:moderation',
    color: Color.LIGHT_WARNING,
  },
  [UserStatus.Banned]: {
    label: 'components:banned',
    color: Color.LIGHT_DANGER,
  },
  [UserStatus.Rejected]: {
    label: 'components:profile_rejected',
    color: Color.LIGHT_SECONDARY,
  },
  [UserStatus.SelfRegistrationNotCompleted]: {
    label: 'components:registration_not_completed',
    color: Color.LIGHT_SECONDARY,
  },
};

export enum WithdrawalStatus {
  PENDING = 0,
  REJECTED = 1,
  APPROVED = 2,
  CANCELED = 3,
  PAYED = 4,
}

export const WithdrawalStatusSettings: StatusSetting = {
  [WithdrawalStatus.PENDING]: {
    color: Color.LIGHT_WARNING,
    label: 'wallet:status_pending',
  },
  [WithdrawalStatus.REJECTED]: {
    color: Color.LIGHT_DANGER,
    label: 'wallet:status_rejected',
  },
  [WithdrawalStatus.APPROVED]: {
    color: Color.LIGHT_SUCCESS,
    label: 'wallet:status_approved',
  },
  [WithdrawalStatus.CANCELED]: {
    color: Color.LIGHT_DANGER,
    label: 'wallet:status_canceled',
  },
  [WithdrawalStatus.PAYED]: {
    color: Color.LIGHT_SUCCESS,
    label: 'wallet:status_payed',
  },
};

export enum InvoiceStatus {
  CREATED = 1,
  PENDING = 2,
  APPROVED = 3,
  PAYED = 4,
  PARTED = 5,
  REJECTED = 6,
}

export enum InvoiceCreatedSource {
  PARTNER = 1,
  ADMIN = 2,
  AUTO = 3,
}

export const InvoiceStatusSettings: StatusSetting = {
  [InvoiceStatus.PENDING]: {
    color: Color.LIGHT_WARNING,
    label: 'wallet:status_pending',
  },
  [InvoiceStatus.CREATED]: {
    color: Color.LIGHT_WARNING,
    label: 'wallet:status_pending',
  },
  [InvoiceStatus.APPROVED]: {
    color: Color.LIGHT_SECONDARY,
    label: 'wallet:status_approved',
  },
  [InvoiceStatus.PARTED]: {
    color: Color.LIGHT_SECONDARY,
    label: 'wallet:status_approved',
  },
  [InvoiceStatus.PAYED]: {
    color: Color.LIGHT_SUCCESS,
    label: 'wallet:status_payed',
  },
  [InvoiceStatus.REJECTED]: {
    color: Color.LIGHT_DANGER,
    label: 'wallet:status_rejected',
  },
};

export const InvoicePartnerStatusSettings: StatusSetting = {
  [InvoiceStatus.PENDING]: {
    color: Color.LIGHT_WARNING,
    label: 'wallet:status_pending',
  },
  [InvoiceStatus.CREATED]: {
    color: Color.LIGHT_WARNING,
    label: 'wallet:status_pending',
  },
  [InvoiceStatus.APPROVED]: {
    color: Color.LIGHT_SUCCESS,
    label: 'wallet:status_approved',
  },
  [InvoiceStatus.PARTED]: {
    color: Color.LIGHT_SUCCESS,
    label: 'wallet:status_approved',
  },
  [InvoiceStatus.PAYED]: {
    color: Color.LIGHT_SUCCESS,
    label: 'wallet:status_payed',
  },
  [InvoiceStatus.REJECTED]: {
    color: Color.LIGHT_DANGER,
    label: 'wallet:status_rejected',
  },
};

export const InvoiceStatusConversionSettings: StatusSetting = {
  [InvoiceStatus.PENDING]: {
    color: Color.LIGHT_WARNING,
    label: 'components:awaiting_payment',
  },
  [InvoiceStatus.APPROVED]: {
    color: Color.LIGHT_WARNING,
    label: 'components:awaiting_payment',
  },
  [InvoiceStatus.PARTED]: {
    color: Color.LIGHT_WARNING,
    label: 'components:awaiting_payment',
  },
  [InvoiceStatus.PAYED]: {
    color: Color.LIGHT_SUCCESS,
    label: 'components:paid',
  },
};

export const LandingTypeBadgeSettings: StatusSetting = {
  [0]: {
    color: Color.LIGHT_SUCCESS,
    label: 'components:for_the_offer',
  },
  [1]: {
    color: Color.LIGHT_WARNING,
    label: 'components:for_the_product',
  },
};

export enum ConditionType {
  Goal,
  Qualification,
  Formula,
  CPM,
}

export const ConditionTypeBadgeSettings: StatusSetting = {
  [ConditionType.Goal]: {
    color: Color.LIGHT_SECONDARY,
    label: 'offer:goal',
    size: Size.MD,
    bold: true,
  },
  [ConditionType.Qualification]: {
    color: Color.LIGHT_SUCCESS,
    label: 'offer:qualification',
    size: Size.MD,
    bold: true,
  },
  [ConditionType.Formula]: {
    color: Color.LIGHT_INFO,
    label: 'offer:revshare',
    size: Size.MD,
    bold: true,
  },
  [ConditionType.CPM]: {
    color: Color.LIGHT_CARROT,
    label: 'offer:cpm',
    size: Size.MD,
    bold: true,
  },
};

export enum ExportStatus {
  PREPARING = 1,
  DONE = 2,
}

export const ExportStatusSettings: StatusSetting = {
  [ExportStatus.PREPARING]: {
    color: Color.LIGHT_WARNING,
    label: 'components:preparing',
  },
  [ExportStatus.DONE]: {
    color: Color.LIGHT_SUCCESS,
    label: 'components:done',
  },
};

export enum ConversionStatus {
  HOLD = 0,
  CONFIRMED = 1,
  REJECTED = 2,
  PENDING = 4,
}

export const conversionStatuses: Array<ISelectOption> = [
  {
    value: ConversionStatus.PENDING,
    label: 'components:pending',
  },
  {
    value: ConversionStatus.CONFIRMED,
    label: 'components:confirmed',
  },
  {
    value: ConversionStatus.HOLD,
    label: 'components:hold',
  },
  {
    value: ConversionStatus.REJECTED,
    label: 'components:rejected',
  },
];

export const conversionStatusesArray: ConversionStatus[] = map(
  conversionStatuses,
  (s) => s.value,
);

export const ConversionStatusSettings: StatusSetting = {
  [ConversionStatus.PENDING]: {
    color: Color.LIGHT_WARNING,
    label: 'components:pending',
  },
  [ConversionStatus.CONFIRMED]: {
    color: Color.LIGHT_SUCCESS,
    label: 'components:confirmed',
  },
  [ConversionStatus.HOLD]: {
    color: Color.LIGHT_WARNING,
    label: 'components:hold',
  },
  [ConversionStatus.REJECTED]: {
    color: Color.LIGHT_DANGER,
    label: 'components:rejected',
  },
};

export enum ConversionPaymentModel {
  CPA = 1,
  Revshare = 2,
  CPM = 3,
}

export const ConversionPaymentModelSettings: StatusSetting = {
  [ConversionPaymentModel.CPA]: {
    color: Color.LIGHT_SECONDARY,
    label: 'components:cpa',
    size: Size.MD,
  },
  [ConversionPaymentModel.Revshare]: {
    color: Color.LIGHT_INFO,
    label: 'components:revshare',
    size: Size.MD,
  },
  [ConversionPaymentModel.CPM]: {
    color: Color.LIGHT_SECONDARY,
    label: 'components:cpm',
    size: Size.MD,
  },
};

export enum TrafficSource {
  Click = 1,
  Promocode = 2,
  Lead = 3,
}

export enum LinkSource {
  Offer = 1,
  Landing = 2,
}

export const ConversionFraudSettings: StatusSetting = {
  [ConversionFraudLevel.NotAnalyzed]: {
    color: Color.LIGHT_SECONDARY,
    label: `components:fraud_level_${ConversionFraudLevel.NotAnalyzed}`,
  },
  [ConversionFraudLevel.OK]: {
    color: Color.LIGHT_SUCCESS,
    label: `components:fraud_level_${ConversionFraudLevel.OK}`,
  },
  [ConversionFraudLevel.Low]: {
    color: Color.LIGHT_SUCCESS,
    label: `components:fraud_level_${ConversionFraudLevel.Low}`,
  },
  [ConversionFraudLevel.Mid]: {
    color: Color.LIGHT_WARNING,
    label: `components:fraud_level_${ConversionFraudLevel.Mid}`,
  },
  [ConversionFraudLevel.High]: {
    color: Color.LIGHT_DANGER,
    label: `components:fraud_level_${ConversionFraudLevel.High}`,
  },
};

export const conversionStatusPostback: Array<StatusFieldPostback> = [
  {
    name: 'pending',
    value: ConversionStatus.PENDING,
    label: 'components:pending',
  },
  {
    name: 'confirmed',
    value: ConversionStatus.CONFIRMED,
    label: 'components:confirmed',
  },
  {
    name: 'hold',
    value: ConversionStatus.HOLD,
    label: 'components:hold',
  },
  {
    name: 'rejected',
    value: ConversionStatus.REJECTED,
    label: 'components:rejected',
  },
];

export const ContactStatusSettings: StatusSetting = {
  true: {
    color: Color.LIGHT_WARNING,
    label: 'components:required',
  },
  false: {
    color: Color.LIGHT_SECONDARY,
    label: 'components:optional',
  },
};

export const PartnerPostbackPaymentModelSettings: StatusSetting = {
  [PostbackPaymentModel.CPA]: {
    color: Color.LIGHT_SUCCESS,
    label: 'components:cpa',
  },
  [PostbackPaymentModel.Revshare]: {
    color: Color.LIGHT_INFO,
    label: 'components:revshare',
  },
};

export const PartnerPostbackRequestTypeSettings: StatusSetting = {
  false: {
    color: Color.LIGHT_SUCCESS,
    label: 'GET',
  },
  true: {
    color: Color.LIGHT_WARNING,
    label: 'POST',
  },
};

export const postbackTriggers: PostbackTrigger[] = [
  {
    label: 'offer:trigger_creating',
    value: 1,
  },
  {
    label: 'offer:trigger_status_change',
    value: 2,
  },
  {
    label: 'offer:trigger_payout_change',
    value: 3,
  },
];
