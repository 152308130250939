import { createSelector } from 'reselect';
import get from 'lodash/get';

import emptyObject from 'utils/emptyObject';

import { IReduxState } from 'models/IReduxState';
import { IWidget } from 'models/widget/IWidget';
import { IFilter } from 'models/widget/IFilter';

export const getWidgets = (state: IReduxState): Record<string, IWidget> =>
  get(state, 'widgets') || emptyObject;

export const getWidgetById = (widgetId: string) =>
  createSelector<Array<typeof getWidgets>, IWidget>(
    getWidgets,
    (widgets: Record<string, IWidget>) => get(widgets, widgetId) || emptyObject,
  );

export const getWidgetFilter = (widgetId: string) =>
  createSelector<Array<ReturnType<typeof getWidgetById>>, IWidget['filter']>(
    getWidgetById(widgetId),
    (widget: IWidget) => get(widget, 'filter') || emptyObject,
  );

export const getWidgetFilterValues = (widgetId: string) =>
  createSelector<
    Array<ReturnType<typeof getWidgetFilter>>,
    IWidget['filter']['values']
  >(getWidgetFilter(widgetId), (filter: IFilter) =>
    get(filter, 'values', emptyObject),
  );
