import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Integration = {
  GET_ALL: {
    url: generateAdminApiUrl(ModelPath.Integration),
    method: 'GET',
  },
  GET: {
    url: generateAdminApiUrl(':id', ModelPath.Integration),
    method: 'GET',
  },
  UPDATE: {
    url: generateAdminApiUrl(':id', ModelPath.Integration),
    method: 'PATCH',
  },
} as const;

export default Integration;
