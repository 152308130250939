import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Offer = {
  GET_OFFERS: {
    url: generatePartnerApiUrl(ModelPath.Offer),
    method: 'GET',
  },
  GET_OFFER_BY_ID: {
    url: generatePartnerApiUrl(':id', ModelPath.Offer),
    method: 'GET',
  },
  REQUEST_ACCESS: {
    url: generatePartnerApiUrl(':id/request_access', ModelPath.Offer),
    method: 'POST',
  },
  GET_POSTBACKS: {
    url: generatePartnerApiUrl(':id/postbacks', ModelPath.Offer),
    method: 'GET',
  },
  GET_POSTBACK_BY_ID: {
    url: generatePartnerApiUrl(':offer_id/postbacks/:id', ModelPath.Offer),
    method: 'GET',
  },
  UPDATE_POSTBACK_BY_ID: {
    url: generatePartnerApiUrl(':offer_id/postbacks/:id', ModelPath.Offer),
    method: 'PATCH',
  },
  CREATE_POSTBACK: {
    url: generatePartnerApiUrl(':id/postbacks', ModelPath.Offer),
    method: 'POST',
  },
  DELETE_POSTBACK_BY_ID: {
    url: generatePartnerApiUrl(':offer_id/postbacks/:id', ModelPath.Offer),
    method: 'DELETE',
  },
} as const;

export default Offer;
