export enum ModelPath {
  Admin = 'admins',
  Advertiser = 'inserent',
  Category = 'product_categories',
  News = 'news',
  Exports = 'exports',
  EventGroup = 'groups/event',
  GoalGroup = 'groups/goal',
  Country = 'countries',
  General = 'general',
  Event = 'events',
  Landing = 'landings',
  Offer = 'offers',
  Condition = 'conditions',
  IndividualCondition = 'individual_conditions',
  Partner = 'partners',
  WithdrawalRequest = 'withdrawal_requests',
  Invoice = 'invoices',
  Product = 'products',
  Goal = 'goals',
  Expression = 'expressions',
  Profile = 'profile',
  Integration = 'integrations',
  Role = 'roles',
  Statistic = 'statistic',
  Domain = 'domains',
  TrackingDomain = 'tracking_domains',
  TrackingLink = 'tracking_link',
  Wallet = 'wallets',
  Qualification = 'qualifications',
  Conversions = 'conversions',
  PromoMaterial = 'promo',
  PromoCode = 'promocodes',
  UserContact = 'profile_fields',
  TwoFactorAuth = '2fa',
  GeneralContact = 'contacts',
  Dashboard = 'dashboards',
  Settings = 'settings',
  PaymentThreshold = 'thresholds',
  Documents = 'documents',
  Tag = 'tags',
  Security = 'security',
  Currency = 'currency',
  Referrals = 'referrals',
  PaymentSystem = 'payment_systems',
  Dictionary = 'dictionary',
  Groups = 'groups',
  Postback = 'postbacks',
  Click = 'clicks',
}
