import { all } from 'redux-saga/effects';

import initialSaga from './appSaga';
import widgetSaga from './widgetSaga';
import notificationSaga from './notificationSaga';
import fetchSaga from './fetchSaga';
import dictsSaga from './dictsSaga';
import offerPartnersSaga from './offerPartnersSaga';

export default function* rootSaga() {
  yield all([
    ...initialSaga,
    ...widgetSaga,
    ...notificationSaga,
    ...fetchSaga,
    ...dictsSaga,
    ...offerPartnersSaga,
  ]);
}
