import { ReactComponent as AddElemIcon } from 'assets/icons/add-elem.svg';
import { ReactComponent as AdvertiserIcon } from 'assets/icons/advertisers.svg';
import { ReactComponent as AlertCircleIcon } from 'assets/icons/alert-circle.svg';
import { ReactComponent as AlertTriangleFilledIcon } from 'assets/icons/alert-triangle-filled.svg';
import { ReactComponent as AlertTriangleIcon } from 'assets/icons/alert-triangle.svg';
import { ReactComponent as AngleDownBoldIcon } from 'assets/icons/angle-down-bold.svg';
import { ReactComponent as AngleDownIcon } from 'assets/icons/angle-down.svg';
import { ReactComponent as AngleLeftIcon } from 'assets/icons/angle-left.svg';
import { ReactComponent as AngleRightIcon } from 'assets/icons/angle-right.svg';
import { ReactComponent as AngleUpIcon } from 'assets/icons/angle-up.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ArrowSplitIcon } from 'assets/icons/arrow-split.svg';
import { ReactComponent as BackspaceIcon } from 'assets/icons/backspace.svg';
import { ReactComponent as BarsRoundIcon } from 'assets/icons/bars-round.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { ReactComponent as ChainIcon } from 'assets/icons/chain.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CheckRoundedIcon } from 'assets/icons/check-rounded.svg';
import { ReactComponent as CheckRoundedInvertedIcon } from 'assets/icons/check-rounded-inverted.svg';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as CopyThickIcon } from 'assets/icons/copyThick.svg';
import { ReactComponent as CreateCellIcon } from 'assets/icons/create-cell.svg';
import { ReactComponent as DiscountIcon } from 'assets/icons/discount.svg';
import { ReactComponent as DoubleAngleLeftIcon } from 'assets/icons/double-angle-left.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as DragIndicator } from 'assets/icons/drag-indicator.svg';
import { ReactComponent as EditFieldIcon } from 'assets/icons/edit-field.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as EmptyEllipseIcon } from 'assets/icons/empty-ellipse.svg';
import { ReactComponent as EmptySearchIcon } from 'assets/icons/empty-search.svg';
import { ReactComponent as EyeHideIcon } from 'assets/icons/eye-hide.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as FileTypeCsv } from 'assets/icons/fileTypes/csv.svg';
import { ReactComponent as GearIcon } from 'assets/icons/gear.svg';
import { ReactComponent as GraphIcon } from 'assets/icons/graph.svg';
import { ReactComponent as GraphLinearIcon } from 'assets/icons/graphLinear.svg';
import { ReactComponent as HeadphonesIcon } from 'assets/icons/headphones.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/image.svg';
import { ReactComponent as IndexIcon } from 'assets/icons/index.svg';
import { ReactComponent as InfinityIcon } from 'assets/icons/infinity.svg';
import { ReactComponent as InfoRoundedIcon } from 'assets/icons/info-rounded.svg';
import { ReactComponent as IssueIcon } from 'assets/icons/issue.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as LanguageFlagRuIcon } from 'assets/icons/flags/flag-language-ru.svg';
import { ReactComponent as LanguageFlagUsIcon } from 'assets/icons/flags/flag-language-us.svg';
import { ReactComponent as LanguageFlagEsIcon } from 'assets/icons/flags/flag-language-es.svg';
import { ReactComponent as ListFlashIcon } from 'assets/icons/list-flash.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as ListOrderedIcon } from 'assets/icons/list-ordered.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as LogoWhiteIcon } from 'assets/icons/logo-white.svg';
import { ReactComponent as MagnifyIcon } from 'assets/icons/magnifier.svg';
import { ReactComponent as MailContourIcon } from 'assets/icons/mail-contour.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as ManagerIcon } from 'assets/icons/manager.svg';
import { ReactComponent as MenuVerticalIcon } from 'assets/icons/menu-vertical.svg';
import { ReactComponent as MoveIcon } from 'assets/icons/move.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/news.svg';
import { ReactComponent as OfferColorIcon } from 'assets/icons/offer-color.svg';
import { ReactComponent as OfferGrayIcon } from 'assets/icons/offer-gray.svg';
import { ReactComponent as OptionsIcon } from 'assets/icons/options.svg';
import { ReactComponent as OsTypeAndroidIcon } from 'assets/icons/osTypes/android.svg';
import { ReactComponent as OsTypeLinuxIcon } from 'assets/icons/osTypes/linux.svg';
import { ReactComponent as OsTypeMacOsIcon } from 'assets/icons/osTypes/macos.svg';
import { ReactComponent as OsTypeNintendoIcon } from 'assets/icons/osTypes/nintendo.svg';
import { ReactComponent as OsTypeNokiaIcon } from 'assets/icons/osTypes/nokia.svg';
import { ReactComponent as OsTypeOtherIcon } from 'assets/icons/osTypes/other.svg';
import { ReactComponent as OsTypeUnixIcon } from 'assets/icons/osTypes/unix.svg';
import { ReactComponent as OsTypeWindowsIcon } from 'assets/icons/osTypes/windows.svg';
import { ReactComponent as OsTypeXboxIcon } from 'assets/icons/osTypes/xbox.svg';
import { ReactComponent as PadlockIcon } from 'assets/icons/padlock.svg';
import { ReactComponent as PartnerIcon } from 'assets/icons/partner.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/payment.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg';
import { ReactComponent as PieChartIcon } from 'assets/icons/pieChart.svg';
import { ReactComponent as PinIcon } from 'assets/icons/pin.svg';
import { ReactComponent as PinGrayIcon } from 'assets/icons/pin-gray.svg';
import { ReactComponent as PinFilledIcon } from 'assets/icons/pin-filled.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ProductColorIcon } from 'assets/icons/product-color.svg';
import { ReactComponent as ProductContourIcon } from 'assets/icons/product-contour.svg';
import { ReactComponent as ProductIcon } from 'assets/icons/product.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as ProfileRoundedIcon } from 'assets/icons/profile-rounded.svg';
import { ReactComponent as ProtectionIcon } from 'assets/icons/protection.svg';
import { ReactComponent as ReferralIcon } from 'assets/icons/referral.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as SortDescIcon } from 'assets/icons/sort-desc.svg';
import { ReactComponent as StatisticsIcon } from 'assets/icons/statistics.svg';
import { ReactComponent as StatisticsBlueIcon } from 'assets/icons/statistics-blue.svg';
import { ReactComponent as TelegramColorRoundedIcon } from 'assets/icons/telegram-color-rounded.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { ReactComponent as TelegramRoundedIcon } from 'assets/icons/telegram-rounded.svg';
import { ReactComponent as TilesIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as TilesRotatedIcon } from 'assets/icons/tiles-rotated.svg';
import { ReactComponent as Trash2Icon } from 'assets/icons/trash2.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg';
import { ReactComponent as WalletWithCardIcon } from 'assets/icons/wallet-with-card.svg';
import { ReactComponent as WebmoneyIcon } from 'assets/icons/webmoney.svg';
import { ReactComponent as WithdrawalIcon } from 'assets/icons/withdrawal.svg';
import { ReactComponent as WorldIcon } from 'assets/icons/world.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import FlagIcons from './FlagIcons';
import FlagPngIcons from './FlagPngIcons';

const Icons = {
  addElem: AddElemIcon,
  advertiser: AdvertiserIcon,
  alertCircle: AlertCircleIcon,
  alertTriangle: AlertTriangleIcon,
  alertTriangleFilled: AlertTriangleFilledIcon,
  angleDown: AngleDownIcon,
  angleDownBold: AngleDownBoldIcon,
  angleLeft: AngleLeftIcon,
  angleRight: AngleRightIcon,
  angleUp: AngleUpIcon,
  arrowRight: ArrowRightIcon,
  arrowSplit: ArrowSplitIcon,
  backspace: BackspaceIcon,
  barsRound: BarsRoundIcon,
  cancel: CancelIcon,
  chain: ChainIcon,
  check: CheckIcon,
  checkRounded: CheckRoundedIcon,
  checkRoundedInverted: CheckRoundedInvertedIcon,
  chevron: ChevronIcon,
  clock: ClockIcon,
  close: CloseIcon,
  comment: CommentIcon,
  copy: CopyIcon,
  copyThick: CopyThickIcon,
  createCell: CreateCellIcon,
  discount: DiscountIcon,
  doubleAngleLeft: DoubleAngleLeftIcon,
  download: DownloadIcon,
  dragIndicator: DragIndicator,
  edit: EditIcon,
  editField: EditFieldIcon,
  emptyEllipse: EmptyEllipseIcon,
  emptySearch: EmptySearchIcon,
  eye: EyeIcon,
  eyeHide: EyeHideIcon,
  fileTypeCsv: FileTypeCsv,
  gear: GearIcon,
  graph: GraphIcon,
  graphLinear: GraphLinearIcon,
  headphones: HeadphonesIcon,
  image: ImageIcon,
  index: IndexIcon,
  infinity: InfinityIcon,
  infoRounded: InfoRoundedIcon,
  issue: IssueIcon,
  key: KeyIcon,
  languageFlagRu: LanguageFlagRuIcon,
  languageFlagUs: LanguageFlagUsIcon,
  languageFlagEs: LanguageFlagEsIcon,
  list: ListIcon,
  listFlash: ListFlashIcon,
  listOrdered: ListOrderedIcon,
  lock: LockIcon,
  logo: LogoIcon,
  logout: LogoutIcon,
  logoWhite: LogoWhiteIcon,
  magnifier: MagnifyIcon,
  mail: MailIcon,
  mailContour: MailContourIcon,
  manager: ManagerIcon,
  menuVertical: MenuVerticalIcon,
  move: MoveIcon,
  news: NewsIcon,
  offerColor: OfferColorIcon,
  offerGray: OfferGrayIcon,
  options: OptionsIcon,
  osTypeAndroid: OsTypeAndroidIcon,
  osTypeLinux: OsTypeLinuxIcon,
  osTypeMacOs: OsTypeMacOsIcon,
  osTypeNintendo: OsTypeNintendoIcon,
  osTypeNokia: OsTypeNokiaIcon,
  osTypeOther: OsTypeOtherIcon,
  osTypeUnix: OsTypeUnixIcon,
  osTypeWindows: OsTypeWindowsIcon,
  osTypeXbox: OsTypeXboxIcon,
  padlock: PadlockIcon,
  partner: PartnerIcon,
  payment: PaymentIcon,
  pen: PenIcon,
  people: PeopleIcon,
  pieChart: PieChartIcon,
  pin: PinIcon,
  pinGray: PinGrayIcon,
  pinFilled: PinFilledIcon,
  plus: PlusIcon,
  product: ProductIcon,
  productColor: ProductColorIcon,
  productContour: ProductContourIcon,
  profile: ProfileIcon,
  profileRounded: ProfileRoundedIcon,
  protection: ProtectionIcon,
  referral: ReferralIcon,
  refresh: RefreshIcon,
  remove: RemoveIcon,
  statistics: StatisticsIcon,
  statisticsBlue: StatisticsBlueIcon,
  settings: SettingsIcon,
  sortDesc: SortDescIcon,
  telegram: TelegramIcon,
  telegramColorRounded: TelegramColorRoundedIcon,
  telegramRounded: TelegramRoundedIcon,
  tiles: TilesIcon,
  tilesRotated: TilesRotatedIcon,
  trash2: Trash2Icon,
  trash: TrashIcon,
  upload: UploadIcon,
  wallet: WalletIcon,
  walletWithCard: WalletWithCardIcon,
  webmoney: WebmoneyIcon,
  withdrawal: WithdrawalIcon,
  world: WorldIcon,
  share: ShareIcon,
  ...FlagIcons,
  ...FlagPngIcons,
} as const;

export default Icons;
