import React, { lazy } from 'react';

import { ClientId } from 'constants/ClientId';
import Loading from 'components/Loading';
import clearAuthStorage from 'utils/clearAuthStorage';
const AdminRouter = lazy(() => import('modules/admin/routes/Router'));
const AdvertiserRouter = lazy(() => import('modules/advertiser/routes/Router'));
const PartnerRouter = lazy(() => import('modules/partner/routes/Router'));

const DEFAULT_LOGIN_URL = '/login';

interface IRouter {
  clientId: string | number | null;
}

const RouterLoader = {
  [ClientId.ADMIN]: AdminRouter,
  [ClientId.ADVERTISER]: AdvertiserRouter,
  [ClientId.PARTNER]: PartnerRouter,
};

const getModule = (clientId: string | number) =>
  !RouterLoader[clientId] ? null : RouterLoader[clientId];

function ModuleLoader({ clientId }: IRouter) {
  const module = !clientId ? null : getModule(clientId);

  if (!module) {
    console.error(`clientId ${clientId} not found`);
    clearAuthStorage();
    window.location.replace(
      process.env.REACT_APP_LOGIN_URL || DEFAULT_LOGIN_URL,
    );
    return null;
  }

  return !clientId ? <Loading /> : React.createElement(module);
}

export default ModuleLoader;
