import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const TrackingDomain = {
  GET_TRACKING_DOMAINS: {
    url: generateAdminApiUrl(ModelPath.TrackingDomain),
    method: 'GET',
  },
  CREATE_TRACKING_DOMAIN: {
    url: generateAdminApiUrl(ModelPath.TrackingDomain),
    method: 'POST',
    bodyMapping: [
      { key: 'name', optional: false },
      { key: 'https', optional: false },
      { key: 'is_default', optional: true },
    ],
  },
  GET_TRACKING_DOMAIN_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.TrackingDomain),
    method: 'GET',
  },
  UPDATE_TRACKING_DOMAIN_BY_ID: {
    url: generateAdminApiUrl(':tracking_domainId', ModelPath.TrackingDomain),
    method: 'PATCH',
    bodyMapping: [
      { key: 'name', optional: false },
      { key: 'https', optional: false },
    ],
  },
  DELETE_TRACKING_DOMAIN_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.TrackingDomain),
    method: 'DELETE',
  },
  CHECK_TRACKING_DOMAIN_BY_ID: {
    url: generateAdminApiUrl(':id/check', ModelPath.TrackingDomain),
    method: 'POST',
  },
  SET_TRACKING_DOMAIN_DEFAULT: {
    url: generateAdminApiUrl(':id/default', ModelPath.TrackingDomain),
    method: 'PATCH',
  },
  REPLACE_TARGET_LINK_DOMAINS: {
    url: generateAdminApiUrl('target', ModelPath.Domain),
    method: 'PATCH',
  },
  REVERT_REPLACE_TARGET_LINK_DOMAINS: {
    url: generateAdminApiUrl('target/revert', ModelPath.Domain),
    method: 'PATCH',
  },
} as const;

export default TrackingDomain;
