import toString from 'lodash/toString';
import toNumber from 'lodash/toNumber';
import isEmpty from 'lodash/isEmpty';

import { ClientId } from 'constants/ClientId';
import { UserType } from 'constants/enums/UserType';
import clearAuthStorage from './clearAuthStorage';
import { getClientIdFromStorage } from './authUtils';
import { Constants } from 'constants/Constants';

const TIMEZONE = 'timezone';
const DEFAULT_LOGIN_URL = '/login';
const LOGO = 'logo';
const FAVICON = 'favicon';
const STATISTIC_CURRENCY = 'statistic_currency';
const PER_PAGE = 'per_page';

const fireEvent = () => {
  try {
    const event = document.createEvent('HTMLEvents');
    event.initEvent(Constants.StorageEvent, true, true);
    document.dispatchEvent(event);
  } catch (e) {
    console.error(e);
  }
};

export const setStorageValue = (key: string, value: string): void => {
  try {
    localStorage.setItem(key, value);
    fireEvent();
  } catch (e) {
    console.error(`Can not put ${key} value to local storage.`);
    console.error(e);
  }
};

export const removeStorageValue = (key: string): void => {
  try {
    localStorage.removeItem(key);
    fireEvent();
  } catch (e) {
    console.error(`Can not remove ${key} from local storage.`);
    console.error(e);
  }
};

export const getStorageValue = (key: string): string | null => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error(`Can not get ${key} value from local storage.`);
    console.error(e);
  }
  return null;
};

export const getClientId = (
  defaultId: number | null = null,
): string | number | null => {
  const clientId: string | null = getClientIdFromStorage();

  if (!clientId) {
    console.error(`clientId ${clientId} not found`);
    clearAuthStorage();
    window.location.replace(
      process.env.REACT_APP_LOGIN_URL || DEFAULT_LOGIN_URL,
    );
    return null;
  }

  return clientId || defaultId;
};

export const getUserType = (): UserType | undefined => {
  const clientId = getClientId();
  switch (clientId) {
    case ClientId.ADMIN:
      return UserType.ADMIN;
    case ClientId.ADVERTISER:
      return UserType.ADVERTISER;
    case ClientId.PARTNER:
      return UserType.PARTNER;
  }
};

export const getClientIdByUserType = (
  userType: UserType,
): string | number | null => {
  switch (userType) {
    case UserType.ADMIN:
      return ClientId.ADMIN;
    case UserType.ADVERTISER:
      return ClientId.ADVERTISER;
    case UserType.PARTNER:
      return ClientId.PARTNER;
  }
};

export const getTimezoneName = (): string | null => getStorageValue(TIMEZONE);

export const setTimezoneName = (timezoneName?: string): void => {
  if (isEmpty(timezoneName)) {
    removeStorageValue(TIMEZONE);
  } else {
    setStorageValue(TIMEZONE, timezoneName || '');
  }
};

export const getLogo = (): string | null => getStorageValue(LOGO);

export const setLogo = (logoUrl?: string | null): void =>
  setStorageValue(LOGO, logoUrl || '');

export const getFavicon = (): string | null => getStorageValue(FAVICON);

export const setFavicon = (faviconUrl?: string | null): void =>
  setStorageValue(FAVICON, faviconUrl || '');

export const getStatisticCurrency = (): string | null =>
  getStorageValue(STATISTIC_CURRENCY);

export const setStatisticCurrency = (currency?: string | null): void =>
  setStorageValue(STATISTIC_CURRENCY, currency || '');

export const getPerPageOption = (): number | null => {
  const perPage = getStorageValue(PER_PAGE);
  if (!perPage) return null;

  return toNumber(perPage);
};

export const setPerPageOption = (perPage?: number | null): void =>
  setStorageValue(PER_PAGE, toString(perPage ?? ''));
