export default {
  add_event_groups: 'Добавить группу событий',
  no_event_groups: 'Нет группы событий',
  name: 'Название группы событий',
  event_group_editing: 'Редактирование группы событий',
  event_group_adding: 'Добавление группы событий',
  event_group_name: 'Название группы событий',
  event_group_name_placeholder: 'Введите название группы событий',
  event_group_successfully_created: 'Группа событий успешно добавлена',
  event_group_successfully_updated: 'Группа событий успешно изменена',
  event_group_remove_confirm: 'Вы хотите удалить группу событий?',
  product: 'Продукт',
};
