export default {
  enable_integration: 'Habilitar integración',
  integration_settings: 'Configuración de integración',
  integration_settings_updated:
    'Configuración de integración actualizada con éxito',
  fraudscore_description:
    'FraudScore es una solución antifraude independiente que ayuda a los anunciantes a evitar tráfico de mala calidad. El sistema FraudScore evalúa la calidad del tráfico en varias métricas (fraud reasons): navegador, sistema operativo, emuladores, proxies, dispositivos, etc. FraudScore detecta el fraude y proporciona pruebas e informes detallados.',
  fraudscore_enabled_button_help:
    'La primera conversión dentro de click_id en los estados "Pending", "Hold" o "Confirmed" se enviará a FraudScore',
  fraudscore_channel_api_key_help:
    'Se utiliza para enviar conversiones a FraudScore',
  fraudscore_channel_api_key_name: 'Channel API key FraudScore',
  fraudscore_channel_id_key_help:
    'Se utiliza para recuperar datos a través de la API',
  fraudscore_channel_id_key_name: 'Channel ID FraudScore',
  fraudscore_user_api_key_name: 'User API key FraudScore',
  gtm_description:
    'Google Tag Manager es un sistema de gestión de etiquetas (TMS) donde puedes actualizar fácilmente el código de seguimiento y otros fragmentos de código (etiquetas) en tu sitio web o aplicación móvil.',
};
