import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import toLower from 'lodash/toLower';

import { ICountry } from 'models/ICountry';

const countryCodes: Record<string, string> = {};

export const hashCountryCodes = (countries?: ICountry[]) => {
  if (isEmpty(countryCodes) && !isEmpty(countries)) {
    forEach(countries, (country) => {
      countryCodes[toLower(country.name)] = country.iso;
    });
  }
};

export const getCountryCode = (
  name: string,
  countries?: ICountry[],
): string | undefined => {
  hashCountryCodes(countries);
  return countryCodes[toLower(name)];
};
