export default {
  wallet: 'Wallet',
  history: 'History',
  no_withdrawals: 'No withdrawals',
  table_withdrawals_id: 'ID',
  table_withdrawals_date: 'Date',
  table_withdrawals_id_date: 'ID & Date',
  table_withdrawals_system: 'System',
  table_withdrawals_wallet: 'Wallet',
  table_withdrawals_amount: 'Amount',
  table_withdrawals_status: 'Status',
  table_withdrawals_comment: 'Comment',
  status_pending: 'Pending',
  status_rejected: 'Rejected',
  status_approved: 'Approved',
  status_canceled: 'Canceled',
  status_payed: 'Paid',
  money_hold: 'Money hold',
  free_for_withdrawal: 'Free for withdrawal',
  withdrawal_cancel_confirm: 'Do you want to cancel the withdrawal?',
  withdrawal_successfully_created:
    'Withdrawal successfully added. The data in the table may appear with a slight delay.',
  withdrawal: 'Withdrawal',
  withdrawals: 'Withdrawals',
  to_withdrawal: 'Withdraw',
  payment_method: 'Payment method',
  payment_methods: 'Payment methods',
  add_wallet: 'Add wallet',
  edit_wallet: 'Edit wallet',
  wallet_number: 'Wallet number',
  webmoney_wallet_number: 'Webmoney wallet number',
  webmoney_wallet_number_start_symbol:
    'Webmoney wallet number must start with letter',
  webmoney_wallet_number_number_count:
    'Webmoney wallet number must contain twelve numbers',
  webmoney_wallet_number_error:
    'Webmoney wallet number must contain only numbers and letter at the beginning',
  wallet_successfully_created: 'Wallet successfully added',
  wallet_successfully_edited: 'Wallet successfully edited',
  wallet_successfully_deleted: 'Wallet successfully deleted',
  wallet_remove_confirm: 'Do you want to delete the wallet?',
  no_wallets: 'No wallets.',
  please_create_wallet: 'Please create first wallet.',
  main_balance: 'Main balance',
  hold: 'Hold',
  referral_balance: 'Referral balance',
  referral_hold_balance: 'Hold referral balance',
  referral_hold_balance_help:
    'Referral balance of unpaid conversions. It will become available for withdrawal when the conversions are verified and paid.',
  revshare_balance: 'Revshare balance',
  revshare_hold_balance: 'Hold revshare balance',
  revshare_balance_help: 'Conversion balance with Revshare payment model',
  payment_system: 'Payment system',
  available_for_withdrawal: 'Available',
  table_history_empty: 'History is empty, you had no money withdrawals',
  cancel_withdrawal: 'Cancel withdrawal?',
  cancel_withdrawal_confirm: 'Are you sure you want to cancel the withdrawal?',
  remove_wallet: 'Remove withdrawal method?',
  remove_wallet_confirm:
    'Are you sure you want to remove the withdrawal method?',
  cancel_withdrawal_request: 'Cancel withdrawal',
  cannot_cancel_withdrawal:
    'Approved applications cannot be canceled. It will be paid out soon.',
  set_default_method: 'Set as default method',
  withdrawal_methods: 'Withdrawal methods',
  no_withdrawal_methods: 'No withdrawal methods',
  add_method: 'Add method',
  available: 'Available',
  please_add_method: 'Please add withdrawal method',
  please_set_default_method:
    'Please set one of your withdrawal methods as default method',
  default_method: 'Default method',
  default_method_help:
    'By default, this wallet is used to withdraw money. All money available for withdrawal will be converted into the currency of this wallet.',
  min_withdraw_amount: 'Minimum withdrawal amount',
};
