import {
  OfferPartnersApproveRequestAction,
  OfferPartnersFetchRequestsAction,
  OfferPartnersInitSendingAction,
  OfferPartnersRejectRequestAction,
  OfferPartnersSetLoadingAction,
  OfferPartnersSetRequestsLoadingAction,
  OfferPartnersSetRequestsAction,
  OfferPartnersSetRequestsStatusAction,
} from './types';
import { OfferPartnersActionTypes } from 'core/redux/constants/OfferPartnersActionTypes';
import { IOfferPartnerRequest } from 'models/IOfferPartnerRequest';
import { IOfferPartnersState } from 'models/IOfferPartnersState';
import { PreModerationStatus } from 'constants/enums/PreModerationStatus';

export function fetchRequests(
  offerId: number,
  offerWidgetId: string,
  endpoints: IOfferPartnersState['endpoints'],
): OfferPartnersFetchRequestsAction {
  return {
    type: OfferPartnersActionTypes.FetchRequests,
    payload: {
      offerId,
      offerWidgetId,
      endpoints,
    },
  };
}

export function setLoading(isLoading: boolean): OfferPartnersSetLoadingAction {
  return {
    type: OfferPartnersActionTypes.SetLoading,
    payload: {
      isLoading,
    },
  };
}

export function setRequestsLoading(
  ids: number[],
  isLoading: boolean,
): OfferPartnersSetRequestsLoadingAction {
  return {
    type: OfferPartnersActionTypes.SetRequestsLoading,
    payload: {
      ids,
      isLoading,
    },
  };
}

export function setRequestsStatus(
  ids: number[],
  status: PreModerationStatus | null,
): OfferPartnersSetRequestsStatusAction {
  return {
    type: OfferPartnersActionTypes.SetRequestsStatus,
    payload: {
      ids,
      status,
    },
  };
}

export function initSending(): OfferPartnersInitSendingAction {
  return {
    type: OfferPartnersActionTypes.InitSending,
  };
}

export function setRequests(
  requests: IOfferPartnerRequest[],
): OfferPartnersSetRequestsAction {
  return {
    type: OfferPartnersActionTypes.SetRequests,
    payload: {
      requests,
    },
  };
}

export function approveRequest(
  requestIds: number[],
): OfferPartnersApproveRequestAction {
  return {
    type: OfferPartnersActionTypes.ApproveRequest,
    payload: {
      requestIds,
    },
  };
}

export function rejectRequest(
  requestIds: number[],
): OfferPartnersRejectRequestAction {
  return {
    type: OfferPartnersActionTypes.RejectRequest,
    payload: {
      requestIds,
    },
  };
}
