import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const PaymentThreshold = {
  GET_ALL: {
    url: generatePartnerApiUrl(ModelPath.PaymentThreshold),
    method: 'GET',
  },
} as const;

export default PaymentThreshold;
