import React, { useEffect, useRef, useState } from 'react';
import uniqid from 'uniqid';
import { UseHoverProps } from '@floating-ui/react/src/hooks/useHover';
import { Placement } from '@floating-ui/core/src/types';
import {
  useFloating,
  useInteractions,
  useHover,
  useClick,
  useFocus,
  autoPlacement,
  offset,
  flip,
  shift,
  arrow,
  FloatingArrow,
  FloatingPortal,
  useMergeRefs,
} from '@floating-ui/react';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';

const ARROW_HEIGHT = 5;
const ARROW_WIDTH = 10;
const GAP = 2;

export type OverlayTriggerType = 'hover' | 'click' | 'focus';

export interface ITooltipProps {
  id?: string;
  trigger?: OverlayTriggerType | OverlayTriggerType[] | null;
  delay?: UseHoverProps['delay'];
  show?: boolean;
  overlay: React.ReactNode;
  children: React.ReactNode;
  placement?: Placement;
  container?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>;
}

export type TooltipOptions = Omit<ITooltipProps, 'children'>;

function Tooltip({
  id,
  placement,
  delay,
  trigger = ['hover', 'focus'],
  overlay,
  children,
  container,
  show,
}: ITooltipProps): React.JSX.Element {
  const [defaultId] = useState<string>(uniqid());
  const [isOpen, setIsOpen] = useState(show);
  const arrowRef = useRef(null);

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const autoPlacementArray = isNil(placement)
    ? [
        autoPlacement({
          alignment: 'start',
        }),
      ]
    : [];

  const flipArray = isNil(placement) ? [] : [flip()];

  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement,
    middleware: [
      offset(ARROW_HEIGHT + GAP),
      shift(),
      ...flipArray,
      ...autoPlacementArray,
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const hover = useHover(context, {
    enabled:
      trigger === 'hover' || (isArray(trigger) && includes(trigger, 'hover')),
    delay: delay ?? {
      open: 300,
      close: 0,
    },
  });
  const focus = useFocus(context, {
    enabled:
      trigger === 'focus' || (isArray(trigger) && includes(trigger, 'focus')),
  });
  const click = useClick(context, {
    enabled:
      trigger === 'click' || (isArray(trigger) && includes(trigger, 'click')),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    click,
  ]);

  const child = React.Children.toArray(children)?.[0];
  const ref = useMergeRefs([refs.setReference, (child as any)?.ref]);

  if (!overlay) {
    return <>{children}</>;
  }

  return (
    <>
      {React.Children.map(children, (child, index) => {
        if (!child) {
          return null;
        }
        const elem = child as React.ReactElement;
        return React.cloneElement(elem, {
          ref: ref,
          ...getReferenceProps(elem?.props),
        });
      })}
      {isOpen && (
        <FloatingPortal root={container}>
          <div
            key={id ?? defaultId}
            className="tooltip show"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div className="tooltip-inner">{overlay}</div>
            <FloatingArrow
              ref={arrowRef}
              className="tooltip-arrow"
              context={context}
              height={ARROW_HEIGHT}
              width={ARROW_WIDTH}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  );
}

export default Tooltip;
