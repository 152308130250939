import { GlobalActions } from 'core/redux/enums/GlobalActions';
import { IGlobals } from 'models/IGlobals';
import { IAction } from 'models/IAction';

const initialState: IGlobals = {
  title: 'Alanbase',
  dict: {},
  loading: false,
};

export default function (state = initialState, action: IAction) {
  switch (action.type) {
    case GlobalActions.SetTitle:
      return Object.assign({}, state, { title: action.payload.title });
    case GlobalActions.SetDict:
      return Object.assign({}, state, { dict: action.payload.dict });
    case GlobalActions.SetLoading:
      return Object.assign({}, state, { loading: action.payload.loading });
    case GlobalActions.ChangeLang:
      return Object.assign({}, state, { lang: action.payload.lang });
    default:
      return state;
  }
}
