import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Currency = {
  GET_LIST: {
    url: generatePartnerApiUrl(ModelPath.Currency),
    method: 'GET',
  },
  GET_ENABLED_LIST: {
    url: generatePartnerApiUrl('enabled', ModelPath.Currency),
    method: 'GET',
  },
};

export default Currency;
