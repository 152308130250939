import { UserType } from 'constants/enums/UserType';
import { getUserType } from 'utils/storageUtils';
import IUserContact from 'models/IUserContact';
import ContactFieldType from 'constants/enums/ContactFieldType';

const checkUserType = (userType: UserType): boolean => {
  const storageUserType = getUserType();
  return storageUserType === userType;
};

export const isAdmin = (): boolean => checkUserType(UserType.ADMIN);
export const isPartner = (): boolean => checkUserType(UserType.PARTNER);
export const isAdvertiser = (): boolean => checkUserType(UserType.ADVERTISER);

export const isTextContactField = (c?: IUserContact): boolean =>
  !!c &&
  [ContactFieldType.Input, ContactFieldType.Textarea].includes(c.field_type);
