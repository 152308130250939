export default {
  dashboard: 'Tablero de instrumentos',
  empty: 'No tienes paneles de control',
  create: 'Crear un tablero de instrumentos',
  settings: 'Ajustes',
  new: 'Nuevo tablero de instrumentos',
  add_new: 'Añadir un nuevo panel de control',
  name: 'Nombre',
  type: 'Tipo',
  private: 'Privado',
  public: 'Público',
  private_dashboards: 'Dashboards privados',
  public_dashboards: 'Tableros públicos',
  my: 'Mi',
  me: 'YO',
  news: 'Noticias',
  partner_search: 'Panel de afiliados ',
  partner_id: 'ID del socio',
  only_one_news_widget:
    'Solo puede haber un widget de noticias en el panel de control',
  only_one_partner_search_widget:
    'Sólo puede haber un widget de afiliado en un panel de control',
  set_as_partners_dashboard: 'Establecer como panel de socios',
  hide_partners_dashboard: 'Ocultar el panel de control de los socios',
  max_private_dashboard: 'Puedes crear hasta 3 paneles privados',
  owner: 'Propietario',
  created: 'Fecha de creación',
  last_modified: 'Última modificación',
  for_partners: 'Para los socios',
  widget_help_title: 'No hay widgets en el panel de control',
  widget_help_text_start: 'Agregue el primer widget haciendo clic en el botón',
  set_partners_dashboard_title:
    'Establecer un panel de control para los socios',
  set_partners_dashboard_message:
    'Desea que el panel <span>"{{newName}}"</span> sea visible para los socios.',
  change_partners_dashboard_title:
    'Cambiar el panel de control para los socios',
  change_partners_dashboard_message:
    'Desea cambiar el panel <span>"{{currentName}}"</span> visible para los socios a <span>"{{newName}}"</span>.',
  hide_partners_dashboard_title: 'Ocultar el panel de control de los socios',
  hide_partners_dashboard_message:
    'Desea ocultar el panel <span>"{{currentName}}"</span> a sus socios.',
  yes_make: 'Sí, sí',
  yes_change: 'Sí, cambiar',
  yes_delete: 'Sí, eliminar',
  choose_new_partner_dashboard: 'Seleccione un nuevo panel de afiliados:',
  delete_private_dashboard:
    'Desea eliminar el panel <span>"{{currentName}}"</span>.',
  delete_public_dashboard:
    'Desea eliminar el panel público <span>"{{currentName}}"</span>. Una vez eliminado, ya no estará disponible para todos los administradores.',
  delete_partner_dashboard:
    'Desea eliminar el panel de afiliados <span>"{{currentName}}"</span>. Una vez eliminado, ya no estará disponible para todos los socios y administradores.',
  dashboard_not_accessible: 'El panel de control no está disponible',
  dashboard_was_deleted:
    '<span>"{{currentName}}"</span> ha sido eliminado por el propietario o movido a privado.',
  choose_dashboard: 'Elegir un panel de control',
  choose_new_or: 'Elija uno diferente o',
  create_new: 'Crear uno nuevo',
  create_new_dashboard: 'Crear un nuevo panel de control',
  statistics_indicators: 'Indicadores estadísticos',
  period: 'Período',
  country: 'País',
  partner: 'Socio',
  offer: 'Oferta',
  product: 'Producto',
  period_1: 'Para hoy',
  period_2: 'Por ayer',
  period_3: 'Para la semana actual',
  period_4: 'La semana anterior',
  period_5: 'Para el mes actual',
  period_6: 'Para el mes anterior',
  period_7: 'Últimos 7 días',
  period_8: 'Últimos 30 días',
  apply_filters: 'Aplicar filtros',
  reset: 'Limpiar',
  preview: 'Vista previa',
  edit_widget: 'Editar widget',
  header: 'Título',
  style: 'Estilo',
  value: 'Significado',
  background_color: 'Color de fondo',
  font_color: 'Color del texto',
  color_black: 'Negro',
  color_white: 'Blanco',
  color_red: 'Rojo',
  color_orange: 'Naranja',
  color_green: 'Verde',
  'color_blue-green': 'Azul-verde',
  color_purple: 'Violeta',
  'color_light-red': 'Rojo',
  'color_light-orange': 'Naranja',
  'color_light-green': 'Verde',
  'color_light-blue-green': 'Azul-verde',
  'color_light-purple': 'Violeta',
  line_chart: 'Gráfico lineal',
  line: 'Línea {{count}}',
  lines: 'Líneas',
  add_line: 'Añadir línea',
  smooth_lines: 'Líneas suaves',
  fill_chart_body: 'Rellenar el cuerpo del gráfico',
  no_data: 'Sin datos',
  pie_chart: 'Gráfico circular',
  sector: 'Sector {{count}}',
  sectors: 'Sectores',
  add_sector: 'Agregar sector',
  highlight_sector: 'Seleccionar sector al pasar el cursor',
  show_percent: 'Mostrar porcentajes para sectores',
};
