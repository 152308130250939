import React, { forwardRef } from 'react';
import cn from 'classnames';
import BsButton, { ButtonProps } from 'react-bootstrap/Button';
import isNil from 'lodash/isNil';

import { Color } from 'constants/enums/Color';
import Loading from 'components/Loading';
import Tooltip, { TooltipOptions } from 'components/Tooltip/Tooltip';
import emptyObject from 'utils/emptyObject';

export interface IButtonProps extends Omit<ButtonProps, 'onClick' | 'variant'> {
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  bold?: boolean;
  onClick?: ((evt: React.MouseEvent | void) => void) | any;
  color?: string | Color;
  square?: boolean;
  value?: string | ReadonlyArray<string> | number;
  isLoading?: boolean;
  tooltip?: React.ReactNode | TooltipOptions;
}

const Button = forwardRef<HTMLElement, IButtonProps>(
  (
    {
      icon,
      iconPosition,
      className,
      children = null,
      color = Color.LIGHT_SECONDARY,
      bold,
      square,
      isLoading,
      disabled,
      tooltip,
      ...rest
    }: IButtonProps,
    ref,
  ) => {
    const loadingIcon = <Loading withoutLogo={true} variant={'currentColor'} />;
    const buttonIcon = isLoading ? loadingIcon : icon;
    let tooltipOptions: TooltipOptions = {
      overlay: undefined,
      delay: 400,
    };
    if (!isNil((tooltip as TooltipOptions)?.overlay)) {
      tooltipOptions = {
        ...tooltipOptions,
        ...(tooltip || emptyObject),
      };
    } else {
      tooltipOptions.overlay = tooltip as React.ReactNode;
    }
    return (
      <Tooltip {...tooltipOptions}>
        <BsButton
          variant={color}
          className={cn('button', className, {
            'button--bold': bold,
            'button--square': square,
          })}
          disabled={disabled || isLoading}
          {...rest}
          ref={ref}
        >
          {buttonIcon && (
            <span
              className={cn('button__icon', {
                'button__icon--right': iconPosition === 'right',
              })}
            >
              {buttonIcon}
            </span>
          )}
          {children && <span>{children}</span>}
        </BsButton>
      </Tooltip>
    );
  },
);
export default Button;
