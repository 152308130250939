export default {
  'new product': 'New product',
  name: 'Name',
  offers: 'Offers',
  category: 'Category',
  note: 'Internal note',
  table_note: 'Note',
  advertiser: 'Advertiser',
  status: 'Status',
  created: 'Created',
  'no products': 'No products',
  products: 'Products',
  products_list: 'Products list',
  product: 'Product',
  monetary: 'Monetary',
  'no events list': 'No events list',
  'no offers list': 'No offers list',
  'name and id': 'Name and id',
  privacy: 'Privacy',
  'creation date': 'Creation date',
  events: 'Events',
  edit: 'Edit',
  delete: 'Delete',
  enabled: 'Enabled',
  manager: 'Manager',
  'click lifetime': 'Click lifetime',
  landings: 'Landings',
  target_link: 'Target link',
  'create new project': 'Create New product',
  'product name': 'Product name',
  description: 'Description',
  'poster image (120 × 120)': 'Poster image (recommend resolution 120 × 120)',
  'create new product': 'Create New product',
  'edit product': 'Edit product',
  'product successfully created': 'Product successfully created',
  'product successfully edited': 'Product successfully edited',
  hours: 'hours',
  new_landing: 'New landing',
  disable_product: 'Disable product',
  are_you_sure_you_want_to_disable_the_product:
    'Are you sure you want to disable the product?\nWhen you disable a product, all of its offers will be disabled.',
  unlimited: 'Unlimited',
  days: 'days',
  goal_remove_conditions:
    'You cannot delete the goal while it is being used in conditions of an offer.',
  goal_deleting: 'Deleting the goal',
  goal_key_help:
    'Goal identifier, which will come with postback in the {goal} parameter. The value will be converted to lowercase.',
  formula_variable_amount_of: 'Amount of',
  formula_variable_goal_sum: '$t(product:formula_variable_amount_of) {{name}}',
  product_variables: 'Product variables',
  coefficients: 'Coefficients',
  edit_formula: 'Edit formula',
  add_formula: 'New formula',
  add_coefficient: 'Add coefficient',
  formula_form_name_help: 'Expression name to work with offer conditions',
  formula_form_expression_help: 'Expression to calculate product income',
  formula_form_coefficient_help:
    'Name and value of coefficients, which will be used in expression. The value is specified in the numeric field in absolute numbers (not in percentages). Coefficients can be redefined in the offer conditions.',
  coefficient_name: 'Name of coefficient',
  coefficient_value: 'Default value',
  coefficient_delete_error:
    'You cannot remove the coefficient included in the formula',
};
