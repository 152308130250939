export default {
  'edit profile': 'Редактировать профиль',
  'change password': 'Сменить пароль',
  created: 'Создано',
  active: 'Активен',
  banned: 'Забанен',
  incomplete_registration: 'Неполная регистрация',
  on_moderation: 'На модерации',
  rejected: 'Отклонен',
  disabled: 'Отключен',
  enabled: 'Активно',
  activate: 'Активировать',
  reject: 'Отклонить',
  account_is_not_confirmed_yet: 'Аккаунт ещё не подтвержден.',
  to_activate_the_user: 'Вы хотите активировать этого пользователя?',
  postback_settings: 'Настройки постбеков',
  event_postback_settings_label: 'Ссылка для отправки постбека с событиями',
  event_postback_settings_help:
    'URL для отправки постбеков с событиями. Вы можете скопировать ссылку для использования в своих продуктах.',
  goal_postback_settings_label: 'Ссылка для отправки постбека с целями',
  goal_postback_settings_help:
    'URL для отправки постбеков с целями. Вы можете скопировать ссылку для использования в своих продуктах.',
  relation_postback_settings_help:
    'URL для отправки связывающих постбеков. Вы можете скопировать ссылку для использования в своих продуктах.',
  profile_approval_has_been_rejected: 'Пользователь успешно отклонен',
  profile_approval_confirm: 'Вы хотите активировать пользователя?',
  profile_reject_confirm: 'Вы хотите отклонить пользователя?',
  manager: 'Менеджер',
  contacts: 'Контакты',
  manager_contacts: 'Контакты менеджера',
  client_key: 'Клиентский ключ',
  your_client_key: 'Ваш клиентский ключ',
  generate_new_client_key: 'Сгенирировать новый клиентский ключ',
  client_key_help:
    'Клиентский ключ используется партнерской системой для идентификации пользователя при получении постбека. Вы можете скопировать клиентский ключ. Также вы можете создать новый.',
  send_follow_up_email: 'Отправить письмо повторно',
  email_has_been_sent: 'Письмо для подтверждения отправлено',
  client_key_generate_confirm:
    'Вы действительно хотите обновить клиентский ключ? После обновления перестанут приниматься постбеки со старым ключом.',
  partner_invite_link: 'Ссылка для приглашения партнеров',
  api_key: 'API-ключ',
  api_key_update_title: 'Сгенерировать новый ключ',
  api_key_update_confirm:
    'Вы действительно хотите сгенерировать новый API-ключ? Запросы со старым ключом перестанут работать.',
  api_key_updated: 'API-ключ успешно обновлен',
  tags: 'Теги',
  source_of_registration: 'Источник регистрации',
  secret_key: 'Секретный ключ',
  secret_key_help:
    'Секретный ключ используется для проверки целостности передаваемых данных при отправке и получении постбека',
  generate_new_secret_key: 'Сгенерировать новый секретный ключ',
  secret_key_generate_confirm:
    'Вы действительно хотите обновить секретный ключ? После обновления перестанут приниматься постбеки со старым ключом.',
};
