export default {
  offer: 'Оффер',
  email: 'Email',
  telegram: 'Telegram',
  roles: 'Роли',
  status: 'Статус',
  'creation date': 'Дата создания',
  admins: 'Администраторы',
  admin: 'Администратор',
  'new admin': 'Новый администратор',
  'edit admin': 'Редактировать администратора',
  name: 'Имя',
  surname: 'Фамилия',
  telegram_name: 'Telegram',
  'current password': 'Текущий пароль',
  'new password': 'Новый пароль',
  'repeat new password': 'Подтвердите новый пароль',
  'change password': 'Сменить пароль',
  'password was changed': 'Пароль изменен',
  'from 8 to 28 Latin letters and symbols':
    'От 8 до 28 латинских букв и символов',
  'no admins': 'Нет созданных администраторов',
  activated: 'Активен',
  inactive: 'Не активен',
  registration_not_completed: 'Регистрация не завершена',
  'profile successfully updated': 'Профиль успешно обновлен',
  admin_successfully_updated: 'Админ успешно обновлен',
};
