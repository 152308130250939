export enum Path {
  Main = '/',
  Profile = '/profile',
  Dashboard = '/dashboard',
  Settings = '/settings',
  ApiDoc = '/docs/api/:userType',
  Statistics = '/statistics',
  Customers = '/customers',
  CohortAnalysis = '/statistics/cohort:params([A-Za-z0-9_])?',
  Logs = '/logs',
  StatisticsEvents = '/statistics/events',
  StatisticsConversions = '/statistics#conversions',
  Error = '/error',
  Partners = '/partners',
  Partner = '/partners/:id',
  PromoMaterials = '/promo',
  PromoCodes = '/promocodes',
  Exports = '/exports',

  TermsOfUse = '/legal-documents/terms-of-use',
  PrivacyPolicy = '/legal-documents/privacy-policy',
  LegalDocumentsAssign = '/legal-documents/assign',

  Products = '/products',
  Product = '/products/:id',
  ProductId = '/products/:productId',
  ProductCreate = '/products/create',
  ProductEdit = '/products/:id/edit',

  News = '/news',
  NewsForm = '/news/:id',
  NewsCreate = '/news/create',
  NewsEdit = '/news/:id/edit',
  Offers = '/offers',
  Offer = '/offers/:id',
  OfferCreate = '/offers/create',
  OfferAccessRequests = '/offers/requests',
  OfferEdit = '/offers/:id/edit',
  OfferConditions = '/offers/:id/conditions',
  ProductOfferConditions = '/products/:productId/offers/:id/conditions',
  Admins = '/admins',
  Admin = '/admin/:id',
  Advertisers = '/advertisers',
  Advertiser = '/advertisers/:id',
  Payments = '/payments',
  ProductOffer = '/products/:productId/offers/:id',
  Wallet = '/wallet',
  Referrals = '/referrals',
}
