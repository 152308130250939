export default {
  offer: 'Oferta',
  email: 'Email',
  telegram: 'Telegram',
  roles: 'Roles',
  status: 'Estado',
  'creation date': 'Fecha de creación',
  admins: 'Administradores',
  admin: 'Administrador',
  'new admin': 'Nuevo administrador',
  'edit admin': 'Editar administrador',
  name: 'Nombre',
  surname: 'Apellido',
  telegram_name: 'Telegram',
  'current password': 'Contraseña actual',
  'new password': 'Nueva contraseña',
  'repeat new password': 'Confirme la nueva contraseña',
  'change password': 'Cambiar contraseña',
  'password was changed': 'Contraseña cambiada',
  'from 8 to 28 Latin letters and symbols':
    'De 8 a 28 letras y caracteres latinos',
  'no admins': 'No hay administradores creados',
  activated: 'Activo',
  inactive: 'Desactivado',
  registration_not_completed: 'Inscripción incompleta',
  'profile successfully updated': 'Perfil actualizado con éxito',
  admin_successfully_updated: 'Administrador actualizado con éxito',
};
