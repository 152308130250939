export default {
  dashboard: 'Dashboard',
  empty: 'You have no dashboards',
  create: 'Create dashboard',
  settings: 'Settings',
  new: 'New dashboard',
  add_new: 'Add new dashboard',
  name: 'Name',
  type: 'Type',
  private: 'Private',
  public: 'Public',
  private_dashboards: 'Private dashboards',
  public_dashboards: 'Public dashboards',
  my: 'My',
  me: 'Me',
  news: 'News',
  partner_search: 'Affiliate panel',
  partner_id: 'Partner ID',
  only_one_news_widget: 'A dashboard can only have one news widget',
  only_one_partner_search_widget:
    'A dashboard can only have one affiliate panel widget',
  set_as_partners_dashboard: 'Set as board for partners',
  hide_partners_dashboard: 'Hide dashboard from partners',
  max_private_dashboard: 'You can create up to 3 private dashboards',
  owner: 'Owner',
  created: 'Created',
  last_modified: 'Last modified',
  for_partners: 'For Partners',
  widget_help_title: 'Dashboard have no widgets',
  widget_help_text_start: 'Add a first widget by clicking on button',
  set_partners_dashboard_title: 'Set dashboard for partners',
  set_partners_dashboard_message:
    'You want to make the <span>"{{newName}}"</span> dashboard visible to partners.',
  change_partners_dashboard_title: 'Change dashboard for partners',
  change_partners_dashboard_message:
    'You want to change the <span>"{{currentName}}"</span> dashboard visible to partners to <span>"{{newName}}"</span>.',
  hide_partners_dashboard_title: 'Hide dashboard from partners',
  hide_partners_dashboard_message:
    'You want to hide the <span>"{{currentName}}"</span> dashboard from partners.',
  yes_make: 'Yes, make',
  yes_change: 'Yes, change',
  yes_delete: 'Yes, delete',
  choose_new_partner_dashboard: 'Select a new partners dashboard:',
  delete_private_dashboard:
    'You want to delete the <span>"{{currentName}}"</span> dashboard.',
  delete_public_dashboard:
    'You want to delete the <span>"{{currentName}}"</span> public dashboard. After deletion, it will become unavailable to all administrators.',
  delete_partner_dashboard:
    'You want to delete the <span>"{{currentName}}"</span> dashboard visible to partners. After deletion, it will become unavailable to all partners and administrators.',
  dashboard_not_accessible: 'Dashboard is not accessible',
  dashboard_was_deleted:
    '<span>"{{currentName}}"</span> was deleted by the owner or moved to private.',
  choose_dashboard: 'Choose dashboard',
  choose_new_or: 'Choose another or',
  create_new: 'create a new one',
  create_new_dashboard: 'Create a new dashboard',
  statistics_indicators: 'Statistics indicators',
  period: 'Period',
  country: 'Country',
  partner: 'Partner',
  offer: 'Offer',
  product: 'Product',
  period_1: 'For today',
  period_2: 'For yesterday',
  period_3: 'For current week',
  period_4: 'For last week',
  period_5: 'For current month',
  period_6: 'For last month',
  period_7: 'For last 7 days',
  period_8: 'For last 30 days',
  apply_filters: 'Apply filters',
  reset: 'Reset',
  preview: 'Preview',
  edit_widget: 'Edit widget',
  header: 'Header',
  style: 'Style',
  value: 'Value',
  background_color: 'Background color',
  font_color: 'Font color',
  color_black: 'Black',
  color_white: 'White',
  color_red: 'Red',
  color_orange: 'Orange',
  color_green: 'Green',
  'color_blue-green': 'Blue-green',
  color_purple: 'Purple',
  'color_light-red': 'Red',
  'color_light-orange': 'Orange',
  'color_light-green': 'Green',
  'color_light-blue-green': 'Blue-green',
  'color_light-purple': 'Purple',
  line_chart: 'Line chart',
  line: 'Line {{count}}',
  lines: 'Lines',
  add_line: 'Add line',
  smooth_lines: 'Smooth lines',
  fill_chart_body: 'Fill chart body',
  no_data: 'No data',
  pie_chart: 'Pie chart',
  sector: 'Sector {{count}}',
  sectors: 'Sectors',
  add_sector: 'Add sector',
  highlight_sector: 'Highlight sector on hover',
  show_percent: 'Show percentage for sectors',
};
