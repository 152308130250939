export default {
  dashboard: 'Дашборд',
  empty: 'У вас нет дашбордов',
  create: 'Создать дашборд',
  settings: 'Настройки',
  new: 'Новый дашборд',
  add_new: 'Добавить новый дашборд',
  name: 'Название',
  type: 'Тип',
  private: 'Приватный',
  public: 'Публичный',
  private_dashboards: 'Приватные дашборды',
  public_dashboards: 'Публичные дашборды',
  my: 'Мой',
  me: 'Я',
  news: 'Новости',
  partner_search: 'Партнёрская панель',
  partner_id: 'ID партнера',
  only_one_news_widget: 'В дашборде может быть только один виджет новостей',
  only_one_partner_search_widget:
    'В дашборде может быть только один виджет партнёрской панели',
  set_as_partners_dashboard: 'Установить как дашборд для партнёров',
  hide_partners_dashboard: 'Скрыть дашборд от партнёров',
  max_private_dashboard: 'Вы можете создать до 3 приватных дашбордов',
  owner: 'Владелец',
  created: 'Дата создания',
  last_modified: 'Последнее изменение',
  for_partners: 'Для партнеров',
  widget_help_title: 'В дашборде нет виджетов',
  widget_help_text_start: 'Добавьте первый виджет, нажав на кнопку',
  set_partners_dashboard_title: 'Установить дашборд для партнёров',
  set_partners_dashboard_message:
    'Вы хотите сделать дашборд <span>"{{newName}}"</span> видимым для партнёров.',
  change_partners_dashboard_title: 'Сменить дашборд для партнёров',
  change_partners_dashboard_message:
    'Вы хотите сменить видимый партнерам дашборд <span>"{{currentName}}"</span> на <span>"{{newName}}"</span>.',
  hide_partners_dashboard_title: 'Скрыть дашборд от партнёров',
  hide_partners_dashboard_message:
    'Вы хотите скрыть от партнеров дашборд <span>"{{currentName}}"</span>.',
  yes_make: 'Да, сделать',
  yes_change: 'Да, сменить',
  yes_delete: 'Да, удалить',
  choose_new_partner_dashboard: 'Выберите новый партнерский дашборд:',
  delete_private_dashboard:
    'Вы хотите удалить дашборд <span>"{{currentName}}"</span>.',
  delete_public_dashboard:
    'Вы хотите удалить публичный дашборд <span>"{{currentName}}"</span>. После удаления он станет недоступен всем администраторам.',
  delete_partner_dashboard:
    'Вы хотите удалить партнёрский дашборд <span>"{{currentName}}"</span>. После удаления он станет недоступен для всех партнеров и администраторов.',
  dashboard_not_accessible: 'Дашборд недоступен',
  dashboard_was_deleted:
    '<span>"{{currentName}}"</span> был удален владельцем или перенесен в приватные.',
  choose_dashboard: 'Выберите дашборд',
  choose_new_or: 'Выберите другой или',
  create_new: 'создайте новый',
  create_new_dashboard: 'Создать новый дашборд',
  statistics_indicators: 'Показатели статистики',
  period: 'Период',
  country: 'Страна',
  partner: 'Партнёр',
  offer: 'Оффер',
  product: 'Продукт',
  period_1: 'За сегодня',
  period_2: 'За вчера',
  period_3: 'За текущую неделю',
  period_4: 'За предыдущую неделю',
  period_5: 'За текущий месяц',
  period_6: 'За предыдущий месяц',
  period_7: 'За последние 7 дней',
  period_8: 'За последние 30 дней',
  apply_filters: 'Применить фильтры',
  reset: 'Очистить',
  preview: 'Предпросмотр',
  edit_widget: 'Редактировать виджет',
  header: 'Заголовок',
  style: 'Стиль',
  value: 'Значение',
  background_color: 'Цвет фона',
  font_color: 'Цвет текста',
  color_black: 'Чёрный',
  color_white: 'Белый',
  color_red: 'Красный',
  color_orange: 'Оранжевый',
  color_green: 'Зелёный',
  'color_blue-green': 'Сине-зеленый',
  color_purple: 'Фиолетовый',
  'color_light-red': 'Красный',
  'color_light-orange': 'Оранжевый',
  'color_light-green': 'Зелёный',
  'color_light-blue-green': 'Сине-зеленый',
  'color_light-purple': 'Фиолетовый',
  line_chart: 'Линейный график',
  line: 'Линия {{count}}',
  lines: 'Линии',
  add_line: 'Добавить линию',
  smooth_lines: 'Плавные линии',
  fill_chart_body: 'Заполнить тело графика',
  no_data: 'Нет данных',
  pie_chart: 'Круговая диаграмма',
  sector: 'Сектор {{count}}',
  sectors: 'Сектора',
  add_sector: 'Добавить сектор',
  highlight_sector: 'Выделять сектор при наведении',
  show_percent: 'Отображать процентное соотношение для секторов',
};
