export default {
  news: 'Noticias',
  all_news: 'Todas las noticias',
  add_news: 'Añadir noticias',
  edit_news: 'Editar noticias',
  no_news: 'No hay noticias',
  header: 'Título',
  text: 'Texto',
  news_remove_confirm: '¿Seguro que quieres eliminar Noticias?',
};
