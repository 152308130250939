import FlagPngAd from 'assets/icons/flags/png/ad.png';
import FlagPngAe from 'assets/icons/flags/png/ae.png';
import FlagPngAf from 'assets/icons/flags/png/af.png';
import FlagPngAg from 'assets/icons/flags/png/ag.png';
import FlagPngAi from 'assets/icons/flags/png/ai.png';
import FlagPngAl from 'assets/icons/flags/png/al.png';
import FlagPngAm from 'assets/icons/flags/png/am.png';
import FlagPngAn from 'assets/icons/flags/png/an.png';
import FlagPngAo from 'assets/icons/flags/png/ao.png';
import FlagPngAq from 'assets/icons/flags/png/aq.png';
import FlagPngAr from 'assets/icons/flags/png/ar.png';
import FlagPngAs from 'assets/icons/flags/png/as.png';
import FlagPngAt from 'assets/icons/flags/png/at.png';
import FlagPngAu from 'assets/icons/flags/png/au.png';
import FlagPngAw from 'assets/icons/flags/png/aw.png';
import FlagPngAx from 'assets/icons/flags/png/ax.png';
import FlagPngAz from 'assets/icons/flags/png/az.png';
import FlagPngBa from 'assets/icons/flags/png/ba.png';
import FlagPngBb from 'assets/icons/flags/png/bb.png';
import FlagPngBd from 'assets/icons/flags/png/bd.png';
import FlagPngBe from 'assets/icons/flags/png/be.png';
import FlagPngBf from 'assets/icons/flags/png/bf.png';
import FlagPngBg from 'assets/icons/flags/png/bg.png';
import FlagPngBh from 'assets/icons/flags/png/bh.png';
import FlagPngBi from 'assets/icons/flags/png/bi.png';
import FlagPngBj from 'assets/icons/flags/png/bj.png';
import FlagPngBl from 'assets/icons/flags/png/bl.png';
import FlagPngBm from 'assets/icons/flags/png/bm.png';
import FlagPngBn from 'assets/icons/flags/png/bn.png';
import FlagPngBo from 'assets/icons/flags/png/bo.png';
import FlagPngBq from 'assets/icons/flags/png/bq.png';
import FlagPngBr from 'assets/icons/flags/png/br.png';
import FlagPngBs from 'assets/icons/flags/png/bs.png';
import FlagPngBt from 'assets/icons/flags/png/bt.png';
import FlagPngBv from 'assets/icons/flags/png/bv.png';
import FlagPngBw from 'assets/icons/flags/png/bw.png';
import FlagPngBy from 'assets/icons/flags/png/by.png';
import FlagPngBz from 'assets/icons/flags/png/bz.png';
import FlagPngCa from 'assets/icons/flags/png/ca.png';
import FlagPngCc from 'assets/icons/flags/png/cc.png';
import FlagPngCd from 'assets/icons/flags/png/cd.png';
import FlagPngCf from 'assets/icons/flags/png/cf.png';
import FlagPngCg from 'assets/icons/flags/png/cg.png';
import FlagPngCh from 'assets/icons/flags/png/ch.png';
import FlagPngCi from 'assets/icons/flags/png/ci.png';
import FlagPngCk from 'assets/icons/flags/png/ck.png';
import FlagPngCl from 'assets/icons/flags/png/cl.png';
import FlagPngCm from 'assets/icons/flags/png/cm.png';
import FlagPngCn from 'assets/icons/flags/png/cn.png';
import FlagPngCo from 'assets/icons/flags/png/co.png';
import FlagPngCr from 'assets/icons/flags/png/cr.png';
import FlagPngCs from 'assets/icons/flags/png/cs.png';
import FlagPngCu from 'assets/icons/flags/png/cu.png';
import FlagPngCv from 'assets/icons/flags/png/cv.png';
import FlagPngCw from 'assets/icons/flags/png/cw.png';
import FlagPngCx from 'assets/icons/flags/png/cx.png';
import FlagPngCy from 'assets/icons/flags/png/cy.png';
import FlagPngCz from 'assets/icons/flags/png/cz.png';
import FlagPngDe from 'assets/icons/flags/png/de.png';
import FlagPngDj from 'assets/icons/flags/png/dj.png';
import FlagPngDk from 'assets/icons/flags/png/dk.png';
import FlagPngDm from 'assets/icons/flags/png/dm.png';
import FlagPngDo from 'assets/icons/flags/png/do.png';
import FlagPngDz from 'assets/icons/flags/png/dz.png';
import FlagPngEc from 'assets/icons/flags/png/ec.png';
import FlagPngEe from 'assets/icons/flags/png/ee.png';
import FlagPngEg from 'assets/icons/flags/png/eg.png';
import FlagPngEh from 'assets/icons/flags/png/eh.png';
import FlagPngEr from 'assets/icons/flags/png/er.png';
import FlagPngEs from 'assets/icons/flags/png/es.png';
import FlagPngEt from 'assets/icons/flags/png/et.png';
import FlagPngEu from 'assets/icons/flags/png/eu.png';
import FlagPngFi from 'assets/icons/flags/png/fi.png';
import FlagPngFj from 'assets/icons/flags/png/fj.png';
import FlagPngFk from 'assets/icons/flags/png/fk.png';
import FlagPngFm from 'assets/icons/flags/png/fm.png';
import FlagPngFo from 'assets/icons/flags/png/fo.png';
import FlagPngFr from 'assets/icons/flags/png/fr.png';
import FlagPngGa from 'assets/icons/flags/png/ga.png';
import FlagPngGb from 'assets/icons/flags/png/gb.png';
import FlagPngGd from 'assets/icons/flags/png/gd.png';
import FlagPngGe from 'assets/icons/flags/png/ge.png';
import FlagPngGf from 'assets/icons/flags/png/gf.png';
import FlagPngGg from 'assets/icons/flags/png/gg.png';
import FlagPngGh from 'assets/icons/flags/png/gh.png';
import FlagPngGi from 'assets/icons/flags/png/gi.png';
import FlagPngGl from 'assets/icons/flags/png/gl.png';
import FlagPngGm from 'assets/icons/flags/png/gm.png';
import FlagPngGn from 'assets/icons/flags/png/gn.png';
import FlagPngGp from 'assets/icons/flags/png/gp.png';
import FlagPngGq from 'assets/icons/flags/png/gq.png';
import FlagPngGr from 'assets/icons/flags/png/gr.png';
import FlagPngGs from 'assets/icons/flags/png/gs.png';
import FlagPngGt from 'assets/icons/flags/png/gt.png';
import FlagPngGu from 'assets/icons/flags/png/gu.png';
import FlagPngGw from 'assets/icons/flags/png/gw.png';
import FlagPngGy from 'assets/icons/flags/png/gy.png';
import FlagPngHk from 'assets/icons/flags/png/hk.png';
import FlagPngHm from 'assets/icons/flags/png/hm.png';
import FlagPngHn from 'assets/icons/flags/png/hn.png';
import FlagPngHr from 'assets/icons/flags/png/hr.png';
import FlagPngHt from 'assets/icons/flags/png/ht.png';
import FlagPngHu from 'assets/icons/flags/png/hu.png';
import FlagPngId from 'assets/icons/flags/png/id.png';
import FlagPngIe from 'assets/icons/flags/png/ie.png';
import FlagPngIl from 'assets/icons/flags/png/il.png';
import FlagPngIm from 'assets/icons/flags/png/im.png';
import FlagPngIn from 'assets/icons/flags/png/in.png';
import FlagPngIo from 'assets/icons/flags/png/io.png';
import FlagPngIq from 'assets/icons/flags/png/iq.png';
import FlagPngIr from 'assets/icons/flags/png/ir.png';
import FlagPngIs from 'assets/icons/flags/png/is.png';
import FlagPngIt from 'assets/icons/flags/png/it.png';
import FlagPngJe from 'assets/icons/flags/png/je.png';
import FlagPngJm from 'assets/icons/flags/png/jm.png';
import FlagPngJo from 'assets/icons/flags/png/jo.png';
import FlagPngJp from 'assets/icons/flags/png/jp.png';
import FlagPngKe from 'assets/icons/flags/png/ke.png';
import FlagPngKg from 'assets/icons/flags/png/kg.png';
import FlagPngKh from 'assets/icons/flags/png/kh.png';
import FlagPngKi from 'assets/icons/flags/png/ki.png';
import FlagPngKm from 'assets/icons/flags/png/km.png';
import FlagPngKn from 'assets/icons/flags/png/kn.png';
import FlagPngKp from 'assets/icons/flags/png/kp.png';
import FlagPngKr from 'assets/icons/flags/png/kr.png';
import FlagPngKw from 'assets/icons/flags/png/kw.png';
import FlagPngKy from 'assets/icons/flags/png/ky.png';
import FlagPngKz from 'assets/icons/flags/png/kz.png';
import FlagPngLa from 'assets/icons/flags/png/la.png';
import FlagPngLb from 'assets/icons/flags/png/lb.png';
import FlagPngLc from 'assets/icons/flags/png/lc.png';
import FlagPngLi from 'assets/icons/flags/png/li.png';
import FlagPngLk from 'assets/icons/flags/png/lk.png';
import FlagPngLr from 'assets/icons/flags/png/lr.png';
import FlagPngLs from 'assets/icons/flags/png/ls.png';
import FlagPngLt from 'assets/icons/flags/png/lt.png';
import FlagPngLu from 'assets/icons/flags/png/lu.png';
import FlagPngLv from 'assets/icons/flags/png/lv.png';
import FlagPngLy from 'assets/icons/flags/png/ly.png';
import FlagPngMa from 'assets/icons/flags/png/ma.png';
import FlagPngMc from 'assets/icons/flags/png/mc.png';
import FlagPngMd from 'assets/icons/flags/png/md.png';
import FlagPngMe from 'assets/icons/flags/png/me.png';
import FlagPngMf from 'assets/icons/flags/png/mf.png';
import FlagPngMg from 'assets/icons/flags/png/mg.png';
import FlagPngMh from 'assets/icons/flags/png/mh.png';
import FlagPngMk from 'assets/icons/flags/png/mk.png';
import FlagPngMl from 'assets/icons/flags/png/ml.png';
import FlagPngMm from 'assets/icons/flags/png/mm.png';
import FlagPngMn from 'assets/icons/flags/png/mn.png';
import FlagPngMo from 'assets/icons/flags/png/mo.png';
import FlagPngMp from 'assets/icons/flags/png/mp.png';
import FlagPngMq from 'assets/icons/flags/png/mq.png';
import FlagPngMr from 'assets/icons/flags/png/mr.png';
import FlagPngMs from 'assets/icons/flags/png/ms.png';
import FlagPngMt from 'assets/icons/flags/png/mt.png';
import FlagPngMu from 'assets/icons/flags/png/mu.png';
import FlagPngMv from 'assets/icons/flags/png/mv.png';
import FlagPngMw from 'assets/icons/flags/png/mw.png';
import FlagPngMx from 'assets/icons/flags/png/mx.png';
import FlagPngMy from 'assets/icons/flags/png/my.png';
import FlagPngMz from 'assets/icons/flags/png/mz.png';
import FlagPngNa from 'assets/icons/flags/png/na.png';
import FlagPngNc from 'assets/icons/flags/png/nc.png';
import FlagPngNe from 'assets/icons/flags/png/ne.png';
import FlagPngNf from 'assets/icons/flags/png/nf.png';
import FlagPngNg from 'assets/icons/flags/png/ng.png';
import FlagPngNi from 'assets/icons/flags/png/ni.png';
import FlagPngNl from 'assets/icons/flags/png/nl.png';
import FlagPngNo from 'assets/icons/flags/png/no.png';
import FlagPngNp from 'assets/icons/flags/png/np.png';
import FlagPngNr from 'assets/icons/flags/png/nr.png';
import FlagPngNu from 'assets/icons/flags/png/nu.png';
import FlagPngNz from 'assets/icons/flags/png/nz.png';
import FlagPngOm from 'assets/icons/flags/png/om.png';
import FlagPngPa from 'assets/icons/flags/png/pa.png';
import FlagPngPe from 'assets/icons/flags/png/pe.png';
import FlagPngPf from 'assets/icons/flags/png/pf.png';
import FlagPngPg from 'assets/icons/flags/png/pg.png';
import FlagPngPh from 'assets/icons/flags/png/ph.png';
import FlagPngPk from 'assets/icons/flags/png/pk.png';
import FlagPngPl from 'assets/icons/flags/png/pl.png';
import FlagPngPm from 'assets/icons/flags/png/pm.png';
import FlagPngPn from 'assets/icons/flags/png/pn.png';
import FlagPngPr from 'assets/icons/flags/png/pr.png';
import FlagPngPs from 'assets/icons/flags/png/ps.png';
import FlagPngPt from 'assets/icons/flags/png/pt.png';
import FlagPngPw from 'assets/icons/flags/png/pw.png';
import FlagPngPy from 'assets/icons/flags/png/py.png';
import FlagPngQa from 'assets/icons/flags/png/qa.png';
import FlagPngRe from 'assets/icons/flags/png/re.png';
import FlagPngRo from 'assets/icons/flags/png/ro.png';
import FlagPngRs from 'assets/icons/flags/png/rs.png';
import FlagPngRu from 'assets/icons/flags/png/ru.png';
import FlagPngRw from 'assets/icons/flags/png/rw.png';
import FlagPngSa from 'assets/icons/flags/png/sa.png';
import FlagPngSb from 'assets/icons/flags/png/sb.png';
import FlagPngSc from 'assets/icons/flags/png/sc.png';
import FlagPngSd from 'assets/icons/flags/png/sd.png';
import FlagPngSe from 'assets/icons/flags/png/se.png';
import FlagPngSg from 'assets/icons/flags/png/sg.png';
import FlagPngSh from 'assets/icons/flags/png/sh.png';
import FlagPngSi from 'assets/icons/flags/png/si.png';
import FlagPngSk from 'assets/icons/flags/png/sk.png';
import FlagPngSl from 'assets/icons/flags/png/sl.png';
import FlagPngSm from 'assets/icons/flags/png/sm.png';
import FlagPngSn from 'assets/icons/flags/png/sn.png';
import FlagPngSo from 'assets/icons/flags/png/so.png';
import FlagPngSr from 'assets/icons/flags/png/sr.png';
import FlagPngSs from 'assets/icons/flags/png/ss.png';
import FlagPngSt from 'assets/icons/flags/png/st.png';
import FlagPngSv from 'assets/icons/flags/png/sv.png';
import FlagPngSx from 'assets/icons/flags/png/sx.png';
import FlagPngSy from 'assets/icons/flags/png/sy.png';
import FlagPngSz from 'assets/icons/flags/png/sz.png';
import FlagPngTc from 'assets/icons/flags/png/tc.png';
import FlagPngTd from 'assets/icons/flags/png/td.png';
import FlagPngTf from 'assets/icons/flags/png/tf.png';
import FlagPngTg from 'assets/icons/flags/png/tg.png';
import FlagPngTh from 'assets/icons/flags/png/th.png';
import FlagPngTj from 'assets/icons/flags/png/tj.png';
import FlagPngTk from 'assets/icons/flags/png/tk.png';
import FlagPngTl from 'assets/icons/flags/png/tl.png';
import FlagPngTm from 'assets/icons/flags/png/tm.png';
import FlagPngTn from 'assets/icons/flags/png/tn.png';
import FlagPngTo from 'assets/icons/flags/png/to.png';
import FlagPngTr from 'assets/icons/flags/png/tr.png';
import FlagPngTt from 'assets/icons/flags/png/tt.png';
import FlagPngTv from 'assets/icons/flags/png/tv.png';
import FlagPngTw from 'assets/icons/flags/png/tw.png';
import FlagPngTz from 'assets/icons/flags/png/tz.png';
import FlagPngUa from 'assets/icons/flags/png/ua.png';
import FlagPngUg from 'assets/icons/flags/png/ug.png';
import FlagPngUs from 'assets/icons/flags/png/us.png';
import FlagPngUy from 'assets/icons/flags/png/uy.png';
import FlagPngUz from 'assets/icons/flags/png/uz.png';
import FlagPngVa from 'assets/icons/flags/png/va.png';
import FlagPngVc from 'assets/icons/flags/png/vc.png';
import FlagPngVe from 'assets/icons/flags/png/ve.png';
import FlagPngVg from 'assets/icons/flags/png/vg.png';
import FlagPngVi from 'assets/icons/flags/png/vi.png';
import FlagPngVn from 'assets/icons/flags/png/vn.png';
import FlagPngVu from 'assets/icons/flags/png/vu.png';
import FlagPngWf from 'assets/icons/flags/png/wf.png';
import FlagPngWs from 'assets/icons/flags/png/ws.png';
import FlagPngXk from 'assets/icons/flags/png/xk.png';
import FlagPngYe from 'assets/icons/flags/png/ye.png';
import FlagPngYt from 'assets/icons/flags/png/yt.png';
import FlagPngZa from 'assets/icons/flags/png/za.png';
import FlagPngZm from 'assets/icons/flags/png/zm.png';
import FlagPngZw from 'assets/icons/flags/png/zw.png';

const FlagIcons = {
  flagPngAd: FlagPngAd,
  flagPngAe: FlagPngAe,
  flagPngAf: FlagPngAf,
  flagPngAg: FlagPngAg,
  flagPngAi: FlagPngAi,
  flagPngAl: FlagPngAl,
  flagPngAm: FlagPngAm,
  flagPngAn: FlagPngAn,
  flagPngAo: FlagPngAo,
  flagPngAq: FlagPngAq,
  flagPngAr: FlagPngAr,
  flagPngAs: FlagPngAs,
  flagPngAt: FlagPngAt,
  flagPngAu: FlagPngAu,
  flagPngAw: FlagPngAw,
  flagPngAx: FlagPngAx,
  flagPngAz: FlagPngAz,
  flagPngBa: FlagPngBa,
  flagPngBb: FlagPngBb,
  flagPngBd: FlagPngBd,
  flagPngBe: FlagPngBe,
  flagPngBf: FlagPngBf,
  flagPngBg: FlagPngBg,
  flagPngBh: FlagPngBh,
  flagPngBi: FlagPngBi,
  flagPngBj: FlagPngBj,
  flagPngBl: FlagPngBl,
  flagPngBm: FlagPngBm,
  flagPngBn: FlagPngBn,
  flagPngBo: FlagPngBo,
  flagPngBq: FlagPngBq,
  flagPngBr: FlagPngBr,
  flagPngBs: FlagPngBs,
  flagPngBt: FlagPngBt,
  flagPngBv: FlagPngBv,
  flagPngBw: FlagPngBw,
  flagPngBy: FlagPngBy,
  flagPngBz: FlagPngBz,
  flagPngCa: FlagPngCa,
  flagPngCc: FlagPngCc,
  flagPngCd: FlagPngCd,
  flagPngCf: FlagPngCf,
  flagPngCg: FlagPngCg,
  flagPngCh: FlagPngCh,
  flagPngCi: FlagPngCi,
  flagPngCk: FlagPngCk,
  flagPngCl: FlagPngCl,
  flagPngCm: FlagPngCm,
  flagPngCn: FlagPngCn,
  flagPngCo: FlagPngCo,
  flagPngCr: FlagPngCr,
  flagPngCs: FlagPngCs,
  flagPngCu: FlagPngCu,
  flagPngCv: FlagPngCv,
  flagPngCw: FlagPngCw,
  flagPngCx: FlagPngCx,
  flagPngCy: FlagPngCy,
  flagPngCz: FlagPngCz,
  flagPngDe: FlagPngDe,
  flagPngDj: FlagPngDj,
  flagPngDk: FlagPngDk,
  flagPngDm: FlagPngDm,
  flagPngDo: FlagPngDo,
  flagPngDz: FlagPngDz,
  flagPngEc: FlagPngEc,
  flagPngEe: FlagPngEe,
  flagPngEg: FlagPngEg,
  flagPngEh: FlagPngEh,
  flagPngEr: FlagPngEr,
  flagPngEs: FlagPngEs,
  flagPngEt: FlagPngEt,
  flagPngEu: FlagPngEu,
  flagPngFi: FlagPngFi,
  flagPngFj: FlagPngFj,
  flagPngFk: FlagPngFk,
  flagPngFm: FlagPngFm,
  flagPngFo: FlagPngFo,
  flagPngFr: FlagPngFr,
  flagPngGa: FlagPngGa,
  flagPngGb: FlagPngGb,
  flagPngGd: FlagPngGd,
  flagPngGe: FlagPngGe,
  flagPngGf: FlagPngGf,
  flagPngGg: FlagPngGg,
  flagPngGh: FlagPngGh,
  flagPngGi: FlagPngGi,
  flagPngGl: FlagPngGl,
  flagPngGm: FlagPngGm,
  flagPngGn: FlagPngGn,
  flagPngGp: FlagPngGp,
  flagPngGq: FlagPngGq,
  flagPngGr: FlagPngGr,
  flagPngGs: FlagPngGs,
  flagPngGt: FlagPngGt,
  flagPngGu: FlagPngGu,
  flagPngGw: FlagPngGw,
  flagPngGy: FlagPngGy,
  flagPngHk: FlagPngHk,
  flagPngHm: FlagPngHm,
  flagPngHn: FlagPngHn,
  flagPngHr: FlagPngHr,
  flagPngHt: FlagPngHt,
  flagPngHu: FlagPngHu,
  flagPngId: FlagPngId,
  flagPngIe: FlagPngIe,
  flagPngIl: FlagPngIl,
  flagPngIm: FlagPngIm,
  flagPngIn: FlagPngIn,
  flagPngIo: FlagPngIo,
  flagPngIq: FlagPngIq,
  flagPngIr: FlagPngIr,
  flagPngIs: FlagPngIs,
  flagPngIt: FlagPngIt,
  flagPngJe: FlagPngJe,
  flagPngJm: FlagPngJm,
  flagPngJo: FlagPngJo,
  flagPngJp: FlagPngJp,
  flagPngKe: FlagPngKe,
  flagPngKg: FlagPngKg,
  flagPngKh: FlagPngKh,
  flagPngKi: FlagPngKi,
  flagPngKm: FlagPngKm,
  flagPngKn: FlagPngKn,
  flagPngKp: FlagPngKp,
  flagPngKr: FlagPngKr,
  flagPngKw: FlagPngKw,
  flagPngKy: FlagPngKy,
  flagPngKz: FlagPngKz,
  flagPngLa: FlagPngLa,
  flagPngLb: FlagPngLb,
  flagPngLc: FlagPngLc,
  flagPngLi: FlagPngLi,
  flagPngLk: FlagPngLk,
  flagPngLr: FlagPngLr,
  flagPngLs: FlagPngLs,
  flagPngLt: FlagPngLt,
  flagPngLu: FlagPngLu,
  flagPngLv: FlagPngLv,
  flagPngLy: FlagPngLy,
  flagPngMa: FlagPngMa,
  flagPngMc: FlagPngMc,
  flagPngMd: FlagPngMd,
  flagPngMe: FlagPngMe,
  flagPngMf: FlagPngMf,
  flagPngMg: FlagPngMg,
  flagPngMh: FlagPngMh,
  flagPngMk: FlagPngMk,
  flagPngMl: FlagPngMl,
  flagPngMm: FlagPngMm,
  flagPngMn: FlagPngMn,
  flagPngMo: FlagPngMo,
  flagPngMp: FlagPngMp,
  flagPngMq: FlagPngMq,
  flagPngMr: FlagPngMr,
  flagPngMs: FlagPngMs,
  flagPngMt: FlagPngMt,
  flagPngMu: FlagPngMu,
  flagPngMv: FlagPngMv,
  flagPngMw: FlagPngMw,
  flagPngMx: FlagPngMx,
  flagPngMy: FlagPngMy,
  flagPngMz: FlagPngMz,
  flagPngNa: FlagPngNa,
  flagPngNc: FlagPngNc,
  flagPngNe: FlagPngNe,
  flagPngNf: FlagPngNf,
  flagPngNg: FlagPngNg,
  flagPngNi: FlagPngNi,
  flagPngNl: FlagPngNl,
  flagPngNo: FlagPngNo,
  flagPngNp: FlagPngNp,
  flagPngNr: FlagPngNr,
  flagPngNu: FlagPngNu,
  flagPngNz: FlagPngNz,
  flagPngOm: FlagPngOm,
  flagPngPa: FlagPngPa,
  flagPngPe: FlagPngPe,
  flagPngPf: FlagPngPf,
  flagPngPg: FlagPngPg,
  flagPngPh: FlagPngPh,
  flagPngPk: FlagPngPk,
  flagPngPl: FlagPngPl,
  flagPngPm: FlagPngPm,
  flagPngPn: FlagPngPn,
  flagPngPr: FlagPngPr,
  flagPngPs: FlagPngPs,
  flagPngPt: FlagPngPt,
  flagPngPw: FlagPngPw,
  flagPngPy: FlagPngPy,
  flagPngQa: FlagPngQa,
  flagPngRe: FlagPngRe,
  flagPngRo: FlagPngRo,
  flagPngRs: FlagPngRs,
  flagPngRu: FlagPngRu,
  flagPngRw: FlagPngRw,
  flagPngSa: FlagPngSa,
  flagPngSb: FlagPngSb,
  flagPngSc: FlagPngSc,
  flagPngSd: FlagPngSd,
  flagPngSe: FlagPngSe,
  flagPngSg: FlagPngSg,
  flagPngSh: FlagPngSh,
  flagPngSi: FlagPngSi,
  flagPngSj: FlagPngBv,
  flagPngSk: FlagPngSk,
  flagPngSl: FlagPngSl,
  flagPngSm: FlagPngSm,
  flagPngSn: FlagPngSn,
  flagPngSo: FlagPngSo,
  flagPngSr: FlagPngSr,
  flagPngSs: FlagPngSs,
  flagPngSt: FlagPngSt,
  flagPngSv: FlagPngSv,
  flagPngSx: FlagPngSx,
  flagPngSy: FlagPngSy,
  flagPngSz: FlagPngSz,
  flagPngTc: FlagPngTc,
  flagPngTd: FlagPngTd,
  flagPngTf: FlagPngTf,
  flagPngTg: FlagPngTg,
  flagPngTh: FlagPngTh,
  flagPngTj: FlagPngTj,
  flagPngTk: FlagPngTk,
  flagPngTl: FlagPngTl,
  flagPngTm: FlagPngTm,
  flagPngTn: FlagPngTn,
  flagPngTo: FlagPngTo,
  flagPngTr: FlagPngTr,
  flagPngTt: FlagPngTt,
  flagPngTv: FlagPngTv,
  flagPngTw: FlagPngTw,
  flagPngTz: FlagPngTz,
  flagPngUa: FlagPngUa,
  flagPngUg: FlagPngUg,
  flagPngUm: FlagPngUs,
  flagPngUs: FlagPngUs,
  flagPngUy: FlagPngUy,
  flagPngUz: FlagPngUz,
  flagPngVa: FlagPngVa,
  flagPngVc: FlagPngVc,
  flagPngVe: FlagPngVe,
  flagPngVg: FlagPngVg,
  flagPngVi: FlagPngVi,
  flagPngVn: FlagPngVn,
  flagPngVu: FlagPngVu,
  flagPngWf: FlagPngWf,
  flagPngWs: FlagPngWs,
  flagPngXk: FlagPngXk,
  flagPngYe: FlagPngYe,
  flagPngYt: FlagPngYt,
  flagPngZa: FlagPngZa,
  flagPngZm: FlagPngZm,
  flagPngZw: FlagPngZw,
} as const;

export default FlagIcons;
