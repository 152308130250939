export default {
  enable_integration: 'Enable integration',
  integration_settings: 'Integration settings',
  integration_settings_updated:
    'Integration settings have been successfully updated',
  fraudscore_description:
    'Fraud Score is an independent anti-fraud solution that helps advertisers avoid low-quality traffic. The FraudScore system evaluates the quality of traffic in several metrics (fraud reasons): browser, OS, emulators, proxy, devices, etc. FraudScore detects fraud and provides detailed evidence and reports.',
  fraudscore_enabled_button_help:
    'The first conversion within click_id with the status "Pending", "Hold" or "Confirmed" will be sent to FraudScore.',
  fraudscore_channel_api_key_help: 'Used to send conversions to FraudScore',
  fraudscore_channel_api_key_name: 'Channel API key FraudScore',
  fraudscore_channel_id_key_help: 'Used to get data via API',
  fraudscore_channel_id_key_name: 'Channel ID FraudScore',
  fraudscore_user_api_key_name: 'User API key FraudScore',
  gtm_description:
    'Google Tag Manager is a tag management system (TMS) where you can easily update tracking code and other code snippets (tags) on your website or mobile app.',
};
