import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Invoices = {
  GET_ALL: {
    url: generateAdminApiUrl(ModelPath.Invoice),
    method: 'GET',
  },
  EXPORT: {
    url: generateAdminApiUrl('export', ModelPath.Invoice),
    method: 'POST',
  },
  GET: {
    url: generateAdminApiUrl(':id', ModelPath.Invoice),
    method: 'GET',
  },
  GET_CONVERSIONS: {
    url: generateAdminApiUrl(':id/conversions', ModelPath.Invoice),
    method: 'GET',
  },
  UPDATE: {
    url: generateAdminApiUrl(':id', ModelPath.Invoice),
    method: 'PATCH',
  },
  DELETE_INVOICES: {
    url: generateAdminApiUrl('batch', ModelPath.Invoice),
    method: 'DELETE',
  },
  UPDATE_INVOICES: {
    url: generateAdminApiUrl('batch', ModelPath.Invoice),
    method: 'PATCH',
  },
  ADD_CORRECTION: {
    url: generateAdminApiUrl(':id/corrections', ModelPath.Invoice),
    method: 'POST',
  },
  DELETE_CORRECTION: {
    url: generateAdminApiUrl(
      ':id/corrections/:correctionId',
      ModelPath.Invoice,
    ),
    method: 'DELETE',
  },
  GENERATE: {
    url: generateAdminApiUrl('generate', ModelPath.Invoice),
    method: 'POST',
  },
  GENERATE_STATUS: {
    url: generateAdminApiUrl('generate/status', ModelPath.Invoice),
    method: 'GET',
  },
} as const;

export default Invoices;
