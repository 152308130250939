export default {
  enable_integration: 'Включить интеграцию',
  integration_settings: 'Настройки интеграции',
  integration_settings_updated: 'Настройки интеграции успешно обновлены',
  fraudscore_description:
    'FraudScore — независимое решение по борьбе с мошенничеством, помогающий рекламодателям избежать некачественный трафик. Система FraudScore оценивает качество трафика, в нескольких метриках (fraud reasons): браузер, ос, эмуляторы, прокси, устройства и т.д. FraudScore выявляет мошенничество и предоставляет подробные доказательства и отчеты.',
  fraudscore_enabled_button_help:
    'Первая конверсия в рамках click_id в статусах "Pending", "Hold" или "Confirmed" будет отправлена в FraudScore',
  fraudscore_channel_api_key_help:
    'Используется для отправки конверсий в FraudScore',
  fraudscore_channel_api_key_name: 'Channel API key FraudScore',
  fraudscore_channel_id_key_help: 'Используется для получения данных через API',
  fraudscore_channel_id_key_name: 'Channel ID FraudScore',
  fraudscore_user_api_key_name: 'User API key FraudScore',
  gtm_description:
    'Google Менеджер тегов – это система управления тегами (TMS), при помощи которой можно с легкостью обновлять код отслеживания и другие фрагменты кода (теги) на сайте или в мобильном приложении.',
};
