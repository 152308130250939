export default {
  'maximum length of a role name is no more than 255 characters':
    'Максимальная длина названия роли не более 255 символов',
  'enter role name': 'Введите название роли',
  required: 'Обязательно к заполнению',
  'must be 200 characters or less': 'Должно быть не более 200 символов',
  'field length from 2 to 200 characters': 'Длина поля от 2 до 200 символов',
  'field length from 2 to 3000 characters': 'Длина поля от 2 до 3000 символов',
  'field length from 3 to 60 characters': 'Длина поля от 3 до 60 символов',
  'field length from 1 to 40 characters': 'Длина поля от 1 до 40 символов',
  'field length from 1 to 50 characters': 'Длина поля от 1 до 50 символов',
  'field length from 1 to 60 characters': 'Длина поля от 1 до 60 символов',
  'field length from 3 to 120 characters': 'Длина поля от 3 до 120 символов',
  'field length no more than 200 characters':
    'Длина поля не более 200 символов',
  'field length no more than 255 characters':
    'Длина поля не более 255 символов',
  'field length no more than 120 characters':
    'Длина поля не более 120 символов',
  'field length no more than 150 characters':
    'Длина поля не более 150 символов',
  'invalid email address': 'Неверный адрес электронной почты',
  'The email has already been taken.': 'Email уже занят',
  'The email has already been taken': 'Email уже занят',
  'The name must be at least 3 characters':
    'Имя должно состоять не менее чем из 3 символов.',
  'The value must be a link': 'Значение должно быть ссылкой',
  'The name must be no more than 30 characters':
    'Имя должно быть не более 30 символов',
  'The name must be no more than 60 characters':
    'Имя должно быть не более 60 символов',
  'The conversion name must be no more than 30 characters':
    'Название конверсии должно быть не более 30 символов',
  'The click lifetime must be from 1 to 1095':
    'Время жизни клика должно быть от 1 до 1095',
  'The minimum password length must be at least 8 characters':
    'Минимальная длина пароля должна быть не менее 8 символов',
  'The maximum password length must be no more than 255 characters':
    'Максимальная длина пароля должна быть не более 255 символов',
  'passwords must match': 'Пароли не совпадают',
  'Old password is incorrect': 'Старый пароль не верный',
  'The ID must be at least 3 characters':
    'Минимальная длина ID должна быть 3 символа',
  'The ID must be no more than 30 characters':
    'Максимальная длина ID должна быть не более 30 символов',
  'The field can contain only latin characters, dot(.), dash(-) and underscore(_)':
    'Поле может содержать только латинские символы, точку (.), Тире (-) и подчеркивание (_)',
  'The key has already been taken.': 'Этот ID уже занят',
  'The rules field must have a value.': 'Выберите права доступа для роли',
  maximum_5_managers_can_be_assigned: 'Можно назначить максимум 5 менеджеров',
  the_URL_must_be_at_least_3_characters:
    'Минимальная длина URL должна быть не менее 3 символов',
  the_URL_must_be_no_more_than_255_characters:
    'Максимальная длина URL должна быть не более 255 символов',
  the_target_link_must_be_at_least_3_characters:
    'Минимальная длина target-ссылки должна быть не менее 3 символов',
  the_target_link_must_be_no_more_than_2000_characters:
    'Максимальная длина target-ссылки должна быть не более 2000 символов',
  the_link_must_be_no_more_than_2000_characters:
    'Максимальная длина ссылки должна быть не более 2000 символов',
  field_length_must_be_no_more_than_2000_characters:
    'Максимальная длина поля должна быть не более 2000 символов',
  the_number_must_be_integer: 'Число должно быть целым',
  the_number_must_be_at_least_1000000: 'Число должно быть не более\n1 000 000',
  the_number_must_be_at_least_10: 'Число должно быть не менее 10',
  the_number_must_be_from_10_to_1000000: 'Число должно быть от 10 до 1 000 000',
  the_number_must_be_from_1_to_1000000: 'Число должно быть от 1 до 1 000 000',
  the_number_must_be_from_0_to_100: 'Число должно быть от 0 до 100',
  tracking_domain_wrong_format: 'Неправильный формат названия домена',
  tracking_domain_without_click:
    'Вы ввели неправильное значение в поле. Пожалуйста, укажите ссылку без приставки "click"',
  hold_period_must_be_from_0_to_60: 'Период холда может быть от 0 до 60',
  postclick_must_be_from_1_to_1825: 'Постклик может быть от 1 до 1825 (5 лет)',
  the_field_must_be_filled_in_or_the_flag_is_unlimited:
    'Поле должно быть заполнено, либо указан флаг "Безлимитный"',
  the_field_must_be_filled_in_or_the_flag_is_use_hold_period:
    'Поле должно быть заполнено или флаг не установлен на "Период холда"',
  only_Latin_letters_are_allowed:
    'Разрешены только латинские буквы, цифры и символы',
  ID_must_be_unique_within_the_offer: 'ID должен быть уникален в рамках оффера',
  countries: 'Страны: ',
  used_in_other_conditions: 'используются в других условиях',
  target_link_wrong_params: 'Неправильно прописаны макросы',
  at_least_one_goal_must_be_added: 'Должна быть добавлена хотя бы одна цель.',
  at_least_one_country_must_be_added:
    'Должна быть добавлена хотя бы одна страна.',
  it_is_impossible_to_calculate_the_percentage_for_payout_for_a_non_monetary_event:
    'Невозможно посчитать процент для выплаты вознаграждения для неденежного события.',
  you_cannot_set_a_SUM_counting_model_for_a_single_goal_with_a_non_monetary_event:
    'Невозможно указать модель подсчета SUM для одной цели с неденежным событием.',
  you_cannot_set_multiple_payout_because_sum_parameter_can_be_reachable_only_once:
    'Невозможно указать Многоразовую выплату, так как параметр суммы достижим только один раз.',
  it_is_impossible_to_calculate_the_percentage_of_payout_for_sum_counting_model:
    'Невозможно посчитать процент вознаграждения для модели подсчета SUM.',
  it_is_impossible_to_calculate_the_percentage_for_payout_for_a_non_monetary_events:
    'Невозможно посчитать процент для выплаты вознаграждения для неденежных событий.',
  it_is_impossible_to_payout_of_a_fixed_amount_several_times_for_non_recurring_non_monetary_events:
    'Нельзя выплатить вознаграждение в виде фиксированной суммы несколько раз за неповторяющиеся неденежные события.',
  it_is_impossible_to_calculate_the_percentage_for_payout_for_a_multiple_goals_with_monetary_events:
    'Невозможно посчитать процент для выплаты вознаграждения для нескольких целей с денежными событиями.',
  it_is_impossible_to_payout_several_times_because_multiple_goals_can_only_work_once:
    'Нельзя выплатить вознаграждение несколько раз так как множество целей могут сработать только один раз.',
  you_cannot_set_a_SUM_counting_model_for_a_multiple_goals_with_a_non_monetary_events:
    'Невозможно указать модель подсчета SUM для нескольких целей с неденежным событиями',
  the_value_cannot_be_more_than_2_147_483_647:
    'Значение не может быть больше 2 147 483 647',
  the_value_cannot_be_more_than_2_147_483:
    'Значение не может быть больше 2 147 483',
  'the_value_cannot_be_less_than_-2_147_483':
    'Значение не может быть меньше -2 147 483',
  the_end_value_must_be_greater_than_or_equal_to_the_start_value:
    'Конечное значение должно быть больше или равно начальному',
  email_length_must_be_no_more_than_255_characters:
    'Длина емайл должна быть не более 255 символов',
  the_value_must_be_at_least_1: 'Значение должно быть не менее 1',
  the_value_must_be_at_least_2147483647:
    'Значение должно быть не менее -2147483647',
  the_value_must_be_no_more_than_2147483647:
    'Значение должно быть не более 2147483647',
  the_name_must_be_no_more_than_128_characters:
    'Имя должно быть не более 128 символов',
  value_of_the_field_must_be_no_more_than_128_characters:
    'Значение поля должно быть не более 128 символов',
  the_value_must_be_no_more_than: 'Значение должно быть не более',
  category_name_must_be_from_3_to_255:
    'Длина имени категории должна быть от 3 символов до 255',
  category_name_wrong_symbols:
    'Имя категории должно включать в себя только следующие символы: буквы, цифры, точки, запятые, пробел и тире',
  event_group_name_must_be_from_3_to_60:
    'Длина имени группы событий должна быть от 3 символов до 60',
  event_group_name_wrong_symbols:
    'Имя группы событий должно включать в себя только следующие символы: буквы, цифры, нижнее подчеркивание, пробел и тире',
  goal_group_name_must_be_from_3_to_60:
    'Длина имени группы целей должна быть от 3 символов до 60',
  goal_group_key_must_be_from_1_to_60:
    'Длина ключа группы целей должна быть от 1 символов до 60',
  field_must_be_from_1_to_60: 'Длина поля должна быть от 1 символов до 60',
  goal_group_name_wrong_symbols:
    'Имя группы целей должно включать в себя только следующие символы: буквы, цифры, нижнее подчеркивание, пробел и тире',
  goal_group_key_wrong_symbols:
    'Ключ группы целей должно включать в себя только следующие символы: латинские буквы, цифры, нижнее подчеркивание и тире',
  field_value_wrong_symbols:
    'Значение поля должно включать в себя только следующие символы: буквы, цифры, нижнее подчеркивание и тире',
  ips_required: 'Необходимо заполнить список IP-адресов',
  ips_wrong: 'Некоторые IP-адреса некорректны',
  ips_limit: 'Вы достигли лимита в 100 IP-адресов',
  type_string: 'Значение поля должно быть строкой',
  type_number: 'Значение поля должно быть числом',
  the_field_value_must_be_greater_than_zero:
    'Значение поля должно быть больше 0',
  the_field_value_must_be_greater_than_or_equal_to_zero:
    'Значение поля должно быть больше или равно 0',
  'The comment must be a string.': 'Комментарий должен быть строкой.',
  'The comment must be at least 1 characters.':
    'Комментарий должен быть хотя бы одним символом.',
  'The comment may not be greater than 255 characters.':
    'Комментарий может быть не более 255 символов.',
  field_length_must_be_at_least_1_character:
    'Длина поля не должна быть меньше 1 символа',
  field_length_must_be_at_least_1_characters:
    'Длина поля не должна быть меньше 1 символа',
  field_length_must_be_at_least_3_characters:
    'Длина поля не должна быть меньше 3 символов',
  field_length_must_be_no_more_than_60_characters:
    'Длина поля должна быть не более 60 символов',
  field_length_must_be_from_1_to_30: 'Длина поля должна быть от 1 до 30',
  field_length_must_be_from_3_to_30: 'Длина поля должна быть от 3 до 30',
  field_length_must_be_from_8_to_255: 'Длина поля должна быть от 8 до 255',
  field_length_must_be_36_characters: 'Длина поля должна равняться 36 символам',
  formula_coefficient_should_be_used:
    'Коэффициент не используется в формуле. Удалите коэффициент перед сохранением.',
  'Goal have SUM type':
    'Невозможно сохранить формулу. Одна или несколько целей в формуле имеют модель подсчета Сумма.',
  'Unable to delete goal. It is used by expression.':
    'Невозможно удалить цель. Цель участвует в одной или нескольких формулах.',
  the_partner: 'У партнера',
  has_an_individual_condition_with_the_country:
    'уже есть индивидуальное условие для страны',
  has_an_individual_condition_with_the_countries:
    'уже есть индивидуальное условие для стран',
  the_link_does_not_contain_a_protocol: 'Ссылка не содержит протокол',
  the_link_contains_only_domain: 'Укажите только домен',
  choose_options: 'Выберите опции:',
  field_length_from_2_to_30_characters: 'Длина поля от 2 до 30 символов',
  field_length_from_2_to_50_characters: 'Длина поля от 2 до 50 символов',
  contact_name_wrong_symbols:
    'Имя контакта должно включать в себя только буквы, цифры и символы: точка, запятая, пробел, тире и нижнее подчеркивание',
  tag_name_wrong_symbols:
    'Название тега может включать в себя только буквы, цифры и символы: точка, запятая, пробел, тире, нижнее подчеркивание, @, круглые скобки и /',
  field_has_been_removed:
    'Поле было удалено. Обновите страницу, чтобы получить актуальный список полей.',
  the_amount_for_non_monetary_events_is_not_available:
    'Сумма для неденежных событий не доступна.',
  the_number_must_be_positive: 'Число должно быть положительным',
  you_must_choose_at_least_one_goal: 'Вы должны выбрать хотя бы одну цель',
  the_conversion_name_should_contains:
    'Название конверсии должно содержать только латинские буквы, дефис и нижнее подчеркивание.',
  general_settings_name_wrong_symbols:
    'Название может включать в себя только буквы, цифры и символы: точка, тире, пробел и нижнее подчеркивание',
  general_settings_email_wrong_symbols:
    'Адрес электронной почты может включать в себя только латинские буквы, цифры и символы: точки, тире, нижнее подчеркивание и @',
  image_wrong_dimensions: 'Неправильные размеры изображения',
  file_wrong_size: 'Неправильный размер файла',
  goal_the_value_from_wrong_symbols:
    'Значение должно быть числом или символами: "@" или "-@".',
  goal_the_value_to_wrong_symbols:
    'Значение должно быть числом или символом "@"',
  'The key should contains only latin characters.':
    'Ключ должен содержать только латинские символы.',
  maximum_length_3_characters: 'Максимальная длина 3 символа',
  maximum_length_10_characters: 'Максимальная длина 10 символов',
  maximum_length_100_characters: 'Максимальная длина 100 символов',
  maximum_length_255_characters: 'Максимальная длина 255 символов',
  maximum_length_1000_characters: 'Максимальная длина 1000 символов',
  maximum_length_2000_characters: 'Максимальная длина 2000 символов',
  maximum_length_13_characters: 'Максимальная длина 13 символов',
  postback_limit: 'Достигнут лимит в 5 постбеков для этого типа',
  'GRPC response error: Postback type limit exceeded':
    'Достигнут лимит в 5 постбеков для этого типа',
  "Can't update condition count if postbacks are taken":
    'Невозможно обновить количество условий, если есть принятые постбеки',
  "Can't update goals if they used in postbacks":
    'Невозможно изменить цели, если они использованы в постбеках',
  formula_error: 'Ошибка в формуле',
  only_latin_characters: 'Поле должно содержать только латинские буквы.',
  only_latin_characters_numbers_dash_and_underscore:
    'Поле должно содержать только латинские буквы, цифры, дефис и нижнее подчеркивание.',
  "Can't delete resource": 'Невозможно удалить запись',
  'The field is required.': '$t(validations:required)',
  currency_with_existing_code: 'Валюта с таким кодом уже существует',
  insufficient_rights_to_perform_the_action:
    'Недостаточно прав для выполнения действия',
  allowed_formats_png_jpeg_jpg: 'Разрешенные форматы: .png, .jpeg, .jpg',
  'Incorrect wallet for the specified currency code':
    'Номер кошелька не соответствует выбранной валюте',
  "Can't update currency, it use in conditions":
    'Невозможно изменить запись, валюта используется в условиях',
  single_currency: 'Нельзя отключить единственную валюту',
  select_currency: 'Выберите валюту',
  invoice_generation_period_required:
    'Не выбран ни один период для автогенерации.',
  invoice_generation_different_days: 'Выберите два разных дня',
  select_conversion_currency: 'Не указана валюта. Пожалуйста, укажите валюту.',
  condition_sub_id_values_length:
    'Длина каждого значения поля не должна превышать 60 символов',
  field_length_must_be_from_1_to_250:
    'Длина поля должна быть от 1 до 250 символов',
  offer_postback_status_required: 'Выберите минимум 1 статус конверсии',
  only_latin_cyrillic_letters_are_allowed:
    'Разрешены только латинские и кириллические буквы, цифры и символы',
  img_wrong_format:
    'Неправильный формат изображения. $t(validations:allowed_formats_png_jpeg_jpg)',
  img_wrong_format_gif: '$t(validations:img_wrong_format), .gif',
  img_wrong_format_ico: '$t(validations:img_wrong_format), .ico',
  img_max_2_mb:
    '$t(validations:file_wrong_size). Допустимы изображения до 2 МБ.',
  output_format_value_required:
    'Переменная "Значение" обязательна в формате вывода',
  'Unable to delete group with events': 'Невозможно удалить группу с событиями',
  'Unable to delete group with goals': 'Невозможно удалить группу с целями',
  custom_column_template_default_currency:
    'Значение и код в валюте по умолчанию доступны, только если указана Валюта или Знак валюты',
  period_more_than_90_days: 'Выбран временной интервал больше 90 дней',
  period_more_than_180_days: 'Выбран временной интервал больше 180 дней',
  period_more_than_270_days: 'Выбран временной интервал больше 270 дней',
  'The partner is already has access to offer.':
    'У партнёра уже есть доступ к этому офферу',
  'Promocode is already assigned.': 'Промокод уже занят',
  'The promocodes must have at least 1 items.':
    'В файле должна быть хотя бы одна запись промокода',
  'The promocodes may not have more than 1000 items.':
    'В файле может быть максимум 1000 записей промокодов',
  'The promocode field is required.': 'Отсутствует промокод',
  'The date_start field is required.':
    'Значение date_start обязательно для заполнения',
  'The promocode must be a string.': 'Значение promocode должно быть строкой',
  'The promocode must be at least 3 characters.':
    'Значение promocode должно состоять как минимум из 3 символов',
  'The promocode may not be greater than 30 characters.':
    'Значение promocode не может быть больше 30 символов',
  'The promocode format is invalid.': 'Неправильный формат значения promocode',
  'The date_start is not a valid date.': 'Неправильный формат даты date_start',
  'The date_finish is not a valid date.':
    'Неправильный формат даты date_finish',
  'The date_finish must be a date after or equal to date_start.':
    'date_finish должен быть больше или равен date_start',
  'The limit must be an integer.': 'Значение limit должно быть числом',
  'The limit must be at least 1.':
    'Значение limit должно быть больше или равно 1',
  'The limit must be at least 0.':
    'Значение limit должно быть больше или равно 0',
  'The limit may not be greater than 2147483.':
    'Значение limit не может быть больше 2147483',
  'The selected headers.0 is invalid.': 'Неправильный заголовок колонки #1',
  'The selected headers.1 is invalid.': 'Неправильный заголовок колонки #2',
  'The selected headers.2 is invalid.': 'Неправильный заголовок колонки #3',
  'The selected headers.3 is invalid.': 'Неправильный заголовок колонки #4',
  'The selected headers.4 is invalid.': 'Неправильный заголовок колонки #5',
  'The selected headers.5 is invalid.': 'Неправильный заголовок колонки #6',
  'The selected headers.6 is invalid.': 'Неправильный заголовок колонки #7',
  'The selected headers.7 is invalid.': 'Неправильный заголовок колонки #8',
  'The selected headers.8 is invalid.': 'Неправильный заголовок колонки #9',
  'The header is invalid.': 'Неправильный заголовок.',
  'The data has incorrect delimiters.':
    'В файле используются неправильные разделители',
  'The conversion row must contain 9 items.':
    'Строка конверсии должна содержать 9 колонок',
  field_is_required: 'Значение обязательно для заполнения',
  field_must_be_string: 'Значение должно быть строкой',
  field_must_be_number: 'Значение должно быть числом.',
  field_must_be_uuid: 'Значение должно быть правильным UUID.',
  'The click_id field is required.': '$t(validations:field_is_required)',
  'The click_id must be a string.': '$t(validations:field_must_be_string)',
  'The click_id must be a valid UUID.': '$t(validations:field_must_be_uuid)',
  'The tid field is required.': '$t(validations:field_is_required)',
  'The tid must be a string.': '$t(validations:field_must_be_string)',
  'The tid may not be greater than 60 characters.':
    'Значение не должно быть длиннее 60 символов',
  'The status must be a string.': '$t(validations:field_must_be_string)',
  'The income must be a number.': '$t(validations:field_must_be_number)',
  'The payout must be a number.': '$t(validations:field_must_be_number)',
  'The income field is required when income_currency is present.':
    'Значение income обязательно, если заполнено income_currency.',
  'The income must be at least -2147483.':
    '$t(validations:the_value_cannot_be_less_than_-2_147_483).',
  'The income may not be greater than 2147483.':
    '$t(validations:the_value_cannot_be_more_than_2_147_483).',
  'The income_currency must be a string.':
    '$t(validations:field_must_be_string)',
  'The income_currency field is required when income is present.':
    'Значение income_currency обязательно, если заполнено income.',
  'The payout field is required when payout_currency is present.':
    'Значение payout обязательно, если заполнено payout_currency.',
  'The payout must be at least -2147483.':
    '$t(validations:the_value_cannot_be_less_than_-2_147_483).',
  'The payout may not be greater than 2147483.':
    '$t(validations:the_value_cannot_be_more_than_2_147_483).',
  'The payout_currency must be a string.':
    '$t(validations:field_must_be_string)',
  'The payout_currency field is required when payout is present.':
    'Значение payout_currency обязательно, если заполнено payout.',
  'The comment_to_partner must be a string.':
    '$t(validations:field_must_be_string)',
  'The comment_to_partner may not be greater than 1000 characters.':
    '$t(validations:maximum_length_1000_characters)',
  'Unknown status': 'Неизвестный статус',
  'The currency is not activated.': 'Эта валюта не активирована.',
  'The file must be a file of type: csv': 'Файл должен иметь тип: csv',
  'The data has incorrect csv delimiters.':
    'В файле используется неправильный разделитель csv',
  'The file is empty.': 'Файл пуст',
  'The promocode has another promocode type.':
    'Тип промокода не может быть изменён',
  'The promocode is personal.': 'Этот промокод является персональным',
  'The admin panel url format is invalid.':
    'Формат URL панели администратора неправильный',
  'The partner panel url format is invalid.':
    'Формат URL панели партнера неправильный',
  'The advertiser panel url format is invalid.':
    'Формат URL панели рекламодателя неправильный',
  'The field must be at least 2 characters.':
    'Длина поля должна быть как минимум 2 символа.',
  'The field may not be greater than 2000 characters.':
    'Длина поля не должна быть больше 2000 символов.',
  choose_reasons: 'Необходимо выбрать причины для скрытия',
  'The old domain format is invalid.':
    'Введите домен в таком формате https/http/example.com->example.com',
  'The domain format is invalid.':
    'Введите домен в таком формате https/http/example.com->example.com',
  'You have reached the limit of 3 private dashboards.':
    'Вы достигли лимита в 3 приватных дашборда.',
  'Dashboard set for a partner cannot be private.':
    'Партнерский дашборд не может быть приватным.',
  'The conversions must have at least 1 items.':
    'В файле должна быть хотя бы одна запись',
  'The conversions may not have more than 1000 items.':
    'В файле не может быть больше 1000 записей.',
  'The selected partner_ids is invalid.': 'Выбран неактивный партнер',
  'Invalid two factor code': 'Неверный код',
  'Invalid two factor GA code': 'Неверный код',
  'The selected filter.goal key is invalid.': 'Неверный ключ цели',
  image_wrong_type: 'Неправильный тип изображения',
  color_near_error: 'Цвет не может совпадать с цветом соседнего сектора',
  customers_statistics_goal_group_required: `Обязательно к заполнению, если указано поле "$t(statistics:customers_cohort_period)"`,
  customers_statistics_cohort_period_goal_group_required: `Необходимо выбрать группу целей`,
  gtm_prefix: 'Google Tag Manager ID должен начинаться с "GTM-"',
  product_formula_error:
    'В формуле не допускается указывать слагаемые в виде обычных чисел, переменных коэффициентов или произведения целей продукта. Это связано с особенностями вычисления числовых последовательностей и расчета формулы в случае изменения условий выплат и переменных коэффициентов.',
};
