import Profile from './Profile';
import Offer from './Offer';
import TrackingLink from './TrackingLink';
import Wallet from './Wallet';
import Withdrawal from './Withdrawal';
import Statistics from './Statistics';
import Dict from './Dict';
import Product from './Product';
import Advertiser from './Advertiser';
import Contact from './Contact';
import Tag from './Tag';
import Settings from './Settings';
import Goal from './Goal';
import Currency from './Currency';
import Referrals from './Referrals';
import PaymentSystem from './PaymentSystem';
import PromoMaterials from './PromoMaterials';
import Exports from './Exports';
import Documents from './Documents';
import PromoCode from './PromoCode';
import PaymentThreshold from './PaymentThreshold';
import Invoice from './Invoice';
import Postback from './Postback';
import GoalGroup from './GoalGroup';
import News from './News';
import Log from './Log';
import Dashboard from './Dashboard';
import Landing from './Landing';
import TwoFactorAuth from './TwoFactorAuth';

const Api = {
  Offer,
  TrackingLink,
  Profile,
  Wallet,
  Withdrawal,
  Statistics,
  Dict,
  Product,
  Advertiser,
  Contact,
  Tag,
  Settings,
  Goal,
  Currency,
  Referrals,
  PaymentSystem,
  PromoMaterials,
  Exports,
  Documents,
  PromoCode,
  PaymentThreshold,
  Invoice,
  Postback,
  GoalGroup,
  News,
  Log,
  Dashboard,
  Landing,
  TwoFactorAuth,
};

export default Api;
