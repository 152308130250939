export default {
  add_tag: 'Añadir una etiqueta',
  add_offer_tag: 'Añadir una etiqueta de oferta',
  add_partner_tag: 'Añadir una etiqueta de socio',
  no_tags: 'No se han creado etiquetas',
  tag_remove_confirm: '¿Quieres quitar la etiqueta?',
  tag_successfully_created: 'La etiqueta se ha añadido correctamente',
  tag_successfully_updated: 'La etiqueta se ha actualizado correctamente',
  tag_adding: 'Añadir una etiqueta',
  tag_editing: 'Editar una etiqueta',
  tag_name: 'Nombre de la etiqueta',
  tag_name_placeholder: 'Introduzca el nombre de la etiqueta',
  click_to_remove_tag: 'Haga clic para eliminar la etiqueta',
  offer_tags: 'Etiquetas de la oferta',
  partner_tags: 'Etiquetas de socios',
};
