/**
 * Contains keys of the types of the statistics pages
 * to restrict parameters sent in requests
 */

import map from 'lodash/map';
import isString from 'lodash/isString';
import { defaultTableFilterKeysWithSort } from 'utils/defaultTableFilter';
import { QueryMapping, QueryMappingKey } from 'models/IDataProvider';
import { getFieldOrValue } from 'utils/commonUtils';
import filter from 'lodash/filter';

export const subIdsKeys: QueryMapping = Object.freeze([
  'sub_id1',
  'sub_id2',
  'sub_id3',
  'sub_id4',
  'sub_id5',
  'sub_id6',
  'sub_id7',
  'sub_id8',
  'sub_id9',
  'sub_id10',
]);

export const customFieldsKeys: QueryMapping = Object.freeze([
  'custom1',
  'custom2',
  'custom3',
  'custom4',
  'custom5',
]);

export const customFieldsRequestModelKeys: QueryMapping = Object.freeze([
  'custom_field1',
  'custom_field2',
  'custom_field3',
  'custom_field4',
  'custom_field5',
]);

export const baseFilterKeys: QueryMapping = Object.freeze([
  { key: 'date_from', required: true },
  { key: 'date_to', required: true },
  'offer_id',
  'excluded_offer_id',
  'excluded_offer_ids',
  'product_id',
  'excluded_product_id',
  'excluded_product_ids',
  'partner_ids',
  'excluded_partner_ids',
  'admin_ids',
  'advertiser_id',
  'country',
  'timezone',
  'excluded_tags',
]);

export const baseMultipleFilterKeys: QueryMapping = Object.freeze([
  { key: 'date_from', required: true },
  { key: 'date_to', required: true },
  'offer_ids',
  'product_ids',
  'partner_ids',
  'landing_ids',
  'admin_ids',
  'advertiser_ids',
  'countries',
  'country_codes',
  'city_ids',
  'timezone',
  'tags',
]);

export const baseExcludedMultipleFilterKeys: QueryMapping = Object.freeze([
  'excluded_offer_ids',
  'excluded_product_ids',
  'excluded_partner_ids',
  'excluded_tags',
]);

function addPrefix(prefix: string, array: QueryMapping): QueryMappingKey[] {
  return map(array, (s) =>
    isString(s)
      ? `${prefix}.${s}`
      : {
          ...s,
          key: `${prefix}.${s.key}`,
        },
  );
}

export const baseFilterQueryKeys: QueryMapping = Object.freeze([
  ...addPrefix('filter', baseFilterKeys),
  ...addPrefix('filter', subIdsKeys),
  'tags',
  'excluded_tags',
  { key: 'currency_code', required: true },
]);

export const baseMultipleFilterQueryKeys: QueryMapping = Object.freeze([
  ...addPrefix('filter', baseMultipleFilterKeys),
  ...addPrefix('filter', baseExcludedMultipleFilterKeys),
  ...addPrefix('filter', subIdsKeys),
  { key: 'currency_code', required: true },
]);

export const commonFilterKeys: QueryMapping = Object.freeze([
  ...baseMultipleFilterQueryKeys,
  ...defaultTableFilterKeysWithSort,
  ...addPrefix('filter', subIdsKeys),
  ...addPrefix('filter', [
    'goal_keys',
    'event_keys',
    'partner_tags',
    'excluded_partner_tags',
    'traffic_source',
    'link_sources',
    'promocode',
    'oses',
    'device_types',
  ]),
  'group_by',
  'include',
  'resale_type',
  'cached',
]);

export const cohortAnalysisFilterKeys: QueryMapping = Object.freeze([
  ...addPrefix(
    'filter',
    filter(baseMultipleFilterKeys, (k) => k !== 'advertiser_ids'),
  ),
  ...addPrefix(
    'filter',
    filter(baseExcludedMultipleFilterKeys, (k) => k !== 'excluded_product_ids'),
  ),
  ...addPrefix('filter', subIdsKeys),
  ...addPrefix('filter', customFieldsRequestModelKeys),
  ...addPrefix('filter', [
    'product_id',
    'excluded_product_id',
    'cohort_date_from',
    'cohort_date_to',
    'cohort_type',
    'goal_key',
    'event_key',
    'partner_tags',
    'excluded_partner_tags',
    'traffic_sources',
    'link_sources',
    'promocode',
    { key: 'currency_code', required: true },
  ]),
  ...defaultTableFilterKeysWithSort,
  'include',
  'group_by',
]);

export const customersFilterKeys: QueryMapping = Object.freeze([
  ...addPrefix(
    'filter',
    filter(baseMultipleFilterKeys, (k) => {
      const key: string = getFieldOrValue('key')(k);
      return !['landing_ids', 'advertiser_ids', 'countries', 'cities'].includes(
        key,
      );
    }),
  ),
  ...addPrefix('filter', baseExcludedMultipleFilterKeys),
  ...addPrefix('filter', subIdsKeys),
  ...addPrefix('filter', customFieldsRequestModelKeys),
  ...addPrefix('filter', [
    'customer_id',
    'goal_group_id',
    'cohort_date_from',
    'cohort_date_to',
    'partner_tags',
    'excluded_partner_tags',
    'country_codes',
  ]),
  { key: 'currency_code', required: true },
  ...defaultTableFilterKeysWithSort,
]);

export const eventsFilterKeys: QueryMapping = Object.freeze([
  ...baseFilterQueryKeys,
  ...defaultTableFilterKeysWithSort,
  ...addPrefix('filter', ['click_id', 'event_key']),
]);

export const partnerEventsFilterKeys: QueryMapping = Object.freeze([
  ...baseFilterQueryKeys.filter(
    (key) => getFieldOrValue('key')(key) !== 'currency_code',
  ),
  ...defaultTableFilterKeysWithSort,
  ...addPrefix('filter', ['click_id', 'event_key']),
]);

export const conversionsFilterKeys: QueryMapping = Object.freeze([
  ...baseMultipleFilterQueryKeys,
  ...defaultTableFilterKeysWithSort,
  ...addPrefix('filter', customFieldsKeys),
  ...addPrefix('filter', [
    'click_id',
    'id',
    'statuses',
    'status_descriptions',
    'tid',
    'oses',
    'os_versions',
    'browsers',
    'fraud_risk_levels',
    'device_types',
    'goal_key',
    'promocode',
    'promocode_user_id',
    'amount',
    'payment_models',
    'invoice_statuses',
  ]),
]);

export const conversionsExportFilterKeys: QueryMapping = Object.freeze([
  ...filter(conversionsFilterKeys, (k) => k !== 'page' && k !== 'per_page'),
  ...addPrefix('filter', ['extension']),
]);

export const eventsExportFilterKeys: QueryMapping = Object.freeze([
  ...filter(eventsFilterKeys, (k) => k !== 'page' && k !== 'per_page'),
  ...addPrefix('filter', ['extension']),
]);
