import React from 'react';
import cn from 'classnames';
import { Variant } from 'react-bootstrap/esm/types';

import BrandLogo from 'core/components/BrandLogo';

interface ILoadingProps {
  translucent?: boolean;
  withoutLogo?: boolean;
  fullScreen?: boolean;
  variant?: Variant | 'currentColor';
}

const Loading = ({
  translucent,
  withoutLogo = false,
  fullScreen,
  variant = 'muted',
}: ILoadingProps): JSX.Element => {
  return (
    <div
      className={cn('loading', {
        'loading--translucent': translucent,
        'loading--full-screen': fullScreen,
      })}
    >
      {!withoutLogo && <BrandLogo maxWidth="350px" maxHeight="120px" />}
      <div className={cn('spinner', withoutLogo && 'sm')}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Loading;
