import 'scheduler-polyfill';

type Task<Value = unknown> = () => Value;

class SchedulerApi {
  public visibleTask<V = unknown>(task: Task<V>): Promise<ReturnType<Task<V>>> {
    return scheduler.postTask(task, {
      priority: 'user-visible',
    });
  }

  public blockingTask<V = unknown>(
    task: Task<V>,
  ): Promise<ReturnType<Task<V>>> {
    return scheduler.postTask(task, {
      priority: 'user-blocking',
    });
  }

  public backgroundTask<V = unknown>(
    task: Task<V>,
  ): Promise<ReturnType<Task<V>>> {
    return scheduler.postTask(task, {
      priority: 'background',
    });
  }

  public yield(): Promise<ReturnType<Task>> {
    return scheduler.yield();
  }
}

const schedulerApi = new SchedulerApi();
export default schedulerApi;
