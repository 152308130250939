export default {
  'name of event': 'Nombre del evento',
  'create event': 'Crear un evento',
  'edit event': 'Editar evento',
  id: 'ID',
  key: 'Clave',
  monetary: 'Efectivo',
  monetary_event_hint:
    'Si el evento implica trabajar con dinero u otras cantidades, debes activar este modo.\nEn caso contrario, no podrá aceptar valores de eventos.\nEjemplos de hechos monetarios: compra, ingreso, retirada, etc.\nEjemplos de un evento no monetario: registro, pasar un nivel en el juego, atraer a un usuario, etc.\nNo se puede cambiar la apariencia de un evento una vez creado.',
  'no events list': 'No hay lista de actos',
  'from 3 to 30 characters': 'De 3 a 30 caracteres',
  'event successfully created': 'Evento creado con éxito',
  'event successfully deleted': 'Evento eliminado con éxito',
  'event successfully updated': 'Evento actualizado correctamente',
  'Are you sure you want to delete the event': 'Desea eliminar el evento?',
  event_cannot_be_deleted:
    'El evento no puede borrarse porque se utiliza para fines del producto.',
  event_key_help:
    'El identificador del evento que vendrá en el postback en el parámetro {event}. El valor se pondrá en minúsculas.',
  enter_to_search_or_add: 'Entrar para buscar o añadir...',
  group: 'Grupo',
};
