import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Goal = {
  GET_GOALS: {
    url: generateAdminApiUrl(':id/goals', ModelPath.Product),
    method: 'GET',
  },
  CREATE_GOAL: {
    url: generateAdminApiUrl(':id/goals', ModelPath.Product),
    method: 'POST',
  },
  UPDATE_GOAL: {
    url: generateAdminApiUrl(':id', ModelPath.Goal),
    method: 'PATCH',
  },
  GET_GOAL_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Goal),
    method: 'GET',
  },
  DELETE_GOAL_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Goal),
    method: 'DELETE',
  },
} as const;

export default Goal;
