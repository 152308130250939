import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Exports = {
  GET_EXPORTS: {
    url: generateAdminApiUrl(ModelPath.Exports),
    method: 'GET',
  },
} as const;

export default Exports;
