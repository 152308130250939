export default {
  terms_of_use: 'Условия использования',
  privacy_policy: 'Политика конфиденциальности',
  privacy_policy_accusative_case: 'Политику конфиденциальности',
  legal_documents_agreement: 'Согласие с правовыми документами',
  legal_documents_agreement_text: 'Внесены изменения в ',
  legal_documents_agreement_text_accept:
    'Для продолжения работы с системой требуется ознакомиться с новой версией документа и подтвердить своё согласие.',
  legal_documents_agreement_text_accept_plural:
    'Для продолжения работы с системой требуется ознакомиться с новой версией документов и подтвердить своё согласие.',
  legal_documents_agreement_agree: 'Я принимаю',
};
