export default {
  payments: 'Payments',
  email: 'Email',
  amount: 'Amount',
  payment_system: 'Payment system',
  wallet: 'Wallet',
  creation_date: 'Date',
  status: 'Status',
  approve: 'Approve',
  pay: 'Pay',
  reject: 'Reject',
  paid_in_part: 'Paid in part',
  corrections_one: 'correction',
  corrections_other: 'corrections',
  paid: 'Paid',
  details: 'Details',
  revshare: 'Revshare',
  payment_status_successfully_updated:
    'Status of the payment has updated successfully',
  payment_successfully_deleted: 'Payment successfully deleted',
  payment_successfully_rejected: 'Payment successfully rejected',
  comment: 'Comment',
  internal_note: 'Internal note',
  comment_for_partner: 'Comment for partner',
  write_your_comment_for_partner: 'Write your comment for partner',
  no_payments: 'No payments',
  partner_id: 'Partner ID',
  partner: 'Partner',
  currency_any: 'Any',
  payment_systems: 'Payment systems',
  payment_system_name: 'Payment system name',
  add_payment_system: 'Add payment system',
  no_payment_systems: 'No payment systems',
  payment_system_editing: 'Editing the payment system',
  payment_system_adding: 'Addition a payment system',
  payment_system_add_wallet_field: 'Add field',
  payment_system_wallet_fields: 'Fields of a wallet',
  payment_system_wallet_field_name: 'Name',
  payment_system_wallet_field_required: 'Required field',
  payment_system_successfully_created: 'Payment system successfully added',
  payment_system_successfully_updated: 'Payment system successfully updated',
  payment_system_remove_confirm:
    'Do you want to delete the payment system "{{name}}"?',
  generating_now:
    'The invoices process is in progress. This may take some time.',
  tab_pending: 'Pending',
  tab_approved: 'Approved',
  tab_payed: 'Paid',
  tab_rejected: 'Rejected',
  generate: 'Generate',
  generate_new_invoices: 'Generate new invoices',
  generate_invoices: 'Generate invoices',
  invoice: 'Invoice',
  wallet_details: 'Details',
  offer_conversions: 'Conversions',
  offer_operation: 'Operation',
  offer_description: 'Description',
  offer_conversions_amount: 'Sum',
  correction: 'Correction',
  correction_help:
    'Correction allows you to decrease or increase the invoice amount. When you add a correction, you specify the amount by which you want to change the invoice. The correction amount can be specified with a plus or minus sign. If the amount is specified with a plus sign (will not be displayed), then the total invoice amount will increase. If the amount is specified with a minus sign, then the total amount of the invoice will decrease. You can also add a comment for correction.',
  total_conversions: 'Total for CPA conversions',
  total_corrections: 'Total corrections',
  total_referral_balance: 'Referral balance',
  total_revshare_balance: 'Revshare balance',
  total_cpm_balance: 'Total for CPM conversions',
  total: 'Total',
  prepayment: 'Prepayment',
  conversions_date_offer: 'Date & Offer',
  conversions_conversion: 'Conversion',
  conversions_payment: 'Payment',
  threshold: 'Threshold',
  generate_form_success:
    'Invoices are being generated and will be displayed on the Pending tab. Refresh the page for data.',
  generate_form_partner_placeholder: 'Input partner id or email',
  generate_form_offer_placeholder: 'Input offer id or name',
  generate_form_product_placeholder: 'Input product id or name',
  generate_form_threshold_help:
    'If checked, an invoice will be generated if the total amount is greater than the threshold. Otherwise, the invoice can be generated with any total amount, even negative.',
  generate_form_use_threshold: 'Use Threshold',
  invoice_to_approved: 'Approve',
  invoice_to_payed: 'Pay in full',
  invoice_to_rejected: 'Reject',
  invoice_to_pending: 'Pending',
  invoice_to_cancel: 'Cancel',
  invoice_to_end_status_confirm:
    'Do you confirm the change of status? The action cannot be undone.',
  add_internal_note: 'Add internal note',
  add_comment_for_partner: 'Add comment for partner',
  make_prepayment: 'Make prepayment',
  prepay: 'Prepay',
  available: 'Available',
  change: 'Change',
  current_value: 'Current value',
  add_correction: 'Add correction',
  description_optional: 'Description (optional)',
  conversion_to_reject: 'Reject',
  undated: 'Undated',
  undated_help:
    'If checked, all unpaid conversions up to the date specified in the right field will be included in the invoice.',
  no_withdrawals_with_selected_params:
    'You had no withdrawals with selected parameters',
  invoice_error: 'An error occurred while trying to get the invoice',
  invoice_delete_confirm:
    'Do you confirm invoice deletion? The action cannot be undone',
  invoice_delete_button_tooltip: 'invoice must be rejected',
  period: 'Period',
};
