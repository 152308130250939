export default {
  wallet: 'Финансы',
  history: 'История',
  no_withdrawals: 'Нет выводов',
  table_withdrawals_id: 'ID',
  table_withdrawals_date: 'Дата',
  table_withdrawals_id_date: 'ID & Дата',
  table_withdrawals_system: 'Платежная система',
  table_withdrawals_wallet: 'Кошелек',
  table_withdrawals_amount: 'Сумма',
  table_withdrawals_status: 'Статус',
  table_withdrawals_comment: 'Комментарий',
  status_pending: 'В ожидании',
  status_rejected: 'Отклонено',
  status_approved: 'Одобрено',
  status_canceled: 'Отменено',
  status_payed: 'Выплачено',
  money_hold: 'Средства на холде',
  free_for_withdrawal: 'Доступно для вывода',
  withdrawal_cancel_confirm: 'Вы хотите отменить вывод средств?',
  withdrawal_successfully_created:
    'Запрос успешно добавлен. Данные в таблице могут появиться с небольшой задержкой.',
  withdrawal: 'Вывод',
  withdrawals: 'Выводы средств',
  to_withdrawal: 'Вывести',
  payment_method: 'Способ оплаты',
  payment_methods: 'Способы оплаты',
  add_wallet: 'Добавить кошелек',
  edit_wallet: 'Редактировать кошелек',
  wallet_number: 'Номер кошелька',
  webmoney_wallet_number: 'Номер кошелька Webmoney',
  webmoney_wallet_number_start_symbol:
    'Номер кошелька Webmoney должен начинаться с буквы',
  webmoney_wallet_number_number_count:
    'Номер кошелька Webmoney должен содержать двенадцать цифр',
  webmoney_wallet_number_error:
    'Номер кошелька Webmoney должен содержать только цифры и букву в начале',
  wallet_successfully_created: 'Кошелек успешно добавлен',
  wallet_successfully_edited: 'Кошелек успешно изменен',
  wallet_successfully_deleted: 'Кошелек успешно удален',
  wallet_remove_confirm: 'Вы хотите удалить этот кошелек?',
  no_wallets: 'Нет кошельков.',
  please_create_wallet: 'Пожалуйста, создаёте первый кошелек.',
  main_balance: 'Основной баланс',
  hold: 'Баланс холда',
  referral_balance: 'Реферальный баланс',
  referral_hold_balance: 'Холд реф.баланс',
  referral_hold_balance_help:
    'Реферальный баланс по невыплаченным конверсиям. Он станет доступен для вывода, когда будут проверены и оплачены соответствующие конверсии.',
  revshare_balance: 'Баланс ревшары',
  revshare_hold_balance: 'Баланс Холд ревшары',
  revshare_balance_help: 'Баланс по конверсиям с моделью оплаты Ревшара',
  payment_system: 'Система оплаты',
  available_for_withdrawal: 'Доступно',
  table_history_empty: 'История пуста, у вас нет денежных выплат',
  cancel_withdrawal: 'Отменить вывод?',
  cancel_withdrawal_confirm: 'Вы уверены, что хотите отменить вывод средств?',
  remove_wallet: 'Удалить метод вывода?',
  remove_wallet_confirm: 'Вы уверены, что хотите удалить метод вывода?',
  cancel_withdrawal_request: 'Отменить вывод',
  cannot_cancel_withdrawal:
    'Одобренные заявки нельзя отменить. Скоро она будет выплачена.',
  set_default_method: 'Установить как метод по умолчанию',
  withdrawal_methods: 'Методы вывода',
  no_withdrawal_methods: 'Нет методов вывода',
  add_method: 'Добавить метод',
  available: 'Доступно',
  please_add_method: 'Пожалуйста, добавьте метод вывода',
  please_set_default_method:
    'Пожалуйста, установите один из своих методов вывода как метод по умолчанию',
  default_method: 'По умолчанию',
  default_method_help:
    'По умолчанию для вывода средств используется данный кошелек. Все средства, доступные для вывода, будут конвертированы в валюту данного кошелька.',
  min_withdraw_amount: 'Минимальная сумма для вывода средств',
};
