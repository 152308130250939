export default {
  add_tag: 'Add tag',
  add_offer_tag: 'Add offer tag',
  add_partner_tag: 'Add partner tag',
  no_tags: 'No tags',
  tag_remove_confirm: 'Do you want to delete the tag?',
  tag_successfully_created: 'Tag successfully added',
  tag_successfully_updated: 'Tag successfully updated',
  tag_adding: 'Adding tag',
  tag_editing: 'Editing tag',
  tag_name: 'Name of tag',
  tag_name_placeholder: 'Enter name of the tag',
  click_to_remove_tag: 'Click to remove the tag',
  offer_tags: 'Offer tags',
  partner_tags: 'Partner tags',
};
