import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Dict = {
  GET_COUNTRIES: {
    url: generatePartnerApiUrl(ModelPath.Country),
    method: 'GET',
  },
  GET_DICTIONARY: {
    url: generatePartnerApiUrl(ModelPath.Dictionary),
    method: 'GET',
  },
  GET_OS_TYPES: {
    url: generatePartnerApiUrl('os_types', ModelPath.General),
    method: 'GET',
  },
  GET_CITIES: {
    url: generatePartnerApiUrl('cities', ModelPath.General),
    method: 'GET',
  },
} as const;

export default Dict;
