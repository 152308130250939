import { generateAdminApiUrl } from 'utils/apiUtils';

const ReferralLink = {
  GET_LINK_BY_OFFER_ID: {
    url: generateAdminApiUrl('offers/:offer_id/reflink/:partner_id'),
    method: 'GET',
  },
} as const;

export default ReferralLink;
