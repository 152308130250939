import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Partner = {
  CREATE_PARTNER: {
    url: generateAdminApiUrl(ModelPath.Partner),
    method: 'POST',
    bodyMapping: [
      'email',
      {
        key: 'admin_ids',
        optional: true,
      },
      {
        key: 'profile_fields',
        optional: true,
      },
      {
        key: 'status',
        optional: true,
      },
      {
        key: 'referral_value',
        optional: true,
      },
      {
        key: 'tags',
        optional: true,
      },
      {
        key: 'password',
        optional: true,
      },
      {
        key: 'note',
        optional: true,
      },
    ],
  },
  GET_PARTNER: {
    url: generateAdminApiUrl(':id', ModelPath.Partner),
    method: 'GET',
  },
  UPDATE_PARTNER_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.Partner),
    method: 'PATCH',
    bodyMapping: [
      'email',
      {
        key: 'admin_ids',
        optional: true,
      },
      {
        key: 'profile_fields',
        optional: true,
      },
      {
        key: 'status',
        optional: true,
      },
      {
        key: 'referral_value',
        optional: true,
      },
      {
        key: 'tags',
        optional: true,
      },
      {
        key: 'password',
        optional: true,
      },
      {
        key: 'note',
        optional: true,
      },
    ],
  },
  GET_PARTNERS: {
    url: generateAdminApiUrl(ModelPath.Partner),
    method: 'GET',
    includes: ['balances'],
    queryMapping: [
      'filter',
      'sort',
      'include',
      { key: 'page', required: true },
      { key: 'per_page', required: true },
    ],
  },
  GET_ALL_PARTNERS: {
    url: generateAdminApiUrl('all', ModelPath.Partner),
    method: 'GET',
  },
  UPDATE_PARTNERS: {
    url: generateAdminApiUrl('batch', ModelPath.Partner),
    method: 'PATCH',
  },
  BAN_PARTNER: {
    url: generateAdminApiUrl(':id/ban', ModelPath.Partner),
    method: 'PATCH',
  },
  UNBAN_PARTNER: {
    url: generateAdminApiUrl(':id/unban', ModelPath.Partner),
    method: 'PATCH',
  },
  APPROVE_PARTNER: {
    url: generateAdminApiUrl(':id/approve', ModelPath.Partner),
    method: 'PATCH',
  },
  REJECT_PARTNER: {
    url: generateAdminApiUrl(':id/reject', ModelPath.Partner),
    method: 'PATCH',
  },
  RESEND_EMAIL: {
    url: generateAdminApiUrl(':id/resend-email', ModelPath.Partner),
    method: 'POST',
  },
  EXPORT: {
    url: generateAdminApiUrl('export', ModelPath.Partner),
    method: 'GET',
  },
  CREATE_WALLET: {
    url: generateAdminApiUrl(':id/wallets', ModelPath.Partner),
    method: 'POST',
    bodyMapping: [
      'payment_system_id',
      'currency_code',
      'field_values',
      {
        key: '2fa_code',
        optional: true,
      },
    ],
    twoFactorAuth: true,
  },
  EDIT_WALLET: {
    url: generateAdminApiUrl(':id/wallets/:walletId', ModelPath.Partner),
    method: 'PATCH',
  },
  DELETE_WALLET: {
    url: generateAdminApiUrl(':id/wallets/:walletId', ModelPath.Partner),
    method: 'DELETE',
    twoFactorAuth: true,
  },
  GET_WALLETS: {
    url: generateAdminApiUrl(':id/wallets', ModelPath.Partner),
    method: 'GET',
  },
  GET_WALLET_BY_ID: {
    url: generateAdminApiUrl(':id/wallets/:walletId', ModelPath.Partner),
    method: 'GET',
  },
  SET_WALLET_AS_DEFAULT: {
    url: generateAdminApiUrl(
      ':id/wallets/:walletId/default',
      ModelPath.Partner,
    ),
    method: 'PATCH',
    twoFactorAuth: true,
  },
  GET_REFERRALS: {
    url: generateAdminApiUrl(':id/referrals', ModelPath.Partner),
    method: 'GET',
  },
  GET_POSTBACKS: {
    url: generateAdminApiUrl(':id/postbacks', ModelPath.Partner),
    method: 'GET',
  },
  GET_POSTBACK_BY_ID: {
    url: generateAdminApiUrl(':id/postbacks/:postbackId', ModelPath.Partner),
    method: 'GET',
  },
  UPDATE_POSTBACK_BY_ID: {
    url: generateAdminApiUrl(':id/postbacks/:postbackId', ModelPath.Partner),
    method: 'PATCH',
  },
  CREATE_POSTBACK: {
    url: generateAdminApiUrl(':id/postbacks', ModelPath.Partner),
    method: 'POST',
  },
  DELETE_POSTBACK_BY_ID: {
    url: generateAdminApiUrl(':id/postbacks/:postbackId', ModelPath.Partner),
    method: 'DELETE',
  },
} as const;

export default Partner;
