import cloneDeep from 'lodash/cloneDeep';
import toNumber from 'lodash/toNumber';
import mapKeys from 'lodash/mapKeys';
import assign from 'lodash/assign';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import map from 'lodash/map';

import { LandingsActions } from '../actions/types';
import ILandingsData from 'models/ILandingsData';
import { LandingActionTypes } from 'core/redux/constants/LandingActionTypes';
import { Constants } from 'constants/Constants';
import { ILanding } from 'models/ILanding';

const initialState: ILandingsData = {
  products: {},
  offers: {},
};

export default function landingsReducer(
  state = initialState,
  action: LandingsActions,
): ILandingsData {
  let landing: ILanding;
  let newState;
  switch (action.type) {
    case LandingActionTypes.Set:
      return assign({}, initialState, action.payload);
    case LandingActionTypes.Update:
      return assign({}, initialState, {
        products: assign({}, state.products, action.payload?.products),
        offers: assign({}, state.offers, action.payload?.offers),
        allDataLoadTime:
          action.payload?.allDataLoadTime ?? state.allDataLoadTime,
      });
    case LandingActionTypes.UpdateOne:
      landing = action.payload;
      newState = cloneDeep(state);
      if (landing.offer_id) {
        const data = newState.offers[landing.offer_id]?.data;
        if (data) {
          newState.offers[landing.offer_id].data = map(data, (d) => {
            if (d.id === landing.id) {
              return landing;
            }
            return d;
          });
        }
      } else if (landing.product_id) {
        const data = newState.products[landing.product_id]?.data;
        if (data) {
          newState.products[landing.product_id].data = map(data, (d) => {
            if (d.id === landing.id) {
              return landing;
            }
            return d;
          });
        }
      }
      return newState;
    case LandingActionTypes.Delete:
      return assign({}, initialState, {
        products: assign({}, omit(state.products, action.payload.products)),
        offers: assign({}, omit(state.offers, action.payload.offers)),
        allDataLoadTime: state.allDataLoadTime,
      });
    case LandingActionTypes.DeleteOne:
      landing = action.payload;
      newState = cloneDeep(state);
      if (landing.offer_id) {
        const data = newState.offers[landing.offer_id]?.data;
        if (data) {
          newState.offers[landing.offer_id].data = filter(data, (d) => {
            return d.id !== landing.id;
          });
        }
      } else if (landing.product_id) {
        const data = newState.products[landing.product_id]?.data;
        if (data) {
          newState.products[landing.product_id].data = filter(data, (d) => {
            return d.id !== landing.id;
          });
        }
      }
      return newState;
    case LandingActionTypes.DeleteAll:
      return assign({}, initialState, {
        lastCleaningTime: new Date().getTime(),
      });
    case LandingActionTypes.DeleteExpired:
      const products: ILandingsData['products'] = {};
      const offers: ILandingsData['offers'] = {};
      const currentTime = new Date().getTime();
      const allDataLoadTime = state.allDataLoadTime;
      const allDataIsExpired =
        !isNil(allDataLoadTime) &&
        currentTime - allDataLoadTime > Constants.LandingsCacheTime;
      if (!allDataIsExpired) {
        mapKeys(state.products, (value, key) => {
          const isExpired =
            currentTime - value.loadTime > Constants.LandingsCacheTime;
          if (!isExpired) {
            products[toNumber(key)] = value;
          }
        });
        mapKeys(state.offers, (value, key) => {
          const isExpired =
            currentTime - value.loadTime > Constants.LandingsCacheTime;
          if (!isExpired) {
            offers[toNumber(key)] = value;
          }
        });
      }
      return assign({}, initialState, {
        products: products,
        offers: offers,
        lastCleaningTime: currentTime,
        allDataLoadTime: allDataIsExpired ? undefined : allDataLoadTime,
      });
    default:
      return state;
  }
}
