export default {
  news: 'Новости',
  all_news: 'Все новости',
  add_news: 'Добавить новость',
  edit_news: 'Изменить новость',
  no_news: 'Нет новостей',
  header: 'Заголовок',
  text: 'Текст',
  news_remove_confirm: 'Уверены, что хотите удалить Новость?',
};
