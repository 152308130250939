export enum OfferPartnersActionTypes {
  FetchRequests = 'offerPartners/FETCH_REQUESTS',
  SetLoading = 'offerPartners/SET_LOADING',
  SetRequestsLoading = 'offerPartners/SET_REQUEST_LOADING',
  InitSending = 'offerPartners/INIT_SENDING',
  SetRequests = 'offerPartners/SET_REQUESTS',
  ApproveRequest = 'offerPartners/APPROVE_REQUEST',
  SetRequestsStatus = 'offerPartners/SET_REQUEST_STATUS',
  RejectRequest = 'offerPartners/REJECT_REQUEST',
}
