import { generateAdvertiserApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Contact = {
  GET_CONTACTS: {
    url: generateAdvertiserApiUrl(ModelPath.UserContact),
    method: 'GET',
  },
} as const;

export default Contact;
