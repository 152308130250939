import { generateAdvertiserApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Dict = {
  GET_COUNTRIES: {
    url: generateAdvertiserApiUrl(ModelPath.Country),
    method: 'GET',
  },
  GET_DICTIONARY: {
    url: generateAdvertiserApiUrl(ModelPath.Dictionary),
    method: 'GET',
  },
} as const;

export default Dict;
