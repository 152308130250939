import keys from 'lodash/keys';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import isUndefined from 'lodash/isUndefined';

export default function getObjectOnlyValidValues(
  obj: Record<any, any>,
  removeUndefined = true,
): Record<any, any> {
  const validObject: Record<any, any> = {};
  const objKeys = filter(keys(obj), (key) => {
    const keep = !removeUndefined || !isUndefined(obj[key]);
    return keep;
  });

  forEach(objKeys, (key) => {
    validObject[key] = obj[key];
  });

  return validObject;
}
