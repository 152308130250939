import { generatePartnerApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Postback = {
  GET_POSTBACKS: {
    url: generatePartnerApiUrl(ModelPath.Postback),
    method: 'GET',
  },
  GET_POSTBACK_BY_ID: {
    url: generatePartnerApiUrl(':id', ModelPath.Postback),
    method: 'GET',
  },
  UPDATE_POSTBACK_BY_ID: {
    url: generatePartnerApiUrl(':id', ModelPath.Postback),
    method: 'PATCH',
  },
  CREATE_POSTBACK: {
    url: generatePartnerApiUrl(ModelPath.Postback),
    method: 'POST',
  },
  DELETE_POSTBACK_BY_ID: {
    url: generatePartnerApiUrl(':id', ModelPath.Postback),
    method: 'DELETE',
  },
} as const;

export default Postback;
