export default {
  date_or_period: 'Fecha o período',
  offer: 'Oferta',
  country: 'País',
  advertiser: 'Anunciante',
  product: 'Producto',
  offer_and_product: '$t(statistics:offer)/$t(statistics:product)',
  partner: 'Socio',
  no_statistics: 'Sin estadísticas',
  cohort_no_statistics:
    'Para generar un análisis de cohortes, configure los ajustes de la cohorte, los parámetros de filtrado y haga clic en el botón "Aplicar"',
  statistics: 'Estadísticas',
  common: 'General',
  sub_id: 'Sub ID',
  custom_field: 'Custom Field',
  show_filter_by_sub_id: 'Mostrar filtros por Sub ID',
  show_filter_by_custom_field: 'Mostrar filtros por Custom',
  conversions: 'Conversiones',
  click_id: 'ID de clic',
  clicks: 'Clics',
  unique_clicks: 'Único. clicks',
  traffic: 'Tráfico',
  referer: 'Referencia de clic',
  qualifications: 'Cualificaciones',
  conversion_id: 'ID de conversión',
  goal: 'Objetivo',
  events: 'Eventos',
  event_id: 'ID del evento',
  event_key: 'Clave del evento',
  event: 'Evento',
  idempotency_key: 'Clave de idempotencia',
  landing: 'Landing',
  date: 'Fecha',
  value: 'Significado',
  traffic_source: 'Fuente de tráfico',
  traffic_source_lead_help:
    'Al agrupar por Sub ID 1 - Sub ID 10, puede elegir la fuente de tráfico, ya sea Clics y Códigos promocionales o Leads externos.',
  traffic_source_click_disabled_help:
    'Para seleccionar Clics, anule la selección de Leads externos.',
  traffic_source_promo_disabled_help:
    'Para seleccionar los Códigos Promocionales, anule la selección de Leads Externos.',
  traffic_source_leads_disabled_help:
    'Para seleccionar Leads externos, anule la selección de Clics y Códigos promocionales.',
  link_source: 'Fuente de referencia',
  qualification_id: 'ID de calificación',
  benefit: 'Remuneración',
  denied: 'Cancelado',
  deny: 'Cancelar',
  group: 'Agrupar',
  by: 'Por',
  city: 'Ciudad',
  group_by: 'Agrupar por',
  group_by_add: '+ Agrupación',
  group_by_day: 'días',
  group_by_hour: 'reloj',
  group_by_offer: 'ofertas',
  group_by_advertiser: 'anunciantes',
  group_by_product: 'productos',
  group_by_partner: 'socios',
  group_by_geo: 'países',
  group_by_city: 'ciudades',
  group_by_os: 'sistemas operativos',
  group_by_device: 'dispositivos',
  group_by_landing: 'aterrizajes',
  group_by_offer_tags: 'etiquetas de oferta',
  group_by_partner_tags: 'etiquetas de socio',
  group_by_promocodes: 'códigos promocionales',
  group_by_sub_id: 'Sub ID {{subIdNumber}}',
  group_by_custom_field: 'custom{{customNumber}}',
  group_by_days_7: '7 días',
  group_by_days_10: '10 días',
  group_by_days_15: '15 días',
  group_by_month: 'meses',
  sub_id_number: 'Sub ID {{subIdNumber}}',
  custom_field_number: 'custom{{customNumber}}',
  column_date: 'Fecha',
  column_hour: 'Hora',
  column_month: 'Mes',
  column_offer: 'Oferta',
  column_count: 'Número',
  column_advertiser: 'Anunciante',
  column_product: 'Producto',
  column_partner: 'Socio',
  column_geo: 'País',
  column_city: 'ciudad',
  column_os: 'Sistema operativo',
  column_device: 'Dispositivo',
  column_landing: 'Landing',
  column_tag: 'Etiquetas',
  column_region: 'Región',
  column_region_all: '$t(statistics:column_city)/$t(statistics:column_region)',
  group_empty: 'No rellenado',
  group_unknown: 'Desconocido',
  qualification_deny_confirm: '¿Desea anular la calificación?',
  incomes: 'Ingresos',
  payouts: 'Pagos',
  profit: 'Beneficios',
  show_events: 'Mostrar eventos',
  show_conversions: 'Mostrar conversiones',
  offer_goals: 'Objetivos de la oferta',
  offer_conversions: 'Conversiones de oferta {{offer}}',
  product_events: 'Eventos de producto {{product}}',
  amount: 'Cantidad',
  amount_in_dollars: 'Cantidad en dólares',
  amount_in_original_currency: 'Importe en moneda original',
  name_and_flag: 'Nombre y bandera',
  date_and_time: 'Fecha y hora',
  date_and_time_of_click: 'Fecha y hora del clic',
  date_and_time_all:
    '$t(statistics:date_and_time)/$t(statistics:date_and_time_of_click)',
  condition_id: 'ID de la condición',
  its_number_in_the_database: 'Número de base de datos',
  for_webmaster: 'Para el webmaster',
  payout_for_webmaster: 'Pago al webmaster',
  amount_and_currency: 'Cantidad y moneda',
  tag: 'Etiquetas',
  offer_tags: 'Etiquetas de oferta',
  partner_tags: 'Etiquetas de socio',
  timezone: 'Zona horaria',
  cohort_analysis: 'Análisis de cohortes',
  cohort_settings: 'Configuración de la cohorte',
  cohort_period: 'Período de cohorte',
  cohort_condition: 'Condición de la cohorte',
  cohort_period_group: 'Agrupación de cohortes',
  logs: 'Logis',
  grouping_cohort_day: 'Por días',
  grouping_cohort_week: 'Por semana',
  grouping_cohort_month: 'Por Meses',
  cohort_condition_clicks: 'Clics',
  common_statistics: 'Estadísticas generales',
  webmaster: 'Webmaster',
  qualification: 'Calificación',
  conversion_name: 'Nombre de la conversión',
  lead_id: 'Lead ID',
  name: 'Nombre',
  hint: 'Pista',
  formula: 'Fórmula',
  add_custom_column: 'Añadir campo personalizado',
  edit_custom_column: 'Modificar campo personalizado',
  add_formula: 'Añadir fórmula',
  edit_formula: 'Modificar fórmula',
  formula_output_type_amount: 'Cantidad',
  formula_output_type_average: 'Medio',
  variables_click: 'Tráfico',
  variables_event: 'Eventos',
  variables_goal: 'Objetivos',
  variables_conversion: 'Conversiones',
  variables_column: 'Valor de la columna',
  variables_column_disabled:
    'Una columna utiliza el valor de la columna actual en su fórmula. Las columnas no pueden referirse entre sí.',
  variable_click_count: 'Anfitriones',
  variable_click_unique_count: 'Clics únicos',
  variable_count: '<span class="variable-count">Cuenta</span>',
  variable_amount: '<span class="variable-amount">Importe</span>',
  variable_count_of: '<span class="variable-count">Cuenta</span>',
  variable_amount_of: '<span class="variable-amount">Importe</span>',
  variable_event_count: '$t(statistics:variable_count_of) eventos',
  variable_event_sum: '$t(statistics:variable_amount_of) eventos',
  variable_goal_count: '$t(statistics:variable_count_of) conversiones',
  variable_goal_sum: '$t(statistics:variable_amount_of) conversiones',
  variable_event_group_count:
    '$t(statistics:variable_count). Grupos de eventos',
  variable_event_group_sum: '$t(statistics:variable_amount). Grupos de eventos',
  variable_goal_group_count:
    '$t(statistics:variable_count). Grupos de objetivos',
  variable_goal_group_sum:
    '$t(statistics:variable_amount). Grupos de objetivos',
  variable_conversion_count: 'Número de conversiones',
  variable_conversion_income: 'Ingresos por conversiones con el modelo de CPA',
  variable_conversion_amount: 'Pagos por conversiones con el modelo CPA',
  variable_conversion_count_0: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_0: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_0: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_1: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_1: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_1: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_2: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_2: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_2: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_3: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_3: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_3: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_4: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_4: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_4: '$t(statistics:variable_conversion_amount)',
  variable_conversion_revshare_income:
    'Ingresos por conversiones con el modelo Revshare',
  variable_conversion_revshare_amount:
    'Pagos por conversiones con el modelo Revshare',
  variable_conversion_cpm_income: 'Ingresos por conversiones con el modelo CPM',
  variable_conversion_cpm_amount: 'Pagos por conversiones con el modelo CPM',
  variable_conversion_cpm_count: 'Número de acciones (CPM)',
  variable_active_users_count: 'Número de usuarios activos',
  qualification_0: 'espera',
  qualification_1: 'pagado',
  qualification_2: 'rechazado',
  qualification_3: 'por encima del límite',
  qualification_4: 'Hold',
  add_column: 'Añadir columna',
  column_clicks: 'Número total de clics',
  column_unique_clicks: 'Número de clics únicos',
  column_income: 'Ingresos',
  column_qualifications_count_payed:
    'Número de conversiones en estado "Confirmado"',
  column_conversions_count_payed:
    'Número de conversiones en estado "Confirmado"',
  column_income_payed: 'Ingresos por conversiones en estado "Confirmado"',
  column_payments: 'Pagos',
  column_payments_payed: 'Pagos por conversiones en estado "Confirmado"',
  table_settings: 'Configuración de la tabla',
  default_cohort_settings_tooltip:
    'El análisis de cohortes no está configurado. Personalice la tabla de análisis de cohortes agregando columnas con las métricas requeridas.',
  clear_all: 'Limpiar',
  admin_scheme: 'Tabla para administradores',
  partner_scheme: 'Tabla de socios',
  reset_table_scheme: '¿Restaurar la configuración predeterminada de la tabla?',
  cohort_select_product: 'Seleccione un producto para el análisis de cohortes',
  status_description: 'Razón de la desviación',
  revshare_model: 'Revshare',
  payment_model: 'Modelo de pago',
  conversions_form_revshare_warning:
    'Entre los seleccionados se encuentra una conversión con el modelo de pago <span class="font-weight-bold">Revshare</span>. No habrá cambios en el pago ni en los ingresos correspondientes.',
  tid: 'ID de transacción',
  in_pending: 'En previsión',
  in_hold: 'Hold',
  in_confirmed: 'Confirmado',
  in_rejected: 'Rechazada',
  pay_out: 'Pagar',
  partner_manager: 'Gerente de socios',
  cancel: 'Cancelar',
  browser: 'Navegador',
  os: 'OS',
  os_version: 'Versión del OS',
  click_ip: 'IP de clic',
  device_type: 'Tipo de dispositivo',
  goal_key: 'Clave del objetivo',
  payout_value: 'Monto del pago',
  payout_currency: 'Moneda de pago',
  income_value: 'Cantidad de ingresos',
  income_currency: 'Moneda de ingresos',
  note: 'Nota',
  changes_in_data_affect_profits:
    'Los cambios en los datos afectan a las ganancias',
  conversions_changed: 'Conversiones cambiadas con éxito',
  all_conversions_changed:
    '{{count}} primeras conversiones cambiadas con éxito',
  select_all_conversions_1: 'Seleccionar',
  select_all_conversions_2: 'conversiones',
  no_landing: 'Sin aterrizaje',
  for: 'Para',
  for_selected_set: 'dedicado instalar:',
  edited_by_admin: 'Editado por el administrador',
  custom_column_formula_placeholder:
    'Introduzca la fórmula para contar el valor de la columna usando los parámetros y operaciones que se enumeran a continuación',
  output_format: 'Formato de salida',
  output_format_type: 'Formato de salida "Total"',
  output_format_help:
    'Escriba cualquier texto y agregue prefijos. Este formato mostrará la información en las estadísticas.',
  per_page: 'en la página',
  conversions_import_main_button: 'Cambiar conversiones a través de CSV',
  conversions_import_title: 'Cambiar la conversión a través de CSV',
  conversions_import_button: 'Cambiar conversiones',
  conversions_import_go_back: 'Volver a las estadísticas',
  conversions_import_file_accepted: 'Archivo aceptado',
  conversions_import_file_not_processed: 'Archivo sin procesar',
  conversions_import_success:
    'Archivo aceptado. El procesamiento llevará algún tiempo. Todas las conversiones que se encuentren en el archivo serán cambiadas.',
  conversions_import_fail_1:
    'Se han encontrado errores críticos en el archivo.',
  conversions_import_fail_2: 'Las conversiones no se pueden cambiar.',
  conversions_import_help_1:
    'Los nuevos parámetros se aplicarán a las conversiones existentes.',
  conversions_import_help_2: 'No se crearán nuevas conversiones.',
  conversions_import_help_3:
    "Utilice separadores (coma ',' o punto y coma ';') en su archivo CSV. No se pueden especificar dos separadores simultáneamente en el mismo archivo.",
  filter_promo_code: 'Código Promocional',
  filter_promo_code_user_id: 'ID de usuario',
  filter_fraud_risk_level: 'Nivel de riesgo de fraude',
  for_admins: 'Para administradores',
  for_partners: 'Para socios',
  all_partners: 'todo',
  table_set_as_default: 'La tabla está configurada por defecto',
  default_for_admins: 'Predeterminado para administradores',
  default_for_partners: 'Predeterminado para los socios',
  set_default_for_admins: 'Establecer como predeterminado para administradores',
  set_default_for_partners:
    'Establecer como predeterminado para administradores',
  table_for_admins: 'Tabla para administradores',
  cohort_table_for_admins: 'Análisis de cohortes',
  table_for_partners: 'Tabla de socios',
  new_table_for_admins: 'Nueva tabla para administradores',
  new_table_for_partners: 'Nueva tabla de asociados',
  new_table_limit_title: 'Límite alcanzado',
  new_table_limit_text:
    'Puedes crear un máximo de {{count}} hojas para administradores o socios. Elimine las hojas innecesarias y cree nuevas en su lugar.',
  limit_placeholder: 'Desde 1 hasta 1 000 000',
  table_default_partners:
    'La tabla básica está disponible para todos los socios',
  scheme_data_settings: 'Ajustes',
  scheme_data_duplicate: 'Duplicar tabla',
  scheme_remove_confirm: 'Desea eliminar una tabla',
  scheme_remove_default_title: 'No se puede eliminar la tabla',
  scheme_remove_default_text_admins:
    'Esta es la tabla predeterminada para todos los administradores. Para eliminarla, primero establezca otra tabla predeterminada.',
  scheme_remove_default_text_partners:
    'Esta es la tabla predeterminada para todos los socios. Para eliminarla, primero establezca otra tabla predeterminada.',
  status_note: 'Nota',
  comment_to_partner: 'Comentario a un socio',
  comment: 'Comentario',
  to_group: 'Agrupar',
  group_name: 'Nombre del grupo',
  group_together: 'Agrupar juntos',
  add_to_group: 'Añadir al grupo',
  conversions_columns_settings: 'Configurar columnas',
  table_columns: 'Columnas de tabla',
  deleted: 'eliminado',
  customers_statistics: 'Clientes',
  customers_goal_group: 'Grupo de objetivos',
  customers_user: 'Cliente',
  invoice_statuses: 'Estado del pago',
  status_not_paid: 'No pagado',
  status_awaiting_payment: 'Pago pendiente',
  status_payed: 'Pagado',
  customers_cohort_period:
    'Período de conversión para el grupo de objetivos seleccionado',
  share: 'Сompartir',
  link_copied: 'Enlace copiado en el portapapeles',
  to_filter: 'Filtro',
  go_to_card: 'Ir a la pagina',
  go_to_card_offer: 'Ir a la ficha de la oferta',
  go_to_card_partner: 'Ir a la tarjeta de socio',
};
