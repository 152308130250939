import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Profile = {
  GET_PROFILE: {
    url: generateAdminApiUrl(ModelPath.Profile),
    method: 'GET',
  },
  UPDATE_PROFILE: {
    url: generateAdminApiUrl(':profileId', ModelPath.Admin),
    method: 'PATCH',
    bodyMapping: [
      'email',
      'name',
      'surname',
      'profile_fields',
      { key: 'roles', optional: true },
    ],
  },
  CHANGE_PASSWORD: {
    url: generateAdminApiUrl('password/change'),
    method: 'PATCH',
    bodyMapping: ['old_password', 'new_password', 'new_password_confirmation'],
  },
  UPDATE_API_KEY: {
    url: generateAdminApiUrl('refresh_api_key'),
    method: 'PATCH',
  },
  LOGOUT: {
    url: generateAdminApiUrl('logout'),
    method: 'POST',
  },
} as const;

export default Profile;
