export default {
  new_landing: 'Nuevo aterrizaje',
  edit_landing: 'Editar landing',
  no_landings_list: 'No hay lista de aterrizajes creados',
  name: 'Nombre',
  target_link: 'Target-enlace',
  target_link_hint:
    '{click_id} - ID de clic único;\n{partner_id} - ID de socio;\n{offer_id} - ID de oferta;\n{creative_id} - ID de material promocional;\n{sub_id1..10} - SubId #1 - SubId #10.',
  name_of_landing: 'Nombre del Landing',
  name_of_landing_hint: 'De 3 a 128 caracteres',
  URL: 'URL',
  delete_landing: 'Eliminar landing',
  do_you_want_to_delete: '¿Quieres eliminar',
  landing: 'aterrizaje',
  type: 'Tipo',
  landing_successfully_deleted: 'Landing eliminado con éxito',
  landing_successfully_created: 'Landing creado con éxito',
  landing_successfully_updated: 'Landing actualizado con éxito',
  private: 'Privado',
  partners_help:
    'Este aterrizaje estará disponible para los socios seleccionados en el enlace de seguimiento',
  undated: 'Ilimitado',
  undated_help:
    'Si se selecciona, la página de destino estará activa a partir de la fecha indicada a la izquierda.',
  is_enabled: 'Activo',
  is_availible: 'A disposición de los socios',
  date_from: 'Activo de ',
  date_to: 'Por',
  status_active: 'Activo',
  status_inactive: 'Inactivo',
};
