import cloneDeep from 'lodash/cloneDeep';
import defaultTo from 'lodash/defaultTo';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import assign from 'lodash/assign';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import {
  SET_MODEL,
  MODEL_PUSH,
  MODEL_DELETE,
  MODEL_UPDATE,
} from 'core/redux/constants/models';
import { IAction } from 'models/IAction';
import { ModelChangeOptionsType } from 'core/redux/actions/models';

function updateArrayModel(state: any[] | null, { payload }: IAction) {
  return map(state, (item: any) => {
    const modelFieldId = get(payload, 'options.modelFieldId', 'id');

    const isSameItem = (data: any): boolean => {
      return (
        get(item, modelFieldId) === get(data, modelFieldId) ||
        get(item, modelFieldId) === get(payload, 'options.modelId')
      );
    };

    if (isArray(payload.datasource) && !isEmpty(payload.datasource)) {
      for (let i = 0; i < payload.datasource.length; i++) {
        const datasourceElement = payload.datasource[i];
        if (isSameItem(datasourceElement)) {
          return assign({}, item, datasourceElement);
        }
      }
    } else if (isSameItem(payload.datasource)) {
      return assign({}, item, payload.datasource);
    }
    return item;
  });
}

function updateObjectModel(state = null, { payload }: IAction) {
  const preparedDatasource = isArray(payload.datasource)
    ? payload.datasource
    : pickBy(payload.datasource, (v: any) => !isNil(v));
  let valueToAssign = {};

  if (payload.options?.path) {
    set(valueToAssign, payload.options.path, preparedDatasource);
  } else {
    valueToAssign = preparedDatasource;
  }

  return assign({}, state, valueToAssign);
}

function resolveModelState(state: any = null, action: IAction) {
  const options: ModelChangeOptionsType = action.payload.options;
  const path = options?.path;
  switch (action.type) {
    case SET_MODEL:
      return action.payload.datasource;
    case MODEL_PUSH:
      const addDatasource = (oldState?: Array<unknown> | null) => {
        if (isArray(action.payload.datasource)) {
          return options?.place === 'top'
            ? [...action.payload.datasource, ...(oldState || [])]
            : [...(oldState || []), ...action.payload.datasource];
        }
        return options?.place === 'top'
          ? [action.payload.datasource, ...(oldState || [])]
          : [...(oldState || []), action.payload.datasource];
      };
      if (path) {
        const newState = Object.assign({}, state || {});

        set(newState, path, addDatasource(get(state, path, [])));

        return newState;
      }

      return addDatasource(state);
    case MODEL_UPDATE:
      let newState = undefined;
      let changingState = state;
      if (path) {
        changingState = get(state, path);
      }
      if (isArray(changingState)) {
        newState = updateArrayModel(changingState, action);
      } else {
        newState = updateObjectModel(changingState, action);
      }

      if (path) {
        const stateCopy = cloneDeep(state);
        set(stateCopy, path, newState);
        newState = stateCopy;
      }

      return newState;
    case MODEL_DELETE:
      const modelFieldId = defaultTo(options?.modelFieldId, 'id');
      const filterFn = (item: any) => {
        if (options?.modelId) {
          return item[modelFieldId] !== options.modelId;
        } else {
          return item[modelFieldId] !== action.payload.datasource[modelFieldId];
        }
      };

      if (path) {
        const newState = Object.assign({}, state || {});
        set(newState, path, filter(get(newState, path, []), filterFn));

        return newState;
      }

      const stateToFilter = defaultTo(path ? get(state, path) : state, []);

      return filter(stateToFilter, filterFn);
    default:
      return state;
  }
}

export default function modelsReducer(
  state: { [key: string]: any } = {},
  action: IAction,
) {
  const modelId = action.payload?.modelId;

  switch (action.type) {
    case SET_MODEL:
    case MODEL_PUSH:
    case MODEL_DELETE:
    case MODEL_UPDATE:
      return Object.assign({}, state, {
        [modelId]: resolveModelState(state[modelId], action),
      });
    default:
      return state;
  }
}
