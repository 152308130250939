import isPlainObject from 'lodash/isPlainObject';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import assign from 'lodash/assign';
import set from 'lodash/set';

/**
 * Return flattened object.
 * For example for given object { a: { b: 1, c: 2 }, d: { e: 3 } }
 * function return { "a[b]": 1, "a[c]": 2, "d[e]": 3 }
 * @param key - object name or nested object key
 * @param obj - object for flattening
 */
export function flattenNestedObject(key: string | null, obj: any): any {
  return Object.assign(
    {},
    ...(function _flatten(o: any): any {
      return [].concat(
        ...Object.keys(o).map((k) => {
          if (typeof o[k] && isPlainObject(o[k])) {
            return flattenNestedObject(k, o[k]);
          } else {
            return { [key ? `${key}[${k}]` : k]: o[k] };
          }
        }),
      );
    })(obj),
  );
}

/**
 * Merges multiple objects deeply into a single object.
 *
 * @param {any} target - The target object to merge into.
 * @param {...any} sources - The source objects to merge.
 * @return {any} - The merged object.
 */
export function mergeObjectsDeep(target: any, ...sources: any[]): any {
  if (!sources.length) return target;
  const source: any = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      const sourceElement = get(source, key);
      if (isObject(sourceElement)) {
        const targetElement = get(target, key);
        if (!targetElement) {
          assign(target, { [key]: {} });
        } else {
          set(target, key, assign({}, targetElement));
        }
        mergeObjectsDeep(get(target, key), sourceElement);
      } else {
        assign(target, { [key]: sourceElement });
      }
    }
  }

  return mergeObjectsDeep(target, ...sources);
}
