import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const Dashboard = {
  GET_ALL: {
    url: generateAdminApiUrl(ModelPath.Dashboard),
    method: 'GET',
  },
  CREATE: {
    url: generateAdminApiUrl(ModelPath.Dashboard),
    method: 'POST',
  },
  GET: {
    url: generateAdminApiUrl(':id', ModelPath.Dashboard),
    method: 'GET',
  },
  UPDATE: {
    url: generateAdminApiUrl(':id', ModelPath.Dashboard),
    method: 'PATCH',
  },
  DELETE: {
    url: generateAdminApiUrl(':id', ModelPath.Dashboard),
    method: 'DELETE',
  },
  CREATE_WIDGET: {
    url: generateAdminApiUrl(':id/widgets', ModelPath.Dashboard),
    method: 'POST',
  },
  UPDATE_WIDGET: {
    url: generateAdminApiUrl(':id/widgets/:widgetId', ModelPath.Dashboard),
    method: 'PATCH',
  },
  DELETE_WIDGET: {
    url: generateAdminApiUrl(':id/widgets/:widgetId', ModelPath.Dashboard),
    method: 'DELETE',
  },
  SET_DEFAULT_FOR_PARTNERS: {
    url: generateAdminApiUrl('dashboard', ModelPath.Settings),
    method: 'PATCH',
  },
} as const;

export default Dashboard;
