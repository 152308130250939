export default {
  promo_codes: 'Promo codes',
  promo_types: 'Types',
  add_new_type: 'New type',
  add_new_promo_code: 'New promo code',
  delete_type: 'Delete type',
  add_type: 'Add type',
  edit_type_properties: 'Edit type properties',
  type_editing: 'Editing the type',
  type_adding: 'Addition a type',
  type_successfully_created: 'The type successfully added',
  type_successfully_updated: 'The type successfully updated',
  type_form_product: 'Product',
  type_form_name: 'Name',
  type_form_description: 'Description',
  type_form_poster: 'Poster',
  type_form_upload: 'Upload',
  type_delete_title: 'Delete type?',
  type_delete_message:
    'Are you sure you want to remove Promocode type? With the type, all its promo codes, including active ones, will be deleted.',
  type_delete_button: 'Yes, delete',
  column_promo_code: 'Promo code',
  column_limit: 'Used / Total',
  column_partner_offer: 'Partner & Offer',
  column_offer: 'Offer',
  column_type_product: 'Type & Product',
  column_description: 'Description',
  promo_code_personal: 'Personal',
  promo_code_end_limit: 'Usage limit reached',
  promo_code_expired: 'Expired',
  promo_code_assign: 'Assign',
  promo_code_unlimited: 'Unlimited',
  promo_code_import_csv: 'Import from CSV',
  promo_code_create_personal: 'Create personal',
  promo_code_filter_promo_code: 'Promo code',
  promo_code_filter_offer: 'Offer',
  promo_code_filter_type: 'Type',
  promo_code_filter_partner: 'Partner',
  promo_code_filter_product: 'Product',
  promo_code_filter_assignment: 'Assignment',
  promo_code_filter_reset: 'Reset all filters',
  promo_code_no_data: 'There is no data',
  promo_code_successfully_created: 'The promo code successfully added',
  promo_code_successfully_updated: 'The promo code successfully updated',
  promo_code_successfully_assigned: 'The promo code successfully assigned',
  promo_code_delete_title: 'Delete promo code?',
  promo_code_form_title_add: 'Personal promo code',
  promo_code_form_title_edit: 'Edit promo code',
  promo_code_form_title_assign: 'Assign promo code',
  promo_code_form_active_from: 'Active from',
  promo_code_form_active_to: 'to',
  promo_code_form_conversions_limit: 'Usage limit',
  promo_code_form_type_placeholder: 'Choose type...',
  promo_code_form_period: 'Period',
  promo_code_form_conversions: 'Usage limit',
  promo_code_form_assign_code: 'Assign code',
  promo_code_form_import_title: 'Import promo codes',
  promo_code_form_import_codes: 'Import codes',
  promo_code_form_template: 'Get template',
  promo_code_form_import_upload_csv: 'Upload CSV',
  promo_code_form_import_help_1:
    'New codes will be added to list of available codes of this type.',
  promo_code_form_import_help_2:
    'For existing codes, the new parameters (limit and validity period) will be applied.',
  promo_code_form_import_help_3:
    'Changes will be applied to both free and assigned promotional codes.',
  promo_code_form_import_success: 'Import completed',
  promo_code_form_import_fail: 'Import failed',
  promo_code_form_go_to_list: 'Go to list',
  promo_code_form_try_again: 'Try again',
  promo_code_form_imported: 'imported',
  promo_code_form_processing_errors: 'processing errors',
  promo_code_form_fail_message:
    'There are critical errors at the file. \nPromo codes with these errors can’t be imported.',
  promo_code_import_table_number: 'Line Number',
  promo_code_import_table_field: 'Field',
  promo_code_import_table_message: 'Error message',
  promo_code_copied: 'Promo code is copied',
  promo_code_search_promo_code: 'Search promo code',
  promo_code_dont_have_promo_codes:
    'You don’t have promo codes with chosen parameters',
  promo_code_to_get_new_promo_code: 'To get new promo code,',
  promo_code_choose_offer: 'choose offer',
  promo_code_open_tab: 'and open tab Promo codes',
  promo_code_why: 'Why are promo codes needed',
  promo_code_why_place:
    'A promo code can be used where it is not possible to place referral links and advertise goods and services (on Instagram, on video, in offline advertising).',
  promo_code_why_registration:
    'A user can enter a promo code when registering on a site, which allows you to automatically associate him with you. In this case, there is no need for a new client to go to the site using an affiliate link.',
  promo_code_why_bonus:
    'When registering with a promo code, a user receives an increased bonus, so he is interested in using it. The conditions for accruing bonuses when registering using a promo code can be clarified with the manager.',
  promo_code_your_promo_code: 'Your promo code',
  promo_code_copy_code: 'Copy code',
  promo_code_get_new_code: 'Get a new code',
  promo_code_maximum: '{{count}} codes is maximum for the offer',
  promo_code_reach_maximum: 'You reached a limit {{count}} codes for the offer',
  promo_code_also_you_can: 'Also you can',
  promo_code_ask_code: 'request a personal promo code',
  promo_code_active_codes: '{{count}} active codes',
  promo_code_maximum_tooltip_1:
    'This restriction applies to all promo codes (personal and regular), but an administrator in special cases can issue additional codes in excess of the limit.',
  promo_code_maximum_tooltip_2:
    'To get an additional or personal code (with a unique name and parameters), click "request a personal promo code".',
  promo_code_request: 'Request a promo code',
  promo_code_request_admin: 'Write to your administrator',
  promo_code_to_telegram: 'in telegram',
  promo_code_request_message:
    'request for additional promo code. Please indicate in your message:',
  promo_code_request_type_name: 'promotion name',
  promo_code_request_period: 'validity period',
  promo_code_request_conversions: 'usage limit',
  promo_code_request_personal:
    'If you need a personal promo code, then also specify the desired promo word.',
  promo_code_partner_empty_types: 'There are no available codes',
  promo_code_partner_empty_types_extra:
    'This offer have no active promotions right now',
  promo_code_partner_offer_empty_promo_codes: 'You have no active promo codes',
  promo_code_partner_offer_empty_promo_codes_extra:
    'To get promo code, click button «Get a code».',
};
