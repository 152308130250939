import { generateAdminApiUrl } from 'utils/apiUtils';
import { ModelPath } from 'routes/ModelPath';

const PromoMaterials = {
  GET_PROMO_MATERIALS: {
    url: generateAdminApiUrl('', ModelPath.PromoMaterial),
    method: 'GET',
  },
  CREATE_PROMO_MATERIAL: {
    url: generateAdminApiUrl(ModelPath.PromoMaterial),
    method: 'POST',
  },
  UPDATE_PROMO_MATERIAL_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.PromoMaterial),
    method: 'PATCH',
  },
  GET_PROMO_MATERIAL_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.PromoMaterial),
    method: 'GET',
  },
  DELETE_PROMO_MATERIAL_BY_ID: {
    url: generateAdminApiUrl(':id', ModelPath.PromoMaterial),
    method: 'DELETE',
  },
} as const;

export default PromoMaterials;
