export enum Dicts {
  Countries = 'countries',
  AdminFieldTypes = 'adminFieldTypes',
  PartnerFieldTypes = 'partnerFieldTypes',
  AdvertiserFieldTypes = 'advertiserFieldTypes',
  Rules = 'rules',
  Tags = 'tags',
  PartnerTags = 'partnerTags',
  UtcOffsets = 'utcOffsets',
  GeneralSettings = 'generalSettings',
  Offers = 'offers',
  OffersActive = 'offersActive',
  Admins = 'admins',
  Roles = 'roles',
  Partners = 'partners',
  PartnersAll = 'partnersAll',
  PartnersActive = 'partnersActive',
  Advertisers = 'advertisers',
  StatisticVariables = 'statisticVariables',
  Products = 'products',
  Currencies = 'currencies',
  CurrenciesAll = 'currenciesAll',
  Browsers = 'browsers',
  Os = 'os',
  OsTypes = 'osTypes',
  GroupedOsTypes = 'groupedOsTypes',
  OfferDeviceTypes = 'offerDeviceTypes',
  DeviceTypes = 'deviceTypes',
  EventGroups = 'eventGroups',
  GoalGroups = 'goalGroups',
  PaymentSystems = 'paymentSystems',
  Languages = 'languages',
  ImgFormats = 'imgFormats',
  ConversionStatusDescriptions = 'conversionStatusDescriptions',
  Categories = 'categories',
  PromoCodeTypes = 'promoCodeTypes',
  PromoCodeGroupedTypes = 'promoCodeGroupedTypes',
  Balances = 'balances',
  ExportTypes = 'exportTypes',
}
