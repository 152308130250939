import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

export default function Error(): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const status = location?.state?.status || '404';
  const stateDescription = location?.state?.description;
  const description =
    stateDescription && !isEmpty(stateDescription)
      ? t(stateDescription)
      : t('common:oops something went wrong here');

  return (
    <div className="error-page">
      <div className="error-page__content">
        <h1 className="error-page__title">{status}</h1>
        <p className="error-page__description">{description}</p>
      </div>
    </div>
  );
}
