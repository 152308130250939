import { takeEvery, delay, put } from 'redux-saga/effects';
import { removeNotificationById } from 'core/redux/actions/notifications';
import { NotificationTypes } from 'core/redux/constants/notifications';
import { IAction } from 'models/IAction';
import { INotification } from 'models/INotification';

const DEFAULT_TIMEOUT = 5000;

function* addNotification({ payload }: IAction<INotification>) {
  const { id, timeout = DEFAULT_TIMEOUT }: INotification = payload;

  if (timeout) {
    yield delay(timeout);
    yield put(removeNotificationById(id));
  }
}

export default [takeEvery(NotificationTypes.ADD_NOTIFICATION, addNotification)];
