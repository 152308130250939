export default {
  dashboard: 'Дашборд',
  products: 'Продукты',
  product: 'Продукт',
  offers: 'Офферы',
  offers_all: 'Все',
  offers_available: 'Доступные',
  statistics: 'Статистика',
  customers: 'Клиенты',
  promo: 'Промоматериалы',
  settings: 'Настройки',
  payments: 'Оплата',
  admins: 'Администраторы',
  advertisers: 'Рекламодатели',
  partners: 'Партнеры',
  wallet: 'Финансы',
  your_manager: 'Ваш менеджер',
  support: 'Поддержка',
  referrals: 'Рефералы',
  members: 'Пользователи',
  api_doc: 'API документация',
};
